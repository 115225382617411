import { list } from 'api/feature-toggles'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect, useState } from 'react'

const FeatureToggleContext = React.createContext()
const WithFeatureToggles = ({ children }) => {
  const { setLoading } = useContext(LoadingContext)
  const [featureToggles, setFeatureToggles] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const toggles = await list()
      if (toggles) {
        setFeatureToggles(toggles)
      }
    }

    fetchData()
  }, [setLoading])

  const hasFeature = feature => {
    return featureToggles && featureToggles.indexOf(feature) !== -1
  }

  const defaultContext = {
    featureToggles,
    hasFeature,
  }
  return (
    <FeatureToggleContext.Provider value={defaultContext}>
      {children}
    </FeatureToggleContext.Provider>
  )
}

export { FeatureToggleContext }
export default WithFeatureToggles
