import Auth from 'containers/layouts/Auth'
import Unauth from 'containers/layouts/Unauth'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext } from 'react'

function App() {
  const { authenticating, authenticated } = useContext(AuthContext)

  if (!authenticating) {
    return authenticated ? <Auth /> : <Unauth />
  }

  return null
}

export default App
