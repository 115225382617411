import { Box, Typography } from '@material-ui/core'
import Link from 'components/Link'
import React from 'react'

function Header({ posts, linkPrefix }) {
  return (
    <Box alignItems='center'>
      {posts.meta.category.parent_category.id && (
        <React.Fragment>
          <Link
            to={`/${linkPrefix}/categories/${posts.meta.category.parent_category.id}`}>
            <Typography variant='h6'>
              {posts.meta.category.parent_category.name}
            </Typography>
          </Link>
        </React.Fragment>
      )}

      <Typography variant='h3' gutterBottom>
        {posts.meta.category.name}
      </Typography>
    </Box>
  )
}

export default Header
