import { Box, Button, IconButton, Tooltip } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import LaunchIcon from '@material-ui/icons/Launch'
import ReplayIcon from '@material-ui/icons/Replay'
import MuiAlert from '@material-ui/lab/Alert'
import { getQueryStringFromFiltersObject } from 'api/helpers'
import { deleteNumberPort, geographicPortResubmit, list } from 'api/number-porting'
import UpdateStatusModal from 'components/number-porting/geographic-port/UpdateStatusModal'
import NumberPortStatusChip from 'components/number-porting/NumberPortStatusChip'
import TableSearchBox from 'components/TableSearchBox'
import TableToolbar from 'components/TableToolbar'
import { AuthContext } from 'contexts/AuthContext'
import { LoadingContext } from 'contexts/LoadingContext'
import debounce from 'lodash.debounce'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

const storageKey = 'port-filters-v3'

const statusesList = {
  unsubmitted: 'Unsubmitted',
  in_progress: 'In Progress',
  rejected: 'Rejected',
  cancelled: 'Cancelled',
  accepted: 'Accepted',
  completed: 'Completed',
}

const tagsList = ['IPEX', 'Non-Geographic', 'Mixed Operator']

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  pagination: {
    marginBottom: '60px',
  },
  filterStatus: {
    width: 300,
  },
}))

function List(props) {
  const classes = useStyles()
  const { loading, setLoading } = useContext(LoadingContext)
  const { user } = useContext(AuthContext)
  const { addToast } = useToasts()
  const [numberPorts, setNumberPorts] = useState({})
  const [resubmitOpen, setResubmitOpen] = useState(false)
  const [resubmitId, setResubmitId] = useState('')
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [filters, setFilters] = useState()

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)

      const newFilters = localStorage.getItem(storageKey)
        ? JSON.parse(localStorage.getItem(storageKey))
        : { search: '', status: [], type: 'all', tags: [] }

      setFilters(newFilters)
      setNumberPorts(await list(15, 1, newFilters))
      setLoading(false)
    }

    fetchData()
  }, [setLoading])

  const handleSetFilters = newFilters => {
    setFilters(newFilters)
    localStorage.setItem(storageKey, JSON.stringify(newFilters))
  }

  const handleChangePage = async (event, newPage) => {
    setLoading(true)
    setNumberPorts(
      await list(parseInt(numberPorts.meta.per_page), parseInt(newPage + 1), filters)
    )
    setLoading(false)
  }

  const handleChangeRowsPerPage = async event => {
    setLoading(true)
    setNumberPorts(await list(parseInt(event.target.value, 10), 1, filters))
    setLoading(false)
  }

  const handleChangeSearchFilter = async event => {
    const newFilters = { ...filters, search: event.target.value }
    handleSetFilters(newFilters)
    debouncedSearch(parseInt(numberPorts.meta.per_page), newFilters)
  }

  const handleChangeTypeFilter = async event => {
    setLoading(true)
    const newFilters = { ...filters, type: event.target.value }
    handleSetFilters(newFilters)
    setNumberPorts(await list(parseInt(numberPorts.meta.per_page), 1, newFilters))
    setLoading(false)
  }

  const handleChangeStatusFilter = async event => {
    setLoading(true)
    const newFilters = { ...filters, status: event.target.value }
    handleSetFilters(newFilters)
    setNumberPorts(await list(parseInt(numberPorts.meta.per_page), 1, newFilters))
    setLoading(false)
  }

  const handleChangeTagsFilter = async event => {
    setLoading(true)
    const newFilters = { ...filters, tags: event.target.value }
    handleSetFilters(newFilters)
    setNumberPorts(await list(parseInt(numberPorts.meta.per_page), 1, newFilters))
    setLoading(false)
  }

  const debouncedSearch = useRef(
    debounce(async (perPage, newFilters) => {
      setLoading(true)
      setNumberPorts(await list(perPage, 1, newFilters))
      setLoading(false)
    }, 1000)
  ).current

  const handleOpenResubmitDialog = id => {
    setResubmitId(id)
    setResubmitOpen(true)
  }

  const handleCloseResubmitDialog = () => {
    setResubmitOpen(false)
  }

  const handleOpenDeleteDialog = id => {
    setDeleteId(id)
    setDeleteOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteOpen(false)
  }

  const handleSort = async col => {
    setLoading(true)
    const sort = filters && filters.hasOwnProperty('sort') ? filters.sort : ''
    const [_col, _dir] = sort.split(':')
    const isNewCol = _col !== col
    const dir = isNewCol ? 'desc' : _dir === 'asc' ? 'desc' : 'asc'
    const newSort = `${col}:${dir}`
    const newFilters = { ...filters, sort: newSort }
    handleSetFilters(newFilters)
    setNumberPorts(await list(parseInt(numberPorts.meta.per_page), 1, newFilters))
    setLoading(false)
  }

  const handleResubmit = async id => {
    setLoading(true)
    const res = await geographicPortResubmit(id)
    const portId = res.data.id

    if (portId) {
      setNumberPorts(await list(parseInt(numberPorts.meta.per_page), 1, filters))
      addToast('Successfully created new port request.', { appearance: 'success' })
    } else {
      addToast('Failed to create port request.', { appearance: 'error' })
    }

    setLoading(false)
    props.history.push({ pathname: `/number-porting/geographic-port/${portId}` })
  }

  const handleDelete = async id => {
    setLoading(true)
    const success = await deleteNumberPort(id)

    if (success) {
      setNumberPorts(await list(parseInt(numberPorts.meta.per_page), 1, filters))
      addToast('Successfully delete number port request.', { appearance: 'success' })
    } else {
      addToast('Failed to delete number port request.', { appearance: 'error' })
    }

    setDeleteOpen(false)
    setLoading(false)
  }

  const afterStatusUpdateSubmit = async () => {
    setNumberPorts(
      await list(
        parseInt(numberPorts.meta.per_page),
        parseInt(numberPorts.meta.current_page),
        filters
      )
    )
  }

  const getExportUrl = () => {
    const filterString = getQueryStringFromFiltersObject(filters)
    return `/download/number-ports?${filterString}`
  }

  if (!numberPorts.data) {
    return null
  }

  return (
    <div>
      <Typography variant='h3' gutterBottom>
        Number Ports
      </Typography>
      <TableContainer component={Paper}>
        <TableToolbar>
          <TableSearchBox
            label='Search Number Ports'
            defaultValue={filters.search}
            handleChange={handleChangeSearchFilter}
          />

          {user.type === 'distributor' && (
            <FormControl variant='outlined' className={classes.filterStatus}>
              <InputLabel id='type_label'>Type</InputLabel>
              <Select
                labelId='type_label'
                id='type'
                name='type'
                value={filters.type}
                onChange={handleChangeTypeFilter}
                label='Type'>
                <MenuItem value='all'>All</MenuItem>
                <MenuItem value='Manual'>Manual</MenuItem>
                <MenuItem value='Manual Migration'>Migration</MenuItem>
                <MenuItem value='API'>API</MenuItem>
              </Select>
            </FormControl>
          )}

          <FormControl variant='outlined' className={classes.filterStatus}>
            <InputLabel id='status_label' shrink={true}>
              Status
            </InputLabel>
            <Select
              multiple
              displayEmpty
              notched
              labelId='status_label'
              id='status'
              name='status'
              value={filters.status}
              onChange={handleChangeStatusFilter}
              renderValue={selected =>
                !Array.isArray(selected) ||
                selected.length === 0 ||
                selected.length === Object.keys(statusesList).length
                  ? 'All Statuses'
                  : selected.map(x => statusesList[x]).join(', ')
              }
              label='Status'>
              {Object.entries(statusesList).map(x => {
                const [value, label] = x

                return (
                  <MenuItem key={value} value={value}>
                    <Checkbox
                      color='primary'
                      checked={filters.status.indexOf(value) > -1}
                    />
                    <ListItemText primary={label} />
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>

          {user.type === 'distributor' && (
            <FormControl variant='outlined' className={classes.filterStatus}>
              <InputLabel id='tags_label' shrink={true}>
                Tags
              </InputLabel>
              <Select
                multiple
                displayEmpty
                notched
                labelId='tags_label'
                id='tags'
                name='tags'
                value={filters.tags}
                onChange={handleChangeTagsFilter}
                renderValue={selected =>
                  !Array.isArray(selected) ||
                  selected.length === 0 ||
                  selected.length === tagsList.length
                    ? 'All Tags'
                    : selected.join(', ')
                }
                label='Tags'>
                {tagsList.map(x => (
                  <MenuItem key={x} value={x}>
                    <Checkbox color='primary' checked={filters.tags.indexOf(x) > -1} />
                    <ListItemText primary={x} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {user.type === 'distributor' && (
            <Tooltip
              placement='top'
              title='Exports as CSV, uses your current filter selections to limit export'>
              <Button
                variant='contained'
                color='primary'
                size='large'
                as='a'
                href={getExportUrl()}
                target='_blank'>
                Export
              </Button>
            </Tooltip>
          )}
        </TableToolbar>

        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Reference</TableCell>
              {user.type === 'distributor' && <TableCell>Reseller</TableCell>}
              {user.type === 'distributor' && <TableCell>Type</TableCell>}
              <TableCell>Company Name</TableCell>
              <TableCell>Main Number</TableCell>
              <TableCell>Post Code</TableCell>
              <TableCell
                className='cursor-pointer'
                onClick={() => {
                  handleSort('porting_date')
                }}>
                Port Date
              </TableCell>
              <TableCell>Status</TableCell>
              {user.type === 'distributor' && <TableCell>Tags</TableCell>}
              <TableCell align='right'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {numberPorts.data.map(row => (
              <TableRow key={row.id}>
                <TableCell scope='row'>{row.id}</TableCell>
                {user.type === 'distributor' && (
                  <TableCell scope='row'>{row.reseller.name}</TableCell>
                )}
                {user.type === 'distributor' && (
                  <TableCell scope='row'>
                    {row.status === 'DRAFT' ? 'TBC' : row.process_type}
                  </TableCell>
                )}
                <TableCell scope='row'>{row.customer.company_name}</TableCell>
                <TableCell scope='row'>
                  {row.main_number.std_code}
                  {row.main_number.local_number}
                </TableCell>
                <TableCell scope='row'>{row.address_details.post_code}</TableCell>
                <TableCell scope='row'>{row.port_date.readable}</TableCell>
                <TableCell scope='row' width={360}>
                  <NumberPortStatusChip hubStatus={row.hub_status} status={row.status} />
                  <Box mt={2}>
                    {row.hub_status === 'ACCEPTED' && (
                      <MuiAlert severity='info'>
                        Please ensure that you raise an order for these DDIs on the{' '}
                        <a
                          href='https://portal.ipecs-cloud.co.uk/back/cust/orderProcess/orderProcess.do'
                          target='_blank'
                          rel='noopener noreferrer'>
                          OMS Cloud Portal
                        </a>
                      </MuiAlert>
                    )}
                  </Box>
                </TableCell>
                {user.type === 'distributor' && (
                  <TableCell scope='row'>
                    {Array.isArray(row.tags) ? row.tags.join(', ') : ''}
                  </TableCell>
                )}
                <TableCell scope='row' style={{ minWidth: '250px' }} align='right'>
                  {user.type === 'distributor' && row.process_type === 'Manual' && (
                    <UpdateStatusModal id={row.id} afterSubmit={afterStatusUpdateSubmit}>
                      <Tooltip placement='top' title='Update port status'>
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </UpdateStatusModal>
                  )}
                  <Tooltip
                    placement='top'
                    title={
                      row.hub_status === 'UNSUBMITTED'
                        ? 'Continue with this port'
                        : 'View details'
                    }>
                    <IconButton
                      color='default'
                      component={NavLink}
                      to={`/number-porting/geographic-port/${row.id}`}>
                      <LaunchIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip placement='top' title='Resubmit as new request'>
                    <IconButton
                      onClick={() => handleOpenResubmitDialog(row.id)}
                      color='default'
                      style={{
                        visibility:
                          row.hub_status === 'REJECTED' || row.hub_status === 'CANCELLED'
                            ? 'visible'
                            : 'hidden',
                      }}>
                      <ReplayIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip placement='top' title='Delete port request'>
                    <IconButton
                      onClick={() => handleOpenDeleteDialog(row.id)}
                      color='secondary'
                      style={{
                        visibility:
                          row.can_delete === 'UNSUBMITTED' ? 'visible' : 'hidden',
                      }}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {numberPorts.data.length === 0 && (
          <Box mt={6} mb={6} textAlign='center'>
            No number ports found
          </Box>
        )}
      </TableContainer>

      <TablePagination
        className={classes.pagination}
        rowsPerPageOptions={[5, 15, 25]}
        component='div'
        count={numberPorts.meta.total}
        rowsPerPage={parseInt(numberPorts.meta.per_page)}
        page={numberPorts.meta.current_page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog open={resubmitOpen} onClose={handleCloseResubmitDialog}>
        <DialogTitle>{'Resubmit Number Port'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will allow this port order to be updated with new details, you will same
            reference number for this port.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleCloseResubmitDialog}>
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={() => handleResubmit(resubmitId)}
            color='primary'>
            Resubmit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>{'Delete Number Port'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this number port request?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleCloseDeleteDialog}>
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={() => handleDelete(deleteId)}
            color='secondary'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default List
