import { Box } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import MuiAlert from '@material-ui/lab/Alert'
import { me, resetPassword } from 'api/auth'
import { AuthContext } from 'contexts/AuthContext'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

function PasswordForm(props) {
  const { token } = useParams()
  const { loading, setLoading } = useContext(LoadingContext)
  const { setAuthenticated, setUser } = useContext(AuthContext)
  const [email, setEmail] = useState()
  const [errors, setErrors] = useState([])
  const classes = useStyles()

  useEffect(() => {
    setEmail(new URLSearchParams(props.location.search).get('email'))
    setLoading(false)
  }, [setLoading, props.location])

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: value })
  }

  const handleSubmit = async event => {
    event.preventDefault()

    setLoading(true)
    const result = await resetPassword(token, email, values.password)
    if (result.success) {
      setUser(await me())
      setAuthenticated(true)
      setLoading(false)
      props.history.push({
        pathname: '/',
        state: {
          severity: 'success',
          message: 'Password successfully reset',
        },
      })
    } else {
      setErrors(result.errors)
      setLoading(false)
    }
  }

  const [values, setValues] = useState({
    password: '',
  })

  const renderForm = () => {
    return (
      <form className={classes.form} onSubmit={handleSubmit} noValidate>
        <TextField
          variant='standard'
          margin='normal'
          required
          fullWidth
          id='password'
          label='New Password'
          type='password'
          name='password'
          autoFocus
          onChange={handleChange.bind(this)}
        />
        {errors.length > 0 && (
          <MuiAlert severity='error'>
            {errors.map(error => {
              return <div>{error[1][0]}</div>
            })}
          </MuiAlert>
        )}
        <Button
          type='submit'
          fullWidth
          variant='contained'
          color='primary'
          disabled={loading}
          className={classes.submit}>
          Submit
        </Button>
      </form>
    )
  }

  const renderInvalid = () => {
    return (
      <p>
        <em>Something went wrong</em>
      </p>
    )
  }

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        <Link href='/'>
          <img alt='Logo' src='/Logo100.png' />
        </Link>
        <Box marginTop={4}>
          <Typography component='h1' variant='h5'>
            Reset Password
          </Typography>
        </Box>
        {email ? renderForm() : renderInvalid()}
      </div>
    </Container>
  )
}

export default PasswordForm
