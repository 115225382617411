import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import InputAdornment from '@material-ui/core/InputAdornment'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Alert from '@material-ui/lab/Alert'
import { itemAdjust, itemDelete } from 'api/quote-builder'
import { QuoteContext } from 'contexts/QuoteContext'
import React, { useContext, useEffect, useState } from 'react'

const useStyles = makeStyles(theme => ({
  form: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    flexFlow: 'column nowrap',
    height: '400px',
  },
  buttons: {
    marginTop: 'auto',
  },
  submit: {
    marginTop: theme.spacing(1),
  },
  delete: {
    marginTop: theme.spacing(2),
  },
  whiteTextField: {
    '& .MuiInputLabel-root': {
      color: '#FFF', // Label
    },
    '& .MuiInputBase-input': {
      color: '#FFF', // Text color
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#FFF9', // Solid underline
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#FFF', // Solid underline on hover
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#FFF', // Solid underline on focus
    },
  },
  loader: {
    marginTop: theme.spacing(3),
  },
  chipProfit: {
    backgroundColor: theme.palette.success.main,
    color: '#FFF',
    height: '24px',
  },
  chipLoss: {
    backgroundColor: theme.palette.error.main,
    color: '#FFF',
    height: '24px',
  },
}))

const getUpfrontTotal = item => {
  return item.total_upfront / 100
}

const getMonthlyTotal = item => {
  return item.total_monthly / 100
}

function EditActions({ variant = 'dark', item }) {
  const classes = useStyles()
  const { state, dispatch, load } = useContext(QuoteContext)
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState({
    upfront: { value: null },
    upfront_per_unit: { value: null },
    monthly: { value: null },
    monthly_per_unit: { value: null },
  })

  useEffect(() => {
    setValues({
      upfront: { value: floatFormatted(getUpfrontTotal(item)) },
      upfront_per_unit: { value: floatFormatted(getUpfrontTotal(item) / item.quantity) },
      monthly: { value: floatFormatted(getMonthlyTotal(item)) },
      monthly_per_unit: { value: floatFormatted(getMonthlyTotal(item) / item.quantity) },
    })
  }, [item, setValues])

  const hasChanged = () => {
    const upfrontChanged =
      values.upfront.value !== null &&
      floatFormatted(values.upfront.value) !== floatFormatted(getUpfrontTotal(item))

    const monthlyChanged =
      values.monthly.value !== null &&
      floatFormatted(values.monthly.value) !== floatFormatted(getMonthlyTotal(item))

    return upfrontChanged || monthlyChanged
  }

  const handleChangeUpfront = async event => {
    setValues({
      ...values,
      upfront: { value: event.target.value },
      upfront_per_unit: {
        value: event.target.value
          ? floatFormatted(event.target.value / item.quantity)
          : '',
      },
    })
  }

  const handleChangeMonthly = async event => {
    setValues({
      ...values,
      monthly: { value: event.target.value },
      monthly_per_unit: {
        value: event.target.value
          ? floatFormatted(event.target.value / item.quantity)
          : '',
      },
    })
  }

  const handleChangeUpfrontPerUnit = event => {
    setValues({
      ...values,
      upfront_per_unit: { value: event.target.value },
      upfront: {
        value: event.target.value
          ? floatFormatted(event.target.value * item.quantity)
          : '',
      },
    })
  }

  const handleChangeMonthlyPerUnit = event => {
    setValues({
      ...values,
      monthly_per_unit: { value: event.target.value },
      monthly: {
        value: event.target.value
          ? floatFormatted(event.target.value * item.quantity)
          : '',
      },
    })
  }

  const handleReset = () => {
    setValues({
      upfront: { value: floatFormatted(getUpfrontTotal(item)) },
      upfront_per_unit: { value: floatFormatted(getUpfrontTotal(item) / item.quantity) },
      monthly: { value: floatFormatted(getMonthlyTotal(item)) },
      monthly_per_unit: { value: floatFormatted(getMonthlyTotal(item) / item.quantity) },
    })
  }

  const handleDelete = async () => {
    setLoading(true)
    const summary = await itemDelete(item.item_id)
    if (summary) {
      load()
      dispatch({ type: 'SET_SUMMARY', payload: summary })
    }
    setLoading(false)
  }

  const getUpfrontProfit = () => {
    return values.upfront.value - item.total_upfront_cost / 100
  }

  const getUpfrontProfitPerUnit = () => {
    return getUpfrontProfit() / item.quantity
  }

  const getMonthlyProfit = () => {
    return values.monthly.value - item.total_monthly_cost / 100
  }

  const getMonthlyProfitPerUnit = () => {
    return getMonthlyProfit() / item.quantity
  }

  const floatFormatted = value => {
    return parseFloat(value).toFixed(2)
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setLoading(true)
    const summary = await itemAdjust(item.item_id, {
      upfront: values.upfront.value * 100,
      monthly: values.monthly.value * 100,
    })
    if (summary) {
      dispatch({ type: 'SET_SUMMARY', payload: summary })
    }
    setLoading(false)
  }

  return (
    <>
      <div style={{ color: variant === 'light' ? 'white' : 'black' }}>
        Quantity: {item.quantity}
      </div>
      {state.settings.summary_price_editing && (
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          {(!item.hasOwnProperty('payment_type') || item.payment_type === 'upfront') && (
            <>
              <TextField
                className={variant === 'light' ? classes.whiteTextField : ''}
                variant='standard'
                margin='normal'
                required
                fullWidth
                id='upfront'
                label='Upfront (total)'
                name='upfront'
                disabled={loading}
                value={values.upfront.value}
                onChange={handleChangeUpfront}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Chip
                        className={
                          getUpfrontProfit() >= 0 ? classes.chipProfit : classes.chipLoss
                        }
                        label={floatFormatted(getUpfrontProfit())}
                      />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                className={variant === 'light' ? classes.whiteTextField : ''}
                variant='standard'
                margin='normal'
                required
                fullWidth
                id='upfront_per_unit'
                label='Upfront (per unit)'
                name='upfront_per_unit'
                disabled={loading}
                value={values.upfront_per_unit.value}
                onChange={handleChangeUpfrontPerUnit}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Chip
                        className={
                          getUpfrontProfit() >= 0 ? classes.chipProfit : classes.chipLoss
                        }
                        label={floatFormatted(getUpfrontProfitPerUnit())}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </>
          )}

          {(!item.hasOwnProperty('payment_type') || item.payment_type === 'monthly') && (
            <>
              <TextField
                className={variant === 'light' ? classes.whiteTextField : ''}
                variant='standard'
                margin='normal'
                required
                fullWidth
                id='monthly'
                label='Monthly (total)'
                name='monthly'
                disabled={loading}
                value={values.monthly.value}
                onChange={handleChangeMonthly}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Chip
                        className={
                          getMonthlyProfit() >= 0 ? classes.chipProfit : classes.chipLoss
                        }
                        label={floatFormatted(getMonthlyProfit())}
                      />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                className={variant === 'light' ? classes.whiteTextField : ''}
                variant='standard'
                margin='normal'
                required
                fullWidth
                id='monthly'
                label='Monthly (per unit)'
                name='monthly'
                disabled={loading}
                value={values.monthly_per_unit.value}
                onChange={handleChangeMonthlyPerUnit}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Chip
                        className={
                          getMonthlyProfit() >= 0 ? classes.chipProfit : classes.chipLoss
                        }
                        label={floatFormatted(getMonthlyProfitPerUnit())}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </>
          )}

          {loading && <CircularProgress className={classes.loader} />}

          {!loading && (
            <div className={classes.buttons}>
              {getUpfrontProfit() < 0 || getMonthlyProfit() < 0 ? (
                <Alert className={classes.submit} severity='warning'>
                  Item must be in profit to save changes.
                </Alert>
              ) : (
                <>
                  <Button
                    type='submit'
                    fullWidth
                    size='small'
                    variant='contained'
                    color='primary'
                    disabled={loading || !hasChanged()}
                    className={classes.submit}>
                    Save Changes
                  </Button>

                  <Button
                    onClick={handleReset}
                    fullWidth
                    size='small'
                    variant='contained'
                    color='default'
                    disabled={loading || !hasChanged()}
                    className={classes.submit}>
                    Reset Values
                  </Button>
                </>
              )}
            </div>
          )}
        </form>
      )}

      <Button
        onClick={handleDelete}
        size='small'
        fullWidth
        variant='contained'
        color='secondary'
        className={classes.delete}>
        Delete
      </Button>
    </>
  )
}

export default EditActions
