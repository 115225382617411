import { Typography } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import CostPriceField from 'components/CostPriceField'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

function UpfrontRRP({ name, defaultValue, handleChange }) {
  const { t } = useTranslation()

  return (
    <TextField
      variant='standard'
      margin='normal'
      required
      fullWidth
      type='number'
      step='0.01'
      id={name}
      label={t('Upfront RRP')}
      name={name}
      InputLabelProps={{
        shrink: true,
      }}
      defaultValue={defaultValue}
      onChange={handleChange}
    />
  )
}

function MonthlyRRP({ name, defaultValue, handleChange }) {
  const { t } = useTranslation()

  return (
    <TextField
      variant='standard'
      margin='normal'
      required
      fullWidth
      type='number'
      step='0.01'
      id={name}
      label={t('Monthly RRP')}
      name={name}
      InputLabelProps={{
        shrink: true,
      }}
      defaultValue={defaultValue}
      onChange={handleChange}
    />
  )
}

function FormDefaultPricing({ isOwner, values, setValues, handleChange }) {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <TextField
        variant='standard'
        margin='normal'
        fullWidth
        disabled={!isOwner}
        id='product_code'
        label='Product Code'
        name='product_code'
        defaultValue={values.product_code.value}
        onChange={handleChange}
      />

      <CostPriceField
        values={values}
        setValues={setValues}
        valuesProperty='upfront_cost_price'
        label={t('Upfront Cost')}
      />
      <CostPriceField
        values={values}
        setValues={setValues}
        valuesProperty='monthly_cost_price'
        label={t('Upfront Cost')}
      />

      <TextField
        variant='standard'
        margin='normal'
        required
        fullWidth
        type='number'
        step='0.01'
        id='upfront_rrp'
        label={t('Upfront RRP')}
        name='upfront_rrp'
        InputLabelProps={{
          shrink: true,
        }}
        defaultValue={values.upfront_rrp.value}
        onChange={handleChange}
      />
      <TextField
        variant='standard'
        margin='normal'
        required
        fullWidth
        type='number'
        step='0.01'
        id='monthly_rrp'
        label={t('Monthly RRP')}
        name='monthly_rrp'
        InputLabelProps={{
          shrink: true,
        }}
        defaultValue={values.monthly_rrp.value}
        onChange={handleChange}
      />

      {values.has_contract_pricing && (
        <React.Fragment>
          <Typography variant='subtitle1' style={{ marginTop: '3rem' }}>
            3 Year Contract Pricing
          </Typography>
          <CostPriceField
            values={values}
            setValues={setValues}
            valuesProperty='upfront_cost_price_contract_3_year'
            label={t('Upfront Cost')}
          />
          <CostPriceField
            values={values}
            setValues={setValues}
            valuesProperty='monthly_cost_price_contract_3_year'
            label={t('Monthly Cost')}
          />
          <UpfrontRRP
            name='upfront_rrp_contract_3_year'
            defaultValue={values.upfront_rrp_contract_3_year.value}
            handleChange={handleChange}
          />
          <MonthlyRRP
            name='monthly_rrp_contract_3_year'
            defaultValue={values.monthly_rrp_contract_3_year.value}
            handleChange={handleChange}
          />

          <Typography variant='subtitle1' style={{ marginTop: '3rem' }}>
            5 Year Contract Pricing
          </Typography>
          <CostPriceField
            values={values}
            setValues={setValues}
            valuesProperty='upfront_cost_price_contract_5_year'
            label={t('Upfront Cost')}
          />
          <CostPriceField
            values={values}
            setValues={setValues}
            valuesProperty='monthly_cost_price_contract_5_year'
            label={t('Monthly Cost')}
          />
          <UpfrontRRP
            name='upfront_rrp_contract_5_year'
            defaultValue={values.upfront_rrp_contract_5_year.value}
            handleChange={handleChange}
          />
          <MonthlyRRP
            name='monthly_rrp_contract_5_year'
            defaultValue={values.monthly_rrp_contract_5_year.value}
            handleChange={handleChange}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

FormDefaultPricing.propTypes = {
  isOwner: PropTypes.bool,
  values: PropTypes.object,
  setValues: PropTypes.func,
  handleChange: PropTypes.func,
}

export default FormDefaultPricing
