import { IconButton } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Snackbar from '@material-ui/core/Snackbar'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import MuiAlert from '@material-ui/lab/Alert'
import { list, setFavourite } from 'api/products/system'
import CostPriceTableDisplay from 'components/CostPriceTableDisplay'
import ProductFavourite from 'components/ProductFavourite'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

function List(props) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { setLoading } = useContext(LoadingContext)
  const [products, setProducts] = useState({})
  const [hideContent, setHideContent] = useState(true)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = useState('info')
  const [snackbarMessage, setSnackbarMessage] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      setProducts(await list())
      setLoading(false)
      setHideContent(false)
    }

    if (props.location.state) {
      setSnackbarSeverity(props.location.state.severity)
      setSnackbarMessage(props.location.state.message)
      setSnackbarOpen(true)
      props.history.replace()
    }

    fetchData()
  }, [setLoading, props.location, props.history])

  const handleChangePage = async (event, newPage) => {
    setLoading(true)
    setProducts(await list(parseInt(products.meta.per_page), parseInt(newPage + 1)))
    setLoading(false)
  }

  const handleChangeRowsPerPage = async event => {
    setLoading(true)
    setProducts(await list(parseInt(event.target.value, 10)))
    setLoading(false)
  }

  const handleAddProductToFavourites = async id => {
    setLoading(true)
    await setFavourite(id, true)
    setProducts(await list(parseInt(products.meta.per_page)))
    setLoading(false)
  }

  const handleRemoveProductFromFavourites = async id => {
    setLoading(true)
    await setFavourite(id, false)
    setProducts(await list(parseInt(products.meta.per_page)))
    setLoading(false)
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  const getDisplayPrice = price => {
    if (price === 0) {
      return price.toFixed(2)
    } else if (price > 0) {
      return (price / 100).toFixed(2)
    }

    return ''
  }

  if (hideContent) {
    return null
  }

  return (
    <div>
      <Typography variant='h3' gutterBottom>
        System Products
      </Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Product Code</TableCell>
              <TableCell>{t('Upfront Cost')}</TableCell>
              <TableCell>{t('Monthly Cost')}</TableCell>
              <TableCell>{t('Upfront RRP')}</TableCell>
              <TableCell>{t('Monthly RRP')}</TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.data.map(row => (
              <TableRow key={row.id}>
                <TableCell scope='row'>{row.name}</TableCell>
                <TableCell scope='row'>
                  {row.has_reseller_type_pricing
                    ? 'Reseller Type Dependent'
                    : row.product_code}
                </TableCell>
                <TableCell scope='row'>
                  {row.has_reseller_type_pricing ? (
                    'Reseller Type Dependent'
                  ) : (
                    <CostPriceTableDisplay
                      value={getDisplayPrice(row.upfront_cost_price)}
                    />
                  )}
                </TableCell>
                <TableCell scope='row'>
                  {row.has_reseller_type_pricing ? (
                    'Reseller Type Dependent'
                  ) : (
                    <CostPriceTableDisplay
                      value={getDisplayPrice(row.monthly_cost_price)}
                    />
                  )}
                </TableCell>
                <TableCell scope='row'>
                  {row.has_reseller_type_pricing
                    ? 'Reseller Type Dependent'
                    : getDisplayPrice(row.upfront_rrp)}
                </TableCell>
                <TableCell scope='row'>
                  {row.has_reseller_type_pricing
                    ? 'Reseller Type Dependent'
                    : getDisplayPrice(row.monthly_rrp)}
                </TableCell>

                <TableCell align='right'>
                  <ProductFavourite
                    productId={row.id}
                    isFavourite={row.is_favourite}
                    onAdd={handleAddProductToFavourites}
                    onRemove={handleRemoveProductFromFavourites}
                  />
                  <IconButton
                    aria-label='edit'
                    color='default'
                    component={NavLink}
                    to={`/products/system/update/${row.id}`}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 15, 25]}
        component='div'
        count={products.meta.total}
        rowsPerPage={parseInt(products.meta.per_page)}
        page={products.meta.current_page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <MuiAlert
          elevation={6}
          variant='filled'
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  )
}

export default List
