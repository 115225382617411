import { ResponsivePie } from '@nivo/pie'
import React from 'react'

function PieChart({ data, colors = { scheme: 'nivo' } }) {
  return (
    <ResponsivePie
      data={data}
      sortByValue={true}
      margin={{ top: 40, right: 80, bottom: 80, left: 40 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      colors={colors}
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      radialLabelsSkipAngle={20}
      radialLabelsTextXOffset={6}
      radialLabelsTextColor='#333333'
      radialLabelsLinkOffset={0}
      radialLabelsLinkDiagonalLength={16}
      radialLabelsLinkHorizontalLength={24}
      radialLabelsLinkStrokeWidth={1}
      radialLabelsLinkColor={{ from: 'color' }}
      slicesLabelsSkipAngle={20}
      slicesLabelsTextColor='#333333'
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      legends={[]}
    />
  )
}

export default PieChart
