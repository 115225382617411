import config from 'config'

export async function get(queryString = undefined) {
  try {
    let url = config.API_URL + `/api/sales-dash`
    if (queryString) {
      url += queryString
    }

    const result = await fetch(url)
    return await result.json()
  } catch (e) {
    return false
  }
}
