import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'

function ProductDeleteModal({ open, handleClose, isOwner, loading, handleDelete }) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id='alert-dialog-title'>{'Product Delete'}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {isOwner
            ? 'Are you sure you want to delete this product?'
            : 'This product was created by the distributor and can not be completely removed.  Deleting this product will disable it and prevent it from being added to quote.'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleClose} color='primary'>
          Cancel
        </Button>
        <Button disabled={loading} onClick={handleDelete} color='secondary'>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ProductDeleteModal
