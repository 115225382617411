import React from 'react'
import { useReducer } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'

const initialState = {
  post_id: null,
  files: [],
  folders: [{ id: '', name: 'root' }],
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_POST_ID':
      return {
        ...state,
        post_id: action.payload.post.id,
      }
    case 'ADD_FILE':
      return {
        ...state,
        files: [...state.files, action.payload.file],
      }
    case 'SET_FILE_DISPLAY_TYPE':
      return {
        ...state,
        files: state.files.map(x =>
          x.id === action.payload.file.id
            ? { ...x, display_type: action.payload.file.display_type }
            : x
        ),
      }
    case 'DELETE_FILE':
      return {
        ...state,
        files: state.files.map(x =>
          x.id === action.payload.id ? { ...x, deleted: true } : x
        ),
      }
    case 'ADD_FOLDER':
      return { ...state, folders: [...state.folders, action.payload] }
    case 'DELETE_FOLDER':
      return {
        ...state,
        folders: state.folders.map(x =>
          x.id === action.payload.id ? { ...x, deleted: true } : x
        ),
      }
    case 'UPDATE_FILES':
      return {
        ...state,
        files: action.payload.files.concat(
          state.files.filter(x => !action.payload.files.map(x => x.id).includes(x.id))
        ),
      }
    case 'UPDATE_FOLDERS':
      return {
        ...state,
        folders: action.payload.folders,
      }
    default:
      return state
  }
}

const FileManagerContext = React.createContext()
const FileManagerProvider = ({ post, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const addFolder = async folderName => {
    dispatch({
      type: 'ADD_FOLDER',
      payload: { name: folderName },
    })
  }

  const updateFiles = async files => {
    dispatch({ type: 'UPDATE_FILES', payload: { files } })
  }

  const updateFolders = folders => {
    dispatch({ type: 'UPDATE_FOLDERS', payload: { folders } })
  }

  const addFile = file => {
    dispatch({ type: 'ADD_FILE', payload: { file: file } })
  }

  const setFileDisplayType = async (file, displayType) => {
    dispatch({
      type: 'SET_FILE_DISPLAY_TYPE',
      payload: { file: { ...file, display_type: displayType } },
    })
  }

  const deleteFile = async file => {
    dispatch({ type: 'DELETE_FILE', payload: { id: file.id } })
  }

  const deleteFolder = async folder => {
    dispatch({ type: 'DELETE_FOLDER', payload: { id: folder.id } })
  }

  useEffect(() => {
    if (post) {
      dispatch({
        type: 'SET_POST_ID',
        payload: { post: post },
      })

      post.data.folders.map(folder =>
        dispatch({
          type: 'ADD_FOLDER',
          payload: { id: folder.id, name: folder.name, sort_order: folder.sort_order },
        })
      )

      post.data.files.map(file =>
        dispatch({
          type: 'ADD_FILE',
          payload: { file: { ...file, deleted: false } },
        })
      )
    }
  }, [post])

  const defaultContext = {
    state,
    dispatch,
    addFile,
    addFolder,
    updateFiles,
    setFileDisplayType,
    deleteFile,
    deleteFolder,
    updateFolders,
  }
  return (
    <FileManagerContext.Provider value={defaultContext}>
      {children}
    </FileManagerContext.Provider>
  )
}

function useFileManager() {
  return useContext(FileManagerContext)
}

export { FileManagerProvider, useFileManager }
