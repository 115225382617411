import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { home, search as apiSearch } from 'api/library/library'
import CreateButtons from 'components/library/home/CreateButtons'
import Home from 'components/library/home/Home'
import { AuthContext } from 'contexts/AuthContext'
import { LoadingContext } from 'contexts/LoadingContext'
import debounce from 'lodash.debounce'
import React, { useContext, useEffect, useRef, useState } from 'react'

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: theme.breakpoints.width('lg'),
    margin: 'auto',
  },
}))

function LibraryHome(props) {
  const classes = useStyles()
  const { user } = useContext(AuthContext)
  const { setLoading } = useContext(LoadingContext)
  const [data, setData] = useState()
  const [search, setSearch] = useState('')
  const [searchResults, setSearchResults] = useState()

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      setData(await home())
      setLoading(false)
    }

    fetchData()
  }, [setLoading])

  const handleChangeSearch = async event => {
    setLoading(true)
    const searchTerm = event.target.value
    setSearch(searchTerm)

    if (searchTerm === '') {
      setLoading(false)
      setSearchResults()
    } else {
      debouncedSearch(searchTerm)
    }
  }

  const debouncedSearch = useRef(
    debounce(async searchTerm => {
      setSearchResults(await apiSearch(searchTerm))
      setLoading(false)
    }, 1000)
  ).current

  if (!data) {
    return null
  }

  return (
    <div className={classes.container}>
      <Typography variant='h3' gutterBottom>
        Library
      </Typography>
      {user.type === 'distributor' && (
        <Box mb={3} display='flex'>
          <CreateButtons
            categoryLink='/library/categories/create'
            postLink='/library/posts/create'
            canCreatePost={data.categories.all.length > 0}
          />
        </Box>
      )}

      {data.categories.all.length === 0 ? (
        <Box pt={2}>
          <p>
            {user.type === 'distributor'
              ? 'Start by creating a category'
              : 'The library is empty'}
          </p>
        </Box>
      ) : (
        <Home
          data={data}
          searchResults={searchResults}
          search={search}
          handleChangeSearch={handleChangeSearch}
          history={props.history}
          linkPrefix='library'
        />
      )}
    </div>
  )
}

export default LibraryHome
