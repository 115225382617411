import { Button, Typography } from '@material-ui/core'
import { updateCoveringLetter } from 'api/proposals'
import CoveringLetterComponent from 'components/covering-letter/CoveringLetter'
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

function CoveringLetter({ defaultEditorState, readOnly, setSnackbar }) {
  const [loading, setLoading] = useState(false)
  const [editorState, setEditorState] = useState(
    defaultEditorState
      ? EditorState.createWithContent(convertFromRaw(JSON.parse(defaultEditorState)))
      : EditorState.createEmpty()
  )

  const handleChange = editorState => {
    setEditorState(editorState)
  }

  const handleSave = async () => {
    setLoading(true)
    const res = await updateCoveringLetter({
      content: convertToRaw(editorState.getCurrentContent()),
    })
    setSnackbar(res.success ? 'success' : 'error', res.message)
    setLoading(false)
  }

  return (
    <div>
      <Typography variant='h4'>Covering Letter</Typography>
      <p>
        This is appended to the start of the proposal document. If you would prefer not to
        have a covering letter, you can remove all text from the editor below and save.
      </p>

      <CoveringLetterComponent
        editorState={editorState}
        loading={loading}
        readOnly={readOnly}
        handleChange={handleChange}
      />
      <Button variant='contained' disabled={loading} color='primary' onClick={handleSave}>
        Save Covering Letter
      </Button>
    </div>
  )
}

CoveringLetter.propTypes = {
  defaultEditorState: PropTypes.string,
  readOnly: PropTypes.bool,
  setSnackbar: PropTypes.func,
}

CoveringLetter.defaultProps = {
  readOnly: false,
}

export default CoveringLetter
