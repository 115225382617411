import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { useEffect, useState } from 'react'
import SummaryBox from './SummaryBox'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  summaryBlue: {
    backgroundColor: theme.palette.primary.main,
  },
  transparentText: {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  summaryText: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    color: '#FFF',
  },
}))

const getPounds = value => {
  return (
    (value / 100)
      .toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      .split('.')[0] ?? ''
  )
}

const getPence = value => {
  return (
    (value / 100)
      .toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      .split('.')[1] ?? '00'
  )
}

function QuoteSummaryWithoutLeasing({ summaryData }) {
  const classes = useStyles()
  const [initial, setInitial] = useState()
  const [monthly, setMonthly] = useState()

  const { t } = useTranslation()

  useEffect(() => {
    const _monthly = summaryData.summary.totals.monthly_rrp

    if (summaryData.summary.hasOwnProperty('leasing')) {
      setInitial(_monthly + summaryData.summary.leasing.initial)
      setMonthly(_monthly + summaryData.summary.leasing.monthly)
    } else {
      setInitial(_monthly)
      setMonthly(_monthly)
    }
  }, [setInitial, setMonthly, summaryData.summary])

  return (
    <React.Fragment>
      {initial !== monthly && (
        <SummaryBox
          className={classes.summaryBlue}
          header='Month One Amount'
          text={
            <>
              <Typography variant='h2'>{'£' + getPounds(initial)}</Typography>
              <Typography variant='body1'>.{getPence(initial)}</Typography>
              <Typography className={classes.transparentText} variant='caption'>
                Your first monthly payment amount.
              </Typography>
            </>
          }
          variant='text'>
          <Typography className={classes.summaryText} variant='body1'>
            First Monthly charge.
          </Typography>
        </SummaryBox>
      )}

      <SummaryBox
        className={classes.summaryBlue}
        header={t('Quote Monthly')}
        text={
          <>
            <Typography variant='h2'>{'£' + getPounds(monthly)}</Typography>
            <Typography variant='body1'>.{getPence(monthly)}</Typography>
            <Typography className={classes.transparentText} variant='caption'>
              For contract term of {summaryData.contract_length} months
            </Typography>
          </>
        }
        variant='text'>
        <Typography className={classes.summaryText} variant='body1'>
          Monthly charges.
        </Typography>
      </SummaryBox>
    </React.Fragment>
  )
}

export default QuoteSummaryWithoutLeasing
