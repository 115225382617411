import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'

function OpenDialog({ loading, quoteId, open, handleClose, handleSubmit }) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id='alert-dialog-title'>{'Quote Open'}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          Are you sure you want to set this quote as open, this will allow it to be
          changed and edited again?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleClose} color='primary'>
          Cancel
        </Button>
        <Button disabled={loading} onClick={() => handleSubmit(quoteId)} autoFocus>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default OpenDialog
