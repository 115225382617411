import { Box, TextField } from '@material-ui/core'
import React from 'react'
import SearchResults from './SearchResults'
import Summary from './Summary'

function Home({ data, searchResults, search, handleChangeSearch, linkPrefix, history }) {
  return (
    <React.Fragment>
      <Box mb={4}>
        <TextField
          fullWidth
          style={{ background: '#fff' }}
          variant='outlined'
          id='search'
          label='Search'
          onChange={handleChangeSearch}
        />
      </Box>

      {search !== '' ? (
        <SearchResults data={searchResults} linkPrefix={linkPrefix} />
      ) : (
        <Summary data={data} history={history} linkPrefix={linkPrefix} />
      )}
    </React.Fragment>
  )
}

export default Home
