import { Box } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Link from '@material-ui/core/Link'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { generate, get } from 'api/proposals'
import CoveringLetterComponent from 'components/covering-letter/CoveringLetter'
import { LoadingContext } from 'contexts/LoadingContext'
import { QuoteContext } from 'contexts/QuoteContext'
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js'
import PDFJS from 'pdfjs-dist'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Page from './Page'

PDFJS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.js`

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'wrap',
    flexWrap: 'wrap',
  },
  formControl: {
    width: '300px',
    marginBottom: theme.spacing(3),
  },
  loader: {
    marginTop: theme.spacing(12),
    textAlign: 'center',
  },
  button: {
    float: 'right',
    color: '#FFF',
    '&:hover': {
      textDecoration: 'none',
    },
    marginLeft: '8px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '24px',
    },
  },
  questions: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: theme.spacing(1),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr',
      gridGap: theme.spacing(3),
    },
    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
      gridGap: theme.spacing(3),
    },
  },
  formControlQuestion: {
    width: '100%',
  },
  questionMenu: {
    width: '200px',
  },
  questionItem: {
    whiteSpace: 'normal',
  },
  accordionDetails: {
    backgroundColor: theme.palette.lightBlue,
    display: 'block',
  },
}))

function ProposalStep({ src, questions }) {
  const scale = 0.5
  const classes = useStyles()
  const { id } = useParams()
  const { loading, setLoading } = useContext(LoadingContext)
  const { state, hasLoaded, load } = useContext(QuoteContext)

  const [pdf, setPdf] = useState(null)
  const [selectedPages, setSelectedPages] = useState([])
  const [pricingAfterPage, setPricingAfterPage] = useState(null)
  const [answers, setAnswers] = useState({})
  const [coverLetterState, setCoverLetterState] = useState()

  useEffect(() => {
    const loadPdf = async () => {
      setLoading(true)

      const proposal = await get()
      if (proposal.data) {
        setCoverLetterState(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(proposal.data.covering_letter.content))
          )
        )
      }

      const pdf = await PDFJS.getDocument(src).promise
      const selected = []
      Array.from(Array(pdf.numPages)).map((_, index) => selected.push(index + 1))
      setPricingAfterPage(pdf.numPages)
      setSelectedPages(selected)
      setPdf(pdf)
      setLoading(false)
    }

    if (src) {
      loadPdf()
    }
  }, [src, setLoading])

  const toggleSelected = (pageNum, selected) => {
    const pages = selectedPages.map(x => x)
    selected ? pages.push(pageNum) : pages.splice(selectedPages.indexOf(pageNum), 1)
    pages.sort()
    setSelectedPages(pages)

    if (pricingAfterPage > pages.length) {
      setPricingAfterPage(pages.length)
    }
  }

  const handlePricingAfterPage = event => {
    setPricingAfterPage(event.target.value)
  }

  const generatePdf = async () => {
    const cl = convertToRaw(coverLetterState.getCurrentContent())

    await generate(id, selectedPages, pricingAfterPage, answers, cl)

    load()
  }

  const handleChangeAnswers = event => {
    const id = event.target.name.substring(9)
    const newAnswers = { ...answers }
    newAnswers[id] = event.target.value
    setAnswers(newAnswers)
  }

  if (!hasLoaded) {
    return (
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    )
  }

  if (!state.stepsCompleted) {
    return (
      <div className={classes.loader}>
        You have not completed and saved all steps of the quote builder.
      </div>
    )
  }

  if (!src) {
    return (
      <Box>
        <p>
          <strong>You have not uploaded a proposal document yet.</strong>
        </p>
        <p>
          Generating a proposal allows you to add a covering letter, PDF document and
          embed a generate pricing page from this quote into a single document.
        </p>
        <p>To use this feature you need to first upload a proposal document.</p>
        <p>
          If you have the required permissions, you will have a "proposal" section on the
          left menu, from here you can upload your PDF document and setup the covering
          letter.
        </p>
      </Box>
    )
  }

  if (loading || !pdf) {
    return (
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div>
      {questions.length > 0 && (
        <Accordion elevation={0}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id='covering-letter'>
            <Typography>Answer covering letter questions</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <p>
              Optionally add answers to the below questions, any questions that you add
              answers to will be shown on the covering letter.
            </p>
            <div className={classes.questions}>
              {questions.map(x => (
                <FormControl
                  key={`question_${x.id}`}
                  variant='standard'
                  className={classes.formControlQuestion}>
                  <InputLabel id={`question_${x.id}_label`}>{x.content}</InputLabel>
                  <Select
                    MenuProps={{ className: classes.questionMenu }}
                    labelId={`question_${x.id}_label`}
                    id={`question_${x.id}`}
                    name={`question_${x.id}`}
                    onChange={handleChangeAnswers}>
                    {x.answers.map(y => (
                      <MenuItem
                        key={`answer_${x.id}`}
                        className={classes.questionItem}
                        value={y.id}>
                        {y.content}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      )}

      {state.settings.covering_letter_enabled && coverLetterState && (
        <Accordion elevation={0}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id='covering-letter'>
            <Typography>Edit the covering letter</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <div>
              <CoveringLetterComponent
                editorState={coverLetterState}
                loading={loading}
                handleChange={editorState => {
                  setCoverLetterState(editorState)
                }}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      )}

      <Accordion elevation={0} expanded>
        <AccordionSummary id='page-setup'>
          <Typography>Page Setup</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <div>
            <p>
              You can select where in the proposal PDF to place the generated pricing page
              using the below, you can also toggle on and off certain pages from the PDF
              so they are not included in the generated proposal document.
            </p>

            <FormControl variant='standard' className={classes.formControl}>
              <InputLabel id='page_no_label'>Insert pricing after page...</InputLabel>
              <Select
                labelId='page_no_label'
                id='page_no'
                name='page_no'
                value={pricingAfterPage}
                onChange={handlePricingAfterPage}>
                {Array.from(Array(selectedPages.length)).map((_, index) => (
                  <MenuItem key={`pages_${index}`} value={index + 1}>
                    {index + 1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div className={classes.container}>
              {Array.from(Array(pdf.numPages)).map((_, index) => (
                <Page
                  key={index}
                  pdf={pdf}
                  pageNum={index + 1}
                  selectedPageNum={selectedPages.indexOf(index + 1) + 1}
                  scale={scale}
                  selected={selectedPages.includes(index + 1)}
                  toggleSelected={toggleSelected}
                  totalSelected={selectedPages.length}
                />
              ))}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      <div>
        <Button
          component={Link}
          onClick={generatePdf}
          type='submit'
          variant='contained'
          color='primary'
          disabled={loading}
          className={classes.button}>
          {state.options.pdf_generated ? 'Re-generate PDF' : 'Generate PDF'}
        </Button>
      </div>
      <div>
        {state.options.pdf_generated && (
          <Button
            component={Link}
            href={'/api/quotes/builder/proposal/' + id + '/download'}
            type='submit'
            variant='contained'
            color='primary'
            disabled={loading}
            className={classes.button}>
            Download
          </Button>
        )}
      </div>
    </div>
  )
}

export default ProposalStep
