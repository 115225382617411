import config from 'config'
import { getJsonHeaders, getQueryStringFromFiltersObject } from './helpers'

const axios = require('axios')

export async function get(id) {
  let url = `/api/notes/${id}`
  const result = await fetch(config.API_URL + url, { headers: await getJsonHeaders() })
  return await result.json()
}

export async function list(limit = 15, page = 1, filters = {}) {
  let queryString = `?per_page=${limit}&page=${page}`
  const filterString = getQueryStringFromFiltersObject(filters)

  if (filterString) {
    queryString += `&${filterString}`
  }

  const res = await axios.get(config.API_URL + '/api/notes' + queryString)

  return res.data
}

export async function create(body) {
  let url = '/api/notes'

  const result = await fetch(config.API_URL + url, {
    method: 'POST',
    headers: await getJsonHeaders(),
    body: JSON.stringify(body),
  })

  return {
    status: result.status,
    response: await result.json(),
  }
}

export async function update(id, body) {
  let url = '/api/notes'
  if (id) {
    url += `/${id}`
  }

  const result = await fetch(config.API_URL + url, {
    method: 'PUT',
    headers: await getJsonHeaders(),
    body: JSON.stringify(body),
  })

  return {
    status: result.status,
    response: await result.json(),
  }
}

export async function deleteNote(id) {
  let url = '/api/notes'
  if (id) {
    url += `/${id}`
  }

  const result = await fetch(config.API_URL + url, {
    method: 'DELETE',
    headers: await getJsonHeaders(),
  })

  return {
    status: result.status,
    response: await result.json(),
  }
}
