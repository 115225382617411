import { AuthContext } from 'contexts/AuthContext'
import { FeatureToggleContext } from 'contexts/FeatureToggleContext'
import React, { useContext } from 'react'
import { Route, Switch } from 'react-router-dom'
import AuthenticatedRoute from './components/routes/AuthenticatedRoute'
import AnnouncementList from './containers/announcements/List'
import AnnouncementForm from './containers/announcements/Form'
import NotificationList from './containers/notifications/List'
import Notification from './containers/notifications/Notification'
import UnauthenticatedRoute from './components/routes/UnauthenticatedRoute'
import MyAccount from './containers/my-account/MyAccount'
import BlogList from './containers/blog/List'
import BlogPost from './containers/blog/Post'
import Candio from './containers/Candio'
import CloudOrdersInfo from './containers/cloud-orders-info/CloudOrdersInfo'
import DiscountCodesForm from './containers/discount-codes/Form'
import DiscountCodesList from './containers/discount-codes/List'
import GettingStarted from './containers/GettingStarted'
import LeasingForm from './containers/leasing/Form'
import LeasingList from './containers/leasing/List'
import LibraryCategory from './containers/library/categories/Category'
import LibraryCategoryCreate from './containers/library/categories/CategoryCreate'
import LibraryHome from './containers/library/home/LibraryHome'
import LibraryDistResellerPostCreate from './containers/library/post/LibraryDistResellerPostCreate'
import LibraryPost from './containers/library/post/Post'
import LibraryPostCreate from './containers/library/post/PostCreate'
import LibraryShared from './containers/library/Shared'
import Login from './containers/login/Login'
import Logout from './containers/logout/Logout'
import MarketingLibraryCategory from './containers/marketing-library/categories/Category'
import MarketingLibraryCategoryCreate from './containers/marketing-library/categories/CategoryCreate'
import MarketingLibraryHome from './containers/marketing-library/home/LibraryHome'
import MarketingLibraryPost from './containers/marketing-library/post/Post'
import MarketingLibraryPostCreate from './containers/marketing-library/post/PostCreate'
import MyLibraryCategory from './containers/my-library/categories/Category'
import MyLibraryCategoryCreate from './containers/my-library/categories/CategoryCreate'
import MyLibraryHome from './containers/my-library/home/LibraryHome'
import MyLibraryPost from './containers/my-library/post/Post'
import MyLibraryPostCreate from './containers/my-library/post/PostCreate'
import NotFound from './containers/not-found/NotFound'
import GeographicPort from './containers/number-porting/GeographicPort'
import NumberPortsList from './containers/number-porting/List'
import PortabilityCheck from './containers/number-porting/PortabilityCheck'
import PasswordResetEmailForm from './containers/password-reset/EmailForm'
import PasswordResetPasswordForm from './containers/password-reset/PasswordForm'
import PriceList from './containers/price-list/PriceList'
import BroadbandPackagesForm from './containers/products/broadband-packages/Form'
import BroadbandPackagesList from './containers/products/broadband-packages/List'
import BundledMinutesForm from './containers/products/bundled-minutes/Form'
import BundledMinutesList from './containers/products/bundled-minutes/List'
import CallRecordingForm from './containers/products/call-recording/Form'
import CallRecordingList from './containers/products/call-recording/List'
import HardwareForm from './containers/products/hardware/Form'
import HardwareList from './containers/products/hardware/List'
import NetworkHardwareForm from './containers/products/network-hardware/Form'
import NetworkHardwareList from './containers/products/network-hardware/List'
import NetworkServicesForm from './containers/products/network-services/Form'
import NetworkServicesList from './containers/products/network-services/List'
import NumberPortingForm from './containers/products/number-porting/Form'
import NumberPortingList from './containers/products/number-porting/List'
import SystemProductForm from './containers/products/system/Form'
import SystemProductList from './containers/products/system/List'
import UcApplicationsForm from './containers/products/uc-applications/Form'
import UcApplicationsList from './containers/products/uc-applications/List'
import UcLicensesForm from './containers/products/uc-licenses/Form'
import UcLicensesList from './containers/products/uc-licenses/List'
import ProposalsForm from './containers/proposals/Form'
import QuickStartLibraryCategory from './containers/quickstart-library/categories/Category'
import QuickStartLibraryCategoryCreate from './containers/quickstart-library/categories/CategoryCreate'
import QuickStartLibraryHome from './containers/quickstart-library/home/LibraryHome'
import QuickStartLibraryPost from './containers/quickstart-library/post/Post'
import QuickStartLibraryPostCreate from './containers/quickstart-library/post/PostCreate'
import QuotesCreate from './containers/quotes/create/Create'
import QuotesView from './containers/quotes/create/View'
import QuotesList from './containers/quotes/List'
import QuoteShared from './containers/quotes/Shared'
import ResellersForm from './containers/resellers/Form'
import ResellersList from './containers/resellers/List'
import SalesDash from './containers/sales-dash/SalesDash'
import SalesCommission from './containers/sales-commission/SalesCommission'
import SalesHub from './containers/sales-hub/SalesHub'
import Signup from './containers/signup/Signup'
import TariffsForm from './containers/tariffs/Form'
import TariffsList from './containers/tariffs/List'
import TrainingLibraryCategory from './containers/training-library/categories/Category'
import TrainingLibraryCategoryCreate from './containers/training-library/categories/CategoryCreate'
import TrainingLibraryHome from './containers/training-library/home/LibraryHome'
import TrainingLibraryPost from './containers/training-library/post/Post'
import TrainingLibraryPostCreate from './containers/training-library/post/PostCreate'
import TrainingForm from './containers/training/Form'
import TrainingList from './containers/training/List'
import UsersForm from './containers/users/Form'
import UsersList from './containers/users/List'
import VideoLibraryCategory from './containers/video-library/categories/Category'
import VideoLibraryCategoryCreate from './containers/video-library/categories/CategoryCreate'
import VideoLibraryHome from './containers/video-library/home/LibraryHome'
import VideoLibraryPost from './containers/video-library/post/Post'
import VideoLibraryPostCreate from './containers/video-library/post/PostCreate'

function AppRoutes() {
  const { user, hasAccountFeature } = useContext(AuthContext)
  const { hasFeature } = useContext(FeatureToggleContext)

  const homeComponent = () => {
    switch (user && user.type) {
      case 'distributor':
        return SalesHub
      default:
        return user && user.getting_started_percent < 100 ? GettingStarted : SalesHub
    }
  }

  const salesDashTypes = () => {
    const types = []
    if (hasFeature('distributor_sales_dash')) {
      types.push('distributor')
    }

    if (hasFeature('reseller_sales_dash')) {
      types.push('reseller')
    }

    return types
  }

  const libraryTypes = () => {
    const types = ['distributor']
    if (hasAccountFeature('library')) {
      types.push('reseller')
    }

    return types
  }

  const myLibraryTypes = () => {
    const types = []
    if (hasFeature('library')) {
      types.push('reseller')
    }

    return types
  }

  return (
    <Switch>
      <AuthenticatedRoute path='/' exact component={homeComponent()} />

      <AuthenticatedRoute
        types={['reseller']}
        roles={['admin', 'standard']}
        path='/getting-started'
        exact
        component={GettingStarted}
      />

      <AuthenticatedRoute
        types={['reseller', 'distributor']}
        roles={['admin', 'standard']}
        path='/sales-hub'
        exact
        component={SalesHub}
      />

      {salesDashTypes().length > 0 && (
        <AuthenticatedRoute
          types={salesDashTypes()}
          roles={['admin']}
          path='/sales-dash'
          exact
          component={SalesDash}
        />
      )}

      <AuthenticatedRoute
        types={['reseller']}
        roles={['admin']}
        path='/sales-commission'
        exact
        component={SalesCommission}
      />

      <AuthenticatedRoute
        types={['distributor']}
        roles={['admin']}
        path='/training'
        exact
        component={TrainingList}
      />
      <AuthenticatedRoute
        types={['distributor']}
        roles={['admin']}
        path='/training/create'
        exact
        component={TrainingForm}
      />
      <AuthenticatedRoute
        types={['distributor']}
        roles={['admin']}
        path='/training/update/:id'
        exact
        component={TrainingForm}
      />

      <AuthenticatedRoute
        types={['distributor']}
        roles={['admin']}
        path='/announcements'
        exact
        component={AnnouncementList}
      />

      <AuthenticatedRoute
        types={['distributor']}
        roles={['admin']}
        path='/announcements/create'
        exact
        component={AnnouncementForm}
      />

      <AuthenticatedRoute
        types={['distributor']}
        roles={['admin']}
        path='/announcements/update/:id'
        exact
        component={AnnouncementForm}
      />

      <AuthenticatedRoute
        types={['distributor', 'reseller']}
        roles={['admin', 'standard']}
        path='/notifications'
        exact
        component={NotificationList}
      />

      <AuthenticatedRoute
        types={['distributor', 'reseller']}
        roles={['admin', 'standard']}
        path='/notifications/:id'
        exact
        component={Notification}
      />

      <AuthenticatedRoute
        types={libraryTypes()}
        roles={['admin', 'standard']}
        path='/library'
        exact
        component={LibraryHome}
      />
      <AuthenticatedRoute
        types={libraryTypes()}
        roles={['admin', 'standard']}
        path='/library/categories/create'
        exact
        component={LibraryCategoryCreate}
      />
      <AuthenticatedRoute
        types={libraryTypes()}
        roles={['admin', 'standard']}
        path='/library/categories/:id'
        exact
        component={LibraryCategory}
      />
      <AuthenticatedRoute
        types={['distributor']}
        roles={['admin', 'standard']}
        path='/library/posts/create'
        exact
        component={LibraryPostCreate}
      />
      <AuthenticatedRoute
        types={['distributor']}
        roles={['admin', 'standard']}
        path='/library/posts/create/:resellerId/:resellerName'
        exact
        component={LibraryDistResellerPostCreate}
      />
      <AuthenticatedRoute
        types={libraryTypes()}
        roles={['admin', 'standard']}
        path='/library/posts/:id'
        exact
        component={LibraryPost}
      />

      <AuthenticatedRoute
        types={libraryTypes()}
        roles={['admin', 'standard']}
        path='/video-library'
        exact
        component={VideoLibraryHome}
      />
      <AuthenticatedRoute
        types={libraryTypes()}
        roles={['admin', 'standard']}
        path='/video-library/categories/create'
        exact
        component={VideoLibraryCategoryCreate}
      />
      <AuthenticatedRoute
        types={libraryTypes()}
        roles={['admin', 'standard']}
        path='/video-library/categories/:id'
        exact
        component={VideoLibraryCategory}
      />
      <AuthenticatedRoute
        types={libraryTypes()}
        roles={['admin', 'standard']}
        path='/video-library/posts/create'
        exact
        component={VideoLibraryPostCreate}
      />
      <AuthenticatedRoute
        types={libraryTypes()}
        roles={['admin', 'standard']}
        path='/video-library/posts/:id'
        exact
        component={VideoLibraryPost}
      />

      <AuthenticatedRoute
        types={libraryTypes()}
        roles={['admin', 'standard']}
        path='/training-library'
        exact
        component={TrainingLibraryHome}
      />
      <AuthenticatedRoute
        types={libraryTypes()}
        roles={['admin', 'standard']}
        path='/training-library/categories/create'
        exact
        component={TrainingLibraryCategoryCreate}
      />
      <AuthenticatedRoute
        types={libraryTypes()}
        roles={['admin', 'standard']}
        path='/training-library/categories/:id'
        exact
        component={TrainingLibraryCategory}
      />
      <AuthenticatedRoute
        types={libraryTypes()}
        roles={['admin', 'standard']}
        path='/training-library/posts/create'
        exact
        component={TrainingLibraryPostCreate}
      />
      <AuthenticatedRoute
        types={libraryTypes()}
        roles={['admin', 'standard']}
        path='/training-library/posts/:id'
        exact
        component={TrainingLibraryPost}
      />

      <AuthenticatedRoute
        types={libraryTypes()}
        roles={['admin', 'standard']}
        path='/quickstart-library'
        exact
        component={QuickStartLibraryHome}
      />
      <AuthenticatedRoute
        types={libraryTypes()}
        roles={['admin', 'standard']}
        path='/quickstart-library/categories/create'
        exact
        component={QuickStartLibraryCategoryCreate}
      />
      <AuthenticatedRoute
        types={libraryTypes()}
        roles={['admin', 'standard']}
        path='/quickstart-library/categories/:id'
        exact
        component={QuickStartLibraryCategory}
      />
      <AuthenticatedRoute
        types={libraryTypes()}
        roles={['admin', 'standard']}
        path='/quickstart-library/posts/create'
        exact
        component={QuickStartLibraryPostCreate}
      />
      <AuthenticatedRoute
        types={libraryTypes()}
        roles={['admin', 'standard']}
        path='/quickstart-library/posts/:id'
        exact
        component={QuickStartLibraryPost}
      />

      <AuthenticatedRoute
        types={libraryTypes()}
        roles={['admin', 'standard']}
        path='/marketing-library'
        exact
        component={MarketingLibraryHome}
      />
      <AuthenticatedRoute
        types={libraryTypes()}
        roles={['admin', 'standard']}
        path='/marketing-library/categories/create'
        exact
        component={MarketingLibraryCategoryCreate}
      />
      <AuthenticatedRoute
        types={libraryTypes()}
        roles={['admin', 'standard']}
        path='/marketing-library/categories/:id'
        exact
        component={MarketingLibraryCategory}
      />
      <AuthenticatedRoute
        types={libraryTypes()}
        roles={['admin', 'standard']}
        path='/marketing-library/posts/create'
        exact
        component={MarketingLibraryPostCreate}
      />
      <AuthenticatedRoute
        types={libraryTypes()}
        roles={['admin', 'standard']}
        path='/marketing-library/posts/:id'
        exact
        component={MarketingLibraryPost}
      />

      <AuthenticatedRoute
        path='/my-account'
        exact
        component={MyAccount}></AuthenticatedRoute>

      <AuthenticatedRoute
        types={myLibraryTypes()}
        roles={['admin', 'standard']}
        path='/my-library'
        exact
        component={MyLibraryHome}
      />
      <AuthenticatedRoute
        types={myLibraryTypes()}
        roles={['admin', 'standard']}
        path='/my-library/categories/create'
        exact
        component={MyLibraryCategoryCreate}
      />
      <AuthenticatedRoute
        types={myLibraryTypes()}
        roles={['admin', 'standard']}
        path='/my-library/categories/:id'
        exact
        component={MyLibraryCategory}
      />
      <AuthenticatedRoute
        types={myLibraryTypes()}
        roles={['admin', 'standard']}
        path='/my-library/posts/create'
        exact
        component={MyLibraryPostCreate}
      />
      <AuthenticatedRoute
        types={myLibraryTypes()}
        roles={['admin', 'standard']}
        path='/my-library/posts/:id'
        exact
        component={MyLibraryPost}
      />

      <AuthenticatedRoute
        types={libraryTypes()}
        roles={['admin', 'standard']}
        permission='price_list'
        path='/price-list'
        exact
        component={PriceList}
      />

      <AuthenticatedRoute
        types={libraryTypes()}
        roles={['admin', 'standard']}
        path='/cloud-orders-info'
        exact
        component={CloudOrdersInfo}
      />

      <AuthenticatedRoute
        types={['distributor']}
        roles={['admin']}
        path='/resellers'
        exact
        component={ResellersList}
      />
      <AuthenticatedRoute
        types={['distributor']}
        roles={['admin']}
        path='/resellers/create'
        exact
        component={ResellersForm}
      />
      <AuthenticatedRoute
        types={['distributor']}
        roles={['admin']}
        path='/resellers/update/:id'
        exact
        component={ResellersForm}
      />

      <AuthenticatedRoute
        types={['distributor']}
        roles={['admin']}
        path='/resellers/:reseller/users'
        exact
        component={UsersList}
      />
      <AuthenticatedRoute
        types={['distributor']}
        roles={['admin']}
        path='/resellers/:reseller/users/create'
        exact
        component={UsersForm}
      />
      <AuthenticatedRoute
        types={['distributor']}
        roles={['admin']}
        path='/resellers/:reseller/users/update/:id'
        exact
        component={UsersForm}
      />

      <AuthenticatedRoute
        types={['distributor', 'reseller']}
        roles={['admin']}
        path='/users'
        exact
        component={UsersList}
      />
      <AuthenticatedRoute
        types={['distributor', 'reseller']}
        roles={['admin']}
        path='/users/create'
        exact
        component={UsersForm}
      />
      <AuthenticatedRoute
        types={['distributor', 'reseller']}
        roles={['admin']}
        path='/users/update/:id'
        exact
        component={UsersForm}
      />

      <AuthenticatedRoute
        types={['reseller', 'distributor']}
        roles={['admin', 'standard']}
        path='/quotes'
        exact
        component={QuotesList}
      />

      <AuthenticatedRoute
        types={['reseller']}
        roles={['admin', 'standard']}
        path='/quotes/create'
        exact
        component={QuotesCreate}
      />

      <AuthenticatedRoute
        types={['reseller']}
        roles={['admin', 'standard']}
        path='/quotes/:id/:step?'
        exact
        component={QuotesView}
      />

      <AuthenticatedRoute
        types={['distributor', 'reseller']}
        roles={['admin']}
        path='/products/system'
        exact
        component={SystemProductList}
      />
      <AuthenticatedRoute
        types={['distributor', 'reseller']}
        roles={['admin']}
        path='/products/system/update/:id'
        exact
        component={SystemProductForm}
      />

      <AuthenticatedRoute
        types={['distributor', 'reseller']}
        roles={['admin']}
        path='/products/call-recording'
        exact
        component={CallRecordingList}
      />
      <AuthenticatedRoute
        types={['distributor', 'reseller']}
        roles={['admin']}
        path='/products/call-recording/create'
        exact
        component={CallRecordingForm}
      />
      <AuthenticatedRoute
        types={['distributor', 'reseller']}
        roles={['admin']}
        path='/products/call-recording/update/:id'
        exact
        component={CallRecordingForm}
      />

      <AuthenticatedRoute
        types={['distributor', 'reseller']}
        roles={['admin']}
        path='/products/number-porting'
        exact
        component={NumberPortingList}
      />
      <AuthenticatedRoute
        types={['distributor', 'reseller']}
        roles={['admin']}
        path='/products/number-porting/update/:id'
        exact
        component={NumberPortingForm}
      />

      <AuthenticatedRoute
        types={['distributor', 'reseller']}
        roles={['admin']}
        path='/products/bundled-minutes'
        exact
        component={BundledMinutesList}
      />
      <AuthenticatedRoute
        types={['distributor', 'reseller']}
        roles={['admin']}
        path='/products/bundled-minutes/create'
        exact
        component={BundledMinutesForm}
      />
      <AuthenticatedRoute
        types={['distributor', 'reseller']}
        roles={['admin']}
        path='/products/bundled-minutes/update/:id'
        exact
        component={BundledMinutesForm}
      />

      <AuthenticatedRoute
        types={['distributor', 'reseller']}
        roles={['admin']}
        path='/products/uc-licences'
        exact
        component={UcLicensesList}
      />
      <AuthenticatedRoute
        types={['distributor', 'reseller']}
        roles={['admin']}
        path='/products/uc-licences/create'
        exact
        component={UcLicensesForm}
      />
      <AuthenticatedRoute
        types={['distributor', 'reseller']}
        roles={['admin']}
        path='/products/uc-licences/update/:id'
        exact
        component={UcLicensesForm}
      />

      <AuthenticatedRoute
        types={['distributor', 'reseller']}
        roles={['admin']}
        path='/products/uc-applications'
        exact
        component={UcApplicationsList}
      />
      <AuthenticatedRoute
        types={['distributor', 'reseller']}
        roles={['admin']}
        path='/products/uc-applications/create'
        exact
        component={UcApplicationsForm}
      />
      <AuthenticatedRoute
        types={['distributor', 'reseller']}
        roles={['admin']}
        path='/products/uc-applications/update/:id'
        exact
        component={UcApplicationsForm}
      />

      <AuthenticatedRoute
        types={['reseller']}
        roles={['admin']}
        path='/products/connectivity'
        exact
        component={BroadbandPackagesList}
      />
      <AuthenticatedRoute
        types={['reseller']}
        roles={['admin']}
        path='/products/connectivity/create'
        exact
        component={BroadbandPackagesForm}
      />
      <AuthenticatedRoute
        types={['reseller']}
        roles={['admin']}
        path='/products/connectivity/update/:id'
        exact
        component={BroadbandPackagesForm}
      />

      <AuthenticatedRoute
        types={['distributor', 'reseller']}
        roles={['admin']}
        path='/products/hardware'
        exact
        component={HardwareList}
      />
      <AuthenticatedRoute
        types={['distributor', 'reseller']}
        roles={['admin']}
        path='/products/hardware/create'
        exact
        component={HardwareForm}
      />
      <AuthenticatedRoute
        types={['distributor', 'reseller']}
        roles={['admin']}
        path='/products/hardware/update/:id'
        exact
        component={HardwareForm}
      />

      <AuthenticatedRoute
        types={['distributor', 'reseller']}
        roles={['admin']}
        path='/products/connectivity-hardware'
        exact
        component={NetworkHardwareList}
      />
      <AuthenticatedRoute
        types={['distributor', 'reseller']}
        roles={['admin']}
        path='/products/connectivity-hardware/create'
        exact
        component={NetworkHardwareForm}
      />
      <AuthenticatedRoute
        types={['distributor', 'reseller']}
        roles={['admin']}
        path='/products/connectivity-hardware/update/:id'
        exact
        component={NetworkHardwareForm}
      />

      <AuthenticatedRoute
        types={['reseller']}
        roles={['admin']}
        path='/products/engineering'
        exact
        component={NetworkServicesList}
      />
      <AuthenticatedRoute
        types={['distributor', 'reseller']}
        roles={['admin']}
        path='/products/engineering/create'
        exact
        component={NetworkServicesForm}
      />
      <AuthenticatedRoute
        types={['distributor', 'reseller']}
        roles={['admin']}
        path='/products/engineering/update/:id'
        exact
        component={NetworkServicesForm}
      />

      <AuthenticatedRoute
        types={['reseller']}
        roles={['admin']}
        path='/tariffs'
        exact
        component={TariffsList}
      />
      <AuthenticatedRoute
        types={['reseller']}
        roles={['admin']}
        path='/tariffs/create'
        exact
        component={TariffsForm}
      />
      <AuthenticatedRoute
        types={['reseller']}
        roles={['admin']}
        path='/tariffs/update/:id'
        exact
        component={TariffsForm}
      />

      <AuthenticatedRoute
        types={['reseller']}
        roles={['admin']}
        path='/discount-codes'
        exact
        component={DiscountCodesList}
      />
      <AuthenticatedRoute
        types={['reseller']}
        roles={['admin']}
        path='/discount-codes/create'
        exact
        component={DiscountCodesForm}
      />
      <AuthenticatedRoute
        types={['reseller']}
        roles={['admin']}
        path='/discount-codes/update/:id'
        exact
        component={DiscountCodesForm}
      />

      <AuthenticatedRoute
        types={['reseller']}
        roles={['admin']}
        path='/leasing'
        exact
        component={LeasingList}
      />
      <AuthenticatedRoute
        types={['reseller']}
        roles={['admin']}
        path='/leasing/create'
        exact
        component={LeasingForm}
      />
      <AuthenticatedRoute
        types={['reseller']}
        roles={['admin']}
        path='/leasing/update/:id'
        exact
        component={LeasingForm}
      />

      <AuthenticatedRoute
        types={['distributor', 'reseller']}
        roles={['admin', 'standard']}
        path='/number-porting'
        exact
        component={NumberPortsList}
      />
      <AuthenticatedRoute
        types={['distributor', 'reseller']}
        roles={['admin', 'standard']}
        path='/number-porting/portability-check'
        exact
        component={PortabilityCheck}
      />
      <AuthenticatedRoute
        types={['distributor', 'reseller']}
        roles={['admin', 'standard']}
        path='/number-porting/geographic-port/:id?'
        exact
        component={GeographicPort}
      />

      <AuthenticatedRoute
        types={['reseller']}
        roles={['admin']}
        path='/proposal'
        exact
        component={ProposalsForm}
      />

      <AuthenticatedRoute
        types={['reseller', 'distributor']}
        roles={['admin', 'standard']}
        path='/blog'
        exact
        component={BlogList}
      />

      <AuthenticatedRoute
        types={['reseller', 'distributor']}
        roles={['admin', 'standard']}
        path='/blog/:slug'
        exact
        component={BlogPost}
      />

      <AuthenticatedRoute
        types={['reseller']}
        roles={['admin', 'standard']}
        path='/candio'
        exact
        component={Candio}
      />

      <Route path='/library/shared/:id' exact component={LibraryShared} />
      <Route path='/quote-share/:id' exact component={QuoteShared} />

      <UnauthenticatedRoute
        path='/password/reset'
        exact
        component={PasswordResetEmailForm}
      />
      <UnauthenticatedRoute
        path='/password/reset/:token'
        exact
        component={PasswordResetPasswordForm}
      />

      <AuthenticatedRoute path='/logout' exact component={Logout} />
      <UnauthenticatedRoute path='/login' exact component={Login} />
      <UnauthenticatedRoute path='/signup' exact component={Signup} />
      {/* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
    </Switch>
  )
}

export default AppRoutes
