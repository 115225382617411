import Button from '@material-ui/core/Button'
import { green } from '@material-ui/core/colors'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'

const GreenButton = withStyles(theme => ({
  root: {
    color: green[500],
    '&:hover': {
      color: green[700],
    },
  },
}))(Button)

function WonDialog({ loading, quoteId, open, handleClose, handleSubmit }) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id='alert-dialog-title'>{'Quote Won'}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          Are you sure you want to set this quote as won?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleClose} color='primary'>
          Cancel
        </Button>
        <GreenButton
          disabled={loading}
          onClick={() => handleSubmit(quoteId)}
          color='secondary'
          autoFocus>
          Submit
        </GreenButton>
      </DialogActions>
    </Dialog>
  )
}

export default WonDialog
