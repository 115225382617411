import { Box } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import MuiAlert from '@material-ui/lab/Alert'
import { get as getReseller } from 'api/resellers'
import { create, get, update } from 'api/users'
import { AuthContext } from 'contexts/AuthContext'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import UserAvatar from 'components/UserAvatar'
import ImageField from 'components/ImageField'

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  currentAvatar: {
    marginBottom: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  orm: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    minWidth: '100%',
    marginTop: theme.spacing(3),
  },
  permissions: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
}))

function Form(props) {
  const { reseller, id } = useParams()
  const { loading, setLoading } = useContext(LoadingContext)
  const { user } = useContext(AuthContext)
  const [hideContent, setHideContent] = useState(true)
  const [resellerFeatures, setResellerFeature] = useState([])
  const [isVerified, setIsVerified] = useState(false)
  const [values, setValues] = useState({
    first_name: { value: '', error: '' },
    last_name: { value: '', error: '' },
    email: { value: '', error: '' },
    contact_email: { value: '', error: '' },
    phone_number: { value: '', error: '' },
    role: { value: '', error: '' },
    commission_percent: { value: '', error: '' },
    price_list_permission: { value: false },
    quote_permission: { value: false },
    library_permission: { value: false },
    number_porting_permission: { value: false },
  })
  const [meta, setMeta] = useState({})
  const [errors, setErrors] = useState([])
  const classes = useStyles()

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      if (reseller) {
        const resellerResult = await getReseller(reseller)
        setResellerFeature(resellerResult.data.permissions)
      } else {
        setResellerFeature(user.account_features)
      }

      if (id !== undefined) {
        const userResult = await get(id, reseller)
        const dataValues = {
          first_name: { value: userResult.data.first_name },
          last_name: { value: userResult.data.last_name },
          email: { value: userResult.data.email },
          contact_email: { value: userResult.data.contact_email },
          phone_number: { value: userResult.data.phone_number },
          role: { value: userResult.data.role },
          commission_percent: { value: userResult.data.commission_percent },
          quote_permission: {
            value: userResult.data.permissions.indexOf('quote') !== -1,
          },
          library_permission: {
            value: userResult.data.permissions.indexOf('library') !== -1,
          },
          price_list_permission: {
            value: userResult.data.permissions.indexOf('price_list') !== -1,
          },
          number_porting_permission: {
            value: userResult.data.permissions.indexOf('number_porting') !== -1,
          },
          current_avatar: userResult.data.avatar,
        }

        setIsVerified(userResult.data.verified)
        setValues(dataValues)
        setMeta(userResult.meta ?? {})
      }

      setLoading(false)
      setHideContent(false)
    }

    fetchData()
  }, [id, reseller, setValues, setLoading, user.account_features])

  const hasAccountFeature = feature => {
    return resellerFeatures.indexOf(feature) !== -1
  }

  const canSetRole = () => {
    return (reseller || user.type === 'reseller') && user.role === 'admin'
  }

  const canSetCommissionPercent = () => {
    return (reseller || user.type === 'reseller') && user.role === 'admin'
  }

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: { value: value } })
  }

  const handleChangeSwitch = event => {
    setValues({ ...values, [event.target.name]: { value: event.target.checked } })
  }

  const handeAvatarChange = data => {
    setValues({ ...values, avatar: data })
  }

  const handleSubmit = async event => {
    event.preventDefault()

    setLoading(true)
    const body = {
      first_name: values.first_name.value,
      last_name: values.last_name.value,
      email: values.email.value,
      contact_email: values.contact_email.value,
      phone_number: values.phone_number.value,
      avatar: values.avatar,
    }

    if (canSetRole()) {
      body.role = values.role.value
      body.quote_permission = values.quote_permission.value
      body.library_permission = values.library_permission.value
      body.price_list_permission = values.price_list_permission.value
      body.number_porting_permission = values.number_porting_permission.value
    }

    if (canSetCommissionPercent()) {
      body.commission_percent = values.commission_percent.value
    }

    if (reseller) {
      body.rid = reseller
    }

    const result = id ? await update(id, body) : await create(body)
    if (result.success) {
      setLoading(false)
      props.history.push({
        pathname: reseller ? `/resellers/${reseller}/users` : '/users',
        state: {
          severity: 'success',
          message: result.message,
        },
      })
    } else {
      setErrors(result.errors)
      setLoading(false)
    }
  }

  const getButtonLabel = () => {
    if (id) {
      return isVerified ? 'Update' : 'Update & Verify User'
    } else {
      return 'Create'
    }
  }

  if (hideContent) {
    return null
  }

  return (
    <div>
      <Typography variant='h3' gutterBottom>
        {id
          ? reseller
            ? `Update ${meta.reseller_name} User`
            : 'Update User'
          : 'Create User'}
      </Typography>

      <form className={classes.form} onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item sm={12} md={7} xl={5}>
            <Typography variant='h5' gutterBottom>
              Main Info
            </Typography>
            <Paper className={classes.paper}>
              <TextField
                variant='standard'
                margin='normal'
                required
                fullWidth
                id='first_name'
                label='First Name'
                name='first_name'
                defaultValue={values.first_name.value}
                autoFocus
                onChange={handleChange.bind(this)}
              />
              <TextField
                variant='standard'
                margin='normal'
                required
                fullWidth
                id='last_name'
                label='Last Name'
                name='last_name'
                defaultValue={values.last_name.value}
                onChange={handleChange.bind(this)}
              />
              <TextField
                variant='standard'
                margin='normal'
                required
                fullWidth
                type='email'
                id='email'
                label='Email'
                name='email'
                defaultValue={values.email.value}
                onChange={handleChange.bind(this)}
                helperText="This user's login email address"
              />
              {(reseller || user.type === 'reseller') && (
                <TextField
                  variant='standard'
                  margin='normal'
                  fullWidth
                  type='email'
                  id='email'
                  label='Contact Email'
                  name='contact_email'
                  defaultValue={values.contact_email.value}
                  onChange={handleChange.bind(this)}
                  helperText='This address will be displayed on quotes created by this user'
                />
              )}
              <TextField
                variant='standard'
                margin='normal'
                fullWidth
                id='phone_number'
                label='Phone Number'
                name='phone_number'
                defaultValue={values.phone_number.value}
                onChange={handleChange.bind(this)}
              />

              {canSetRole() && (
                <React.Fragment>
                  <FormControl variant='standard' className={classes.formControl}>
                    <InputLabel id='role_label'>Role</InputLabel>
                    <Select
                      labelId='role_label'
                      id='role'
                      name='role'
                      value={values.role.value}
                      onChange={handleChange.bind(this)}
                      label='Role'>
                      <MenuItem value='standard'>Standard</MenuItem>
                      <MenuItem value='admin'>Admin</MenuItem>
                    </Select>
                  </FormControl>
                  <Box mt={2}>
                    <Typography variant='caption'>
                      Admin users will have access to user manage and configurations
                      settings (products, leasing) etc. standard users will not have
                      access to these settings
                    </Typography>
                  </Box>
                </React.Fragment>
              )}

              {canSetCommissionPercent() && (
                <TextField
                  variant='standard'
                  margin='normal'
                  fullWidth
                  type='commission_percent'
                  id='commission_percent'
                  label='Commission Percent'
                  name='commission_percent'
                  defaultValue={values.commission_percent.value}
                  onChange={handleChange.bind(this)}
                />
              )}
            </Paper>

            <Paper className={classes.paper}>
              <Typography variant='subtitle1' gutterBottom>
                Profile Image
              </Typography>
              <UserAvatar className={classes.currentAvatar} user={user}></UserAvatar>
              <ImageField onChange={handeAvatarChange} />
            </Paper>

            {errors.length > 0 && (
              <MuiAlert severity='error'>
                {errors.map((error, index) => {
                  return <div key={index}>{error[1]}</div>
                })}
              </MuiAlert>
            )}

            <Button
              type='submit'
              variant='contained'
              color='primary'
              disabled={loading}
              className={classes.submit}>
              {getButtonLabel()}
            </Button>
          </Grid>

          {(hasAccountFeature('quote') ||
            hasAccountFeature('library') ||
            hasAccountFeature('price_list') ||
            hasAccountFeature('number_porting')) && (
            <Grid item md={5} xl={5}>
              <Typography variant='h5' gutterBottom>
                Features & Permission
              </Typography>

              <div className={classes.permissions}>
                {hasAccountFeature('quote') && (
                  <Card className={classes.cardRoot}>
                    <CardContent>
                      <Typography variant='h5' component='h2'>
                        Quoting
                      </Typography>

                      <Typography variant='body2' component='p' gutterBottom>
                        Allows users to view, create and save quotes.
                      </Typography>

                      <Typography variant='body2' component='p'>
                        If the user is set as an admin they will be able to see all
                        quotes, otherwise they will only be able to see their own quotes.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Switch
                        checked={values.quote_permission.value}
                        onChange={handleChangeSwitch}
                        name='quote_permission'
                        color='primary'
                      />
                    </CardActions>
                  </Card>
                )}

                {hasAccountFeature('library') && (
                  <Card className={classes.cardRoot}>
                    <CardContent>
                      <Typography variant='h5' component='h2'>
                        Library
                      </Typography>

                      <Typography variant='body2' component='p'>
                        Gives access to the main Library and Videos section. Also enables
                        MyLibrary access where they can create and manage your reseller
                        library content .
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Switch
                        checked={values.library_permission.value}
                        onChange={handleChangeSwitch}
                        name='library_permission'
                        color='primary'
                      />
                    </CardActions>
                  </Card>
                )}

                {hasAccountFeature('price_list') && (
                  <Card className={classes.cardRoot}>
                    <CardContent>
                      <Typography variant='h5' component='h2'>
                        Price List
                      </Typography>

                      <Typography variant='body2' component='p'>
                        Gives access to the Price List section on the left nav menu.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Switch
                        checked={values.price_list_permission.value}
                        onChange={handleChangeSwitch}
                        name='price_list_permission'
                        color='primary'
                      />
                    </CardActions>
                  </Card>
                )}

                {hasAccountFeature('number_porting') && (
                  <Card className={classes.cardRoot}>
                    <CardContent>
                      <Typography variant='h5' component='h2'>
                        Number Porting
                      </Typography>

                      <Typography variant='body2' component='p'>
                        Gives access to the number porting section to create and manage
                        number port request.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Switch
                        checked={values.number_porting_permission.value}
                        onChange={handleChangeSwitch}
                        name='number_porting_permission'
                        color='primary'
                      />
                    </CardActions>
                  </Card>
                )}
              </div>
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  )
}

export default Form
