import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import React, { useEffect, useState } from 'react'
import SummaryBox from 'components/quotes/SummaryBox'

const useStyles = makeStyles(theme => ({
  action: {
    width: '100px',
    marginTop: '0',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  button: {
    width: '140px',
    marginTop: '0',
  },
}))

const WhiteTextField = withStyles({
  root: {
    '& .MuiInputLabel-root': {
      color: '#FFF', // Label
    },
    '& .MuiInputBase-input': {
      color: '#FFF', // Text color
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#FFF9', // Solid underline
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#FFF', // Solid underline on hover
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#FFF', // Solid underline on focus
    },
  },
})(TextField)

function StandardItem({ options, selectedItems, id, onChange }) {
  const classes = useStyles()
  const [selected, setSelected] = useState(false)
  const [quantity, setQuantity] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    const itemIndex =
      selectedItems && Array.isArray(selectedItems)
        ? selectedItems.findIndex(obj => obj.id === id)
        : -1
    if (itemIndex !== -1) {
      setQuantity(selectedItems[itemIndex].quantity)
      setSelected(true)
    }
    setLoading(false)
  }, [selectedItems, id, setQuantity, setSelected])

  const handleClick = () => {
    setSelected(true)
  }

  const handleChangeQuantity = event => {
    const value = parseInt(event.target.value)
    setQuantity(value > 0 ? value : '')
    handleChangeItem(value)
  }

  const handleBlur = () => {
    if (!quantity) {
      setSelected(false)
    }
  }

  const handleChangeItem = qty => {
    onChange(id, qty)
  }

  const getImageUrl = () => {
    return options[id].hasOwnProperty('image_url') ? options[id].image_url : ''
  }

  const getImageThumbUrl = () => {
    return options[id].hasOwnProperty('image_thumb_url')
      ? options[id].image_thumb_url
      : ''
  }

  return (
    <SummaryBox
      variant={getImageThumbUrl() ? 'image' : 'text'}
      header={getImageThumbUrl() ? options[id].name : ''}
      text={options[id].name}
      interop_url={options[id].interop_url ?? null}
      image_thumb_url={getImageThumbUrl()}
      image_url={getImageUrl()}>
      {loading && <CircularProgress />}

      {!loading && !selected && (
        <Button
          variant='contained'
          color='default'
          style={{ marginTop: '16px' }}
          className={classes.button}
          onClick={handleClick}>
          Select
        </Button>
      )}

      {!loading && selected && (
        <div onBlur={handleBlur}>
          {getImageThumbUrl() ? (
            <TextField
              className={classes.action}
              variant='standard'
              margin='normal'
              required
              value={quantity}
              onChange={handleChangeQuantity}
              id='quantity'
              label='Quantity'
              name='quantity'
              type='number'
            />
          ) : (
            <WhiteTextField
              className={classes.action}
              variant='standard'
              margin='normal'
              required
              value={quantity}
              onChange={handleChangeQuantity}
              id='quantity'
              label='Quantity'
              name='quantity'
              type='number'
            />
          )}
        </div>
      )}
    </SummaryBox>
  )
}

export default StandardItem
