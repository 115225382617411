import { Popover } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    //    background: 'transparent',
  },
  container: {
    display: 'inline-block',
  },
  wrapper: {
    height: '800px',
    width: '800px',
    verticalAlign: 'middle',
    textAlign: 'center',
    display: 'table-cell',
    background: `transparent url('https://cdnjs.cloudflare.com/ajax/libs/galleriffic/2.0.1/css/loader.gif') center no-repeat`,
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    display: 'block',
    margin: 'auto',
    background: `transparent url('https://cdnjs.cloudflare.com/ajax/libs/galleriffic/2.0.1/css/loader.gif') center no-repeat`,
  },
}))

function HardwareImagePopover({ imageUrl, anchorEl, setAnchorEl }) {
  const classes = useStyles()

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  return (
    <Popover
      id='mouse-over-popover'
      PaperProps={{ className: classes.paper }}
      className={classes.popover}
      open={anchorEl ? true : false}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
      transformOrigin={{ vertical: 'center', horizontal: 'center' }}
      onClose={handlePopoverClose}
      disableRestoreFocus>
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <img className={classes.image} alt='' src={imageUrl} />
        </div>
      </div>
    </Popover>
  )
}

export default HardwareImagePopover
