import config from 'config'
import { getCookie, getJsonHeaders } from '../helpers'

export async function list(categoryId = '', limit = 15, page = 1, search = '') {
  let url = `/api/quickstart-library/posts?per_page=${limit}&page=${page}`

  if (categoryId) {
    url += `&cats=${categoryId}`
  }

  if (search) {
    url += `&search=${search}`
  }

  const result = await fetch(config.API_URL + url)
  return await result.json()
}

export async function get(id) {
  let url = `/api/quickstart-library/posts/${id}`
  const result = await fetch(config.API_URL + url)
  return await result.json()
}

export async function update(id, body) {
  try {
    const res = await fetch(config.API_URL + `/api/quickstart-library/posts/${id}`, {
      method: 'POST',
      headers: {
        'X-XSRF-TOKEN': await getCookie('XSRF-TOKEN'),
      },
      body: body,
    })

    const decoded = await res.json()

    if (res.status === 200) {
      return {
        success: true,
        message: 'Successfully updated post',
        post: decoded,
      }
    }

    return {
      success: false,
      message: decoded.message,
      errors: Object.entries(decoded.errors).map(error => {
        return error
      }),
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to update post',
      errors: [['', 'Failed to update post']],
    }
  }
}

export async function create(body) {
  try {
    const res = await fetch(config.API_URL + `/api/quickstart-library/posts`, {
      method: 'POST',
      headers: {
        'X-XSRF-TOKEN': await getCookie('XSRF-TOKEN'),
      },
      body: body,
    })

    const decoded = await res.json()

    if (res.status === 201) {
      return {
        success: true,
        message: 'Successfully created post',
        post: decoded,
      }
    }
    return {
      success: false,
      message: decoded.message,
      errors: Object.entries(decoded.errors).map(error => {
        return error
      }),
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to create post',
      errors: [['', 'Failed to create post']],
    }
  }
}

export async function deletePost(id) {
  try {
    let url = `/api/quickstart-library/posts/${id}`
    const res = await fetch(config.API_URL + url, {
      method: 'DELETE',
      headers: await getJsonHeaders(),
      body: JSON.stringify({}),
    })

    if (res.status === 204) {
      return true
    }

    return false
  } catch (e) {
    return false
  }
}
