import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getShared } from 'api/library/library'
import PostView from 'components/library/post/PostView'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: theme.breakpoints.width('lg'),
    margin: 'auto',
    marginTop: theme.spacing(6),
  },
}))

function Shared(props) {
  const classes = useStyles()
  const { id } = useParams()
  const { setLoading } = useContext(LoadingContext)
  const [entity, setEntity] = useState()

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      setEntity(await getShared(id))
      setLoading(false)
    }

    fetchData()
  }, [id, setLoading])

  if (!entity) {
    return null
  }

  return (
    <React.Fragment>
      <Box className={classes.container}>
        <PostView post={entity} />
      </Box>
    </React.Fragment>
  )
}

export default Shared
