import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { GeographicPortContext } from 'contexts/GeographicPortContext'
import React, { useContext } from 'react'
import PortActionInputLabel from './PortActionInputLabel'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(4),
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
}))

function MainNumber() {
  const classes = useStyles()
  const { state, dispatch } = useContext(GeographicPortContext)

  const handleChangeAction = event => {
    dispatch({
      type: 'SET_MAIN_NUMBER',
      payload: { key: 'action', value: event.target.value },
    })
  }

  const handleChangeInService = event => {
    dispatch({
      type: 'SET_MAIN_NUMBER',
      payload: { key: 'is_in_service', value: event.target.value === 'yes' },
    })
  }

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5' gutterBottom>
        Main Number
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            variant='standard'
            margin='normal'
            fullWidth
            required
            disabled={true}
            id='std_code'
            label='STD Code (area code)'
            name='std_code'
            value={state.number_details.std_code.value}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <TextField
            variant='standard'
            margin='normal'
            fullWidth
            required
            disabled={true}
            id='main_number_local_number'
            label='Local Number'
            name='main_number_local_number'
            value={state.number_details.main_number.local_number.value}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <FormControl variant='standard' className={classes.formControl}>
            <PortActionInputLabel />
            <Select
              labelId='action_label'
              id='action'
              name='action'
              value={state.number_details.main_number.action.value}
              onChange={handleChangeAction}
              label='Action'>
              <MenuItem value='PORT'>Port</MenuItem>
              <MenuItem value='CEASE'>Cease</MenuItem>
              <MenuItem value='REMAIN'>Remain</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <FormControl variant='standard' className={classes.formControl}>
            <InputLabel id='in_service_label'>In Service</InputLabel>
            <Select
              labelId='in_service_label'
              id='is_in_service'
              name='is_in_service'
              value={state.number_details.main_number.is_in_service.value ? 'yes' : 'no'}
              onChange={handleChangeInService}
              label='In Service'>
              <MenuItem value='yes'>Yes</MenuItem>
              <MenuItem value='no'>No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default MainNumber
