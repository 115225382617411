import { Box, Link } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import MuiAlert from '@material-ui/lab/Alert'
import { create, get, listCategories, update } from 'api/products/network-hardware'
import CostPriceField from 'components/CostPriceField'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-image-crop/lib/ReactCrop.scss'
import { useParams } from 'react-router-dom'
import HardwareImagePopover from '../../../components/HardwareImagePopover'
import ImageCropper from '../../../components/ImageCropper'

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  paperFlex: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  orm: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    minWidth: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  image: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
  },
  selectedImage: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: '0.75rem',
    textAlign: 'center',
  },
  productImage: {
    marginTop: theme.spacing(3),
  },
}))

function Form(props) {
  const { id } = useParams()
  const { t } = useTranslation()
  const { loading, setLoading } = useContext(LoadingContext)
  const [hideContent, setHideContent] = useState(true)

  const [categories, setCategories] = useState([])
  const [values, setValues] = useState({
    category_id: { value: '', error: '' },
    name: { value: '', error: '' },
    product_code: { value: '', error: '' },
    description: { value: '', error: '' },
    upfront_cost_price: { value: '', error: '' },
    upfront_rrp: { value: '', error: '' },
    monthly_cost_price: { value: '', error: '' },
    monthly_rrp: { value: '', error: '' },
    video_url: { value: '', error: '' },
    interop_url: { value: '', error: '' },
    image: { value: '', error: '', fileName: '' },
  })
  const [monthlyEnabled, setMonthlyEnabled] = useState(false)
  const [originalImageBase64, setOriginalImageBase64] = useState()
  const [imageBase64, setImageBase64] = useState()
  const [imageUrl, setImageUrl] = useState({ full: '', thumb: '' })
  const [errors, setErrors] = useState([])
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [isOwner, setIsOwner] = useState(true)

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      if (id !== undefined) {
        const [product, cats] = await Promise.all([get(id), listCategories()])
        setCategories(cats.data)
        const dataValues = {
          category_id: { value: product.data.category_id },
          name: { value: product.data.name },
          product_code: { value: product.data.product_code },
          description: { value: product.data.description },
          upfront_cost_price: { value: getDisplayPrice(product.data.upfront_cost_price) },
          upfront_rrp: { value: getDisplayPrice(product.data.upfront_rrp) },
          monthly_cost_price: { value: getDisplayPrice(product.data.monthly_cost_price) },
          monthly_rrp: { value: getDisplayPrice(product.data.monthly_rrp) },
          video_url: { value: product.data.video_url },
          interop_url: { value: product.data.interop_url },
          image: { value: '', error: '', fileName: '' },
        }
        if (product.data.monthly_rrp > 0) {
          setMonthlyEnabled(true)
        }
        setImageUrl({ full: product.data.image_url, thumb: product.data.image_thumb_url })
        setValues(dataValues)
        setIsOwner(product.data.is_owner)
        setLoading(false)
        setHideContent(false)
      } else {
        const cats = await listCategories()
        setCategories(cats.data)
      }

      setLoading(false)
      setHideContent(false)
    }

    fetchData()
  }, [id, setValues, setLoading])

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: { value: value } })
  }

  const handleChangeMonthlyEnabled = event => {
    if (parseInt(event.target.value) === 1) {
      setMonthlyEnabled(true)
    } else {
      setMonthlyEnabled(false)
      setValues({ ...values, monthly_cost_price: { value: '' } })
      setValues({ ...values, monthly_rrp: { value: '' } })
    }
  }

  const handleImageChange = event => {
    setLoading(true)
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    const key = 'image'
    setValues({ ...values, [key]: { value: file, fileName: file.name } })
    reader.onloadend = () => {
      setOriginalImageBase64(reader.result)
      setImageBase64(reader.result)
      setLoading(false)
    }
  }

  const handleSubmit = async event => {
    event.preventDefault()

    setLoading(true)
    const body = {
      category_id: values.category_id.value,
      name: values.name.value,
      product_code: values.product_code.value,
      description: values.description.value,
      upfront_cost_price: values.upfront_cost_price.value * 100,
      monthly_cost_price: values.monthly_cost_price.value * 100,
      upfront_rrp: values.upfront_rrp.value * 100,
      monthly_rrp: values.monthly_rrp.value * 100,
      video_url: values.video_url.value,
      interop_url: values.interop_url.value,
    }

    if (values.image.value) {
      body.image = imageBase64
    }

    const result = id ? await update(id, body) : await create(body)
    if (result.success) {
      setLoading(false)
      props.history.push({
        pathname: '/products/connectivity-hardware',
        state: {
          severity: 'success',
          message: result.message,
        },
      })
    } else {
      setErrors(result.errors)
      setLoading(false)
    }
  }

  const getDisplayPrice = price => {
    if (price === 0) {
      return price.toFixed(2)
    } else if (price > 0) {
      return (price / 100).toFixed(2)
    }

    return ''
  }

  if (hideContent) {
    return null
  }

  if (categories.length === 0) {
    return (
      <Box textAlign='center' marginTop={5}>
        You need to create a category before creating a product.
        <br />
        <Link to='/products/connectivity-hardware'>
          Click here to go to connectivity products where you can click "manage
          categories".
        </Link>
      </Box>
    )
  }

  return (
    <div>
      <Typography variant='h3' gutterBottom>
        {id ? 'Update Connectivity Hardware' : 'Create Connectivity Hardware'}
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit} noValidate>
        <Paper className={classes.paper}>
          <FormControl className={classes.formControl}>
            <InputLabel id='cat-id-label'>Category</InputLabel>
            <Select
              labelId='cat-id-label'
              name='category_id'
              value={values.category_id.value}
              disabled={!isOwner}
              onChange={handleChange}>
              {categories.map(x => (
                <MenuItem key={x.id} value={x.id}>
                  {x.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            variant='standard'
            margin='normal'
            required
            fullWidth
            id='name'
            label='Name'
            name='name'
            disabled={!isOwner}
            defaultValue={values.name.value}
            onChange={handleChange.bind(this)}
          />
          <CostPriceField
            values={values}
            setValues={setValues}
            valuesProperty='upfront_cost_price'
            label={t('Upfront Cost')}
          />
          <TextField
            variant='standard'
            margin='normal'
            required
            fullWidth
            type='number'
            step='0.01'
            id='upfront_rrp'
            label={t('Upfront RRP')}
            name='upfront_rrp'
            InputLabelProps={{
              shrink: true,
            }}
            defaultValue={values.upfront_rrp.value}
            onChange={handleChange.bind(this)}
          />
          <TextField
            variant='standard'
            margin='normal'
            fullWidth
            id='video_url'
            label='Video URL'
            name='video_url'
            disabled={!isOwner}
            defaultValue={values.video_url.value}
            onChange={handleChange.bind(this)}
          />
          <TextField
            variant='standard'
            margin='normal'
            fullWidth
            id='interop_url'
            label='Interop Checker URL'
            name='interop_url'
            disabled={!isOwner}
            defaultValue={values.interop_url.value}
            onChange={handleChange.bind(this)}
          />
          <TextField
            variant='standard'
            margin='normal'
            fullWidth
            id='product_code'
            label='Product Code'
            name='product_code'
            disabled={!isOwner}
            defaultValue={values.product_code.value}
            onChange={handleChange.bind(this)}
          />
          <TextField
            variant='standard'
            margin='normal'
            fullWidth
            id='description'
            label='Description'
            name='description'
            disabled={!isOwner}
            defaultValue={values.description.value}
            onChange={handleChange.bind(this)}
            multiline
            rows={4}
          />
        </Paper>

        <Paper className={classes.paper}>
          <div>
            <FormControl variant='standard' className={classes.formControl}>
              <InputLabel id='monthly_enabled_label'>Allow Monthly Payments</InputLabel>
              <Select
                labelId='monthly_enabled_label'
                id='monthly_enabled'
                name='monthly_enabled'
                value={monthlyEnabled ? '1' : '0'}
                onChange={handleChangeMonthlyEnabled.bind(this)}
                label='Allow Monthly Payments'>
                <MenuItem value='0'>No</MenuItem>
                <MenuItem value='1'>Yes</MenuItem>
              </Select>
            </FormControl>

            {monthlyEnabled && (
              <div>
                <Typography
                  variant='body1'
                  style={{ fontSize: '0.8rem', padding: '14px' }}
                  gutterBottom>
                  Allowing monthly billing allows the user to choose to bill this product
                  monthly ongoing instead of using the upfront price.
                </Typography>

                <TextField
                  variant='standard'
                  margin='normal'
                  required
                  fullWidth
                  type='number'
                  step='0.01'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id='monthly_cost_price'
                  label={t('Monthly Cost')}
                  name='monthly_cost_price'
                  defaultValue={values.monthly_cost_price.value}
                  onChange={handleChange.bind(this)}
                />
                <TextField
                  variant='standard'
                  margin='normal'
                  required
                  fullWidth
                  type='number'
                  step='0.01'
                  id='monthly_rrp'
                  label={t('Monthly RRP')}
                  name='monthly_rrp'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={values.monthly_rrp.value}
                  onChange={handleChange.bind(this)}
                />
              </div>
            )}
          </div>
        </Paper>

        <Paper className={classes.paperFlex}>
          {isOwner && (
            <div className={classes.image}>
              <input
                accept='image/*'
                style={{ display: 'none' }}
                id='image'
                name='image'
                multiple
                type='file'
                onChange={handleImageChange.bind(this)}
              />
              <label htmlFor='image'>
                <Button variant='contained' component='span'>
                  Upload Image
                </Button>
              </label>
              {values.image.fileName && (
                <div className={classes.selectedImage}>
                  Selected file: {values.image.fileName}
                </div>
              )}
            </div>
          )}

          {imageBase64 && (
            <>
              <Typography variant='subtitle1' gutterBottom>
                Crop image as required
              </Typography>
              <ImageCropper
                imageBase64={originalImageBase64}
                onChange={setImageBase64}
                minImageWidth={800}
                maxRenderWidth={800}
                maxRenderHeight={800}
              />
            </>
          )}

          {imageUrl.full && !imageBase64 && (
            <div>
              <div className={classes.productImage}>
                <img
                  style={{
                    minHeight: '200px',
                    minWidth: '200px',
                    background: `transparent url('https://cdnjs.cloudflare.com/ajax/libs/galleriffic/2.0.1/css/loader.gif') center no-repeat`,
                  }}
                  width='200'
                  alt=''
                  src={imageUrl.thumb ? imageUrl.thumb : imageUrl.thumb}
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                />
              </div>

              <HardwareImagePopover
                imageUrl={imageUrl.full}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
              />
            </div>
          )}
        </Paper>

        {errors.length > 0 && (
          <MuiAlert severity='error'>
            {errors.map(error => {
              return <div>{error[1]}</div>
            })}
          </MuiAlert>
        )}
        <Button
          type='submit'
          variant='contained'
          color='primary'
          disabled={loading}
          className={classes.submit}>
          {id ? 'Update' : 'Create'}
        </Button>
      </form>
    </div>
  )
}

export default Form
