export const getPounds = value => {
  return (
    (value / 100)
      .toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      .split('.')[0] ?? ''
  )
}

export const getPence = value => {
  return (
    (value / 100)
      .toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      .split('.')[1] ?? '00'
  )
}
