import { Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MuiAlert from '@material-ui/lab/Alert'
import { createPriceList, getPriceList } from 'api/library/posts'
import PostView from 'components/library/post/PostView'
import { AuthContext } from 'contexts/AuthContext'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect, useState } from 'react'
import PostEdit from '../library/post/PostEdit'

const useStyles = makeStyles(theme => ({
  createContainer: {
    maxWidth: theme.breakpoints.width('sm'),
    margin: 'auto',
  },
  container: {
    maxWidth: theme.breakpoints.width('lg'),
    margin: 'auto',
  },
}))

function PriceList(props) {
  const classes = useStyles()
  const { user } = useContext(AuthContext)
  const { setLoading } = useContext(LoadingContext)
  const [post, setPost] = useState()
  const [errors, setErrors] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [creating, setCreating] = useState()
  const [editMode, setEditMode] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const res = await getPriceList()
      if (res) {
        setPost(res)
      }
      setLoaded(true)
      setLoading(false)
    }

    fetchData()
  }, [setLoading])

  const toggleEditMode = () => {
    setEditMode(!editMode)
  }

  const handleCreate = async () => {
    setCreating(true)
    const res = await createPriceList()
    setCreating(false)

    if (res.success) {
      setPost(res.post)
      setEditMode(true)
    } else {
      setErrors(res.errors)
    }
  }

  const renderCreate = () => {
    if (user.type === 'distributor') {
      return (
        <Box className={classes.createContainer} textAlign='center' pt={5}>
          <Box mb={5}>
            No price list information has been uploaded. Click the button below to get
            started.
          </Box>
          <Button
            size='small'
            variant='contained'
            color='primary'
            onClick={handleCreate}
            disabled={creating}>
            Create Price List
          </Button>

          {errors.length > 0 && (
            <MuiAlert severity='error'>{errors.map(x => x[1])}</MuiAlert>
          )}
        </Box>
      )
    } else {
      return (
        <Box className={classes.createContainer} textAlign='center' pt={5}>
          No pricing lists have been added
        </Box>
      )
    }
  }

  if (!loaded) {
    return null
  }

  if (!post) {
    return renderCreate()
  }

  return (
    <React.Fragment>
      <Box className={classes.container}>
        {user.type === 'distributor' && (
          <Box mb={2}>
            <Button size='small' variant='contained' onClick={toggleEditMode}>
              {editMode ? 'Cancel Editing' : 'Edit Price List'}
            </Button>
          </Box>
        )}
        {editMode ? (
          <PostEdit
            post={post}
            setPost={setPost}
            toggleEditMode={toggleEditMode}
            history={props.history}
          />
        ) : (
          <PostView post={post} shareable={false} />
        )}
      </Box>
    </React.Fragment>
  )
}

export default PriceList
