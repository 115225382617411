import { Divider } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Link from 'components/Link'
import React, { useState } from 'react'
import Post from './Post'
import TextContentRenderer from 'components/TextContentRenderer'

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(6),
    display: 'grid',
    gridGap: theme.spacing(4),
    gridTemplateAreas: '"column-2" "column-1"',
    [theme.breakpoints.up('lg')]: {
      gridTemplateAreas: '"column-1 column-2"',
      gridTemplateColumns: '8fr 4fr',
    },
  },
  column1: {
    gridArea: 'column-1',
  },
  column2: {
    gridArea: 'column-2',
  },
  featuredCategoryCard: {
    height: '100%',
    position: 'relative',
  },
  featuredCategoryPush: {
    height: '50px',
  },
  featuredCategoryActions: {
    height: '50px',
    position: 'absolute',
    bottom: '0',
  },
  featuredCategories: {
    display: 'grid',
    gridGap: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  categoriesMenu: {
    background: theme.palette.backgroundMain,
    '& *': {
      color: '#FFF',
    },
  },
  categoriesMenuItem: {
    padding: 0,
    margin: 0,
  },
  menuItemDivider: {
    background: theme.palette.lightBlue,
  },
  recentlyUpdated: {
    display: 'grid',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    background: '#fff',
    gridColumnGap: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  videos: {
    display: 'grid',
    gridGap: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  video: {
    width: '100%',
    height: '200px',
    marginBottom: '-6px',
  },
  menuLink: {
    textDecoration: 'none',
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  menuLinkIcon: {
    background: theme.palette.primary[500],
    height: '100%',
    padding: theme.spacing(2, 3),
    '&:hover': {
      background: theme.palette.primary[700],
    },
  },
}))

function Summary({ data, history, linkPrefix }) {
  const classes = useStyles()
  const [categoriesOpen, setCategoriesOpen] = useState({})

  const handleCategoryClick = (id, hasSubCats) => {
    if (hasSubCats) {
      const newValue = categoriesOpen.hasOwnProperty(id) ? !categoriesOpen[id] : true
      setCategoriesOpen({ ...categoriesOpen, [id]: newValue })
    } else {
      history.push(`/${linkPrefix}/categories/${id}`)
    }
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.column1}>
        {data.posts.featured.length > 0 && (
          <Box mb={4}>
            <Typography variant='h5' gutterBottom>
              Features
            </Typography>

            <div className={classes.featuredCategories}>
              {data.posts.featured.map(x => {
                if (x.featured === 'video') {
                  return <FeaturedPostVideo key={x.id} post={x} linkPrefix={linkPrefix} />
                } else {
                  return (
                    <FeaturedPostContent key={x.id} post={x} linkPrefix={linkPrefix} />
                  )
                }
              })}
            </div>
          </Box>
        )}

        {data.categories.featured.length > 0 && (
          <Box mb={4}>
            <Typography variant='h5' gutterBottom>
              Featured Categories
            </Typography>

            <div className={classes.featuredCategories}>
              {data.categories.featured.map(x => (
                <FeaturedCategory key={x.id} category={x} linkPrefix={linkPrefix} />
              ))}
            </div>
          </Box>
        )}

        <Box>
          <Typography variant='h5' gutterBottom>
            Recently Updated
          </Typography>

          <Paper className={classes.recentlyUpdated}>
            {data.posts.recently_updated.length === 0 && (
              <p>Nothing has been recently updated</p>
            )}

            {data.posts.recently_updated.map(x => (
              <Post key={x.id} post={x} limitWidth={true} linkPrefix={linkPrefix} />
            ))}
          </Paper>
        </Box>
      </Box>

      <Box className={classes.column2}>
        <Typography variant='h5' gutterBottom>
          Categories
        </Typography>

        <List className={classes.categoriesMenu} disablePadding>
          {data.categories.all.map(x => (
            <CategoryMenuItem
              key={x.id}
              category={x}
              categoriesOpen={categoriesOpen}
              handleCategoryClick={handleCategoryClick}
              linkPrefix={linkPrefix}
            />
          ))}
        </List>
      </Box>
    </Box>
  )
}

function FeaturedPostContent({ post, linkPrefix }) {
  const classes = useStyles()

  return (
    <Link to={`/${linkPrefix}/posts/${post.id}`}>
      <Card className={classes.featuredCategoryCard}>
        <CardContent>
          <Typography variant='h5' component='h2'>
            {post.name}
          </Typography>
          {post.image && (
            <>
              <img
                style={{
                  float: 'left',
                  marginRight: '1rem',
                  maxHeight: '160px',
                  maxWidth: '100%',
                }}
                src={post.image}
                alt='Featured post'
              />
            </>
          )}
          <TextContentRenderer content={post.content} />
          <div style={{ clear: 'both' }}></div>
        </CardContent>
        <div className={classes.featuredCategoryPush}></div>
        <CardActions className={classes.featuredCategoryActions}>
          <Button size='small'>View</Button>
        </CardActions>
      </Card>
    </Link>
  )
}

function FeaturedPostVideo({ post, linkPrefix }) {
  const classes = useStyles()

  return (
    <Link to={`/${linkPrefix}/posts/${post.id}`}>
      <Card className={classes.featuredCategoryCard}>
        <CardContent>
          <Typography variant='h5' component='h2'>
            {post.name}
          </Typography>
        </CardContent>

        <iframe
          title='video'
          className={classes.video}
          src={`https://www.youtube.com/embed/${post.youtube_id}`}
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen></iframe>
      </Card>
    </Link>
  )
}

function FeaturedCategory({ category, linkPrefix }) {
  const classes = useStyles()

  return (
    <Link to={`/${linkPrefix}/categories/${category.id}`}>
      <Card className={classes.featuredCategoryCard}>
        <CardContent>
          <Typography variant='h5' component='h2'>
            {category.name}
          </Typography>

          <TextContentRenderer content={category.description} preview={true} />
        </CardContent>
        <div className={classes.featuredCategoryPush}></div>
        <CardActions className={classes.featuredCategoryActions}>
          <Button size='small'>View</Button>
        </CardActions>
      </Card>
    </Link>
  )
}

function CategoryMenuItem({ category, categoriesOpen, handleCategoryClick, linkPrefix }) {
  const classes = useStyles()

  return (
    <React.Fragment>
      <ListItem
        onClick={() =>
          handleCategoryClick(category.id, category.sub_categories.length > 0)
        }
        className={classes.categoriesMenuItem}
        button
        key={category.id}>
        <Link
          className={classes.menuLink}
          to={`/${linkPrefix}/categories/${category.id}`}>
          <ListItemText primary={category.name} />
        </Link>
        {category.sub_categories.length > 0 && (
          <Box className={classes.menuLinkIcon}>
            {categoriesOpen[category.id] ? <ExpandLess /> : <ExpandMore />}
          </Box>
        )}
      </ListItem>

      <Divider className={classes.menuItemDivider} />

      {category.sub_categories.length > 0 && (
        <Collapse in={categoriesOpen[category.id]} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {category.sub_categories.map(subCategory => (
              <Box pl={3} key={subCategory.id}>
                <CategoryMenuItem
                  category={subCategory}
                  categoriesOpen={categoriesOpen}
                  handleCategoryClick={handleCategoryClick}
                  linkPrefix={linkPrefix}
                />
              </Box>
            ))}
          </List>
        </Collapse>
      )}
    </React.Fragment>
  )
}

export default Summary
