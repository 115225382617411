import { Box, FormControl, FormHelperText } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { GeographicPortContext } from 'contexts/GeographicPortContext'
import React, { useContext } from 'react'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(4),
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
}))

function AddressDetails() {
  const classes = useStyles()
  const { state, dispatch } = useContext(GeographicPortContext)

  const handleChangeCheckbox = event => {
    const { name, checked } = event.target
    dispatch({
      type: 'SET_ADDRESS_DETAILS',
      payload: { key: name, value: checked },
    })
  }

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5' gutterBottom>
        Address Details
      </Typography>

      {state.address_details.is_moving.value && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <AddressTextField
              name='old_post_code'
              label='Previous Post Code'
              textFieldProps={{ required: true }}
            />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <AddressTextField
            name='premises'
            label='Name or Building Number'
            textFieldProps={{ required: true }}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <AddressTextField
            name='street'
            label='Street'
            textFieldProps={{ required: true }}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <AddressTextField
            name='town'
            label='Town'
            textFieldProps={{ required: true }}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <AddressTextField name='county' label='County' />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <AddressTextField
            name='post_code'
            label='Post Code'
            textFieldProps={{ required: true }}
            processValue={value => {
              // alphanumeric and 7 chars only
              const sanitizedValue = value
                .replace(/[^a-zA-Z0-9]/g, '')
                .substr(0, 7)
                .toUpperCase()

              const length = sanitizedValue.length
              let spaceAfter = 0

              if (length === 5) {
                spaceAfter = 2
              } else if (length === 6) {
                spaceAfter = 3
              } else if (length === 7) {
                spaceAfter = 4
              }

              if (spaceAfter === 0) return sanitizedValue

              return (
                sanitizedValue.substr(0, spaceAfter) +
                ' ' +
                sanitizedValue.substr(spaceAfter)
              )
            }}
          />
        </Grid>
      </Grid>

      <Box mt={6}>
        <FormControl
          component='fieldset'
          className={classes.radios}
          error={state.errors.hasOwnProperty('customer_confirmed') ? true : false}>
          <FormControlLabel
            name='customer_confirmed'
            checked={state.address_details.customer_confirmed.value}
            onChange={handleChangeCheckbox}
            control={<Checkbox color='primary' />}
            label='Tick this box to confirm this information has been provided by the customer'
            labelPlacement='start'
          />
          <FormHelperText>
            {state.errors.hasOwnProperty('customer_confirmed')
              ? state.errors.customer_confirmed
              : ''}
          </FormHelperText>
        </FormControl>
      </Box>
    </Paper>
  )
}

function AddressTextField({ name, label, textFieldProps, processValue }) {
  const { state, dispatch } = useContext(GeographicPortContext)

  const handleChange = event => {
    const { name, value } = event.target
    const processedValue = processValue !== undefined ? processValue(value) : value

    dispatch({
      type: 'SET_ADDRESS_DETAILS',
      payload: { key: name, value: processedValue },
    })
  }

  return (
    <TextField
      variant='standard'
      margin='normal'
      fullWidth
      id={name}
      label={label}
      name={name}
      value={state.address_details[name].value}
      onChange={handleChange}
      error={state.errors.hasOwnProperty(name) ? true : false}
      helperText={state.errors.hasOwnProperty(name) ? state.errors[name] : ''}
      {...textFieldProps}
    />
  )
}

export default AddressDetails
