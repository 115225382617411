import { Button } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'

function CreateButtons({ categoryLink, postLink, canCreatePost }) {
  return (
    <React.Fragment>
      <Button
        style={{ marginRight: '1rem' }}
        variant='contained'
        color='primary'
        component={Link}
        to={categoryLink}>
        Create Category
      </Button>

      {canCreatePost && (
        <Button variant='contained' color='primary' component={Link} to={postLink}>
          Create Post
        </Button>
      )}
    </React.Fragment>
  )
}

export default CreateButtons
