import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import SummaryBox from 'components/quotes/SummaryBox'

const useStyles = makeStyles(theme => ({
  action: {
    width: '100px',
    marginTop: '0',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  button: {
    width: '140px',
    marginTop: '0',
  },
  formControl: {
    minWidth: '140px',
  },
}))

const WhiteFormControl = withStyles({
  root: {
    '& .MuiInputLabel-root': {
      color: '#FFF', // Label
    },
    '& .MuiInputBase-input': {
      color: '#FFF', // Text color
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#FFF9', // Solid underline
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#FFF', // Solid underline on hover
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#FFF', // Solid underline on focus
    },
  },
})(FormControl)

function DropDownItem({ options, value, fieldName, label, onChange }) {
  const classes = useStyles()
  const [selected, setSelected] = useState(false)

  const handleClick = () => {
    setSelected(true)
  }

  const handleChange = event => {
    onChange(event)
  }

  const handleBlur = () => {
    if (!value) {
      setSelected(false)
    }
  }

  return (
    <SummaryBox variant='text' text={label}>
      {!selected && !value && (
        <Button
          variant='contained'
          color='default'
          style={{ marginTop: '16px' }}
          className={classes.button}
          onClick={handleClick}>
          Select
        </Button>
      )}

      {(selected || value) && (
        <WhiteFormControl variant='standard' className={classes.formControl}>
          <Select
            onBlur={handleBlur}
            id={fieldName}
            name={fieldName}
            value={value}
            onChange={handleChange}
            label={label}>
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {Object.keys(options).map((key, index) => {
              if (options[key].hasOwnProperty('items')) {
                return Object.keys(options[key].items).map(subKey => (
                  <MenuItem
                    key={options[key].items[subKey].id}
                    value={options[key].items[subKey].id}>
                    {options[key].items[subKey].name}
                  </MenuItem>
                ))
              } else {
                return (
                  <MenuItem key={index} value={options[key].id}>
                    {options[key].name}
                  </MenuItem>
                )
              }

              // return (
              //   <MenuItem key={index} value={options[key].id}>
              //     {options[key].name}
              //    </MenuItem>
              //  )
            })}
          </Select>
        </WhiteFormControl>
      )}
    </SummaryBox>
  )
}

export default DropDownItem
