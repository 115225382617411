import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { processTypeUpdate } from 'api/number-porting'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

function UpdateProcessTypeModal({ id, currentType, afterSubmit, children }) {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(currentType)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = event => {
    setValue(event.target.value)
  }

  const handleSubmit = async () => {
    setLoading(true)
    await processTypeUpdate(id, { process_type: value })

    if (afterSubmit) {
      await afterSubmit()
    }

    setLoading(false)
    setOpen(false)
  }

  return (
    <React.Fragment>
      <span onClick={handleOpen}>{children}</span>

      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Port Type</DialogTitle>
        <DialogContent>
          <div>
            <Select
              style={{ width: '100%' }}
              id='value'
              name='value'
              value={value}
              onChange={handleChange}
              label='Port Type'>
              <MenuItem value='Manual'>Manual</MenuItem>
              <MenuItem value='Manual Migration'>Migration</MenuItem>
            </Select>
          </div>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={loading} onClick={handleSubmit} color='primary'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

UpdateProcessTypeModal.propTypes = {
  id: PropTypes.number,
  currentType: PropTypes.string,
  afterSubmit: PropTypes.func,
}

export default UpdateProcessTypeModal
