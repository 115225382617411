import { Button } from '@material-ui/core'
import React from 'react'

function CreateButton({ loading, handleSave }) {
  return (
    <Button disabled={loading} onClick={handleSave} variant='contained' color='primary'>
      Create
    </Button>
  )
}

export default CreateButton
