import { Box, Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { list } from 'api/blog'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  media: {
    height: 120,
  },
  content: {
    flexGrow: '1',
  },
}))

function List(props) {
  const { loading, setLoading } = useContext(LoadingContext)
  const [posts, setPosts] = useState([])
  const [page, setPage] = useState(1)

  useEffect(() => {
    const fetchData = async () => {
      const res = await list(9, 1)
      setPosts(res.data)
      setLoading(false)
    }

    fetchData()
  }, [setLoading])

  const handleLoadMore = async () => {
    setLoading(true)
    const newPage = page + 1
    const res = await list(9, newPage)
    const newPosts = Array.from(posts).concat(res.data)

    setPage(newPage)
    setPosts(newPosts)
    setLoading(false)
  }

  if (posts.length === 0) return null

  return (
    <div>
      <Grid container spacing={4}>
        {posts.map(x => (
          <Grid item sm={12} lg={6} xl={4} key={x.slug}>
            <SimpleCard
              slug={x.slug}
              title={x.title}
              excerpt={x.excerpt}
              image={x.featured_media.url}
            />
          </Grid>
        ))}
      </Grid>

      <Box mt={5} mb={5} textAlign='center'>
        <Button
          disabled={loading}
          onClick={handleLoadMore}
          variant='contained'
          color='primary'>
          Load More
        </Button>
      </Box>
    </div>
  )
}

function SimpleCard({ slug, title, excerpt, image }) {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <CardMedia className={classes.media} image={image} title='Paella dish' />
      <CardContent className={classes.content}>
        <Typography
          variant='h5'
          component='h2'
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <Typography
          variant='body2'
          component='p'
          dangerouslySetInnerHTML={{ __html: excerpt }}
        />
      </CardContent>
      <CardActions>
        <Link to={`/blog/${slug}`}>
          <Button size='small'>Read More</Button>
        </Link>
      </CardActions>
    </Card>
  )
}

export default List
