import config from 'config'

const axios = require('axios')

const getCookieValue = name => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) {
    const ret = parts.pop().split(';').shift()
    return decodeURIComponent(ret)
  }

  return ''
}

export async function getJsonHeaders() {
  return {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-XSRF-TOKEN': await getCookie('XSRF-TOKEN'),
  }
}

export async function getCookie(name) {
  // attempt to get cookie value
  const ret = getCookieValue(name)

  // if there is a value return it
  if (ret) return ret

  // otherwise make a get request so any missing cookie can be set
  // for example, this will set XSRF-TOKEN if missing
  await axios.get(config.API_URL + '/api/csrf')

  // try and get the value again
  return getCookieValue(name)
}

export function getQueryStringFromFiltersObject(filters) {
  const filterString = Object.entries(filters)
    .filter(entry => {
      return entry[1].length
    })
    .map(entry => {
      const [key, value] = entry
      const parsedValue = Array.isArray(value) ? value.join('|') : value
      return `${key}=${parsedValue}`
    })
    .join('&')

  return filterString
}

export async function makeRequest(url, params = {}) {
  const res = await fetch(url, params)

  if (res.status === 401) {
    window.location.href = '/'
  }

  return res
}
