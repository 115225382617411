import config from 'config'
import { getJsonHeaders } from './helpers'

export async function list() {
  try {
    const result = await fetch(config.API_URL + '/api/features', {
      headers: await getJsonHeaders(),
    })

    if (result.status !== 200) {
      return false
    }

    return await result.json()
  } catch (e) {
    return false
  }
}
