import React, { useState, useContext } from 'react'
import ImageCropper from 'components/ImageCropper'
import { Typography, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { LoadingContext } from 'contexts/LoadingContext'
const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  selectedImage: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: '0.75rem',
    textAlign: 'center',
  },
}))

function ImageField({ onChange, maxRenderWidth = 800, maxRenderHeight = 800 }) {
  const { setLoading } = useContext(LoadingContext)
  const [imageBase64, setImageBase64] = useState()
  const [originalImageBase64, setOriginalImageBase64] = useState()
  const [values, setValues] = useState({})
  const [cropWidth, setCropWidth] = useState(0)
  const [cropHeight, setCropHeight] = useState(0)

  const reader = new FileReader()

  const classes = useStyles()

  const handleCropChange = data => {
    onChange(data)
  }

  const handleInputChange = event => {
    const file = event.target.files[0]
    reader.readAsDataURL(file)
    setValues({ ...values, image: { value: file, fileName: file.name } })

    reader.onloadend = () => {
      const img = new Image()
      img.src = reader.result
      img.onload = () => {
        setCropWidth(img.width)
        setCropHeight(img.height)
      }

      setImageBase64(reader.result)
      setOriginalImageBase64(reader.result)
      setLoading(false)
      onChange(reader.result)
    }
  }

  return (
    <>
      <input
        accept='image/*'
        style={{ display: 'none' }}
        id='image'
        name='image'
        multiple
        type='file'
        onChange={handleInputChange.bind(this)}
      />
      <label htmlFor='image'>
        <Button variant='contained' component='span'>
          Change image
        </Button>
      </label>
      {values.image?.fileName && (
        <div className={classes.selectedImage}>
          Selected file: {values.image.fileName}
        </div>
      )}
      {imageBase64 && (
        <>
          <Typography variant='subtitle1' gutterBottom>
            Crop image as required
          </Typography>
          <ImageCropper
            style={{ maxWidth: '800px' }}
            imageBase64={originalImageBase64}
            onChange={handleCropChange}
            minImageWidth={120}
            maxRenderWidth={maxRenderWidth}
            maxRenderHeight={maxRenderHeight}
            height={cropHeight}
            width={cropWidth}
          />
        </>
      )}
    </>
  )
}

export default ImageField
