import { Button } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import { listTags } from 'api/library/posts'
import { list as listCategories } from 'api/my-library/categories'
import { deletePost, update as updatePost } from 'api/my-library/posts'
import PostForm from 'components/library/post/PostForm'
import { LoadingContext } from 'contexts/LoadingContext'
import { getFormData } from 'helpers/library'
import React, { useContext, useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'

const useStyles = makeStyles(theme => ({
  section: {
    display: 'grid',
    gridGap: theme.spacing(2),
    gridTemplateAreas: '"column-2" "column-1"',

    [theme.breakpoints.up('md')]: {
      gridGap: theme.spacing(4),
      gridTemplateAreas: '"column-1 column-2"',
      gridTemplateColumns: '8fr 4fr',
    },
  },
  column1: {
    gridArea: 'column-1',
  },
  column2: {
    gridArea: 'column-2',
  },
}))

function PostEdit({ post, setPost, toggleEditMode, history }) {
  const classes = useStyles()
  const { loading, setLoading } = useContext(LoadingContext)
  const { addToast } = useToasts()
  const [categories, setCategories] = useState({})
  const [tags, setTags] = useState({})
  const [values, setValues] = useState(post.data)
  const [errors, setErrors] = useState([])
  const [deleteOpen, setDeleteOpen] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const [catData, tagData] = await Promise.all([listCategories(1000), listTags()])
      setCategories(catData)
      setTags(tagData)
      setLoading(false)
    }

    fetchData()
  }, [setLoading])

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: value })
  }

  const handleSave = async () => {
    setLoading(true)

    const data = getFormData(values)
    const res = await updatePost(post.data.id, data)

    setLoading(false)

    if (res.success) {
      setPost(res.post)
      addToast('Saved Successfully', { appearance: 'success' })
      toggleEditMode()
    } else {
      setErrors(res.errors)
    }
  }

  const handleDelete = async () => {
    setLoading(true)
    const res = await deletePost(post.data.id)
    setLoading(false)

    if (res) {
      addToast('Deleted Successfully', { appearance: 'success' })
      history.push(`/my-library`)
    } else {
      setErrors([['', 'Error deleting post']])
      setDeleteOpen(false)
    }
  }

  const handleOpenDeleteDialog = id => {
    setDeleteOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteOpen(false)
  }

  if (!categories.data) {
    return null
  }

  return (
    <Box>
      <PostForm
        post={post}
        categories={categories}
        tags={tags}
        values={values}
        errors={errors}
        handleChange={handleChange}
      />

      <Box className={classes.section}>
        <Box className={classes.column1}>
          <Box display='flex'>
            <Button
              disabled={loading}
              onClick={handleSave}
              variant='contained'
              color='primary'>
              Save
            </Button>

            <Box flexGrow='1'></Box>

            <Button
              disabled={loading}
              onClick={handleOpenDeleteDialog}
              variant='contained'
              color='secondary'>
              Delete
            </Button>
          </Box>
        </Box>
      </Box>

      <Dialog open={deleteOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle id='alert-dialog-title'>{'Library Post Delete'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to delete this library post?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleCloseDeleteDialog} color='primary'>
            Cancel
          </Button>
          <Button disabled={loading} onClick={handleDelete} color='secondary'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default PostEdit
