import config from 'config'
import { getJsonHeaders, getQueryStringFromFiltersObject } from './helpers'

export async function list(limit = 15, page = 1, filters = {}) {
  try {
    let queryString = `?per_page=${limit}&page=${page}`
    const filterString = getQueryStringFromFiltersObject(filters)

    if (filterString) {
      queryString += `&${filterString}`
    }

    const result = await fetch(config.API_URL + '/api/notifications' + queryString)
    return await result.json()
  } catch (e) {
    return false
  }
}

export async function get(id) {
  try {
    let url = `/api/notifications/${id}`

    const result = await fetch(config.API_URL + url)
    return await result.json()
  } catch (e) {
    return false
  }
}

export async function markRead(id) {
  try {
    let url = `/api/notifications/${id}/mark-read`

    const result = await fetch(config.API_URL + url, {
      method: 'PUT',
      headers: await getJsonHeaders(),
    })

    return await result.json()
  } catch (e) {
    return false
  }
}
