import { Box, CircularProgress } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { list as listResellers } from 'api/resellers'
import { GeographicPortContext } from 'contexts/GeographicPortContext'
import React, { useContext, useEffect, useState } from 'react'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(4),
  },
}))

function Reseller() {
  const classes = useStyles()
  const { state, dispatch } = useContext(GeographicPortContext)
  const [loading, setLoading] = useState(true)
  const [resellers, setResellers] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const res = await listResellers(10000)
      setResellers(res.data.map(x => ({ id: x.id, name: x.name })))
      setLoading(false)
    }

    fetchData()
  }, [])

  const handleChange = newValue => {
    const resellerId = newValue === null ? '' : newValue.id
    const resellerName = newValue === null ? '' : newValue.name

    dispatch({
      type: 'SET_RESELLER',
      payload: { id: resellerId, name: resellerName },
    })
  }

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5' gutterBottom>
        Reseller Details
      </Typography>

      <Typography variant='body1'>
        Which reseller are you creating the port request for?
      </Typography>

      {loading ? (
        <Box mt={3}>
          <CircularProgress size={24} />
        </Box>
      ) : (
        <Autocomplete
          id='reseller'
          options={resellers}
          getOptionLabel={option => option.name ?? ''}
          value={state.reseller.value}
          onChange={(_, newValue) => {
            handleChange(newValue)
          }}
          renderInput={params => (
            <TextField
              {...params}
              error={state.errors.hasOwnProperty('reseller') ? true : false}
              helperText={
                state.errors.hasOwnProperty('reseller') ? state.errors.reseller : ''
              }
            />
          )}
        />
      )}
    </Paper>
  )
}

export default Reseller
