import config from 'config'
import { getJsonHeaders, getQueryStringFromFiltersObject, makeRequest } from './helpers'

const axios = require('axios')

export async function get(id) {
  let url = `/api/number-porting/${id}`
  const result = await fetch(config.API_URL + url, { headers: await getJsonHeaders() })
  return await result.json()
}

export async function getHistory(id) {
  let url = `/api/number-porting/${id}/history`
  const result = await fetch(config.API_URL + url, { headers: await getJsonHeaders() })

  return {
    status: result.status,
    response: await result.json(),
  }
}

export async function deleteNumberPort(id) {
  const res = await fetch(config.API_URL + `/api/number-porting/${id}`, {
    method: 'DELETE',
    headers: await getJsonHeaders(),
  })

  return res.status === 204
}

export async function list(limit = 15, page = 1, filters = {}) {
  let queryString = `?per_page=${limit}&page=${page}`
  const filterString = getQueryStringFromFiltersObject(filters)

  if (filterString) {
    queryString += `&${filterString}`
  }

  const res = await axios.get(config.API_URL + '/api/number-porting' + queryString)

  return res.data
}

export async function portabilityCheck(cli, postCode) {
  let url = `/api/number-porting/portability-check/${cli}/${postCode}`
  const result = await makeRequest(config.API_URL + url, {
    headers: await getJsonHeaders(),
  })

  return {
    status: result.status,
    response: await result.json(),
  }
}

export async function availablePortDates(cli, lcp, lineType) {
  let url = `/api/number-porting/available-port-dates/${cli}/${lcp}/${lineType}`
  const result = await fetch(config.API_URL + url, { headers: await getJsonHeaders() })
  return await result.json()
}

export async function geographicPortNumberDetails(id, body) {
  let url = '/api/number-porting/geographic-port/number-details'
  if (id) {
    url += `/${id}`
  }

  const result = await fetch(config.API_URL + url, {
    method: 'POST',
    headers: await getJsonHeaders(),
    body: JSON.stringify(body),
  })

  return {
    status: result.status,
    response: await result.json(),
  }
}

export async function geographicPortInstallationDetails(id, body) {
  let url = `/api/number-porting/geographic-port/installation-details/${id}`
  const result = await fetch(config.API_URL + url, {
    method: 'POST',
    headers: await getJsonHeaders(),
    body: JSON.stringify(body),
  })

  if (result.status === 500) {
    throw new Error('Server error')
  }

  return await result.json()
}

export async function geographicPortNumbersToPort(id, body) {
  let url = `/api/number-porting/geographic-port/numbers-to-port/${id}`
  const result = await fetch(config.API_URL + url, {
    method: 'POST',
    headers: await getJsonHeaders(),
    body: JSON.stringify(body),
  })

  if (result.status === 500) {
    throw new Error('Server error')
  }

  return await result.json()
}

export async function geographicPortPortingDate(id, body) {
  let url = `/api/number-porting/geographic-port/porting-date/${id}`
  const result = await fetch(config.API_URL + url, {
    method: 'POST',
    headers: await getJsonHeaders(),
    body: JSON.stringify(body),
  })

  if (result.status === 500) {
    throw new Error('Server error')
  }

  return await result.json()
}

export async function geographicPortPciCompliance(id, body) {
  let url = `/api/number-porting/geographic-port/pci-compliance/${id}`
  const result = await fetch(config.API_URL + url, {
    method: 'POST',
    headers: await getJsonHeaders(),
    body: JSON.stringify(body),
  })

  if (result.status === 500) {
    throw new Error('Server error')
  }

  return await result.json()
}

export async function geographicPortSubmit(id) {
  let url = `/api/number-porting/geographic-port/submit/${id}`
  const result = await fetch(config.API_URL + url, {
    method: 'POST',
    headers: await getJsonHeaders(),
  })

  return {
    status: result.status,
    response: await result.json(),
  }
}

export async function geographicPortResubmit(id) {
  let url = `/api/number-porting/geographic-port/resubmit/${id}`
  const result = await fetch(config.API_URL + url, {
    method: 'POST',
    headers: await getJsonHeaders(),
  })

  if (result.status === 500) {
    throw new Error('Server error')
  }

  return await result.json()
}

export async function processTypeUpdate(id, body) {
  let url = `/api/number-porting/process-type-update/${id}`
  const result = await fetch(config.API_URL + url, {
    method: 'PUT',
    headers: await getJsonHeaders(),
    body: JSON.stringify(body),
  })

  if (result.status === 500) {
    throw new Error('Server error')
  }

  return await result.json()
}

export async function tagsUpdate(id, body) {
  let url = `/api/number-porting/tags-update/${id}`
  const result = await fetch(config.API_URL + url, {
    method: 'PUT',
    headers: await getJsonHeaders(),
    body: JSON.stringify(body),
  })

  if (result.status === 500) {
    throw new Error('Server error')
  }

  return await result.json()
}

export async function statusUpdate(id, body) {
  let url = `/api/number-porting/status-update/${id}`
  const result = await fetch(config.API_URL + url, {
    method: 'PUT',
    headers: await getJsonHeaders(),
    body: JSON.stringify(body),
  })

  if (result.status === 500) {
    throw new Error('Server error')
  }

  return await result.json()
}

export async function externalIdUpdate(id, body) {
  let url = `/api/number-porting/external-id-update/${id}`
  const result = await fetch(config.API_URL + url, {
    method: 'PUT',
    headers: await getJsonHeaders(),
    body: JSON.stringify(body),
  })

  if (result.status === 500) {
    throw new Error('Server error')
  }

  return await result.json()
}

export async function geographicPortManualUpdate(id, body) {
  let url = '/api/number-porting/geographic-port/manual-update'
  if (id) {
    url += `/${id}`
  }

  const result = await fetch(config.API_URL + url, {
    method: 'PUT',
    headers: await getJsonHeaders(),
    body: JSON.stringify(body),
  })

  return {
    status: result.status,
    response: await result.json(),
  }
}
