import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { get } from 'api/video-library/posts'
import EditButton from 'components/library/post/EditButton'
import PostView from 'components/library/post/PostView'
import { AuthContext } from 'contexts/AuthContext'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PostEdit from './PostEdit'

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: theme.breakpoints.width('lg'),
    margin: 'auto',
  },
}))

function Post(props) {
  const classes = useStyles()
  const { user } = useContext(AuthContext)
  const { id } = useParams()
  const { setLoading } = useContext(LoadingContext)
  const [post, setPost] = useState()
  const [editMode, setEditMode] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      setPost(await get(id))
      setLoading(false)
    }

    fetchData()
  }, [id, setLoading])

  const toggleEditMode = () => {
    setEditMode(!editMode)
  }

  if (!post) {
    return null
  }

  return (
    <React.Fragment>
      <Box className={classes.container}>
        {user.type === 'distributor' && (
          <Box mb={2}>
            <EditButton editMode={editMode} toggleEditMode={toggleEditMode} />
          </Box>
        )}

        {editMode ? (
          <PostEdit
            post={post}
            setPost={setPost}
            toggleEditMode={toggleEditMode}
            history={props.history}
          />
        ) : (
          <PostView post={post} />
        )}
      </Box>
    </React.Fragment>
  )
}

export default Post
