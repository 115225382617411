import { Box, Button, CircularProgress, Grid, Paper, Typography } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import { getHistory } from 'api/number-porting'
import Summary from 'components/number-porting/geographic-port/steps/Summary'
import SummaryCopyPaste from 'components/number-porting/geographic-port/SummaryCopyPaste'
import UpdateExternalIdModal from 'components/number-porting/geographic-port/UpdateExternalIdModal'
import UpdatePortTagsModal from 'components/number-porting/geographic-port/UpdatePortTagsModal'
import UpdateProcessTypeModal from 'components/number-porting/geographic-port/UpdateProcessTypeModal'
import UpdateStatusModal from 'components/number-porting/geographic-port/UpdateStatusModal'
import NumberPortStatusChip from 'components/number-porting/NumberPortStatusChip'
import { AuthContext } from 'contexts/AuthContext'
import { GeographicPortContext } from 'contexts/GeographicPortContext'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  pagination: {
    marginBottom: '60px',
  },
  history: {
    marginTop: theme.spacing(3),
  },
  headline: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
  },
  gammaRef: {
    cursor: 'pointer',
  },
}))

function GeographicPortSummary(props) {
  const classes = useStyles()
  const { id } = useParams()
  const { user } = useContext(AuthContext)
  const { state } = useContext(GeographicPortContext)
  const [history, setHistory] = useState([])
  const [historyLoading, setHistoryLoading] = useState(false)
  const [historyLoaded, setHistoryLoaded] = useState(false)
  const [copyPasteMode, setCopyPasteMode] = useState(false)

  useEffect(() => {
    if (user.type === 'distributor') {
      setCopyPasteMode(true)
    }
  }, [user.type])

  const loadHistory = async () => {
    setHistoryLoading(true)
    const res = await getHistory(id)
    if (res.status === 200) {
      setHistory(res.response.data)
    } else {
      setHistory([
        {
          message: 'Failed to retrieve history, please retry again in a few minutes',
          datetime: '',
        },
      ])
    }
    setHistoryLoading(false)
    setHistoryLoaded(true)
  }

  const afterUpdateSubmit = async () => {
    window.location.reload()
  }

  return (
    <div>
      <Typography variant='h3' gutterBottom>
        Geographic Port Request
      </Typography>

      <div className={classes.headline}>
        <Chip label={`Reference: ${state.id.value}`} />

        {user.type === 'distributor' && (
          <React.Fragment>
            <UpdateExternalIdModal id={state.id.value} afterSubmit={afterUpdateSubmit}>
              <Chip
                className={classes.gammaRef}
                label={`Gamma Reference: ${
                  state.external_id.value ? state.external_id.value : 'Unknown'
                }`}
              />
            </UpdateExternalIdModal>
          </React.Fragment>
        )}

        <NumberPortStatusChip
          hubStatus={state.hub_status.value}
          status={state.status.value}
        />

        {user.type === 'distributor' && (
          <>
            {state.tags.value.map(x => (
              <Chip key={x} label={x} />
            ))}
          </>
        )}

        {state.has_history.value && (historyLoading || !historyLoaded) && (
          <Button onClick={loadHistory} disabled={historyLoading}>
            {historyLoading ? <CircularProgress size={24} /> : 'View Port History'}
          </Button>
        )}

        {user.type === 'distributor' && state.is_manual.value && (
          <UpdateStatusModal id={state.id.value} afterSubmit={afterUpdateSubmit}>
            <Button>Update Status</Button>
          </UpdateStatusModal>
        )}

        {user.type === 'distributor' && state.is_manual.value && (
          <UpdateProcessTypeModal
            id={state.id.value}
            currentType={state.process_type.value}
            afterSubmit={afterUpdateSubmit}>
            <Button>Update Type</Button>
          </UpdateProcessTypeModal>
        )}

        {user.type === 'distributor' && (
          <UpdatePortTagsModal
            id={state.id.value}
            currentTags={state.tags.value}
            afterSubmit={afterUpdateSubmit}>
            <Button>Update Tags</Button>
          </UpdatePortTagsModal>
        )}
      </div>

      {historyLoaded && (
        <Paper className={classes.history}>
          <List className={classes.root}>
            {history.length === 0 ? (
              <ListItem alignItems='flex-start'>
                <ListItemText primary='No history records found. Once the port has progressed further you should be able to see more information.' />
              </ListItem>
            ) : (
              <React.Fragment>
                {history.map((x, index) => (
                  <React.Fragment key={`history-${index}`}>
                    <ListItem alignItems='flex-start'>
                      <ListItemText
                        primary={
                          <Grid container>
                            <Grid style={{ width: '200px' }}>{x.datetime}</Grid>
                            <Grid dangerouslySetInnerHTML={{ __html: x.message }} />
                          </Grid>
                        }
                      />
                    </ListItem>
                    {index + 1 < history.length && (
                      <Divider variant='inset' component='li' />
                    )}
                  </React.Fragment>
                ))}
              </React.Fragment>
            )}
          </List>
        </Paper>
      )}

      {state.rejection_reasons.value.length > 0 && (
        <Box mt={3}>
          <Alert icon={false} severity='error'>
            <Typography variant='h5'>Rejection Reasons</Typography>
            {state.rejection_reasons.value.map((x, i) => (
              <div key={`reason-${i}`}>
                {x}
                {state.rejection_related_events.value.length > 0 &&
                  state.rejection_related_events.value.map((x, j) => (
                    <div key={`related-event-${j}`}>{x}</div>
                  ))}
              </div>
            ))}
          </Alert>
        </Box>
      )}

      {copyPasteMode ? <SummaryCopyPaste /> : <Summary />}

      {state.loa_url.value && (
        <Box mt={3}>
          <Button color='primary' variant='contained' as='a' href={state.loa_url.value}>
            Download LOA
          </Button>
        </Box>
      )}
    </div>
  )
}

export default GeographicPortSummary
