import { Grid, Typography } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Tooltip from '@material-ui/core/Tooltip'
import PropTypes from 'prop-types'
import React from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import styles from './CoveringLetter.module.scss'

function CoveringLetter({ editorState, readOnly, loading, handleChange }) {
  return (
    <Grid container spacing={3}>
      <Grid item lg={9}>
        <Editor
          editorState={editorState}
          placeholder='Add your covering letter content here.  You can use keywords to add dynamoic content like this... Hi [[customer_name]], nice to meet you.'
          readOnly={readOnly || loading}
          toolbarClassName={styles.editorToolbar}
          wrapperClassName={styles.editorWrapper}
          editorClassName={styles.editor}
          onEditorStateChange={handleChange}
          toolbar={{
            options: ['inline', 'list', 'textAlign', 'history'],
            inline: {
              options: ['bold', 'italic', 'underline'],
            },
            list: {
              options: ['unordered', 'ordered'],
            },
            textAlign: {
              options: ['left', 'center', 'right'],
            },
          }}
        />
      </Grid>
      <Grid item lg={3}>
        <Typography variant='h4'>Keywords</Typography>
        Keywords allow you to display information dynamically. You can add any of the
        below keywords to the covering letter text and they will be replaced based on teh
        quote the proposal is created for.
        <List dense={true}>
          <ListItem>
            <Tooltip
              placement='left'
              title='This will display the same logo that is displayed in the top left.'>
              <ListItemText primary='[[logo]]' />
            </Tooltip>
          </ListItem>
          <ListItem>
            <Tooltip
              placement='left'
              title='This will display the profile name of the agent who created this quote'>
              <ListItemText primary='[[agent_name]]' />
            </Tooltip>
          </ListItem>
          <ListItem>
            <Tooltip
              placement='left'
              title='This will display the profile photo of the agent who created this quote'>
              <ListItemText primary='[[agent_profile_photo]]' />
            </Tooltip>
          </ListItem>
          <ListItem>
            <Tooltip
              placement='left'
              title='This will display the contact email address of the agent who created this quote'>
              <ListItemText primary='[[agent_contact_email]]' />
            </Tooltip>
          </ListItem>
          <ListItem>
            <Tooltip
              placement='left'
              title='This will display the phone number of the agent who created this quote'>
              <ListItemText primary='[[agent_phone_number]]' />
            </Tooltip>
          </ListItem>
          <ListItem>
            <Tooltip
              placement='left'
              title='The customer first and last name set on the quote.'>
              <ListItemText primary='[[customer_name]]' />
            </Tooltip>
          </ListItem>
          <ListItem>
            <Tooltip placement='left' title='The business name set on the quote.'>
              <ListItemText primary='[[business_name]]' />
            </Tooltip>
          </ListItem>
          <ListItem>
            <Tooltip
              placement='left'
              title='Outputs any questions that the sales person has answered on the proposal page from the list of "covering list questions" below.'>
              <ListItemText primary='[[questions]]' />
            </Tooltip>
          </ListItem>
          <ListItem>
            <Tooltip
              placement='left'
              title='Output the current date in dd/mm/yyyy format'>
              <ListItemText primary='[[date]]' />
            </Tooltip>
          </ListItem>
          <ListItem>
            <Tooltip
              placement='left'
              title='Output the a longer version of the current date, in the format 1st January 2077'>
              <ListItemText primary='[[long_date]]' />
            </Tooltip>
          </ListItem>
        </List>
      </Grid>
    </Grid>
  )
}

CoveringLetter.propTypes = {
  editorState: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  loading: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
}

CoveringLetter.defaultProps = {
  readOnly: false,
  loading: false,
}

export default CoveringLetter
