import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CloudDownload, Folder } from '@material-ui/icons'
import ExpandMore from '@material-ui/icons/ExpandMore'
import React, { useState } from 'react'
import File from './File'

const useStyles = makeStyles(theme => ({
  list: {
    width: '100%',
  },
  box: {
    width: '100%',
  },
  folderHead: {
    marginLeft: theme.spacing(2),
  },
}))

function FileViewer({ files, folders }) {
  const [expanded, setExpanded] = useState(null)

  const classes = useStyles()

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <Box>
      <Typography variant='h6' gutterBottom>
        Files
      </Typography>

      {folders.map(folder => (
        <Accordion
          key={folder.id}
          expanded={expanded === folder.id}
          onChange={handleChange(folder.id)}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls={`folder-${folder.id}-content`}
            id={`folder-${folder.id}-header`}>
            <Folder></Folder>
            <Typography className={classes.folderHead}>{folder.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box className={classes.box}>
              {files
                .filter(x => x.display_type === 'large' && x.folder_name === folder.name)
                .map(file => (
                  <File key={file.id} file={file} />
                ))}
              <List className={classes.list}>
                {files
                  .filter(
                    x => x.display_type === 'small' && x.folder_name === folder.name
                  )
                  .map(file => (
                    <ListItem key={file.id} button component='a' href={file.url}>
                      <ListItemText primary={file.filename} />
                      <CloudDownload />
                    </ListItem>
                  ))}
              </List>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}

      {files
        .filter(x => x.display_type === 'large' && x.folder_name === 'root')
        .map(file => (
          <File key={file.id} file={file} />
        ))}

      <List>
        {files
          .filter(x => x.display_type === 'small' && x.folder_name === 'root')
          .map(file => (
            <ListItem key={file.id} button component='a' href={file.url}>
              <ListItemText primary={file.filename} />
              <CloudDownload />
            </ListItem>
          ))}
      </List>
    </Box>
  )
}

export default FileViewer
