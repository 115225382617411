import { Box, Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import MuiAlert from '@material-ui/lab/Alert'
import { portabilityCheck } from 'api/number-porting'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useState } from 'react'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
}))

function PortabilityCheck() {
  const { loading, setLoading } = useContext(LoadingContext)
  const [values, setValues] = useState({
    cli: { value: '', error: '' },
    post_code: { value: '', error: '' },
  })
  const [results, setResults] = useState()
  const [error, setError] = useState()
  const classes = useStyles()

  const handleChangeNumber = event => {
    const { name, value } = event.target
    const sanitizedValue = value.replace(/\D+/g, '')
    setValues({ ...values, [name]: { value: sanitizedValue } })
  }

  const handleChangePostCode = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: { value: value } })
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setError()
    setResults()

    if (validateForm()) {
      setLoading(true)

      const result = await portabilityCheck(values.cli.value, values.post_code.value)
      if (result.status === 200) {
        setResults(result.response.data)
      } else {
        setError(
          'There was an error check the portability of this number, please try again in a few minutes'
        )
      }

      setLoading(false)
    }
  }

  const validateForm = () => {
    let valid = true
    const cli = values.cli.value
    const postCode = values.post_code.value
    const newValues = { ...values }

    const cliIIsTooShort = cli.length < 10
    const cliIsTooLong = cli.length > 11
    const CliIsInvalidFormat = cli.substr(0, 1) !== '0' && cli.substr(0, 2) !== '44'

    if (cliIIsTooShort || cliIsTooLong || CliIsInvalidFormat) {
      newValues['cli'] = {
        value: values.cli.value,
        error: 'Please enter a valid phone number format, it should start with 0 or 44',
      }

      valid = false
    }

    if (postCode.length < 1) {
      newValues['post_code'] = {
        value: values.post_code.value,
        error: 'Please enter a valid post code',
      }

      valid = false
    }

    setValues(newValues)

    return valid
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit} noValidate>
      <Paper className={classes.paper}>
        <Typography variant='body1' gutterBottom>
          This tool allows you to check the telephone number and postcode held on the
          Openreach Installation Detail checker. Incorrect postcodes account for the
          largest percentage of rejections and this tool will help confirm what details
          Openreach hold.
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              variant='standard'
              margin='normal'
              fullWidth
              required
              id='cli'
              label='Phone Number'
              name='cli'
              value={values.cli.value}
              autoFocus
              onChange={handleChangeNumber}
              error={values.cli.error ? true : false}
              helperText={values.cli.error}
              inputProps={{ maxLength: 11 }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              variant='standard'
              margin='normal'
              fullWidth
              required
              id='post_code'
              label='Post Code'
              name='post_code'
              value={values.post_code.value}
              onChange={handleChangePostCode}
              error={values.post_code.error ? true : false}
              helperText={values.post_code.error}
              inputProps={{ maxLength: 11 }}
            />
          </Grid>
        </Grid>
      </Paper>

      {error && <MuiAlert severity='error'>{error}</MuiAlert>}

      {results && (
        <React.Fragment>
          <MuiAlert severity={results.install.errors.length === 0 ? 'success' : 'info'}>
            <Typography variant='subtitle1'>Postcode Check</Typography>

            {results.install.errors.length === 0 ? (
              <Box mb={1}>
                Telephone number and postcode details provided have been successfully
                matched on the Openreach installation detail checker. You can now proceed
                with Creating a Port Order.
              </Box>
            ) : (
              <Box mb={1}>
                {results.install.errors.map((x, index) => (
                  <div key={`install-errors-${index}`}>{x}</div>
                ))}
              </Box>
            )}

            <br />

            <div>Range Holder: {results.portability.range_holder}</div>
            {results.portability.range_holder_cupid ? (
              <div>Range Holder CUPID: {results.portability.range_holder_cupid}</div>
            ) : (
              ''
            )}
          </MuiAlert>
        </React.Fragment>
      )}

      <Button
        type='submit'
        variant='contained'
        color='primary'
        disabled={loading}
        className={classes.submit}>
        Check Number
      </Button>
    </form>
  )
}

export default PortabilityCheck
