import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles(theme => ({
  itemContent: {
    minHeight: '100px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: '1.2rem',
  },
}))

function ItemContent(props) {
  const classes = useStyles()

  return <div className={classes.itemContent}>{props.children}</div>
}

export default ItemContent
