import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

function SortableListFieldItem({ item, index, ...props }) {
  return (
    <Draggable
      key={`sort-item-${item.id}`}
      draggableId={`sort-item-${item.id}`}
      index={index}>
      {provided => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}>
          {props.children}
        </div>
      )}
    </Draggable>
  )
}

function SortableListField({ onChange, ...props }) {
  const onDragEnd = event => {
    const result = props.children.map(child => child.props.item)
    const [removed] = result.splice(event.source.index, 1)
    if (event.destination) {
      result.splice(event.destination.index, 0, removed)
    }

    onChange(result)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {props.children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export { SortableListField, SortableListFieldItem }
