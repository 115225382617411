import { Grid, Paper, TextField, Typography } from '@material-ui/core'
import { LoadingContext } from 'contexts/LoadingContext'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { update } from 'api/auth'
import { Button } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import { useToasts } from 'react-toast-notifications'
import UserAvatar from 'components/UserAvatar'
import ImageField from 'components/ImageField'

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  currentAvatar: {
    marginBottom: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    width: '100%',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    minWidth: '100%',
    marginTop: theme.spacing(3),
  },
  selectedAvatar: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: '0.75rem',
    textAlign: 'center',
  },
}))

function MyAccount() {
  const { loading, setLoading } = useContext(LoadingContext)

  const { user, setUser } = useContext(AuthContext)
  const [values, setValues] = useState({})
  const [errors, setErrors] = useState([])

  const classes = useStyles()

  const { addToast } = useToasts()

  const handleSubmit = async event => {
    event.preventDefault()
    setErrors([])
    setLoading(true)

    const payload = {
      first_name: values.first_name,
      last_name: values.last_name,
      contact_email: values.contact_email,
      phone_number: values.phone_number,
      avatar: values.avatar,
    }

    const result = await update(payload)

    if (result.success) {
      addToast('Updated Successfully')
      setUser(result.data)
      setLoading(false)
    } else {
      setErrors(result.errors)
      setLoading(false)
    }
  }

  useEffect(() => {
    setValues({
      first_name: user.first_name,
      last_name: user.last_name,
      phone_number: user.phone_number,
    })
    setLoading(false)
  }, [user, setLoading])

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: value })
  }

  const handleAvatarChange = data => {
    setValues({ ...values, avatar: data })
  }

  return (
    <>
      <Typography variant='h3' gutterBottom>
        My Account
      </Typography>

      {!loading && (
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <TextField
                  variant='standard'
                  margin='normal'
                  required
                  fullWidth
                  id='first_name'
                  label='First Name'
                  name='first_name'
                  defaultValue={user.first_name}
                  autoFocus
                  onChange={handleChange.bind(this)}
                />
                <TextField
                  variant='standard'
                  margin='normal'
                  required
                  fullWidth
                  id='last_name'
                  label='Last Name'
                  name='last_name'
                  defaultValue={user.last_name}
                  onChange={handleChange.bind(this)}
                />
                {user.type === 'reseller' && (
                  <TextField
                    variant='standard'
                    margin='normal'
                    fullWidth
                    type='email'
                    id='email'
                    label='Contact Email'
                    name='contact_email'
                    defaultValue={user.contact_email}
                    onChange={handleChange.bind(this)}
                    helperText='Changes to this address will not affect the email address you use to log in.'
                  />
                )}
                <TextField
                  variant='standard'
                  margin='normal'
                  fullWidth
                  id='phone_number'
                  label='Phone Number'
                  name='phone_number'
                  defaultValue={user.phone_number}
                  onChange={handleChange.bind(this)}
                />
              </Paper>

              <Paper className={classes.paper}>
                <Typography variant='subtitle1' gutterBottom>
                  Profile Photo
                </Typography>
                <UserAvatar className={classes.currentAvatar} user={user}></UserAvatar>
                <ImageField onChange={handleAvatarChange} />
              </Paper>
            </Grid>
          </Grid>

          {errors.length > 0 && (
            <MuiAlert severity='error'>
              {errors.map((error, index) => {
                return <div key={index}>{error[1]}</div>
              })}
            </MuiAlert>
          )}
          <Button
            type='submit'
            variant='contained'
            color='primary'
            disabled={loading}
            className={classes.submit}>
            Submit
          </Button>
        </form>
      )}
    </>
  )
}

export default MyAccount
