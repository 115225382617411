import CustomerDetails from 'components/number-porting/geographic-port/CustomerDetails'
import AddressDetails from 'components/number-porting/geographic-port/AddressDetails'
import React from 'react'

function InstallDetails() {
  return (
    <React.Fragment>
      <CustomerDetails />
      <AddressDetails />
    </React.Fragment>
  )
}

export default InstallDetails
