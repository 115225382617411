import PropTypes from 'prop-types'
import React, { useState } from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/lib/ReactCrop.scss'

const getCroppedImg = (image, crop, maxRenderWidth, maxRenderHeight) => {
  const canvas = document.createElement('canvas')
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height

  const scaledCropWidth = crop.width * scaleX
  const scaledCropHeight = crop.height * scaleY

  let width = scaledCropWidth
  let height = scaledCropHeight

  if (scaledCropWidth > maxRenderWidth) {
    const scaleDown = maxRenderWidth / scaledCropWidth
    width = scaledCropWidth * scaleDown
    height = scaledCropHeight * scaleDown
  }

  canvas.width = width
  canvas.height = height
  const ctx = canvas.getContext('2d')

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    width,
    height
  )

  return canvas.toDataURL('image/png')
}

function ImageCropper({
  imageBase64,
  onChange,
  defaultCropWidth,
  defaultCropHeight,
  minImageWidth,
  minImageHeight,
  maxRenderWidth,
  maxRenderHeight,
}) {
  const [crop, setCrop] = useState()
  const [image, setImage] = useState()
  const [scaleX, setScaleX] = useState(1)
  const [scaleY, setScaleY] = useState(1)

  const handleImageLoaded = image => {
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    setScaleX(scaleX)
    setScaleY(scaleY)

    const newCrop = {
      aspect: maxRenderWidth && maxRenderHeight ? maxRenderWidth / maxRenderHeight : null,
      x: 0,
      y: 0,
      width: defaultCropWidth ? defaultCropWidth / scaleX : minImageWidth / scaleX,
      height: defaultCropHeight
        ? defaultCropHeight / scaleY
        : minImageHeight
          ? minImageHeight / scaleY
          : null,
    }

    setCrop(newCrop)
    setImage(image)

    // handle submitting if no crop adjustments made
    const img = getCroppedImg(image, newCrop, maxRenderWidth, maxRenderHeight)
    onChange(img)
  }

  const handleCropComplete = crop => {
    if (image && crop.width && crop.height) {
      const img = getCroppedImg(image, crop, maxRenderWidth, maxRenderHeight)
      onChange(img)
    }
  }

  return (
    <ReactCrop
      style={{ maxWidth: `${maxRenderWidth}px` }}
      src={imageBase64}
      keepSelection={true}
      minWidth={minImageWidth / scaleX}
      minHeight={minImageHeight ? minImageHeight / scaleY : null}
      crop={crop}
      onImageLoaded={image => {
        handleImageLoaded(image)
        return false
      }}
      onChange={newCrop => setCrop(newCrop)}
      onComplete={newCrop => handleCropComplete(newCrop)}
    />
  )
}

ImageCropper.propTypes = {
  imageBase64: PropTypes.string,
  onChange: PropTypes.func,
  minImageWidth: PropTypes.number.isRequired,
  minImageHeight: PropTypes.number,
  maxRenderWidth: PropTypes.number.isRequired,
  maxRenderHeight: PropTypes.number,
  defaultCropWidth: PropTypes.number,
  defaultCropHeight: PropTypes.number,
}

ImageCropper.defaultProps = {
  minImageWidth: 800,
  maxRenderWidth: 800,
}

export default ImageCropper
