import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { DatePicker } from '@material-ui/pickers'
import { statusUpdate } from 'api/number-porting'
import { format } from 'date-fns'
import React, { useState } from 'react'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
})

function UpdateStatusModal({ id, afterSubmit, children }) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState({
    new_status: '',
    rejection_reason: '',
    port_date: new Date(),
  })

  const handleOpen = (id, isOwner) => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: value })
  }

  const handleChangeDate = date => {
    setValues({ ...values, port_date: date })
  }

  const handleSubmit = async () => {
    setLoading(true)
    await statusUpdate(id, {
      status: values.new_status,
      rejection_reason: values.new_status === 'REJECTED' ? values.rejection_reason : '',
      port_date:
        values.new_status === 'ACCEPTED' ? format(values.port_date, 'yyyy-MM-dd') : '',
    })

    if (afterSubmit) {
      await afterSubmit()
    }

    setLoading(false)
    setOpen(false)
  }

  return (
    <React.Fragment>
      <span onClick={handleOpen}>{children}</span>

      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Number Port Status Update</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The contact email associated with this port will receive an email notification
            when this form is submitted.
          </DialogContentText>

          <FormControl className={classes.formControl}>
            <InputLabel id='new_status_label'>New Status</InputLabel>
            <Select
              labelId='new_status_label'
              name='new_status'
              value={values.new_status}
              onChange={handleChange}>
              <MenuItem value='SUBMITTED'>Submitted</MenuItem>
              <MenuItem value='DATE_CHANGE_PENDING'>Date Change Pending</MenuItem>
              <MenuItem value='ACCEPTED'>Accepted</MenuItem>
              <MenuItem value='TRIGGERED'>Triggered</MenuItem>
              <MenuItem value='COMPLETED'>Completed</MenuItem>
              <MenuItem value='REJECTED'>Rejected</MenuItem>
              <MenuItem value='CANCELLED'>Cancelled</MenuItem>
            </Select>
          </FormControl>

          {values.new_status === 'REJECTED' && (
            <div>
              <TextField
                variant='standard'
                margin='normal'
                fullWidth
                multiline
                rows={2}
                id='rejection_reason'
                label='Rejection Reason'
                name='rejection_reason'
                value={values.rejection_reason}
                onChange={handleChange}
              />
            </div>
          )}

          {values.new_status === 'ACCEPTED' && (
            <DatePicker
              style={{ marginTop: '1rem' }}
              label='Port Date'
              disablePast={true}
              className={classes.formControl}
              inputVariant='standard'
              format='dd/MM/yyyy'
              fullWidth
              value={values.port_date ? values.port_date : new Date()}
              onChange={handleChangeDate}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={loading} onClick={handleSubmit} color='primary'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

UpdateStatusModal.propTypes = {
  id: PropTypes.number,
  afterSubmit: PropTypes.func,
}

export default UpdateStatusModal
