import { Box } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import YesNoQuestion from 'components/number-porting/geographic-port/YesNoQuestion'
import { GeographicPortContext } from 'contexts/GeographicPortContext'
import React, { useContext } from 'react'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(4),
  },
}))

function AdditionalDetails() {
  const classes = useStyles()
  const { state, dispatch } = useContext(GeographicPortContext)

  const handleChangeAssociatedProducts = event => {
    dispatch({
      type: 'SET_HAS_ASSOCIATED_PRODUCTS',
      payload: event.target.value === 'yes',
    })
  }

  const handleChangeFeaturePort = event => {
    dispatch({ type: 'SET_IS_FEATURENET_PORT', payload: event.target.value === 'yes' })
  }

  const handleChangeRcf = event => {
    dispatch({ type: 'SET_HAS_RCF', payload: event.target.value === 'yes' })
  }

  const handleChangeRcfPostCode = event => {
    dispatch({ type: 'SET_RCF_POST_CODE', payload: event.target.value })
  }

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5' gutterBottom>
        Additional Details
      </Typography>

      <Typography variant='body1' gutterBottom>
        Please ensure the following options are filled in correctly as incorrect
        information can result in rejections.
      </Typography>

      <Box mt={3}>
        <YesNoQuestion
          value={state.number_details.has_associated_products.value ? 'yes' : 'no'}
          onChange={handleChangeAssociatedProducts}>
          Are there any products associated with this line?
        </YesNoQuestion>
        <YesNoQuestion
          value={state.number_details.is_featurenet_port.value ? 'yes' : 'no'}
          onChange={handleChangeFeaturePort}>
          Is this a featurenet port order?
        </YesNoQuestion>
        <YesNoQuestion
          value={state.number_details.has_rcf.value ? 'yes' : 'no'}
          onChange={handleChangeRcf}>
          Does the line have RCF (Remote Call Forward)?
        </YesNoQuestion>
        {state.number_details.has_rcf.value && (
          <Box mt={-1} mb={3}>
            <TextField
              variant='standard'
              margin='normal'
              fullWidth
              required
              id='rcf_post_code'
              label='RCF Exchange Post Code'
              name='rcf_post_code'
              value={state.number_details.rcf_post_code.value}
              onChange={handleChangeRcfPostCode}
              error={state.errors.hasOwnProperty('rcf_post_code') ? true : false}
              helperText={
                state.errors.hasOwnProperty('rcf_post_code')
                  ? state.errors.rcf_post_code
                  : ''
              }
            />
          </Box>
        )}
      </Box>
    </Paper>
  )
}

export default AdditionalDetails
