import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import SummaryStep from './steps/SummaryStep'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
}))

export default function ReadOnly() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <SummaryStep />
    </div>
  )
}
