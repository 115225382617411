import config from 'config'
import { getJsonHeaders, getQueryStringFromFiltersObject } from './helpers'

export async function list(limit = 15, page = 1, filters = {}) {
  try {
    let queryString = `?per_page=${limit}&page=${page}`
    const filterString = getQueryStringFromFiltersObject(filters)

    if (filterString) {
      queryString += `&${filterString}`
    }

    const result = await fetch(config.API_URL + '/api/resellers' + queryString)
    return await result.json()
  } catch (e) {
    return false
  }
}

export async function get(id) {
  try {
    const result = await fetch(config.API_URL + `/api/resellers/${id}`)
    return await result.json()
  } catch (e) {
    return false
  }
}

export async function create(body) {
  try {
    const res = await fetch(config.API_URL + `/api/resellers`, {
      method: 'POST',
      headers: await getJsonHeaders(),
      body: JSON.stringify(body),
    })

    if (res.status === 201) {
      return {
        success: true,
        message: 'Successfully created reseller',
      }
    }

    const decoded = await res.json()
    return {
      success: false,
      message: decoded.message,
      errors: Object.entries(decoded.errors).map(error => {
        return error
      }),
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to create reseller',
    }
  }
}

export async function update(id, body) {
  try {
    const res = await fetch(config.API_URL + `/api/resellers/${id}`, {
      method: 'PUT',
      headers: await getJsonHeaders(),
      body: JSON.stringify(body),
    })

    if (res.status === 200) {
      return {
        success: true,
        message: 'Successfully updated reseller',
      }
    }

    const decoded = await res.json()
    return {
      success: false,
      message: decoded.message,
      errors: Object.entries(decoded.errors).map(error => {
        return error
      }),
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to update reseller',
      errors: [['', 'Failed to update reseller']],
    }
  }
}

export async function deleteReseller(id) {
  try {
    const res = await fetch(config.API_URL + `/api/resellers/${id}`, {
      method: 'DELETE',
      headers: await getJsonHeaders(),
      body: JSON.stringify({}),
    })

    if (res.status === 204) {
      return true
    }

    return false
  } catch (e) {
    return false
  }
}
