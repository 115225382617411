import React, { useState } from 'react'

const ViewContext = React.createContext()
const WithView = ({ children }) => {
  const [viewMenuItems, setViewMenuItems] = useState([])
  const [selectedView, setSelectedView] = useState(null)

  const defaultContext = {
    viewMenuItems,
    setViewMenuItems,
    selectedView,
    setSelectedView,
  }
  return <ViewContext.Provider value={defaultContext}>{children}</ViewContext.Provider>
}

export { ViewContext }
export default WithView
