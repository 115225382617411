import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import MuiAlert from '@material-ui/lab/Alert'
import { contact } from 'api/leasing'
import React, { useState } from 'react'

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
  },
}))

function LeasingRatesDialog({ open, setOpen }) {
  const classes = useStyles()
  const [values, setValues] = useState({
    business_name: { value: '', error: '' },
    name: { value: '', error: '' },
    contact_number: { value: '', error: '' },
    email: { value: '', error: '' },
  })
  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: { value: value } })
  }

  const handleSubmit = async event => {
    event.preventDefault()

    setErrors([])
    setLoading(true)
    const body = {
      business_name: values.business_name.value,
      name: values.name.value,
      contact_number: values.contact_number.value,
      email: values.email.value,
    }

    const result = await contact(body)
    if (result.success) {
      setLoading(false)
      setSubmitted(true)
    } else {
      setErrors(result.errors)
      setLoading(false)
    }
  }

  const handleCloseRatesDialog = () => {
    setOpen(false)
  }

  return (
    <Dialog open={open} onClose={handleCloseRatesDialog} fullWidth>
      <DialogTitle id='dialog-leasing'>{'Enter Your Details'}</DialogTitle>
      <DialogContent>
        <DialogContentText id='dialog-leasing-description'>
          {submitted && (
            <MuiAlert severity='success'>
              Your contact details have been sent, you should hear from Clear Asset
              Finance shortly.
            </MuiAlert>
          )}

          {!submitted && (
            <form className={classes.form} noValidate>
              <TextField
                disabled={loading}
                variant='standard'
                margin='normal'
                required
                fullWidth
                id='business_name'
                label='Business Name'
                name='business_name'
                value={values.business_name.value}
                autoFocus
                onChange={handleChange.bind(this)}
              />
              <TextField
                disabled={loading}
                variant='standard'
                margin='normal'
                required
                fullWidth
                id='name'
                label='Name'
                name='name'
                value={values.name.value}
                onChange={handleChange.bind(this)}
              />
              <TextField
                disabled={loading}
                variant='standard'
                margin='normal'
                required
                fullWidth
                id='contact_number'
                label='Contact Number'
                name='contact_number'
                value={values.contact_number.value}
                onChange={handleChange.bind(this)}
              />
              <TextField
                disabled={loading}
                variant='standard'
                margin='normal'
                required
                fullWidth
                id='email'
                label='Email'
                name='email'
                value={values.email.value}
                onChange={handleChange.bind(this)}
              />
            </form>
          )}

          {errors.length > 0 && (
            <MuiAlert severity='error'>
              {errors.map(error => {
                return <div>{error[1]}</div>
              })}
            </MuiAlert>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleCloseRatesDialog} color='primary'>
          Close
        </Button>
        {!submitted && (
          <Button
            disabled={loading}
            onClick={handleSubmit}
            variant='contained'
            color='primary'>
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default LeasingRatesDialog
