import { FormHelperText, FormLabel, Paper, TextField } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import MuiAlert from '@material-ui/lab/Alert'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React from 'react'
import TextContentEditor from 'components/TextContentEditor'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  formControl: {
    minWidth: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

function CategoryForm({ values, errors, handleChange, categories }) {
  const classes = useStyles()

  const handleChangeParentCategory = value => {
    handleChange({ target: { name: 'parent_category', value: value } })
  }

  const handleChangeContent = event => {
    handleChange({
      target: { ...event.target, value: JSON.stringify(event.target.value) },
    })
  }

  return (
    <Paper className={classes.paper}>
      {errors.length > 0 && <MuiAlert severity='error'>{errors.map(x => x[1])}</MuiAlert>}

      <TextField
        variant='standard'
        margin='normal'
        required
        fullWidth
        id='name'
        label='Name'
        name='name'
        autoFocus
        value={values.name}
        onChange={handleChange}
        helperText='The main display name for the category.'
      />

      <FormControl variant='standard' className={classes.formControl}>
        <FormLabel>Description</FormLabel>
        <TextContentEditor
          name='description'
          placeHolder='This will display when viewing a category or if it is featured.'
          value={values.description}
          onChange={handleChangeContent}></TextContentEditor>
      </FormControl>

      <FormControl variant='standard' className={classes.formControl}>
        <InputLabel id='featured_label'>Featured</InputLabel>
        <Select
          labelId='featured_label'
          id='featured'
          name='featured'
          value={values.featured ? '1' : '0'}
          onChange={handleChange}
          label='Featured'>
          <MenuItem value='0'>No</MenuItem>
          <MenuItem value='1'>Yes</MenuItem>
        </Select>
        <FormHelperText>
          Featured categories will display on the library homepage.
        </FormHelperText>
      </FormControl>

      <Autocomplete
        id='parent_category'
        options={categories.data}
        getOptionLabel={option => option.name ?? ''}
        value={values.parent_category}
        onChange={(_, newValue) => {
          handleChangeParentCategory(newValue)
        }}
        renderInput={params => (
          <TextField
            {...params}
            helperText='A category with no parent will display on the right side of the library homepage.  If a parent is selected, it will appear nested under that category.  You can always change this later.'
            label='Parent Category'
          />
        )}
      />
    </Paper>
  )
}

export default CategoryForm
