import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import { CloudDownloadOutlined, DescriptionOutlined, Videocam } from '@material-ui/icons'
import Link from 'components/Link'
import PropTypes from 'prop-types'
import React from 'react'
import { Link as ReactRouteLink } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  limitWidth: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '150px',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '200px',
    },
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    flexGrow: 1,
  },
  categoryLink: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

function Post({ post, limitWidth, linkPrefix }) {
  const classes = useStyles()

  return (
    <Box display='flex' alignItems='center' mb={1}>
      <Link to={`/${linkPrefix}/posts/${post.id}`} className={classes.link}>
        <Box display='flex' alignItems='center'>
          <DescriptionOutlined style={{ marginRight: '0.5rem' }} />{' '}
          <Tooltip style={{ marginRight: '0.5rem' }} title={post.name} placement='top'>
            <div className={limitWidth ? classes.limitWidth : ''}>{post.name}</div>
          </Tooltip>
          <Box flexGrow={1}></Box>
          {post.file_count > 0 && (
            <Tooltip
              style={{ marginRight: '0.5rem' }}
              title='Has File Downloads'
              placement='top'>
              <CloudDownloadOutlined />
            </Tooltip>
          )}
          {post.youtube_id && (
            <Tooltip title='Has Video' placement='top'>
              <Videocam />
            </Tooltip>
          )}
        </Box>
      </Link>

      {post.categories.map(cat => (
        <Chip
          className={classes.categoryLink}
          component={ReactRouteLink}
          to={`/${linkPrefix}/categories/${cat.id}`}
          key={cat.id}
          size='small'
          label={`${cat.name.length > 12 ? `${cat.name.substring(0, 12)}...` : cat.name}`}
        />
      ))}
    </Box>
  )
}

Post.propTypes = {
  post: PropTypes.object,
  limitWidth: PropTypes.bool,
}

Post.defaultProps = {
  limitWidth: false,
}

export default Post
