import { Box } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { addMiscItem } from 'api/quote-builder'
import { QuoteContext } from 'contexts/QuoteContext'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
  },
  form: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
}))

const intitialState = {
  name: '',
  quantity: '',
  upfront_cost_price: '',
  monthly_cost_price: '',
  upfront_rrp: '',
  monthly_rrp: '',
  outside_lease: 1,
  hidden: 0,
}

function MiscQuoteItem() {
  const classes = useStyles()

  const { t } = useTranslation()
  const { state, dispatch } = useContext(QuoteContext)

  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState(intitialState)
  const [errors, setErrors] = useState({})

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: value })
    setErrors({})
  }

  const handleSubmit = async () => {
    setLoading(true)
    const res = await addMiscItem(state.id, {
      name: values.name,
      quantity: values.quantity,
      upfront_cost_price: values.upfront_cost_price * 100,
      monthly_cost_price: values.monthly_cost_price * 100,
      upfront_rrp: values.upfront_rrp * 100,
      monthly_rrp: values.monthly_rrp * 100,
      outside_lease: values.outside_lease,
      hidden: values.hidden,
    })
    if (res.success) {
      dispatch({ type: 'SET_SUMMARY', payload: res.body })
      setValues(intitialState)
    } else {
      setErrors(res.body.errors)
    }
    setLoading(false)
  }

  return (
    <Accordion elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id='no-lease-product'>
        <Typography>Add additional misc items</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className={classes.container}>
          <div className={classes.form}>
            <TextField
              variant='standard'
              margin='normal'
              required
              fullWidth
              id='name'
              label='Name'
              name='name'
              value={values.name}
              onChange={handleChange}
              error={errors.hasOwnProperty('name') ? true : false}
              helperText={errors.hasOwnProperty('name') ? errors['name'] : ''}
            />

            <TextField
              variant='standard'
              margin='normal'
              required
              fullWidth
              id='upfront_cost_price'
              label={t('Total Upfront Cost Price')}
              name='upfront_cost_price'
              value={values.upfront_cost_price}
              onChange={handleChange}
              error={errors.hasOwnProperty('upfront_cost_price') ? true : false}
              helperText={
                errors.hasOwnProperty('upfront_cost_price')
                  ? errors['upfront_cost_price']
                  : ''
              }
            />

            <TextField
              variant='standard'
              margin='normal'
              required
              fullWidth
              id='monthly_cost_price'
              label={t('Total Monthly Cost Price')}
              name='monthly_cost_price'
              value={values.monthly_cost_price}
              onChange={handleChange}
              error={errors.hasOwnProperty('monthly_cost_price') ? true : false}
              helperText={
                errors.hasOwnProperty('monthly_cost_price')
                  ? errors['monthly_cost_price']
                  : ''
              }
            />

            <TextField
              variant='standard'
              margin='normal'
              required
              fullWidth
              id='quantity'
              label='Quantity'
              name='quantity'
              value={values.quantity}
              onChange={handleChange}
              error={errors.hasOwnProperty('quantity') ? true : false}
              helperText={errors.hasOwnProperty('quantity') ? errors['quantity'] : ''}
            />

            <TextField
              variant='standard'
              margin='normal'
              required
              fullWidth
              id='upfront_rrp'
              label={t('Total Upfront RRP')}
              name='upfront_rrp'
              value={values.upfront_rrp}
              onChange={handleChange}
              error={errors.hasOwnProperty('upfront_rrp') ? true : false}
              helperText={
                errors.hasOwnProperty('upfront_rrp') ? errors['upfront_rrp'] : ''
              }
            />

            <TextField
              variant='standard'
              margin='normal'
              required
              fullWidth
              id='monthly_rrp'
              label={t('Total Monthly RRP')}
              name='monthly_rrp'
              value={values.monthly_rrp}
              onChange={handleChange}
              error={errors.hasOwnProperty('monthly_rrp') ? true : false}
              helperText={
                errors.hasOwnProperty('monthly_rrp') ? errors['monthly_rrp'] : ''
              }
            />

            <FormControl variant='standard'>
              <InputLabel id='outside_lease_label'>Exclude from Lease?</InputLabel>
              <Select
                labelId='outside_lease_label'
                id='outside_lease'
                name='outside_lease'
                value={values.outside_lease}
                onChange={handleChange}
                label='Exclude from Lease?'>
                <MenuItem value='1'>Yes</MenuItem>
                <MenuItem value='0'>No</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant='standard'>
              <InputLabel id='hidden_label'>Hide from Proposal?</InputLabel>
              <Select
                labelId='hidden_label'
                id='hidden'
                name='hidden'
                value={values.hidden}
                onChange={handleChange}
                label='Hidden from proposal'>
                <MenuItem value='1'>Yes</MenuItem>
                <MenuItem value='0'>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <Box marginTop={3}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              onClick={handleSubmit}
              disabled={loading}>
              Add to Quote
            </Button>
          </Box>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default MiscQuoteItem
