import React from 'react'
import draftToHtml from 'draftjs-to-html'
import { useState } from 'react'
import { useEffect } from 'react'

function PostContentRenderer({ content, preview = false }) {
  const [draftJsContent, setDraftJsContent] = useState(null)
  const [previewContent, setPreviewContent] = useState('')

  useEffect(() => {
    if (preview) {
      try {
        let json = JSON.parse(content)
        setPreviewContent(json.blocks[0].text.substring(0, 199) + '...')
      } catch (e) {
        setPreviewContent(content.substring(0, 199) + '...')
      }
    } else {
      try {
        setDraftJsContent(draftToHtml(JSON.parse(content)))
      } catch (e) {
        setDraftJsContent(null)
      }
    }
  }, [content, preview])

  if (previewContent) {
    return <p>{previewContent}</p>
  }

  return draftJsContent ? (
    <div dangerouslySetInnerHTML={{ __html: draftJsContent }}></div>
  ) : (
    content
      .split('\n')
      .filter(x => x.length > 0)
      .map((x, index) => {
        return <p key={index}>{x}</p>
      })
  )
}

export default PostContentRenderer
