import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import SummaryBox from 'components/quotes/SummaryBox'
import { QuoteContext } from 'contexts/QuoteContext'
import React, { useContext, useEffect, useState } from 'react'

const useStyles = makeStyles(theme => ({
  action: {
    width: '120px',
    marginTop: '0',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  button: {
    width: '140px',
    marginTop: '0',
  },
}))

const WhiteFormControl = withStyles({
  root: {
    '& .MuiInputLabel-root': {
      color: '#FFF', // Label
    },
    '& .MuiInputBase-input': {
      color: '#FFF', // Text color
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#FFF9', // Solid underline
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#FFF', // Solid underline on hover
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#FFF', // Solid underline on focus
    },
  },
})(FormControl)

const WhiteTextField = withStyles({
  root: {
    '& .MuiInputLabel-root': {
      color: '#FFF', // Label
    },
    '& .MuiInputBase-input': {
      color: '#FFF', // Text color
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#FFF9', // Solid underline
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#FFF', // Solid underline on hover
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#FFF', // Solid underline on focus
    },
  },
})(TextField)

function BroadbandItem({ options, selectedItems, id, onChange }) {
  const { state } = useContext(QuoteContext)

  const classes = useStyles()
  const [selected, setSelected] = useState(false)
  const [quantities, setQuantities] = useState({})
  const [router, setRouter] = useState('')
  const [routerQuantity, setRouterQuantity] = useState(1)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)

    const quantities = {}
    if (selectedItems && Array.isArray(selectedItems)) {
      for (let i = 0; i < selectedItems.length; i++)
        if (selectedItems[i].id === id) {
          quantities[selectedItems[i].type] = selectedItems[i].quantity

          if (selectedItems[i]['router']) {
            setRouter(selectedItems[i]['router']['id'])
            setRouterQuantity(selectedItems[i]['router']['quantity'])
          }
        }
    }

    setQuantities(quantities)
    setSelected(Object.keys(quantities).length > 0)
    setLoading(false)
  }, [selectedItems, id, setQuantities, setSelected])

  const handleClick = () => {
    setSelected(true)
  }

  const handleChangeQuantities = (event, option) => {
    const value = parseInt(event.target.value)
    const newQuantities = { ...quantities }
    newQuantities[option] = value
    setQuantities(newQuantities)
    onChange(id, newQuantities, router)
  }

  const handleChangeRouter = event => {
    const value = event.target.value
    setRouter(value)
    onChange(id, quantities, { id: value, quantity: routerQuantity })
  }

  const handleChangeRouterQuantity = event => {
    const value = event.target.value
    setRouterQuantity(value)
    onChange(id, quantities, { id: router, quantity: value })
  }

  const handleBlur = () => {
    const hasValues =
      Object.keys(quantities).filter(x => {
        const qty = parseInt(quantities[x])
        return !isNaN(qty) && qty > 0
      }).length > 0
    setSelected(hasValues)
  }

  const getImageUrl = () => {
    return options[id].hasOwnProperty('image_url') ? options[id].image_url : ''
  }

  const getImageThumbUrl = () => {
    return options[id].hasOwnProperty('image_thumb_url')
      ? options[id].image_thumb_url
      : ''
  }

  const textFieldProps = option => {
    return {
      className: classes.action,
      variant: 'standard',
      margin: 'normal',
      value: quantities.hasOwnProperty(option) ? quantities[option] : '',
      onChange: e => {
        handleChangeQuantities(e, option)
      },
      id: option,
      name: option,
      type: 'number',
    }
  }

  return (
    <SummaryBox
      variant={getImageThumbUrl() ? 'image' : 'text'}
      header={getImageThumbUrl() ? options[id].name : ''}
      text={options[id].name}
      interop_url={options[id].interop_url ?? null}
      image_thumb_url={getImageThumbUrl()}
      image_url={getImageUrl()}>
      {loading && <CircularProgress />}

      {!loading && !selected && (
        <Button
          variant='contained'
          color='default'
          style={{ marginTop: '16px' }}
          className={classes.button}
          onClick={handleClick}>
          Select
        </Button>
      )}

      {!loading && selected && (
        <div onBlur={handleBlur}>
          {getImageThumbUrl() ? (
            <>
              {options[id].product_options.map(x => (
                <TextField
                  key={x.slug}
                  label={`${x.label} Qty`}
                  {...textFieldProps(x.slug)}
                />
              ))}
            </>
          ) : (
            <>
              {options[id].product_options.map(x => (
                <WhiteTextField
                  key={x.slug}
                  label={`${x.label} Qty`}
                  {...textFieldProps(x.slug)}
                />
              ))}
            </>
          )}

          {state.options.network_hardware.hasOwnProperty('Um91dGVycw==') && (
            <div>
              <WhiteFormControl variant='standard' className={classes.action}>
                <InputLabel id='router_label'> Router</InputLabel>
                <Select
                  labelId='router_label'
                  id='router'
                  name='router'
                  value={router}
                  onChange={handleChangeRouter}
                  label='Default Display'>
                  {Object.keys(state.options.network_hardware['Um91dGVycw=='].items).map(
                    x => (
                      <MenuItem
                        value={
                          state.options.network_hardware['Um91dGVycw=='].items[x].id
                        }>
                        {state.options.network_hardware['Um91dGVycw=='].items[x].name}
                      </MenuItem>
                    )
                  )}
                </Select>
              </WhiteFormControl>

              {router && (
                <WhiteTextField
                  style={{ marginTop: '12px' }}
                  label='Router Qty'
                  className={classes.action}
                  variant='standard'
                  margin='normal'
                  value={routerQuantity}
                  onChange={handleChangeRouterQuantity}
                  name='router_quantity'
                  type='number'
                />
              )}
            </div>
          )}
        </div>
      )}
    </SummaryBox>
  )
}

export default BroadbandItem
