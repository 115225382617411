import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import {
  Assignment,
  Category,
  Dialpad,
  FastForward,
  Home,
  LocalOffer,
  LocationOn,
  School,
  SettingsInputComponent,
  Videocam,
  ShoppingBasket,
  Cloud,
  ShoppingCart,
} from '@material-ui/icons'
import BarChart from '@material-ui/icons/BarChart'
import AnnouncementIcon from '@material-ui/icons/Announcement'
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered'
import GroupIcon from '@material-ui/icons/Group'
import HomeIcon from '@material-ui/icons/Home'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import LibraryBooks from '@material-ui/icons/LibraryBooks'
import ListIcon from '@material-ui/icons/List'
import PhoneIcon from '@material-ui/icons/Phone'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import RecentActorsIcon from '@material-ui/icons/RecentActors'
import RecordVoiceIcon from '@material-ui/icons/RecordVoiceOver'
import RouterIcon from '@material-ui/icons/Router'
import SettingsIcon from '@material-ui/icons/Settings'
import TollIcon from '@material-ui/icons/Toll'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { FeatureToggleContext } from 'contexts/FeatureToggleContext'
import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { AuthContext } from 'contexts/AuthContext'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  subNested: {
    paddingLeft: theme.spacing(8),
  },
}))

function MenuItems() {
  const classes = useStyles()
  const { hasFeature } = useContext(FeatureToggleContext)
  const { hasPermission } = useContext(AuthContext)
  const [setupOpen, setSetupOpen] = React.useState(false)
  const [productsOpen, setProductsOpen] = React.useState(false)
  const [salesHubOpen, setSalesHubOpen] = React.useState(false)
  const [appsOpen, setAppsOpen] = React.useState(false)
  const [numberPortingOpen, setNumberPortingOpen] = React.useState(false)
  const [ordersOpen, setOrdersOpen] = React.useState(false)

  const handleSetupClick = () => {
    setSetupOpen(!setupOpen)
  }

  const handleProductsClick = () => {
    setProductsOpen(!productsOpen)
  }

  const handleSalesHubClick = () => {
    setSalesHubOpen(!salesHubOpen)
  }

  const handleAppsClick = () => {
    if (appsOpen !== true) {
      setAppsOpen(true)
    }
  }

  const handleAppsToggle = () => {
    setAppsOpen(false)
  }

  const handleNumberPortingClick = () => {
    setNumberPortingOpen(!numberPortingOpen)
  }

  const handleOrdersClick = () => {
    setOrdersOpen(!ordersOpen)
  }

  return (
    <>
      <ListItem button onClick={handleSalesHubClick}>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary='Sales Hub' />
        {salesHubOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={salesHubOpen} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          <ListItem button className={classes.nested} component={NavLink} to='/sales-hub'>
            <ListItemIcon>
              <VisibilityIcon />
            </ListItemIcon>
            <ListItemText primary='Preview' />
          </ListItem>
          <ListItem button className={classes.nested} component={NavLink} to='/training'>
            <ListItemIcon>
              <EmojiPeopleIcon />
            </ListItemIcon>
            <ListItemText primary='Training' />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={NavLink}
            to='/announcements'>
            <ListItemIcon>
              <AnnouncementIcon />
            </ListItemIcon>
            <ListItemText primary='Announcements' />
          </ListItem>
        </List>
      </Collapse>

      {hasPermission('quote') && (
        <ListItem
          button
          id='quotes-menu-item'
          key='Quotes'
          component={NavLink}
          to='/quotes'>
          <ListItemIcon>
            <FormatListNumberedIcon />
          </ListItemIcon>
          <ListItemText primary='QuickQuote' />
        </ListItem>
      )}

      {hasFeature('distributor_sales_dash') && (
        <ListItem button key='Dashboard' component={NavLink} to='/sales-dash'>
          <ListItemIcon>
            <BarChart />
          </ListItemIcon>
          <ListItemText primary='Dashboard' />
        </ListItem>
      )}

      <ListItem button key='Library' component={NavLink} to='/library'>
        <ListItemIcon>
          <LibraryBooks />
        </ListItemIcon>
        <ListItemText primary='Library' />
      </ListItem>

      <ListItem button key='Video Library' component={NavLink} to='/video-library'>
        <ListItemIcon>
          <Videocam />
        </ListItemIcon>
        <ListItemText primary='Video Library' />
      </ListItem>

      <ListItem button key='QuickStart' component={NavLink} to='/quickstart-library'>
        <ListItemIcon>
          <FastForward />
        </ListItemIcon>
        <ListItemText primary='QuickStart' />
      </ListItem>

      <ListItem button key='Training' component={NavLink} to='/training-library'>
        <ListItemIcon>
          <School />
        </ListItemIcon>
        <ListItemText primary='Training' />
      </ListItem>

      <ListItem button key='Marketing' component={NavLink} to='/marketing-library'>
        <ListItemIcon>
          <Assignment />
        </ListItemIcon>
        <ListItemText primary='Marketing' />
      </ListItem>

      <ListItem button key='Price List' component={NavLink} to='/price-list'>
        <ListItemIcon>
          <LocalOffer />
        </ListItemIcon>
        <ListItemText primary='Price List' />
      </ListItem>

      <>
        <ListItem id='orders-menu-item' button onClick={handleOrdersClick}>
          <ListItemIcon>
            <ShoppingCart />
          </ListItemIcon>
          <ListItemText primary='Orders' />
          {ordersOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={ordersOpen} timeout='auto' unmountOnExit>
          <ListItem
            button
            id='cloud-orders-info-menu-item'
            key='Cloud Orders Info'
            component={NavLink}
            to='/cloud-orders-info'>
            <ListItemIcon>
              <Cloud />
            </ListItemIcon>
            <ListItemText primary='Cloud Orders Info' />
          </ListItem>

          <ListItem
            button
            key='Order Online'
            component='a'
            target='_blank'
            href='https://orders.wearepragma.co.uk'>
            <ListItemIcon>
              <ShoppingBasket />
            </ListItemIcon>
            <ListItemText primary='Order Online' />
          </ListItem>
        </Collapse>
      </>

      <ListItem button onClick={handleNumberPortingClick}>
        <ListItemIcon>
          <SettingsInputComponent />
        </ListItemIcon>
        <ListItemText primary='Number Porting' />
        {numberPortingOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={numberPortingOpen} timeout='auto' unmountOnExit>
        <ListItem
          button
          id='portability-check-menu-item'
          key='Portability Check'
          component={NavLink}
          to='/number-porting/portability-check'>
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText primary='Portability Check' />
        </ListItem>

        <ListItem
          button
          id='geographic-port-menu-item'
          key='Create Port Order'
          component={NavLink}
          to='/number-porting/geographic-port'>
          <ListItemIcon>
            <LocationOn />
          </ListItemIcon>
          <ListItemText primary='Create Port Order' />
        </ListItem>

        <ListItem
          button
          id='number-port-statuses-menu-item'
          key='Statuses'
          component={NavLink}
          to='/number-porting'>
          <ListItemIcon>
            <Category />
          </ListItemIcon>
          <ListItemText primary='Statuses' />
        </ListItem>
      </Collapse>

      <ListItem button onClick={handleSetupClick}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary='Setup' />
        {setupOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={setupOpen} timeout='auto' unmountOnExit>
        <ListItem button key='Resellers' component={NavLink} to='/resellers'>
          <ListItemIcon>
            <RecentActorsIcon />
          </ListItemIcon>
          <ListItemText primary='Resellers' />
        </ListItem>

        <ListItem button key='Users' component={NavLink} to='/users'>
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary='Users' />
        </ListItem>

        <ListItem button onClick={handleProductsClick}>
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary='Products' />
          {productsOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={productsOpen} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <ListItem
              button
              className={classes.nested}
              component={NavLink}
              to='/products/hardware'>
              <ListItemIcon>
                <PhoneIcon />
              </ListItemIcon>
              <ListItemText primary='Devices' />
            </ListItem>

            <ListItem
              button
              component={NavLink}
              className={classes.nested}
              to='/products/uc-applications'
              onClick={handleAppsClick}>
              <ListItemIcon>
                <PhoneIphoneIcon />
              </ListItemIcon>
              <ListItemText primary='Applications & Integrations' />
              {appsOpen ? (
                <ExpandLess onClick={handleAppsToggle} />
              ) : (
                <ExpandMore onClick={handleAppsToggle} />
              )}
            </ListItem>

            <Collapse in={appsOpen} timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                <ListItem
                  button
                  className={classes.subNested}
                  component={NavLink}
                  to='/products/uc-licences'>
                  <ListItemIcon>
                    <TollIcon />
                  </ListItemIcon>
                  <ListItemText primary='UCP Licences' />
                </ListItem>
              </List>
            </Collapse>

            <ListItem
              button
              className={classes.nested}
              component={NavLink}
              to='/products/connectivity-hardware'>
              <ListItemIcon>
                <RouterIcon />
              </ListItemIcon>
              <ListItemText primary='Connectivity Hardware' />
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              component={NavLink}
              to='/products/call-recording'>
              <ListItemIcon>
                <RecordVoiceIcon />
              </ListItemIcon>
              <ListItemText primary='Call Recording' />
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              component={NavLink}
              to='/products/bundled-minutes'>
              <ListItemIcon>
                <HourglassEmptyIcon />
              </ListItemIcon>
              <ListItemText primary='Bundled Minutes' />
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              component={NavLink}
              to='/products/number-porting'>
              <ListItemIcon>
                <Dialpad />
              </ListItemIcon>
              <ListItemText primary='Number Porting' />
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              component={NavLink}
              to='/products/system'>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary='System' />
            </ListItem>
          </List>
        </Collapse>
      </Collapse>
    </>
  )
}

export default MenuItems
