import { details as fetchDetails, options as fetchOptions } from 'api/quote-builder'
import PropTypes from 'prop-types'
import React, { useEffect, useReducer } from 'react'
import { useCallback } from 'react'

const steps = [
  {
    slug: 'customer-details',
    label: 'Customer Details',
    completed: false,
  },
  {
    slug: 'system-details',
    label: 'System Details',
    completed: false,
  },
  {
    slug: 'handsets',
    label: 'Devices, Apps & Integrations',
    completed: false,
  },
  {
    slug: 'network-engineering',
    label: 'Network & Engineering',
    completed: false,
  },
  {
    name: 'numbers',
    slug: 'numbers',
    label: 'Numbers',
    completed: false,
  },
  {
    name: 'expenditure',
    slug: 'expenditure',
    label: 'Expenditure',
    completed: false,
  },
  {
    name: 'payment',
    slug: 'payment',
    label: 'Payment',
    completed: false,
  },
  {
    name: 'summary',
    slug: 'summary',
    label: 'Summary',
    completed: null,
  },
  {
    name: 'proposal',
    slug: 'proposal',
    label: 'Proposal',
    completed: null,
    hide: true,
  },
]

const initialState = {
  optionsLoaded: false,
  quoteLoaded: false,

  id: '',
  rawData: {},
  manualConfig: false,

  steps: [],
  stepsCompleted: null,

  summary: null,
  options: {},

  customerDetails: {
    deal_name: { value: '', error: '' },
    customer_business_name: { value: '', error: '' },
    customer_first_name: { value: '', error: '' },
    customer_last_name: { value: '', error: '' },
    customer_email: { value: '', error: '' },
    tariff: { value: '', error: '' },
  },

  services: {
    system_type: { value: 'cloud', error: '' },
    system_users: { value: null, error: '' },
    lite_licenses: { value: null, error: '' },
    system_users_type: { value: '', error: '' },
    number_of_sip_trunks: { value: '', error: '' },
    remote_users: { value: '', error: '' },
    uc_license: { value: '' },
    crm_integration: { value: '' },
    bundled_minutes: { value: '' },
  },

  callRecording: [],
  broadbandPackages: [],
  ucApplications: [],
  hardware: {},
  networkHardware: {},
  networkServices: [],

  numbers: {
    porting: {},
    new_numbers: { value: 0, error: '' },
  },

  expenditure: {
    calls: { value: 0, error: '' },
    services: { value: 0, error: '' },
    equipment: { value: 0, error: '' },
    maintenance: { value: 0, error: '' },
  },

  payment: {
    type: 'cash',
    leasing: { value: '', error: '' },
    contract_length: { value: '', error: '' },
    early_settlement: { value: 0, error: '' },
    price_by: { value: 'total_value', error: '' },
    price_per_user: { value: '', error: '' },
    discount_code: { value: '', error: '' },
  },

  settings: {
    default_display: 'External',
    quotable_systems: 'all',
  },
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_OPTIONS_LOADED':
      return { ...state, optionsLoaded: action.payload }
    case 'SET_QUOTE_LOADED':
      return { ...state, quoteLoaded: action.payload }
    case 'SET_ID':
      return { ...state, id: action.payload }
    case 'SET_RAW_DATA':
      return { ...state, rawData: action.payload }
    case 'SET_MANUAL_CONFIG':
      return { ...state, manualConfig: action.payload }
    case 'SET_STEPS':
      return {
        ...state,
        steps: action.payload,
        stepsCompleted: action.payload.every(function (item) {
          return item.completed || item.completed === null
        }),
      }
    case 'SET_SUMMARY':
      return { ...state, summary: action.payload }
    case 'SET_OPTIONS':
      return { ...state, options: action.payload }
    case 'SET_CUSTOMER_DETAILS':
      return { ...state, customerDetails: action.payload }
    case 'SET_SERVICES':
      return { ...state, services: action.payload }
    case 'SET_CALL_RECORDING':
      return { ...state, callRecording: action.payload }
    case 'SET_BROADBAND_PACKAGES':
      return { ...state, broadbandPackages: action.payload }
    case 'SET_UC_APPLICATIONS':
      return { ...state, ucApplications: action.payload }
    case 'SET_HARDWARE':
      return { ...state, hardware: action.payload }
    case 'SET_NETWORK_HARDWARE':
      return { ...state, networkHardware: action.payload }
    case 'SET_NETWORK_SERVICES':
      return { ...state, networkServices: action.payload }
    case 'SET_NUMBERS':
      return { ...state, numbers: action.payload }
    case 'SET_EXPENDITURE':
      return { ...state, expenditure: action.payload }
    case 'SET_PAYMENT':
      return { ...state, payment: action.payload }
    case 'SET_SETTINGS':
      return { ...state, settings: action.payload }
    case 'RESET':
      return initialState
    default:
      throw new Error(`Unhandle reducer action ${action.type}`)
  }
}

const hitLimits = (system_type, system_users, remote_users, number_of_sip_trunks) => {
  return (
    system_type === 'onprem' &&
    (parseInt(system_users) + parseInt(remote_users) > 24 ||
      parseInt(number_of_sip_trunks) + parseInt(remote_users) > 22)
  )
}

const QuoteContext = React.createContext()
const WithQuote = ({ id, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const load = useCallback(() => {
    dispatch({ type: 'SET_ID', payload: id ? id : '' })

    fetchOptions(id).then(res => {
      dispatch({ type: 'SET_OPTIONS', payload: res })
      dispatch({ type: 'SET_OPTIONS_LOADED', payload: true })

      steps[0].label = res.labels.customer_details
      steps[1].label = res.labels.services
      steps[2].label = res.labels.hardware
      steps[3].label = res.labels.network
      steps[4].label = res.labels.numbers
      steps[5].label = res.labels.expenditure
      steps[6].label = res.labels.payment
      dispatch({ type: 'SET_STEPS', payload: steps })
    })

    if (id) {
      fetchDetails(id).then(res => {
        dispatch({
          type: 'SET_RAW_DATA',
          payload: res.data,
        })

        dispatch({
          type: 'SET_MANUAL_CONFIG',
          payload: hitLimits(
            res.data.services.system_type,
            res.data.services.system_users,
            res.data.services.remote_users,
            res.data.services.number_of_sip_trunks
          ),
        })

        dispatch({
          type: 'SET_CUSTOMER_DETAILS',
          payload: {
            deal_name: {
              value: res.data.deal_name,
              error: '',
            },
            customer_business_name: {
              value: res.data.customer_details.business_name,
              error: '',
            },
            customer_first_name: {
              value: res.data.customer_details.first_name,
              error: '',
            },
            customer_last_name: {
              value: res.data.customer_details.last_name,
              error: '',
            },
            customer_email: { value: res.data.customer_details.email, error: '' },
            tariff: { value: res.data.customer_details.tariff, error: '' },
          },
        })

        dispatch({
          type: 'SET_SERVICES',
          payload: {
            system_type: { value: res.data.services.system_type, error: '' },
            system_users: { value: res.data.services.system_users, error: '' },
            lite_licenses: { value: res.data.services.lite_licenses, error: '' },
            system_users_type: {
              value: res.data.services.system_users_type,
              error: '',
            },
            number_of_sip_trunks: {
              value: res.data.services.number_of_sip_trunks,
              error: '',
            },
            remote_users: { value: res.data.services.remote_users, error: '' },
            uc_license: { value: res.data.services.uc_license },
            crm_integration: { value: res.data.services.crm_integration },
            bundled_minutes: { value: res.data.services.bundled_minutes },
          },
        })

        dispatch({
          type: 'SET_CALL_RECORDING',
          payload: res.data.services.call_recording,
        })

        dispatch({
          type: 'SET_BROADBAND_PACKAGES',
          payload: res.data.services.broadband_packages,
        })

        dispatch({
          type: 'SET_UC_APPLICATIONS',
          payload: res.data.services.uc_applications,
        })

        dispatch({
          type: 'SET_HARDWARE',
          payload: res.data.hardware,
        })

        dispatch({
          type: 'SET_NETWORK_HARDWARE',
          payload: res.data.network_hardware,
        })

        dispatch({
          type: 'SET_NETWORK_SERVICES',
          payload: res.data.services.network_services,
        })

        dispatch({
          type: 'SET_NUMBERS',
          payload: {
            new_numbers: { value: res.data.numbers.new_numbers, error: '' },
            porting: res.data.numbers.number_porting,
          },
        })

        dispatch({
          type: 'SET_EXPENDITURE',
          payload: {
            calls: { value: res.data.expenditure.calls / 100, error: '' },
            services: { value: res.data.expenditure.services / 100, error: '' },
            equipment: { value: res.data.expenditure.equipment / 100, error: '' },
            maintenance: { value: res.data.expenditure.maintenance / 100, error: '' },
          },
        })

        dispatch({
          type: 'SET_PAYMENT',
          payload: {
            type: res.data.payment.leasing ? 'lease' : 'cash',
            leasing: { value: res.data.payment.leasing, error: '' },
            contract_length: { value: res.data.contract_length, error: '' },
            early_settlement: {
              value: res.data.payment.early_settlement / 100,
              error: '',
            },
            price_by: {
              value: res.data.payment.price_per_user ? 'user' : 'total_value',
              error: '',
            },
            price_per_user: {
              value: res.data.payment.price_per_user
                ? res.data.payment.price_per_user / 100
                : '',
              error: '',
            },
            discount_code: { value: res.data.payment.discount_code, error: '' },
          },
        })

        dispatch({ type: 'SET_SETTINGS', payload: res.data.settings })

        steps[0].completed = res.data.completed_steps.customer_details
        steps[1].completed = res.data.completed_steps.services
        steps[2].completed = res.data.completed_steps.hardware
        steps[3].completed = res.data.completed_steps.network
        steps[4].completed = res.data.completed_steps.numbers
        steps[5].completed = res.data.completed_steps.expenditure
        steps[6].completed = res.data.completed_steps.payment
        dispatch({ type: 'SET_STEPS', payload: steps })

        dispatch({ type: 'SET_QUOTE_LOADED', payload: true })
      })
    } else {
      dispatch({ type: 'RESET' })
      dispatch({ type: 'SET_STEPS', payload: steps })
    }
  }, [id])

  useEffect(() => {
    load()
  }, [load])

  const hasLoaded = () => {
    if (state.stepsCompleted === null) return false

    if (state.id) {
      return state.optionsLoaded && state.quoteLoaded
    } else {
      return state.optionsLoaded
    }
  }

  const defaultContext = {
    state,
    dispatch,
    hitLimits,
    hasLoaded,
    load,
  }
  return <QuoteContext.Provider value={defaultContext}>{children}</QuoteContext.Provider>
}

WithQuote.propTypes = {
  id: PropTypes.string,
  children: PropTypes.object,
}

WithQuote.defaultProps = {
  id: '',
}

export { QuoteContext }
export default WithQuote
