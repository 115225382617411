import { makeStyles } from '@material-ui/core/styles'
import AdditionalDetails from 'components/number-porting/geographic-port/AdditionalDetails'
import MainNumberDetails from 'components/number-porting/geographic-port/MainNumberDetails'
import PortConfirmation from 'components/number-porting/geographic-port/PortConfirmation'
import React from 'react'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridGap: theme.spacing(3),
    },
  },
}))

function NumberDetails() {
  const classes = useStyles()

  return (
    <React.Fragment>
      <MainNumberDetails />
      <div className={classes.container}>
        <AdditionalDetails />
        <PortConfirmation />
      </div>
    </React.Fragment>
  )
}

export default NumberDetails
