import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles(theme => ({
  itemContainer: {
    height: 'calc(100% - 40px)',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: theme.spacing(3),
  },
}))

function OneColumn(props) {
  const classes = useStyles()

  return <div className={classes.itemContainer}>{props.children}</div>
}

export default OneColumn
