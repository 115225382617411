import config from 'config'

export async function home() {
  const result = await fetch(config.API_URL + '/api/my-library/home')
  return await result.json()
}

export async function search(term) {
  const result = await fetch(config.API_URL + `/api/my-library/search/${term}`)
  return await result.json()
}
