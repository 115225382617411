import { Box, IconButton, Tooltip } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import MuiAlert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import { create, update, deleteCategory } from 'api/product-categories'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import SaveIcon from '@material-ui/icons/Check'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React, { useState } from 'react'
import DownIcon from '@material-ui/icons/ArrowDownward'
import UpIcon from '@material-ui/icons/ArrowUpward'

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
}))

function ProductCategories({ categories, productTypeId, moveUp, moveDown }) {
  const classes = useStyles()
  const [cats, setCats] = useState(categories)
  const [adding, setAdding] = useState(false)

  const handleAdd = async () => {
    setAdding(true)
    const result = await create({
      name: 'New Category',
      product_type_id: productTypeId,
    })
    setCats(result.res.data)
    setAdding(false)
  }

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id='manage-categories-header'>
        <Typography>
          <Button color='primary'>Manage Categories</Button>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box width={1}>
          <Box marginBottom={1}>
            <Button
              aria-label='save'
              variant='contained'
              size='small'
              color='primary'
              startIcon={<AddIcon />}
              disabled={adding}
              onClick={handleAdd}>
              Add Category
            </Button>
          </Box>

          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align='right'></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cats.map(row => (
                  <Row
                    key={row.id}
                    category={row}
                    setCats={setCats}
                    moveUp={moveUp}
                    moveDown={moveDown}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

function Row({ category, setCats, moveUp, moveDown }) {
  const [editing, setEditing] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [deleted, setDeleted] = useState(false)

  const [name, setName] = useState(category.name)
  const [errors, setErrors] = useState([])

  const handleChangeName = event => {
    setName(event.target.value)
  }

  const handleEdit = () => {
    setErrors([])
    setEditing(true)
  }

  const handleSave = async () => {
    setEditing(false)
    setErrors([])

    const result = await update(category.id, { name: name })

    if (!result.success) {
      setErrors(result.errors)
    }
  }

  const handleDelete = async () => {
    setDeleting(true)
    setDeleted(await deleteCategory(category.id))
    setDeleting(false)
  }

  const handleMoveUp = async () => {
    const res = await moveUp(category.id)
    setCats(res.data)
  }

  const handleMoveDown = async () => {
    const res = await moveDown(category.id)
    setCats(res.data)
  }

  if (deleted) return null

  return (
    <TableRow key={category.id}>
      <TableCell scope='row'>
        {editing ? (
          <TextField
            variant='standard'
            margin='none'
            fullWidth
            id='name'
            label='Category Name'
            name='name'
            defaultValue={name}
            onChange={handleChangeName}
            onBlur={handleSave}
          />
        ) : (
          <>{name}</>
        )}

        {errors.length > 0 && (
          <MuiAlert severity='error'>
            {errors.map((error, index) => (
              <div key={index}>{error[1]}</div>
            ))}
          </MuiAlert>
        )}
      </TableCell>
      <TableCell align='right'>
        <Tooltip title='Move Up' placement='top'>
          <IconButton color='default' onClick={handleMoveUp}>
            <UpIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title='Move Down' placement='top'>
          <IconButton color='default' onClick={handleMoveDown}>
            <DownIcon />
          </IconButton>
        </Tooltip>

        {editing ? (
          <Tooltip title='Save' placement='top'>
            <IconButton aria-label='save' color='primary' onClick={handleSave}>
              <SaveIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title='Edit' placement='top'>
            <IconButton
              aria-label='edit'
              color='default'
              disabled={deleting}
              onClick={handleEdit}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title='Delete' placement='top'>
          <IconButton
            style={{ visibility: `${category.deleteable ? 'visible' : 'hidden'}` }}
            aria-label='delete'
            color='secondary'
            disabled={deleting}
            onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default ProductCategories
