import { Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { posts as list } from 'api/training-library/categories'
import EditButton from 'components/library/categories/EditButton'
import Header from 'components/library/categories/Header'
import PostList from 'components/library/categories/PostList'
import ReorderPosts from 'components/library/categories/ReorderPosts'
import { AuthContext } from 'contexts/AuthContext'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import CategoryEdit from './CategoryEdit'
import { sortPosts } from 'api/library/categories'

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: theme.breakpoints.width('lg'),
    margin: 'auto',
  },
}))

function Category(props) {
  const { id } = useParams()
  const classes = useStyles()
  const { user } = useContext(AuthContext)
  const { setLoading } = useContext(LoadingContext)
  const [posts, setPosts] = useState({})
  const [editMode, setEditMode] = useState(false)
  const [reorderMode, setReorderMode] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      setPosts(await list(id, 100))
      setLoading(false)
    }

    fetchData()
  }, [id, setLoading])

  const toggleEditMode = () => {
    setReorderMode(editMode && reorderMode)
    setEditMode(!editMode)
  }

  const toggleReorderMode = () => {
    setEditMode(reorderMode && editMode)
    setReorderMode(!reorderMode)
  }

  const setCategory = values => {
    const newPosts = { ...posts }
    newPosts.meta.category = values
    setPosts(newPosts)
  }

  const handleSort = async values => {
    setLoading(true)
    await sortPosts(id, values, 'training-library')
    setPosts(await list(id, 100))
    setReorderMode(false)
    setLoading(false)
  }

  if (!posts || !posts.data) {
    return null
  }

  return (
    <React.Fragment>
      <Box className={classes.container}>
        {user.type === 'distributor' && (
          <Box mb={2}>
            <EditButton editMode={editMode} toggleEditMode={toggleEditMode} />
            <Button reorderMode={reorderMode} onClick={toggleReorderMode}>
              {reorderMode ? 'Cancel Reordering' : 'Reorder Posts'}
            </Button>
          </Box>
        )}
        {editMode && !reorderMode && (
          <CategoryEdit
            category={posts.meta.category}
            setCategory={setCategory}
            toggleEditMode={toggleEditMode}
            history={props.history}
          />
        )}
        {reorderMode && !editMode && (
          <ReorderPosts
            categoryId={id}
            posts={posts}
            onChange={handleSort}
            onCancel={toggleReorderMode}
          />
        )}
        {!reorderMode && !editMode && (
          <React.Fragment>
            <Header posts={posts} linkPrefix='training-library' />
            <PostList
              id={id}
              posts={posts}
              setPosts={setPosts}
              linkPrefix='training-library'
            />
          </React.Fragment>
        )}
      </Box>
    </React.Fragment>
  )
}

export default Category
