import DateFnsUtils from '@date-io/date-fns'
import red from '@material-ui/core/colors/red'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import MuiAlert from '@material-ui/lab/Alert'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import ScrollToTop from 'components/ScrollToTop'
import config from 'config'
import NotificationContext from 'contexts/NotificationContext'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'
import App from './App'
import AuthContext from './contexts/AuthContext'
import FeatureTogglesContext from './contexts/FeatureToggleContext'
import HelpContext from './contexts/HelpContext'
import LoadingContext from './contexts/LoadingContext'
import MainNavContext from './contexts/MainNavContext'
import ViewContext from './contexts/ViewContext'
import './i18n'
import './index.css'
import * as serviceWorker from './serviceWorker'

if (config.SENTRY_DSN) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
  })
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#129eda',
      contrastText: '#fff',
    },
    secondary: red,
    lightBlue: '#EEEEF7',
    backgroundMain: '#000000',
    muted: '#b3b3b3',
  },
  overrides: {
    MuiInputLabel: {
      root: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '80%',
        height: '100%',
      },
      shrink: {
        maxWidth: '100%',
      },
    },
  },
})

const muiAlertToast = ({ appearance, children }) => (
  <MuiAlert
    style={{ marginBottom: '12px' }}
    elevation={6}
    variant='filled'
    severity={appearance}>
    {children}
  </MuiAlert>
)

ReactDOM.render(
  <Router>
    <ThemeProvider theme={theme}>
      <LoadingContext>
        <AuthContext>
          <Sentry.ErrorBoundary fallback={'An error has occurred'}>
            <MainNavContext>
              <FeatureTogglesContext>
                <ToastProvider
                  components={{ Toast: muiAlertToast }}
                  autoDismiss={true}
                  placement='bottom-center'>
                  <HelpContext>
                    <ViewContext>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <NotificationContext>
                          <App />
                        </NotificationContext>
                      </MuiPickersUtilsProvider>
                    </ViewContext>
                  </HelpContext>
                </ToastProvider>
              </FeatureTogglesContext>
            </MainNavContext>
          </Sentry.ErrorBoundary>
        </AuthContext>
      </LoadingContext>
    </ThemeProvider>
    <ScrollToTop />
  </Router>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
