import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import MuiAlert from '@material-ui/lab/Alert'
import { create, get, update } from 'api/resellers'
import { list as listUsers } from 'api/users'
import { FeatureToggleContext } from 'contexts/FeatureToggleContext'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  image: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
  },
  selectedImage: {
    marginTop: theme.spacing(2),
    fontSize: '0.75rem',
    textAlign: 'center',
  },
  productImage: {
    marginTop: theme.spacing(6),
  },
  formControl: {
    minWidth: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  permissions: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
}))

function Form(props) {
  const { id } = useParams()
  const { loading, setLoading } = useContext(LoadingContext)
  const [hideContent, setHideContent] = useState(true)
  const { hasFeature } = useContext(FeatureToggleContext)
  const [accountManagers, setAccountManagers] = useState([])
  const [values, setValues] = useState({
    type: { value: '', error: '' },
    name: { value: '', error: '' },
    account_number: { value: '', error: '' },
    domains: { value: [] },
    porting_notification_emails: { value: [] },
    user_id: { value: '', error: '' },
    rrp_discount_percent: { value: 0, error: '' },
    image: { value: '', error: '', fileName: '' },
    quote_permission: { value: false },
    library_permission: { value: false },
    price_list_permission: { value: false },
    number_porting_permission: { value: false },
  })
  const [imageBase64, setImageBase64] = useState()
  const [imageUrl, setImageUrl] = useState()
  const [errors, setErrors] = useState([])
  const classes = useStyles()

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      if (id !== undefined) {
        const reseller = await get(id)
        const dataValues = {
          type: { value: reseller.data.type },
          name: { value: reseller.data.name },
          account_number: { value: reseller.data.account_number },
          domains: { value: reseller.data.domains },
          porting_notification_emails: {
            value: reseller.data.porting_notification_emails,
          },
          user_id: { value: reseller.data.user.id },
          rrp_discount_percent: { value: reseller.data.rrp_discount_percent },
          image: { value: '', error: '', fileName: '' },
          quote_permission: {
            value: reseller.data.permissions.indexOf('quote') !== -1,
          },
          library_permission: {
            value: reseller.data.permissions.indexOf('library') !== -1,
          },
          price_list_permission: {
            value: reseller.data.permissions.indexOf('price_list') !== -1,
          },
          number_porting_permission: {
            value: reseller.data.permissions.indexOf('number_porting') !== -1,
          },
        }
        setImageUrl(reseller.data.image_url)
        setValues(dataValues)
      }

      const users = await listUsers(undefined, 10000)
      setAccountManagers(users.data)
      setLoading(false)
      setHideContent(false)
    }

    fetchData()
  }, [id, setValues, setLoading])

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: { value: value } })
  }

  const handleChangeSwitch = event => {
    setValues({ ...values, [event.target.name]: { value: event.target.checked } })
  }

  const handleChangeDomains = value => {
    handleChange({ target: { name: 'domains', value: value } })
  }

  const handleBlurDomains = value => {
    if (value) {
      const newDomains = Array.from(values.domains)
      newDomains.push(value)
      handleChange({ target: { name: 'domains', value: newDomains } })
    }
  }

  const handleChangePortingNoticiationEmails = value => {
    handleChange({ target: { name: 'porting_notification_emails', value: value } })
  }

  const handleBlurPortingNotificationEmails = value => {
    if (value) {
      const newValues = Array.from(values.porting_notification_emails)
      newValues.push(value)
      handleChange({ target: { name: 'porting_notification_emails', value: newValues } })
    }
  }

  const handleImageChange = event => {
    setLoading(true)
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    const key = 'image'
    setValues({ ...values, [key]: { value: file, fileName: file.name } })
    reader.onloadend = () => {
      setImageBase64(reader.result)
      setLoading(false)
    }
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setLoading(true)

    const body = {
      type: values.type.value,
      account_number: values.account_number.value,
      domains: values.domains.value,
      porting_notification_emails: values.porting_notification_emails.value,
      name: values.name.value,
      user_id: values.user_id.value,
      rrp_discount_percent: values.rrp_discount_percent.value,
      quote_permission: values.quote_permission.value,
      library_permission: values.library_permission.value,
      price_list_permission: values.price_list_permission.value,
      number_porting_permission: values.number_porting_permission.value,
    }

    if (imageBase64) {
      body.image = imageBase64
    }

    const result = id ? await update(id, body) : await create(body)

    if (result.success) {
      setLoading(false)
      props.history.push({
        pathname: '/resellers',
        state: {
          severity: 'success',
          message: result.message,
        },
      })
    } else {
      setErrors(result.errors)
      setLoading(false)
    }
  }

  if (hideContent) {
    return null
  }

  return (
    <div>
      <Typography variant='h3' gutterBottom>
        {id ? 'Update Reseller' : 'Create Reseller'}
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item sm={12} md={7} xl={5}>
            <Typography variant='h5' gutterBottom>
              Main Info
            </Typography>
            <Paper className={classes.paper}>
              <TextField
                variant='standard'
                margin='normal'
                required
                fullWidth
                id='name'
                label='Name'
                name='name'
                defaultValue={values.name.value}
                autoFocus
                onChange={handleChange.bind(this)}
              />

              <TextField
                variant='standard'
                margin='normal'
                fullWidth
                id='account_number'
                label='Account Number'
                name='account_number'
                defaultValue={values.account_number.value}
                onChange={handleChange.bind(this)}
              />

              <Autocomplete
                multiple
                freeSolo
                id='domains'
                options={[]}
                value={values.domains.value}
                onChange={(_, newValue) => {
                  handleChangeDomains(newValue)
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant='standard'
                    onBlur={event => {
                      handleBlurDomains(event.target.value)
                    }}
                    label='Domains'
                    placeholder='For example, wearepragma.co.uk'
                    helperText='Users who sign-up using these domains will automatically be linked with this reseller'
                  />
                )}
              />

              <FormControl variant='standard' className={classes.formControl}>
                <InputLabel id='type_label'>Type</InputLabel>
                <Select
                  labelId='type_label'
                  id='type'
                  name='type'
                  value={values.type.value ?? ''}
                  onChange={handleChange.bind(this)}
                  label='Type'>
                  <MenuItem value='wholesale'>Wholesale</MenuItem>
                  <MenuItem value='VSP'>VSP</MenuItem>
                  <MenuItem value='SP'>SP</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant='standard' className={classes.formControl}>
                <InputLabel id='user_id_label'>Account Manager</InputLabel>
                <Select
                  labelId='user_id_label'
                  id='user_id'
                  name='user_id'
                  value={values.user_id.value ?? ''}
                  onChange={handleChange.bind(this)}
                  label='Account Manager'>
                  {accountManagers.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.first_name} {item.last_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                variant='standard'
                margin='normal'
                required
                fullWidth
                type='number'
                step='0.01'
                InputLabelProps={{
                  shrink: true,
                }}
                id='rrp_discount_percent'
                label='RRP Discount Percent'
                name='rrp_discount_percent'
                defaultValue={values.rrp_discount_percent.value}
                onChange={handleChange.bind(this)}
              />

              <Autocomplete
                multiple
                freeSolo
                id='porting_notification_emails'
                options={[]}
                value={values.porting_notification_emails.value}
                onChange={(_, newValue) => {
                  handleChangePortingNoticiationEmails(newValue)
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant='standard'
                    onBlur={event => {
                      handleBlurPortingNotificationEmails(event.target.value)
                    }}
                    label='Porting Notification Emails'
                    placeholder='For example, ports@example.com'
                    helperText="These emails will be CC'd into port update emails"
                  />
                )}
              />

              {hasFeature('reseller_logos') && (
                <div className={classes.image}>
                  <p>
                    <em>Logo images should be 200px x 40px</em>
                  </p>
                  <input
                    accept='image/*'
                    style={{ display: 'none' }}
                    id='image'
                    name='image'
                    multiple
                    type='file'
                    onChange={handleImageChange.bind(this)}
                  />
                  <label htmlFor='image'>
                    <Button variant='contained' component='span'>
                      {imageUrl ? 'Select New Logo' : 'Select Logo'}
                    </Button>
                  </label>
                  {values.image.fileName && (
                    <div className={classes.selectedImage}>
                      Selected file: {values.image.fileName}
                    </div>
                  )}
                </div>
              )}

              {hasFeature('reseller_logos') && imageUrl && (
                <div>
                  <div className={classes.productImage}>
                    <img alt='Reseller Logo' src={imageUrl} />
                  </div>
                </div>
              )}
            </Paper>

            {errors.length > 0 && (
              <MuiAlert severity='error'>
                {errors.map((error, index) => {
                  return <div key={index}>{error[1]}</div>
                })}
              </MuiAlert>
            )}
            <Button
              type='submit'
              variant='contained'
              color='primary'
              disabled={loading}
              className={classes.submit}>
              {id ? 'Update' : 'Create'}
            </Button>
          </Grid>

          <Grid item md={5} xl={5}>
            <Typography variant='h5' gutterBottom>
              Features & Permission
            </Typography>

            <div className={classes.permissions}>
              <Card className={classes.cardRoot}>
                <CardContent>
                  <Typography variant='h5' component='h2'>
                    Quoting
                  </Typography>

                  <Typography variant='body2' component='p'>
                    Allows resellers to create and save quotes. This also includes access
                    quote related features such as...
                    <ul>
                      <li>View the Sales Dashboard with quote analytics.</li>
                      <li>View, create, override products.</li>
                      <li>Create leasing, tariff, discount codes for use with quotes.</li>
                      <li>Proposal documents and covering letter creation.</li>
                    </ul>
                  </Typography>
                </CardContent>
                <CardActions>
                  <Switch
                    checked={values.quote_permission.value}
                    onChange={handleChangeSwitch}
                    name='quote_permission'
                    color='primary'
                  />
                </CardActions>
              </Card>

              <Card className={classes.cardRoot}>
                <CardContent>
                  <Typography variant='h5' component='h2'>
                    Library
                  </Typography>

                  <Typography variant='body2' component='p'>
                    Gives access to the main Library and Videos section. Also enables
                    MyLibrary where they can create and manage their own library content.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Switch
                    checked={values.library_permission.value}
                    onChange={handleChangeSwitch}
                    name='library_permission'
                    color='primary'
                  />
                </CardActions>
              </Card>

              <Card className={classes.cardRoot}>
                <CardContent>
                  <Typography variant='h5' component='h2'>
                    Price List
                  </Typography>

                  <Typography variant='body2' component='p'>
                    Gives access to the Price List section on the left nav menu.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Switch
                    checked={values.price_list_permission.value}
                    onChange={handleChangeSwitch}
                    name='price_list_permission'
                    color='primary'
                  />
                </CardActions>
              </Card>

              <Card className={classes.cardRoot}>
                <CardContent>
                  <Typography variant='h5' component='h2'>
                    Number Porting
                  </Typography>

                  <Typography variant='body2' component='p'>
                    Gives access to number porting section where the reseller can create
                    and manage their own porting requests.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Switch
                    checked={values.number_porting_permission.value}
                    onChange={handleChangeSwitch}
                    name='number_porting_permission'
                    color='primary'
                  />
                </CardActions>
              </Card>
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

export default Form
