import config from 'config'

export async function links() {
  try {
    const result = await fetch(config.API_URL + `/api/sales-hub/links`)
    return await result.json()
  } catch (e) {
    return false
  }
}
