import { Popover } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'inline-block',
  },
  wrapper: {
    padding: theme.spacing(2),
    verticalAlign: 'middle',
    textAlign: 'center',
    display: 'table-cell',
  },
}))

function HardwareDescriptionPopover({ description, anchorEl, setAnchorEl }) {
  const classes = useStyles()

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  return (
    <Popover
      id='hardware-description-popover'
      open={anchorEl ? true : false}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
      transformOrigin={{ vertical: 'center', horizontal: 'center' }}
      onClose={handlePopoverClose}>
      <div className={classes.container}>
        <div className={classes.wrapper}>{description}</div>
      </div>
    </Popover>
  )
}

export default HardwareDescriptionPopover
