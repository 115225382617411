import { list, markRead } from 'api/notifications'
import React, { useEffect, useState, useContext } from 'react'
import { useCallback } from 'react'
import { AuthContext } from 'contexts/AuthContext'

const NotificationContext = React.createContext()

const WithNotification = ({ children }) => {
  const [notifications, setNotifications] = useState([])
  const [unreadCount, setUnreadCount] = useState(0)
  const { authenticated } = useContext(AuthContext)

  const fetchNotifications = useCallback(async () => {
    const res = await list(10, 1, { unreadOnly: ['1'] })
    setUnreadCount(res.meta ? res.meta.unread : 0)
    setNotifications(res.data ?? [])
  }, [setNotifications, setUnreadCount])

  const markNotificationRead = useCallback(
    async id => {
      const res = await markRead(id)
      await fetchNotifications()
      return res.data
    },
    [fetchNotifications]
  )

  useEffect(() => {
    if (authenticated) {
      fetchNotifications()
      let timer
      if (timer === undefined) {
        timer = setInterval(fetchNotifications, 1000 * 300)
      }
      return () => {
        clearInterval(timer)
      }
    }
  }, [fetchNotifications, authenticated])

  const defaultContext = {
    notifications,
    fetchNotifications,
    markNotificationRead,
    unreadCount,
  }

  return (
    <NotificationContext.Provider value={defaultContext}>
      {children}
    </NotificationContext.Provider>
  )
}

export { NotificationContext }
export default WithNotification
