import { Box } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Paper from '@material-ui/core/Paper'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { AuthContext } from 'contexts/AuthContext'
import { GeographicPortContext } from 'contexts/GeographicPortContext'
import React, { useContext, useEffect, useRef, useState } from 'react'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridGap: theme.spacing(3),
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(4),
  },
}))

function ContactDetails() {
  const classes = useStyles()
  const { user } = useContext(AuthContext)
  const { state, dispatch } = useContext(GeographicPortContext)
  const [profileContactDetails, setProfileContactDetails] = useState('yes')
  const contactRef = useRef(state.contact)

  useEffect(() => {
    if (!contactRef.current.name.value.length) {
      setProfileContactDetails('yes')
      dispatch({
        type: 'SET_CONTACT_DETAILS',
        payload: { key: 'name', value: `${user.first_name} ${user.last_name}` },
      })

      dispatch({
        type: 'SET_CONTACT_DETAILS',
        payload: { key: 'email', value: user.email },
      })

      dispatch({
        type: 'SET_CONTACT_DETAILS',
        payload: { key: 'phone_number', value: user.phone_number },
      })

      contactRef.current = {
        name: { value: `${user.first_name} ${user.last_name}` },
        email: { value: user.email },
        phone_number: { value: user.phone_number },
      }
    } else {
      const userName = `${user.first_name} ${user.last_name}`
      const userEmail = user.email

      setProfileContactDetails(
        userName === contactRef.current.name.value &&
          userEmail === contactRef.current.email.value
          ? 'yes'
          : 'no'
      )
    }
  }, [dispatch, user])

  const handleChangeProfileContactDetails = event => {
    const value = event.target.value
    const name = value === 'yes' ? `${user.first_name} ${user.last_name}` : ''
    const email = value === 'yes' ? user.email : ''
    const phoneNumber = value === 'yes' ? user.phone_number : ''

    contactRef.current = {
      name: { value: `${user.first_name} ${user.last_name}` },
      email: { value: user.email },
      phone_number: { value: user.phone_number },
    }

    dispatch({
      type: 'SET_CONTACT_DETAILS',
      payload: { key: 'name', value: name },
    })

    dispatch({
      type: 'SET_CONTACT_DETAILS',
      payload: { key: 'email', value: email },
    })

    dispatch({
      type: 'SET_CONTACT_DETAILS',
      payload: { key: 'phone_number', value: phoneNumber },
    })

    setProfileContactDetails(value)
  }

  const handleChangeContactDetails = event => {
    const { name, value } = event.target

    dispatch({
      type: 'SET_CONTACT_DETAILS',
      payload: { key: name, value: value },
    })
  }

  const handleChangeContactDetailsNumber = event => {
    const { name, value } = event.target
    const sanitizedValue = value.replace(/\D+/g, '')

    dispatch({
      type: 'SET_CONTACT_DETAILS',
      payload: { key: name, value: sanitizedValue },
    })
  }

  const contactFieldIsDisabled = () => {
    return profileContactDetails === 'yes'
  }

  return (
    <div className={classes.container}>
      <Paper className={classes.paper}>
        <Typography variant='h5' gutterBottom>
          Contact Details
        </Typography>

        <Typography variant='body1'>
          Are the contact details for this port the same as your login profile details?
        </Typography>

        <Box mt={3}>
          <FormControl component='fieldset'>
            <RadioGroup
              row
              name='profile-contact-details'
              value={profileContactDetails}
              onChange={handleChangeProfileContactDetails}>
              <FormControlLabel
                value='yes'
                control={<Radio color='primary' />}
                label='Yes'
              />
              <FormControlLabel
                value='no'
                control={<Radio color='primary' />}
                label='No'
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </Paper>

      <Paper className={classes.paper}>
        <TextField
          variant='standard'
          margin='normal'
          fullWidth
          required
          id='name'
          label='Name'
          name='name'
          disabled={
            contactFieldIsDisabled() && contactRef.current.name.value ? true : false
          }
          value={state.contact.name.value}
          onChange={handleChangeContactDetails}
          error={state.errors.hasOwnProperty('name') ? true : false}
          helperText={state.errors.hasOwnProperty('name') ? state.errors.name : ''}
        />

        <TextField
          variant='standard'
          margin='normal'
          fullWidth
          required
          id='email'
          label='Email'
          name='email'
          disabled={
            contactFieldIsDisabled() && contactRef.current.email.value ? true : false
          }
          value={state.contact.email.value}
          onChange={handleChangeContactDetails}
          error={state.errors.hasOwnProperty('email') ? true : false}
          helperText={state.errors.hasOwnProperty('email') ? state.errors.email : ''}
        />

        <TextField
          variant='standard'
          margin='normal'
          fullWidth
          required
          id='phone_number'
          label='Phone Number'
          name='phone_number'
          disabled={
            contactFieldIsDisabled() && contactRef.current.phone_number.value
              ? true
              : false
          }
          value={state.contact.phone_number.value}
          onChange={handleChangeContactDetailsNumber}
          error={state.errors.hasOwnProperty('phone_number') ? true : false}
          helperText={
            state.errors.hasOwnProperty('phone_number') ? state.errors.phone_number : ''
          }
        />
      </Paper>
    </div>
  )
}

export default ContactDetails
