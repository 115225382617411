import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { create, list } from 'api/video-library/categories'
import CategoryForm from 'components/library/categories/CategoryForm'
import CreateButton from 'components/library/categories/CreateButton'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: theme.breakpoints.width('lg'),
    margin: 'auto',
  },
}))

function CategoryCreate(props) {
  const classes = useStyles()
  const { loading, setLoading } = useContext(LoadingContext)
  const { addToast } = useToasts()
  const [values, setValues] = useState({
    name: '',
    description: '',
    featured: 0,
    parent_category_id: '',
  })
  const [errors, setErrors] = useState([])
  const [categories, setCategories] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      setCategories(await list(1000))
      setLoading(false)
    }

    fetchData()
  }, [setLoading])

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: value })
  }

  const handleSave = async () => {
    setLoading(true)
    const res = await create({
      name: values.name,
      description: values.description,
      featured: values.featured,
      parent_category_id: values.parent_category ? values.parent_category.id : null,
    })

    setLoading(false)

    if (res.success) {
      addToast('Created Successfully', { appearance: 'success' })
      props.history.push('/video-library')
    } else {
      setErrors(res.errors)
    }
  }

  if (!categories.data) {
    return null
  }

  return (
    <Box className={classes.container}>
      <Typography variant='h3' gutterBottom>
        Create Category
      </Typography>

      <CategoryForm
        values={values}
        errors={errors}
        handleChange={handleChange}
        categories={categories}
      />

      <CreateButton loading={loading} handleSave={handleSave} />
    </Box>
  )
}

export default CategoryCreate
