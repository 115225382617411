import { Box } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import MuiAlert from '@material-ui/lab/Alert'
import { login, me } from 'api/auth'
import { AuthContext } from 'contexts/AuthContext'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

function Login(props) {
  const { setAuthenticated, setUser } = useContext(AuthContext)
  const { loading, setLoading } = useContext(LoadingContext)
  const [message, setMessage] = useState('')
  const [messageSeverity, setMessageSeverity] = useState('info')
  const classes = useStyles()

  useEffect(() => {
    if (props.location.state) {
      setMessageSeverity(props.location.state.severity)
      setMessage(props.location.state.message)
      props.history.replace()
    }

    setLoading(false)
  }, [setLoading, props.location, props.history])

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: value })
  }

  const handleSubmit = async event => {
    event.preventDefault()

    setLoading(true)
    if (validateForm()) {
      const result = await login(values.email, values.password)
      if (result.success) {
        setUser(await me())
        setAuthenticated(true)
      } else {
        setMessage(result.message)
        setMessageSeverity('error')
      }
    } else {
      setMessage('Validation error')
      setMessageSeverity('error')
    }
    setLoading(false)
  }

  const validateForm = () => {
    return values.email.length > 0 && values.password.length > 0
  }

  const [values, setValues] = useState({
    email: '',
    password: '',
    remember: 1,
  })

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        <img
          srcSet='/Logo100.png 207w,
                    /Logo50.png 103w'
          sizes='(max-width: 600px) 211px,
                    422px'
          src='/Logo100.png'
          alt='Logo'
        />
        <Box marginTop={4}>
          <Typography component='h1' variant='h5'>
            Login
          </Typography>
        </Box>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <TextField
            variant='standard'
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email Address'
            disabled={loading}
            name='email'
            autoComplete='email'
            autoFocus
            onChange={handleChange.bind(this)}
          />
          <TextField
            variant='standard'
            margin='normal'
            required
            fullWidth
            disabled={loading}
            name='password'
            label='Password'
            type='password'
            id='password'
            autoComplete='current-password'
            onChange={handleChange.bind(this)}
          />
          {message && <MuiAlert severity={messageSeverity}>{message}</MuiAlert>}
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            disabled={loading}
            className={classes.submit}>
            Sign In
          </Button>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Link component={RouterLink} to='/password/reset' variant='body2'>
                Forgot password?
              </Link>
            </Grid>
            <Grid item xs={12}>
              Need an Account?{' '}
              <Link component={RouterLink} to='/signup' variant='body2'>
                Sign-up now
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  )
}

export default Login
