import config from 'config'
import { getJsonHeaders } from './helpers'

export async function me() {
  try {
    const result = await fetch(config.API_URL + '/api/me', {
      headers: await getJsonHeaders(),
    })

    if (result.status !== 200) {
      return false
    }

    return await result.json()
  } catch (e) {
    return false
  }
}

export async function update(body) {
  try {
    const res = await fetch(config.API_URL + `/api/me`, {
      method: 'PUT',
      headers: await getJsonHeaders(),
      body: JSON.stringify(body),
    })

    const decoded = await res.json()

    if (res.status === 200) {
      return {
        success: true,
        message: 'Successfully updated profile',
        data: decoded,
      }
    }
    return {
      success: false,
      message: decoded.message,
      errors: Object.entries(decoded.errors).map(error => {
        return error
      }),
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to update profile',
      errors: [['', 'Failed to update profile']],
    }
  }
}

export async function login(email, password) {
  try {
    const res = await fetch(config.API_URL + '/api/login', {
      method: 'POST',
      headers: await getJsonHeaders(),
      body: JSON.stringify({
        email: email,
        password: password,
        remember: '1',
      }),
    })

    if (res.status === 204) {
      return {
        success: true,
      }
    }

    const decoded = await res.json()
    return {
      success: false,
      message: decoded.message,
    }
  } catch (e) {
    return {
      success: false,
      message: 'Login failed',
    }
  }
}

export async function logout() {
  try {
    const res = await fetch(config.API_URL + '/api/logout', {
      method: 'POST',
      headers: await getJsonHeaders(),
    })

    if (res.status === 204 || res.status === 302) {
      return true
    }

    return false
  } catch (e) {
    return false
  }
}

export async function resetPasswordEmail(email) {
  try {
    const res = await fetch(config.API_URL + '/api/password/email', {
      method: 'POST',
      headers: await getJsonHeaders(),
      body: JSON.stringify({
        email: email,
      }),
    })

    if (res.status === 200) {
      return {
        success: true,
      }
    }

    const decoded = await res.json()
    return {
      success: false,
      message: decoded.message,
      errors: Object.entries(decoded.errors).map(error => {
        return error
      }),
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to send reset password email',
      errors: [['', 'Failed to send reset password email']],
    }
  }
}

export async function resetPassword(token, email, password) {
  try {
    const res = await fetch(config.API_URL + '/api/password/reset', {
      method: 'POST',
      headers: await getJsonHeaders(),
      body: JSON.stringify({
        token: token,
        email: email,
        password: password,
        password_confirmation: password,
      }),
    })

    if (res.status === 200) {
      return {
        success: true,
      }
    }

    const decoded = await res.json()
    return {
      success: false,
      message: decoded.message,
      errors: Object.entries(decoded.errors).map(error => {
        return error
      }),
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to send reset password email',
      errors: [['', 'Failed to send reset password email']],
    }
  }
}

export async function signup(body) {
  const res = await fetch(config.API_URL + '/api/signup', {
    method: 'POST',
    headers: await getJsonHeaders(),
    body: JSON.stringify(body),
  })

  return {
    success: res.status === 200,
    body: await res.json(),
  }
}

export async function confirmTerms() {
  try {
    const res = await fetch(config.API_URL + '/api/terms', {
      method: 'POST',
      headers: await getJsonHeaders(),
      body: JSON.stringify({}),
    })

    return res.status === 200
  } catch (e) {
    return false
  }
}

export async function dismissGettingStarted(id) {
  try {
    const res = await fetch(config.API_URL + `/api/getting-started/dismiss/${id}`, {
      method: 'POST',
      headers: await getJsonHeaders(),
      body: JSON.stringify({}),
    })

    if (res.status === 200) {
      return {
        success: true,
      }
    }

    const decoded = await res.json()
    return {
      success: false,
      message: decoded.message,
      errors: Object.entries(decoded.errors).map(error => {
        return error
      }),
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to send reset password email',
      errors: [['', 'Failed to send reset password email']],
    }
  }
}
