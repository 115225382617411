import config from 'config'
import { getJsonHeaders } from './helpers'

export async function create(body) {
  try {
    const res = await fetch(config.API_URL + `/api/product-categories`, {
      method: 'POST',
      headers: await getJsonHeaders(),
      body: JSON.stringify(body),
    })

    const decoded = await res.json()

    if (res.status === 200) {
      return {
        success: true,
        message: 'Successfully created category',
        res: decoded,
      }
    }

    return {
      success: false,
      message: decoded.message,
      errors: Object.entries(decoded.errors).map(error => {
        return error
      }),
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to create category',
      errors: [['', 'Failed to create category']],
    }
  }
}

export async function update(id, body) {
  try {
    const res = await fetch(config.API_URL + `/api/product-categories/${id}`, {
      method: 'PUT',
      headers: await getJsonHeaders(),
      body: JSON.stringify(body),
    })

    if (res.status === 200) {
      return {
        success: true,
        message: 'Successfully updated category',
      }
    }

    const decoded = await res.json()
    return {
      success: false,
      message: decoded.message,
      errors: Object.entries(decoded.errors).map(error => {
        return error
      }),
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to update category',
      errors: [['', 'Failed to update category']],
    }
  }
}

export async function deleteCategory(id) {
  try {
    const res = await fetch(config.API_URL + `/api/product-categories/${id}`, {
      method: 'DELETE',
      headers: await getJsonHeaders(),
      body: JSON.stringify({}),
    })

    if (res.status === 204) {
      return true
    }

    return false
  } catch (e) {
    return false
  }
}
