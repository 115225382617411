import { Box, FormHelperText, Paper, Typography } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import MuiAlert from '@material-ui/lab/Alert'
import { availablePortDates } from 'api/number-porting'
import { GeographicPortContext } from 'contexts/GeographicPortContext'
import React, { useContext, useEffect, useState } from 'react'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(4),
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  portDateTypeLabel: {
    marginBottom: theme.spacing(2),
  },
}))

function PortDates() {
  const classes = useStyles()
  const { state, dispatch } = useContext(GeographicPortContext)
  const [portDateType, setPortDateType] = useState('earliest')
  const [loadingPortDates, setLoadingPortDates] = useState(false)
  const [portDates, setPortDates] = useState()

  useEffect(() => {
    dispatch({ type: 'SET_PORT_DATE', payload: '' })
    dispatch({ type: 'SET_PORT_DATE_TYPE', payload: 'STANDARD' })
    dispatch({ type: 'SET_PORT_DATE_TIME', payload: '' })
  }, [dispatch])

  const handleChangePortDateType = async event => {
    const value = event.target.value
    setPortDateType(value)

    if (value === 'select_own') {
      setLoadingPortDates(true)
      const number =
        state.number_details.std_code.value +
        state.number_details.main_number.local_number.value
      const res = await availablePortDates(
        number,
        state.lcp.value,
        state.number_details.line_type.value
      )
      setPortDates(res.data.dates)
      setLoadingPortDates(false)
    } else {
      dispatch({ type: 'SET_PORT_DATE', payload: '' })
      dispatch({ type: 'SET_PORT_DATE_TYPE', payload: 'STANDARD' })
      dispatch({ type: 'SET_PORT_DATE_TIME', payload: '' })
    }
  }

  const handleChange = event => {
    dispatch({ type: 'SET_PORT_DATE', payload: event.target.value })
  }

  const handleChangeType = event => {
    dispatch({ type: 'SET_PORT_DATE_TYPE', payload: event.target.value })
  }

  const handleChangeTime = event => {
    dispatch({ type: 'SET_PORT_DATE_TIME', payload: event.target.value })
  }

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5' gutterBottom>
        Port Date
      </Typography>

      <Box mb={4}>
        <FormControl component='fieldset'>
          <FormLabel component='legend' className={classes.portDateTypeLabel}>
            When would you like to port your numbers?
          </FormLabel>
          <RadioGroup value={portDateType} onChange={handleChangePortDateType}>
            <FormControlLabel
              value='earliest'
              control={<Radio color='primary' />}
              label='Use the earliest possible port date'
              labelPlacement='end'
            />
            <FormControlLabel
              value='select_own'
              control={<Radio color='primary' />}
              label='Select my own port date'
              labelPlacement='end'
            />
          </RadioGroup>
        </FormControl>
      </Box>

      {portDateType === 'select_own' && (
        <>
          {loadingPortDates ? (
            <Box
              width={300}
              mt={3}
              display='flex'
              flexDirection='column'
              alignItems='center'>
              <Box mb={3}>
                <CircularProgress />
              </Box>
              <Typography variant='caption'>
                Requesting available port dates from provider...
              </Typography>
              <Typography variant='caption'>This may take a minute or two...</Typography>
            </Box>
          ) : (
            <>
              <Typography variant='body1' gutterBottom>
                Please select the earliest possible port date from the options below.
              </Typography>

              <Grid container>
                <Grid item xs={12} lg={6} xl={3}>
                  <FormControl variant='standard' className={classes.formControl}>
                    <InputLabel id='port_date_label'>Earliest Port Date</InputLabel>
                    <Select
                      labelId='port_date_label'
                      id='port_date'
                      name='port_date'
                      value={state.port_date.value}
                      onChange={handleChange}
                      label='Earliest Port Date'>
                      {portDates.map(x => (
                        <MenuItem key={x.key} value={x.key}>
                          {x.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl variant='standard' className={classes.formControl}>
                    <InputLabel id='port_date_type_label'>Type</InputLabel>
                    <Select
                      labelId='port_date_type_label'
                      id='port_date_type'
                      name='port_date_type'
                      value={state.port_date_type.value}
                      onChange={handleChangeType}
                      label='Type'>
                      <MenuItem value='STANDARD'>Standard</MenuItem>
                      <MenuItem value='FIXED_TIME'>Fixed Time</MenuItem>
                    </Select>
                  </FormControl>

                  {state.port_date_type.value === 'FIXED_TIME' && (
                    <FormControl variant='standard' className={classes.formControl}>
                      <InputLabel id='port_date_time_label'>Time</InputLabel>
                      <Select
                        labelId='port_date_time_label'
                        id='port_date_time'
                        name='port_date_time'
                        value={state.port_date_time.value}
                        onChange={handleChangeTime}
                        label='Time'>
                        {state.number_details.line_type.value === 'SINGLE' && (
                          <MenuItem value='SLOT_08'>8am</MenuItem>
                        )}
                        <MenuItem value='SLOT_09'>9am</MenuItem>
                        <MenuItem value='SLOT_10'>10am</MenuItem>
                        <MenuItem value='SLOT_11'>11am</MenuItem>
                        <MenuItem value='SLOT_12'>12pm</MenuItem>
                      </Select>
                      <FormHelperText>
                        <MuiAlert severity='info'>
                          This is the beginning of a 4 hour window and the port may not
                          complete until the end of the 4 hours.
                        </MuiAlert>
                      </FormHelperText>
                    </FormControl>
                  )}
                </Grid>
              </Grid>

              <Box mt={5}>
                <MuiAlert severity='info'>
                  <Typography variant='body1' gutterBottom>
                    This is the earliest date the port can happen against industry lead
                    times (unless you have requested another date). Your order will be
                    validated and if accepted submitted to the current Communications
                    Provider / Range Holder of the number. Once the port order has been
                    accepted you will receive an email to confirm the port date. You are
                    advised to wait until the port has been accepted before confirming the
                    port with your customer.
                  </Typography>
                </MuiAlert>
              </Box>
            </>
          )}
        </>
      )}
    </Paper>
  )
}

export default PortDates
