import HelpContactDialog from 'components/HelpContactDialog'
import React, { useState } from 'react'

const HelpContext = React.createContext()
const WithHelp = ({ children }) => {
  const [showHelp, setShowHelp] = useState(false)
  const [helpOpen, setHelpOpen] = useState(false)
  const [helpType, setHelpType] = useState('sales-hub')

  const defaultContext = {
    showHelp,
    setShowHelp,
    helpOpen,
    setHelpOpen,
    helpType,
    setHelpType,
  }
  return (
    <HelpContext.Provider value={defaultContext}>
      {helpOpen && (
        <HelpContactDialog type={helpType} open={helpOpen} setOpen={setHelpOpen} />
      )}
      {children}
    </HelpContext.Provider>
  )
}

export { HelpContext }
export default WithHelp
