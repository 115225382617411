import React, { useEffect, useState } from 'react'

function Page({ pdf, pageNum, scale, className }) {
  const [canvasUrl, setCanvasUrl] = useState(null)

  useEffect(() => {
    const getPage = async () => {
      const page = await pdf.getPage(pageNum)
      const viewport = page.getViewport({ scale: scale })
      const { width, height } = viewport
      const canvas = document.createElement('canvas')
      canvas.width = width
      canvas.height = height
      canvas.className = 'page'
      await page.render({
        canvasContext: canvas.getContext('2d'),
        viewport,
      }).promise

      setCanvasUrl(canvas.toDataURL())
    }

    getPage()
  }, [pageNum, pdf, scale])

  if (!canvasUrl) {
    return null
  }

  return <img className={className} src={canvasUrl} alt='PDF page' />
}

export default Page
