import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { tagsUpdate } from 'api/number-porting'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import { Box } from '@material-ui/core'

const options = ['IPEX', 'Non-Geographic', 'Mixed Operator']

function UpdatePortTagsModal({ id, currentTags, afterSubmit, children }) {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(currentTags)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = event => {
    setValue(event.target.value)
  }

  const handleSubmit = async () => {
    setLoading(true)
    await tagsUpdate(id, { tags: value })

    if (afterSubmit) {
      await afterSubmit()
    }

    setLoading(false)
    setOpen(false)
  }

  return (
    <React.Fragment>
      <span onClick={handleOpen}>{children}</span>

      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Port Tags</DialogTitle>
        <DialogContent>
          <Box mb={5}>
            <Select
              multiple
              displayEmpty
              style={{ width: '100%' }}
              id='value'
              name='value'
              value={value}
              onChange={handleChange}
              label='Port Tags'
              renderValue={selected =>
                Array.isArray(selected) && selected.length > 0
                  ? selected.join(', ')
                  : 'None'
              }>
              {options.map(x => (
                <MenuItem key={x} value={x}>
                  <Checkbox checked={value.indexOf(x) > -1} />
                  <ListItemText primary={x} />
                </MenuItem>
              ))}
            </Select>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={loading} onClick={handleSubmit} color='primary'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

UpdatePortTagsModal.propTypes = {
  id: PropTypes.number,
  currentTags: PropTypes.array,
  afterSubmit: PropTypes.func,
}

export default UpdatePortTagsModal
