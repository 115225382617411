import { Box, FormHelperText } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('xl')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  radios: {
    minWidth: 150,
    marginLeft: theme.spacing(2),
  },
}))

function YesNoQuestion({ value, onChange, error, helperText, children }) {
  const classes = useStyles()

  return (
    <div className={classes.row}>
      <div>{children}</div>
      <Box flexGrow='1'></Box>
      <FormControl component='fieldset' className={classes.radios} error={error}>
        <RadioGroup row value={value} onChange={onChange}>
          <FormControlLabel value='yes' control={<Radio color='primary' />} label='Yes' />
          <FormControlLabel value='no' control={<Radio color='primary' />} label='No' />
        </RadioGroup>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </div>
  )
}

YesNoQuestion.propTypes = {
  error: PropTypes.bool,
  helperText: PropTypes.string,
}

YesNoQuestion.defaultProps = {
  error: false,
  helperText: '',
}

export default YesNoQuestion
