import Container from '@material-ui/core/Container'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import MuiAlert from '@material-ui/lab/Alert'
import { services as apiSave } from 'api/quote-builder'
import { AuthContext } from 'contexts/AuthContext'
import { FeatureToggleContext } from 'contexts/FeatureToggleContext'
import { QuoteContext } from 'contexts/QuoteContext'
import React, { useContext } from 'react'
import * as Yup from 'yup'
import Title from './components/Title'

const useStyles = makeStyles(theme => ({
  table: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(8),
    margin: 'auto',
    [theme.breakpoints.up('lg')]: {
      width: '60%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '40%',
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  formControl: {
    minWidth: '100%',
    marginTop: theme.spacing(2),
  },
  section: {
    marginBottom: theme.spacing(4),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  twoCol: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridColumnGap: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
}))

const canQuoteSystem = (quoteableSystems, currentSystem, targetSystem) => {
  // temporarily disable all onprem ucp quotes
  if (targetSystem === 'onprem') return false

  if (quoteableSystems === 'all') return true

  if (currentSystem === targetSystem) return true

  if (targetSystem === quoteableSystems) return true

  return false
}

function ServicesStep() {
  const { state, dispatch, hitLimits } = useContext(QuoteContext)
  const { hasFeature } = useContext(FeatureToggleContext)
  const { hasPermission } = useContext(AuthContext)
  const classes = useStyles()

  const handleChangeServices = event => {
    const { name, value } = event.target
    const newData = { ...state.services, [name]: { value: value } }
    dispatch({ type: 'SET_SERVICES', payload: newData })
  }

  const getSystemUserTypes = () => {
    if (hasFeature('product_contract_pricing')) {
      const ret = []
      const quotable = state.settings.quotable_licences

      if (quotable.indexOf('monthly') !== -1)
        ret.push({ value: 'default', label: 'Monthly' })
      if (quotable.indexOf('contract_3_year') !== -1)
        ret.push({ value: 'contract_3_year', label: '3 Year Contract' })
      if (quotable.indexOf('contract_5_year') !== -1)
        ret.push({ value: 'contract_5_year', label: '5 Year Contract' })
      if (quotable.indexOf('lifetime') !== -1)
        ret.push({ value: 'lifetime', label: 'Lifetime' })

      return ret
    } else {
      return [
        { value: 'default', label: 'Monthly' },
        { value: 'lifetime', label: 'Lifetime' },
      ]
    }
  }

  return (
    <div>
      <form className={classes.form} noValidate>
        <Paper className={classes.paper}>
          <Container component='main' maxWidth='sm'>
            <div className={classes.section}>
              <Title>System Details</Title>

              {hitLimits(
                state.services.system_type.value,
                state.services.system_users.value,
                state.services.remote_users.value,
                state.services.number_of_sip_trunks.value
              ) && (
                <React.Fragment>
                  {hasPermission('manual_ucp_config') ? (
                    <MuiAlert severity='info' style={{ marginBottom: '24px' }}>
                      This quantity of users or number of required channels can not be
                      quoted with QuickQuote, when pressing save you will be taken through
                      the manual configuration tool.
                    </MuiAlert>
                  ) : (
                    <MuiAlert severity='error' style={{ marginBottom: '24px' }}>
                      This quantity of users or number of required channels can not be
                      quoted through this tool, please speak to a sales manager.
                    </MuiAlert>
                  )}
                </React.Fragment>
              )}

              <FormControl component='fieldset'>
                <RadioGroup
                  row
                  name='system_type'
                  defaultValue={state.services.system_type.value}
                  onChange={handleChangeServices.bind(this)}>
                  {canQuoteSystem(
                    state.settings.quotable_systems,
                    state.services.system_type.value,
                    'cloud'
                  ) && (
                    <FormControlLabel
                      value='cloud'
                      control={<Radio color='primary' />}
                      label='iPECS Cloud'
                      labelPlacement='end'
                    />
                  )}

                  {canQuoteSystem(
                    state.settings.quotable_systems,
                    state.services.system_type.value,
                    'onprem'
                  ) && (
                    <FormControlLabel
                      value='onprem'
                      control={<Radio color='primary' />}
                      label='iPECS UCP'
                      labelPlacement='end'
                    />
                  )}
                </RadioGroup>
              </FormControl>

              {state.services.system_type.value === 'cloud' && (
                <>
                  <TextField
                    variant='standard'
                    margin='normal'
                    required
                    id='system_users'
                    label='User Licences'
                    name='system_users'
                    fullWidth
                    defaultValue={state.services.system_users.value}
                    autoFocus
                    onChange={handleChangeServices}
                    error={state.services.system_users.error ? true : false}
                    helperText={state.services.system_users.error}
                  />

                  <FormControl variant='standard' className={classes.formControl}>
                    <InputLabel id='system_users_type_label'>Licence Type</InputLabel>
                    <Select
                      labelId='system_users_type_label'
                      id='system_users_type'
                      name='system_users_type'
                      value={state.services.system_users_type.value}
                      onChange={handleChangeServices}
                      label='Licence Type'
                      error={state.services.system_users_type.error ? true : false}>
                      {getSystemUserTypes().map(x => (
                        <MenuItem key={x.value} value={x.value}>
                          {x.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <TextField
                    variant='standard'
                    margin='normal'
                    required
                    id='lite_licenses'
                    label='Additional Lite Licences'
                    name='lite_licenses'
                    fullWidth
                    defaultValue={state.services.lite_licenses.value}
                    autoFocus
                    onChange={handleChangeServices}
                    error={state.services.lite_licenses.error ? true : false}
                    helperText={state.services.lite_licenses.error}
                  />
                </>
              )}

              {state.services.system_type.value === 'onprem' && (
                <>
                  <TextField
                    variant='standard'
                    margin='normal'
                    required
                    id='system_users'
                    label='Office Users'
                    name='system_users'
                    fullWidth
                    defaultValue={state.services.system_users.value}
                    autoFocus
                    onChange={handleChangeServices.bind(this)}
                    error={state.services.system_users.error ? true : false}
                    helperText={state.services.system_users.error}
                  />
                  <TextField
                    variant='standard'
                    margin='normal'
                    id='remote_users'
                    label='Remote Users'
                    name='remote_users'
                    fullWidth
                    defaultValue={state.services.remote_users.value}
                    onChange={handleChangeServices.bind(this)}
                    error={state.services.remote_users.error ? true : false}
                    helperText={state.services.remote_users.error}
                  />
                  <TextField
                    variant='standard'
                    margin='normal'
                    id='number_of_sip_trunks'
                    label='Number of SIP Trunks'
                    name='number_of_sip_trunks'
                    fullWidth
                    defaultValue={state.services.number_of_sip_trunks.value}
                    onChange={handleChangeServices.bind(this)}
                    error={state.services.number_of_sip_trunks.error ? true : false}
                    helperText={state.services.number_of_sip_trunks.error}
                  />
                </>
              )}
            </div>
          </Container>
        </Paper>
      </form>
    </div>
  )
}

const validate = async (services, dispatch) => {
  try {
    const rules = {
      system_users: Yup.number()
        .required()
        .min(1)
        .max(99999)
        .typeError('you must specify a number'),
      system_users_type: Yup.string().required(),
    }

    if (services.system_type.value === 'onprem') {
      rules.number_of_sip_trunks = Yup.number()
        .required()
        .min(1)
        .max(99999)
        .typeError('you must specify a number')
      rules.remote_users = Yup.number()
        .required()
        .min(0)
        .max(99999)
        .typeError('you must specify a number')
    }

    await Yup.object().shape(rules).validate(
      {
        system_users: services.system_users.value,
        system_users_type: services.system_users_type.value,
        number_of_sip_trunks: services.number_of_sip_trunks.value,
        remote_users: services.remote_users.value,
      },
      { abortEarly: false }
    )
    return true
  } catch (error) {
    const errors = {}
    error.inner.map(item => {
      var words = item.message.replace(/([A-Z])/g, ' $1')
      return (errors[item.path] = {
        error: (words.charAt(0).toUpperCase() + words.slice(1)).replace(/_/g, ' '),
      })
    })
    dispatch({ type: 'SET_SERVICES', payload: { ...services, ...errors } })
    return false
  }
}

const save = async (id, services) => {
  return await apiSave(id, {
    system_type: services.system_type.value,
    system_users: services.system_users.value,
    system_users_type: services.system_users_type.value,
    lite_licenses: services.lite_licenses.value,
    number_of_sip_trunks: services.number_of_sip_trunks.value,
    remote_users: services.remote_users.value,
  })
}

export { validate, save }
export default ServicesStep
