import config from 'config'
import { getJsonHeaders, getQueryStringFromFiltersObject } from './helpers'

export async function list(limit = 15, page = 1, filters = {}) {
  try {
    let queryString = `?per_page=${limit}&page=${page}`
    const filterString = getQueryStringFromFiltersObject(filters)

    if (filterString) {
      queryString += `&${filterString}`
    }

    const result = await fetch(config.API_URL + '/api/quotes' + queryString)
    return await result.json()
  } catch (e) {
    return false
  }
}

export async function deleteQuote(id) {
  try {
    const res = await fetch(config.API_URL + `/api/quotes/${id}`, {
      method: 'DELETE',
      headers: await getJsonHeaders(),
      body: JSON.stringify({}),
    })

    if (res.status === 204) {
      return true
    }

    return false
  } catch (e) {
    return false
  }
}

export async function setWon(id) {
  try {
    const res = await fetch(config.API_URL + `/api/quotes/won/${id}`, {
      method: 'POST',
      headers: await getJsonHeaders(),
      body: JSON.stringify({}),
    })

    return res.status === 200
  } catch (e) {
    return false
  }
}

export async function setLost(id) {
  try {
    const res = await fetch(config.API_URL + `/api/quotes/lost/${id}`, {
      method: 'POST',
      headers: await getJsonHeaders(),
      body: JSON.stringify({}),
    })

    return res.status === 200
  } catch (e) {
    return false
  }
}

export async function setOpen(id) {
  try {
    const res = await fetch(config.API_URL + `/api/quotes/open/${id}`, {
      method: 'POST',
      headers: await getJsonHeaders(),
      body: JSON.stringify({}),
    })

    return res.status === 200
  } catch (e) {
    return false
  }
}

export async function updateSettings(body) {
  try {
    const res = await fetch(config.API_URL + `/api/quotes/settings`, {
      method: 'POST',
      headers: await getJsonHeaders(),
      body: JSON.stringify(body),
    })

    if (res.status === 200) {
      return {
        success: true,
        message: 'Successfully updated settings',
      }
    }

    return {
      success: false,
      message: 'Failed to update settings',
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to update settings',
    }
  }
}

export async function sharingInfo(id) {
  const result = await fetch(config.API_URL + `/api/quotes/sharing/info/${id}`)
  return await result.json()
}

export async function createShareLink(id, body) {
  const res = await fetch(config.API_URL + `/api/quotes/sharing/${id}`, {
    method: 'POST',
    headers: await getJsonHeaders(),
    body: JSON.stringify(body),
  })

  return await res.json()
}

export async function deleteShareLink(id) {
  const res = await fetch(config.API_URL + `/api/quotes/sharing/${id}`, {
    method: 'DELETE',
    headers: await getJsonHeaders(),
  })

  return await res.json()
}

export async function shared(id) {
  const result = await fetch(config.API_URL + `/api/quotes/share/${id}`)
  return await result.json()
}
