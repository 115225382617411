import {
  geographicPortInstallationDetails,
  geographicPortManualUpdate,
  geographicPortNumberDetails,
  geographicPortNumbersToPort,
  geographicPortPciCompliance,
  geographicPortPortingDate,
  geographicPortSubmit,
  get,
} from 'api/number-porting'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext, useEffect, useReducer } from 'react'
import { create, deleteNote, update } from 'api/notes'

const LCPs = [
  { key: 'OTHER', label: 'Other' },
  { key: 'BARRITEL_LIMITED', label: 'Barritel' },
  { key: 'BSKYB_LLU', label: 'BSKYB' },
  { key: 'BT_OPENREACH', label: 'BT Openreach' },
  { key: 'COLT', label: 'Colt' },
  { key: 'GAMMA', label: 'Gamma' },
  { key: 'GLOBAL_CROSSING', label: 'Global Crossing' },
  { key: 'INCLARITY', label: 'Inclarity' },
  { key: 'INVOCO_LTD', label: 'Invoco' },
  { key: 'KCOM_AFFINITI', label: 'KCOM Affiniti' },
  { key: 'LUMISON', label: 'Lumison' },
  { key: 'MAGRATHEA', label: 'Magrathea' },
  { key: 'MINTAKA_LIMITED', label: 'Mintaka' },
  { key: 'OPAL_TELECOM_TALK_TALK', label: 'Opal Telecom Talk Talk' },
  { key: 'ORANGE_BUSINESS', label: 'Orange Business' },
  { key: 'PRIMUS_TELECOM', label: 'Primus Telecom' },
  { key: 'RESILIENT_PLC', label: 'Resilient PLC' },
  { key: 'SIMWOOD_ESMS_LIMITED', label: 'Simwood ESMS' },
  { key: 'SPITFIRE', label: 'Spitfire' },
  { key: 'STORACALL_X_ON', label: 'Storacall X ON' },
  { key: 'TELEPHONY_SERVICES_GRADWELL', label: 'Gradwell' },
  { key: 'VERIZON', label: 'Verizon' },
  { key: 'VIATEL_UK_LIMITED_VTL', label: 'Viatel UK Ltd VTL' },
  { key: 'VIRGIN_MEDIA_EUROBELL', label: 'Virgin Media Eurobell' },
  { key: 'VIRGIN_MEDIA_NTL', label: 'Virgin Media NTL' },
  { key: 'VIRGIN_MEDIA_TELEWEST', label: 'Virgin Media Telewest' },
  { key: 'VODAFONE_C_AND_W', label: 'Vodafone C&W' },
  { key: 'VODAFONE_ENERGIS', label: 'Vodafone Energis' },
  { key: 'VODAFONE_THUS', label: 'Vodafone Thus' },
  { key: 'VODAFONE_YOUR_COMMS', label: 'Vodafone Your COmms' },
  { key: 'VOIP_UN_LIMITED', label: 'VOIP Un' },
  { key: 'VOXBONE_SA', label: 'Voxbone' },
]

const initialState = {
  loading: true,

  // not actually required for the api request
  process_type: { value: '' },
  is_manual: { value: '' },
  tags: { value: [] },
  reseller: { id: '', name: '' },
  hub_status: { value: 'UNSUBMITTED' },
  status: { value: 'DRAFT' },
  simple_status: { value: 'DRAFT' },
  has_consent: { value: false },
  line_active: { value: false },
  checked_directory_listings: { value: false },
  checked_loa_disclaimer: { value: false },
  loa_file: { value: '' },
  loa_url: { value: '' },
  has_history: { value: false },
  created_by: { name: '' },

  // required info
  id: { value: '' },
  external_id: { value: '' },
  lcp: { value: '' },
  lcp_other: { value: '' },
  lcp_account_ref: { value: '' },
  port_date: { value: '' },
  port_date_type: { value: 'STANDARD' },
  port_date_time: { value: '' },
  number_details: {
    line_type: { value: '' },
    std_code: { value: '' },
    main_number: {
      local_number: { value: '' },
      action: { value: 'PORT' },
      is_in_service: { value: true },
    },
    has_associated_products: { value: false },
    has_rcf: { value: false },
    rcf_post_code: { value: '' },
    is_featurenet_port: { value: false },
    associated_numbers: [],
    ddi_ranges: [],
    single_number_ddis: [],
    other_numbers: [],
  },
  pci_compliance: { value: null },
  contact: {
    name: { value: '' },
    email: { value: '' },
    phone_number: { value: '' },
  },
  customer: {
    company_name: { value: '' },
    first_name: { value: '' },
    last_name: { value: '' },
    contact_number: { value: '' },
    email: { value: '' },
    job_title: { value: '' },
  },
  address_details: {
    is_moving: { value: false },
    old_post_code: { value: '' },
    premises: { value: '' },
    street: { value: '' },
    town: { value: '' },
    county: { value: '' },
    post_code: { value: '' },
    customer_confirmed: { value: false },
  },
  rejection_reasons: { value: [] },
  rejection_related_events: { value: [] },
  errors: {},
  main_error: '',
  notes: [],
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'RESET':
      return initialState
    case 'SET_LOADING':
      return { ...state, loading: action.payload, errors: {} }
    case 'SET_ID':
      return { ...state, id: { value: action.payload }, errors: {} }
    case 'SET_EXTERNAL_ID':
      return { ...state, external_id: { value: action.payload }, errors: {} }
    case 'SET_PROCESS_TYPE':
      return { ...state, process_type: { value: action.payload }, errors: {} }
    case 'SET_IS_MANUAL':
      return { ...state, is_manual: { value: action.payload }, errors: {} }
    case 'SET_TAGS':
      return { ...state, tags: { value: action.payload }, errors: {} }
    case 'SET_RESELLER':
      return {
        ...state,
        reseller: { id: action.payload.id, name: action.payload.name },
        errors: {},
      }
    case 'SET_HUB_STATUS':
      return { ...state, hub_status: { value: action.payload }, errors: {} }
    case 'SET_STATUS':
      return { ...state, status: { value: action.payload }, errors: {} }
    case 'SET_SIMPLE_STATUS':
      return { ...state, simple_status: { value: action.payload }, errors: {} }
    case 'SET_STD_CODE':
      return {
        ...state,
        number_details: {
          ...state.number_details,
          std_code: { value: action.payload },
        },
        errors: {},
      }
    case 'SET_LINE_TYPE':
      return {
        ...state,
        number_details: {
          ...state.number_details,
          line_type: { value: action.payload },
        },
        errors: {},
      }
    case 'SET_RCF_POST_CODE':
      return {
        ...state,
        number_details: {
          ...state.number_details,
          rcf_post_code: { value: action.payload },
          has_rcf: { value: action.payload ? true : false },
        },
        errors: {},
      }
    case 'SET_LCP':
      return { ...state, lcp: { value: action.payload }, errors: {} }
    case 'SET_LCP_OTHER':
      return { ...state, lcp_other: { value: action.payload }, errors: {} }
    case 'SET_LCP_ACCOUNT_REF':
      return { ...state, lcp_account_ref: { value: action.payload }, errors: {} }
    case 'SET_PORT_DATE':
      return { ...state, port_date: { value: action.payload }, errors: {} }
    case 'SET_PORT_DATE_TYPE':
      return { ...state, port_date_type: { value: action.payload }, errors: {} }
    case 'SET_PORT_DATE_TIME':
      return { ...state, port_date_time: { value: action.payload }, errors: {} }
    case 'SET_CONTACT_DETAILS':
      return { ...state, contact: getContactDetails(state, action.payload), errors: {} }
    case 'SET_MAIN_NUMBER':
      return {
        ...state,
        number_details: {
          ...state.number_details,
          main_number: setMainNumber(state, action.payload),
        },
        errors: {},
      }
    case 'SET_HAS_ASSOCIATED_PRODUCTS':
      return {
        ...state,
        number_details: {
          ...state.number_details,
          has_associated_products: { value: action.payload ? true : false },
        },
        errors: {},
      }
    case 'SET_HAS_RCF':
      return {
        ...state,
        number_details: {
          ...state.number_details,
          has_rcf: { value: action.payload ? true : false },
        },
        errors: {},
      }
    case 'SET_IS_FEATURENET_PORT':
      return {
        ...state,
        number_details: {
          ...state.number_details,
          is_featurenet_port: { value: action.payload ? true : false },
        },
        errors: {},
      }
    case 'SET_HAS_CONSENT':
      return {
        ...state,
        has_consent: { value: action.payload ? true : false },
        errors: {},
      }
    case 'SET_LINE_ACTIVE':
      return {
        ...state,
        line_active: { value: action.payload ? true : false },
        errors: {},
      }
    case 'SET_CHECKED_DIRECTORY_LISTINGS':
      return {
        ...state,
        checked_directory_listings: { value: action.payload ? true : false },
        errors: {},
      }
    case 'SET_CHECKED_LOA_DISCLAIMER':
      return {
        ...state,
        checked_loa_disclaimer: { value: action.payload ? true : false },
        errors: {},
      }
    case 'SET_LOA_FILE':
      return { ...state, loa_file: { value: action.payload }, errors: {} }
    case 'SET_LOA_URL':
      return { ...state, loa_url: { value: action.payload }, errors: {} }
    case 'SET_CUSTOMER':
      return { ...state, customer: getCustomer(state, action.payload), errors: {} }
    case 'SET_ADDRESS_DETAILS':
      return {
        ...state,
        address_details: getAddressDetails(state, action.payload),
        errors: {},
      }
    case 'ADD_ASSOCIATED_NUMBER':
      return {
        ...state,
        number_details: {
          ...state.number_details,
          associated_numbers: addNumber(
            state.number_details.std_code.value,
            state.number_details.associated_numbers
          ),
        },
        errors: {},
      }
    case 'UPDATE_ASSOCIATED_NUMBER':
      return {
        ...state,
        number_details: {
          ...state.number_details,
          associated_numbers: updateNumber(
            state.number_details.associated_numbers,
            action.payload
          ),
        },
        errors: {},
      }
    case 'REMOVE_ASSOCIATED_NUMBER':
      return {
        ...state,
        number_details: {
          ...state.number_details,
          associated_numbers: removeNumber(
            state.number_details.associated_numbers,
            action.payload
          ),
        },
        errors: {},
      }
    case 'ADD_DDI_RANGE':
      return {
        ...state,
        number_details: {
          ...state.number_details,
          ddi_ranges: addNumberRange(
            state.number_details.std_code.value,
            state.number_details.ddi_ranges
          ),
        },
        errors: {},
      }
    case 'UPDATE_DDI_RANGE':
      return {
        ...state,
        number_details: {
          ...state.number_details,
          ddi_ranges: updateNumberRange(state.number_details.ddi_ranges, action.payload),
        },
        errors: {},
      }
    case 'REMOVE_DDI_RANGE':
      return {
        ...state,
        number_details: {
          ...state.number_details,
          ddi_ranges: removeNumber(state.number_details.ddi_ranges, action.payload),
        },
        errors: {},
      }
    case 'ADD_SINGLE_NUMBER_DDI':
      return {
        ...state,
        number_details: {
          ...state.number_details,
          single_number_ddis: addNumber(
            state.number_details.std_code.value,
            state.number_details.single_number_ddis
          ),
        },
        errors: {},
      }
    case 'UPDATE_SINGLE_NUMBER_DDI':
      return {
        ...state,
        number_details: {
          ...state.number_details,
          single_number_ddis: updateNumber(
            state.number_details.single_number_ddis,
            action.payload
          ),
        },
        errors: {},
      }
    case 'REMOVE_SINGLE_NUMBER_DDI':
      return {
        ...state,
        number_details: {
          ...state.number_details,
          single_number_ddis: removeNumber(
            state.number_details.single_number_ddis,
            action.payload
          ),
        },
        errors: {},
      }
    case 'ADD_OTHER_NUMBER':
      return {
        ...state,
        number_details: {
          ...state.number_details,
          other_numbers: addNumber(
            state.number_details.std_code.value,
            state.number_details.other_numbers
          ),
        },
        errors: {},
      }
    case 'UPDATE_OTHER_NUMBER':
      return {
        ...state,
        number_details: {
          ...state.number_details,
          other_numbers: updateNumber(state.number_details.other_numbers, action.payload),
        },
        errors: {},
      }
    case 'REMOVE_OTHER_NUMBER':
      return {
        ...state,
        number_details: {
          ...state.number_details,
          other_numbers: removeNumber(state.number_details.other_numbers, action.payload),
        },
        errors: {},
      }
    case 'SET_PCI_COMPLIANCE':
      return {
        ...state,
        pci_compliance: { value: action.payload ? true : false },
        errors: {},
      }
    case 'SET_HAS_HISTORY':
      return { ...state, has_history: { value: action.payload }, errors: {} }
    case 'SET_CREATED_BY':
      return { ...state, created_by: action.payload, errors: {} }
    case 'SET_REJECTION_REASONS':
      return { ...state, rejection_reasons: { value: action.payload }, errors: {} }
    case 'SET_REJECTION_RELATED_EVENTS':
      return { ...state, rejection_related_events: { value: action.payload }, errors: {} }
    case 'SET_ERRORS':
      return { ...state, errors: action.payload }
    case 'SET_MAIN_ERROR':
      return { ...state, main_error: action.payload }
    case 'ADD_NOTE':
      return { ...state, notes: [action.payload, ...state.notes] }
    case 'EDIT_NOTE':
      return {
        ...state,
        notes: state.notes.map(note =>
          action.payload.id === note.id ? action.payload : note
        ),
      }
    case 'SET_NOTES':
      return { ...state, notes: action.payload }
    default:
      throw new Error(`Unhandle reducer action ${action.type}`)
  }
}

const getContactDetails = (state, payload) => {
  const { key, value } = payload
  return { ...state.contact, [key]: { value: value } }
}

const setMainNumber = (state, payload) => {
  const { key, value } = payload
  return { ...state.number_details.main_number, [key]: { value: value } }
}

const getCustomer = (state, payload) => {
  const { key, value } = payload
  return { ...state.customer, [key]: { value: value } }
}

const getAddressDetails = (state, payload) => {
  const { key, value } = payload
  return { ...state.address_details, [key]: { value: value } }
}

const addNumber = (defaultStdCode, state) => {
  const newNumbers = Array.from(state)
  newNumbers.push(getDefaultNumber(defaultStdCode))
  return newNumbers
}

const updateNumber = (state, payload) => {
  const newNumbers = Array.from(state)
  newNumbers[payload.index] = {
    std_code: { value: payload.std_code },
    local_number: { value: payload.local_number },
    action: { value: payload.action },
    is_in_service: { value: payload.is_in_service },
  }
  return newNumbers
}

const removeNumber = (state, index) => {
  const newNumbers = Array.from(state)
  newNumbers.splice(index, 1)
  return newNumbers
}

const addNumberRange = (defaultStdCode, state) => {
  const newNumbers = Array.from(state)
  newNumbers.push(getDefaultNumberRange(defaultStdCode))
  return newNumbers
}

const updateNumberRange = (state, payload) => {
  const newNumbers = Array.from(state)
  newNumbers[payload.index] = {
    std_code: { value: payload.std_code },
    local_number_start: { value: payload.local_number_start },
    local_number_end: { value: payload.local_number_end },
    action: { value: payload.action },
    is_in_service: { value: payload.is_in_service },
  }
  return newNumbers
}

const getDefaultNumber = (stdCode = '') => {
  return {
    std_code: { value: stdCode },
    local_number: { value: '' },
    action: { value: 'PORT' },
    is_in_service: { value: true },
  }
}

const getDefaultNumberRange = (stdCode = '') => {
  return {
    std_code: { value: stdCode },
    local_number_start: { value: '' },
    local_number_end: { value: '' },
    action: { value: 'PORT' },
    is_in_service: { value: true },
  }
}

const GeographicPortContext = React.createContext()
const WithGeographicPort = ({ id, children }) => {
  const { user } = useContext(AuthContext)
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const res = await get(id)

        dispatch({ type: 'SET_ID', payload: res.data.id })
        dispatch({ type: 'SET_EXTERNAL_ID', payload: res.data.external_id })
        dispatch({ type: 'SET_HUB_STATUS', payload: res.data.hub_status })
        dispatch({ type: 'SET_STATUS', payload: res.data.status })
        dispatch({ type: 'SET_SIMPLE_STATUS', payload: res.data.simple_status })
        dispatch({ type: 'SET_STD_CODE', payload: res.data.std_code })
        dispatch({ type: 'SET_LINE_TYPE', payload: res.data.line_type })
        dispatch({ type: 'SET_LCP', payload: res.data.lcp })
        dispatch({ type: 'SET_LCP_OTHER', payload: res.data.lcp_other })
        dispatch({ type: 'SET_PCI_COMPLIANCE', payload: res.data.pci_compliance })
        dispatch({ type: 'SET_LCP_ACCOUNT_REF', payload: res.data.lcp_account_ref })
        dispatch({ type: 'SET_PORT_DATE', payload: res.data.port_date.value })
        dispatch({ type: 'SET_PORT_DATE_TYPE', payload: res.data.port_date_type })
        dispatch({ type: 'SET_PORT_DATE_TIME', payload: res.data.port_date_time })
        dispatch({
          type: 'SET_CONTACT_DETAILS',
          payload: { key: 'name', value: res.data.contact.name },
        })
        dispatch({
          type: 'SET_CONTACT_DETAILS',
          payload: { key: 'email', value: res.data.contact.email },
        })
        dispatch({
          type: 'SET_CONTACT_DETAILS',
          payload: { key: 'phone_number', value: res.data.contact.phone_number },
        })
        dispatch({
          type: 'SET_MAIN_NUMBER',
          payload: { key: 'local_number', value: res.data.main_number.local_number },
        })
        dispatch({
          type: 'SET_MAIN_NUMBER',
          payload: { key: 'action', value: res.data.main_number.action },
        })
        dispatch({
          type: 'SET_MAIN_NUMBER',
          payload: { key: 'is_in_service', value: res.data.main_number.is_in_service },
        })
        dispatch({
          type: 'SET_HAS_ASSOCIATED_PRODUCTS',
          payload: res.data.has_associated_products,
        })
        dispatch({ type: 'SET_HAS_RCF', payload: res.data.rcf_post_code ? true : false })
        dispatch({ type: 'SET_RCF_POST_CODE', payload: res.data.rcf_post_code })
        dispatch({ type: 'SET_IS_FEATURENET_PORT', payload: res.data.is_featurenet_port })
        dispatch({
          type: 'SET_CUSTOMER',
          payload: { key: 'company_name', value: res.data.customer.company_name },
        })
        dispatch({
          type: 'SET_CUSTOMER',
          payload: { key: 'first_name', value: res.data.customer.first_name },
        })
        dispatch({
          type: 'SET_CUSTOMER',
          payload: { key: 'last_name', value: res.data.customer.last_name },
        })
        dispatch({
          type: 'SET_CUSTOMER',
          payload: { key: 'email', value: res.data.customer.email },
        })
        dispatch({
          type: 'SET_CUSTOMER',
          payload: { key: 'contact_number', value: res.data.customer.contact_number },
        })
        dispatch({
          type: 'SET_CUSTOMER',
          payload: { key: 'job_title', value: res.data.customer.job_title },
        })
        dispatch({
          type: 'SET_ADDRESS_DETAILS',
          payload: {
            key: 'is_moving',
            value: res.data.address_details.old_post_code ? true : false,
          },
        })
        dispatch({
          type: 'SET_ADDRESS_DETAILS',
          payload: {
            key: 'old_post_code',
            value: res.data.address_details.old_post_code,
          },
        })
        dispatch({
          type: 'SET_ADDRESS_DETAILS',
          payload: { key: 'premises', value: res.data.address_details.premises },
        })
        dispatch({
          type: 'SET_ADDRESS_DETAILS',
          payload: { key: 'street', value: res.data.address_details.street },
        })
        dispatch({
          type: 'SET_ADDRESS_DETAILS',
          payload: { key: 'town', value: res.data.address_details.town },
        })
        dispatch({
          type: 'SET_ADDRESS_DETAILS',
          payload: { key: 'county', value: res.data.address_details.county },
        })
        dispatch({
          type: 'SET_ADDRESS_DETAILS',
          payload: { key: 'post_code', value: res.data.address_details.post_code },
        })
        res.data.associated_numbers.map((x, index) => {
          dispatch({ type: 'ADD_ASSOCIATED_NUMBER' })
          return dispatch({
            type: 'UPDATE_ASSOCIATED_NUMBER',
            payload: {
              index: index,
              std_code: x.std_code,
              local_number: x.local_number,
              action: x.action,
              is_in_service: x.is_in_service,
            },
          })
        })
        res.data.ddi_ranges.map((x, index) => {
          dispatch({ type: 'ADD_DDI_RANGE' })
          return dispatch({
            type: 'UPDATE_DDI_RANGE',
            payload: {
              index: index,
              std_code: x.std_code,
              local_number_start: x.local_number_start,
              local_number_end: x.local_number_end,
              action: x.action,
              is_in_service: x.is_in_service,
            },
          })
        })
        res.data.single_number_ddis.map((x, index) => {
          dispatch({ type: 'ADD_SINGLE_NUMBER_DDI' })
          return dispatch({
            type: 'UPDATE_SINGLE_NUMBER_DDI',
            payload: {
              index: index,
              std_code: x.std_code,
              local_number: x.local_number,
              action: x.action,
              is_in_service: x.is_in_service,
            },
          })
        })
        res.data.other_numbers.map((x, index) => {
          dispatch({ type: 'ADD_OTHER_NUMBER' })
          return dispatch({
            type: 'UPDATE_OTHER_NUMBER',
            payload: {
              index: index,
              std_code: x.std_code,
              local_number: x.local_number,
              action: x.action,
              is_in_service: x.is_in_service,
            },
          })
        })
        dispatch({ type: 'SET_HAS_CONSENT', payload: true })
        dispatch({ type: 'SET_CHECKED_DIRECTORY_LISTINGS', payload: true })
        dispatch({ type: 'SET_CHECKED_LOA_DISCLAIMER', payload: true })
        dispatch({ type: 'SET_LINE_ACTIVE', payload: true })
        dispatch({ type: 'SET_LOA_URL', payload: res.data.loa_url })
        dispatch({
          type: 'SET_ADDRESS_DETAILS',
          payload: {
            key: 'customer_confirmed',
            value: res.data.address_details.premises ? true : false,
          },
        })
        dispatch({ type: 'SET_HAS_HISTORY', payload: res.data.has_history })
        dispatch({ type: 'SET_CREATED_BY', payload: res.data.created_by })

        if (res.data.hasOwnProperty('rejection_reasons')) {
          dispatch({ type: 'SET_REJECTION_REASONS', payload: res.data.rejection_reasons })
        }

        if (res.data.hasOwnProperty('rejection_related_events')) {
          dispatch({
            type: 'SET_REJECTION_RELATED_EVENTS',
            payload: res.data.rejection_related_events,
          })
        }

        if (res.data.hasOwnProperty('process_type')) {
          dispatch({ type: 'SET_PROCESS_TYPE', payload: res.data.process_type })
        }

        if (res.data.hasOwnProperty('is_manual')) {
          dispatch({ type: 'SET_IS_MANUAL', payload: res.data.is_manual })
        }

        if (res.data.hasOwnProperty('tags')) {
          dispatch({ type: 'SET_TAGS', payload: res.data.tags })
        }

        if (res.data.hasOwnProperty('notes')) {
          dispatch({ type: 'SET_NOTES', payload: res.data.notes })
        }
      }

      dispatch({ type: 'SET_LOADING', payload: false })
    }

    fetchData()
  }, [id])

  const validateBasicDetails = () => {
    const errors = {}

    const hasReseller = user.type === 'reseller' || state.id.value || state.reseller.id
    const hasName = state.contact.name.value.length > 0
    const hasEmail = state.contact.email.value.length > 0
    const hasPhoneNumber = state.contact.phone_number.value.length > 0

    if (!hasReseller) {
      errors.reseller = ['Reseller is required']
    }

    if (!hasName) {
      errors.name = ['Name is required']
    }

    if (!hasEmail) {
      errors.email = ['Email is required']
    }

    if (!hasPhoneNumber) {
      errors.phone_number = ['Phone number is required']
    }
    dispatch({ type: 'SET_ERRORS', payload: errors })

    return hasReseller && hasName && hasEmail && hasPhoneNumber
  }

  const validateNumberDetails = async () => {
    const body = {
      reseller: state.reseller.id,
      contact_name: state.contact.name.value,
      contact_email: state.contact.email.value,
      contact_phone_number: state.contact.phone_number.value,
      std_code: state.number_details.std_code.value,
      local_number: state.number_details.main_number.local_number.value,
      line_type: state.number_details.line_type.value,
      lcp: state.lcp.value,
      lcp_other: state.lcp_other.value,
      lcp_account_ref: state.lcp_account_ref.value,
      has_associated_products: state.number_details.has_associated_products.value,
      is_featurenet_port: state.number_details.is_featurenet_port.value,
      has_rcf: state.number_details.has_rcf.value,
      rcf_post_code: state.number_details.rcf_post_code.value,
      line_active: state.line_active.value,
      has_consent: state.has_consent.value,
      checked_directory_listings: state.checked_directory_listings.value,
      checked_loa_disclaimer: state.checked_loa_disclaimer.value,
      loa_file: state.loa_file.value,
      pci_compliance: state.pci_compliance.value,
    }

    if (!state.id.value) {
      body.reseller = state.reseller.id
    }

    const res = await geographicPortNumberDetails(state.id.value, body)

    if (res.status !== 200 && res.status !== 422) {
      dispatch({
        type: 'SET_MAIN_ERROR',
        payload:
          'There was an error checking the portability of this number, please retry again in a few minutes',
      })

      return false
    } else {
      dispatch({ type: 'SET_MAIN_ERROR', payload: '' })
    }

    if (res.response.hasOwnProperty('data')) {
      dispatch({ type: 'SET_ID', payload: res.response.data.id })
    }

    return !hasErrorsInApiResponse(res.response)
  }

  const validateInstallationDetails = async () => {
    const res = await geographicPortInstallationDetails(state.id.value, {
      company_name: state.customer.company_name.value,
      first_name: state.customer.first_name.value,
      last_name: state.customer.last_name.value,
      email: state.customer.email.value,
      contact_number: state.customer.contact_number.value,
      job_title: state.customer.job_title.value,
      is_moving: state.address_details.is_moving.value,
      old_post_code: state.address_details.old_post_code.value,
      premises: state.address_details.premises.value,
      street: state.address_details.street.value,
      town: state.address_details.town.value,
      county: state.address_details.county.value,
      post_code: state.address_details.post_code.value,
      customer_confirmed: state.address_details.customer_confirmed.value,
    })

    return !hasErrorsInApiResponse(res)
  }

  const validateNumbersToPort = async () => {
    const res = await geographicPortNumbersToPort(state.id.value, {
      main_number_action: state.number_details.main_number.action.value,
      main_number_is_in_service: state.number_details.main_number.is_in_service.value,
      associated_numbers: state.number_details.associated_numbers.map(x => ({
        std_code: x.std_code.value,
        local_number: x.local_number.value,
        action: x.action.value,
        is_in_service: x.is_in_service.value,
      })),
      ddi_ranges: state.number_details.ddi_ranges.map(x => ({
        std_code: x.std_code.value,
        local_number_start: x.local_number_start.value,
        local_number_end: x.local_number_end.value,
        action: x.action.value,
        is_in_service: x.is_in_service.value,
      })),
      single_number_ddis: state.number_details.single_number_ddis.map(x => ({
        std_code: x.std_code.value,
        local_number: x.local_number.value,
        action: x.action.value,
        is_in_service: x.is_in_service.value,
      })),
      other_numbers: state.number_details.other_numbers.map(x => ({
        std_code: x.std_code.value,
        local_number: x.local_number.value,
        action: x.action.value,
        is_in_service: x.is_in_service.value,
      })),
    })

    return !hasErrorsInApiResponse(res)
  }

  const validatePortingDate = async () => {
    const res = await geographicPortPortingDate(state.id.value, {
      porting_date: state.port_date.value,
      porting_date_type: state.port_date_type.value,
      porting_date_time: state.port_date_time.value,
    })

    return !hasErrorsInApiResponse(res)
  }

  const validatePciCompliance = async () => {
    const res = await geographicPortPciCompliance(state.id.value, {
      pci_compliance: state.pci_compliance.value,
    })

    return !hasErrorsInApiResponse(res)
  }

  const addPortNote = async content => {
    const res = await create({
      content: content,
      attach_id: state.id.value,
      type: 'number_ports',
    })

    dispatch({ type: 'ADD_NOTE', payload: res.response.data })
  }

  const editPortNote = async (id, content) => {
    const res = await update(id, { content: content })

    dispatch({ type: 'EDIT_NOTE', payload: res.response.data })
  }

  const deletePortNote = async id => {
    const res = await deleteNote(id)

    dispatch({ type: 'EDIT_NOTE', payload: res.response.data })
  }

  const submitOrder = async () => {
    const res = await geographicPortSubmit(state.id.value)

    if (res.status !== 200 && res.status !== 422) {
      dispatch({
        type: 'SET_MAIN_ERROR',
        payload:
          'There was an error submitting this port request, please retry again in a few minutes',
      })

      return false
    } else {
      dispatch({ type: 'SET_MAIN_ERROR', payload: '' })
    }

    return !hasErrorsInApiResponse(res.response)
  }

  const manualUpdate = async () => {
    const body = {
      contact_name: state.contact.name.value,
      contact_email: state.contact.email.value,
      contact_phone_number: state.contact.phone_number.value,
      premises: state.address_details.premises.value,
      street: state.address_details.street.value,
      town: state.address_details.town.value,
      county: state.address_details.county.value,
      post_code: state.address_details.post_code.value,
      old_post_code: state.address_details.old_post_code.value,
      company_name: state.customer.company_name.value,
      first_name: state.customer.first_name.value,
      last_name: state.customer.last_name.value,
      contact_number: state.customer.contact_number.value,
      email: state.customer.email.value,
      lcp: state.lcp.value,
      is_featurenet_port: state.number_details.is_featurenet_port.value,
      port_date: state.port_date.value,
      has_associated_products: state.number_details.has_associated_products.value,
      pci_compliance: state.pci_compliance.value,
    }

    if (state.number_details.rcf_post_code.value) {
      body.rcf_post_code = state.number_details.rcf_post_code.value
    }

    if (state.address_details.old_post_code.value) {
      body.old_post_code = state.address_details.old_post_code.value
    }

    await geographicPortManualUpdate(state.id.value, body)
  }

  const hasErrorsInApiResponse = response => {
    const hasErrors = response.hasOwnProperty('errors')
    dispatch({ type: 'SET_ERRORS', payload: hasErrors ? response.errors : {} })
    return hasErrors
  }

  const defaultContext = {
    state,
    dispatch,
    LCPs,
    validateBasicDetails,
    validateNumberDetails,
    validateInstallationDetails,
    validateNumbersToPort,
    validatePortingDate,
    validatePciCompliance,
    addPortNote,
    editPortNote,
    deletePortNote,
    submitOrder,
    manualUpdate,
  }
  return (
    <GeographicPortContext.Provider value={defaultContext}>
      {children}
    </GeographicPortContext.Provider>
  )
}

export { GeographicPortContext }
export default WithGeographicPort
