import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { Link as ReactRouteLink } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
}))

function Link({ to, ...props }) {
  const classes = useStyles()
  return (
    <ReactRouteLink to={to} className={props.className ? props.className : classes.link}>
      {props.children}
    </ReactRouteLink>
  )
}

export default Link
