import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  FormControlLabel,
} from '@material-ui/core'
import EditableTableField from 'components/EditableTableField'
import { useDocument } from 'hooks/Document'
import { useToasts } from 'react-toast-notifications'

function DocumentsForm({ quote, title, onClose }) {
  const document = useDocument(quote)
  const { addToast } = useToasts()

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>
          Completing this form will send Network and Leasing documents to the customer for
          e-signing.
        </Typography>
        <Typography variant='h6'>Customer Information</Typography>

        <TextField
          label='Company Reg Number'
          name={'company_number'}
          value={document.state.company_number}
          onChange={document.handleFieldChange}
          error={document.errors['company_number'] ? true : false}
          helperText={document.errors['company_number'] ?? ''}
          fullWidth
        />

        <TextField
          label='Position'
          name={'position'}
          value={document.state.position}
          onChange={document.handleFieldChange}
          error={document.errors['position'] ? true : false}
          helperText={document.errors['position'] ?? ''}
          fullWidth
        />

        <TextField
          label='Company Phone Number'
          name={'company_phone'}
          value={document.state.company_phone}
          onChange={document.handleFieldChange}
          error={document.errors['company_phone'] ? true : false}
          helperText={document.errors['company_phone'] ?? ''}
          fullWidth
        />
        <TextField
          label='Billing Email'
          name={'billing_email_address'}
          value={document.state.billing_email_address}
          onChange={document.handleFieldChange}
          error={document.errors['billing_email_address'] ? true : false}
          helperText={document.errors['billing_email_address'] ?? ''}
          fullWidth
        />
        <TextField
          label='Invoice Address'
          name={'invoice_address'}
          value={document.state.invoice_address}
          onChange={document.handleFieldChange}
          error={document.errors['invoice_address'] ? true : false}
          helperText={document.errors['invoice_address'] ?? ''}
          fullWidth
        />
        <TextField
          label='First line of address'
          name={'address_first_line'}
          value={document.state.address_first_line}
          onChange={document.handleFieldChange}
          error={document.errors['address_first_line'] ? true : false}
          helperText={document.errors['address_first_line'] ?? ''}
          fullWidth
        />

        <TextField
          label='Town'
          name={'address_town'}
          value={document.state.address_town}
          onChange={document.handleFieldChange}
          error={document.errors['address_town'] ? true : false}
          helperText={document.errors['address_town'] ?? ''}
          fullWidth
        />
        <TextField
          label='City'
          name={'address_city'}
          value={document.state.address_city}
          onChange={document.handleFieldChange}
          error={document.errors['address_city'] ? true : false}
          helperText={document.errors['address_city'] ?? ''}
          fullWidth
        />
        <TextField
          label='County'
          name={'address_county'}
          value={document.state.address_county}
          onChange={document.handleFieldChange}
          error={document.errors['address_county'] ? true : false}
          helperText={document.errors['address_county'] ?? ''}
          fullWidth
        />
        <TextField
          label='Postcode'
          name={'address_postcode'}
          value={document.state.address_postcode}
          onChange={document.handleFieldChange}
          error={document.errors['address_postcode'] ? true : false}
          helperText={document.errors['address_postcode'] ?? ''}
          fullWidth
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={document.state.ten_or_more_employees}
              onChange={e => {
                const event = {
                  target: {
                    name: e.target.name,
                    value: e.target.checked ? true : false,
                  },
                }

                document.handleFieldChange(event)
              }}
              name='ten_or_more_employees'
              color='primary'
            />
          }
          label='10 or more employees?'
        />

        <Box mt={4}>
          <Typography variant='h6'>Direct Debit</Typography>
          <TextField
            label='Account Holder'
            name={'dd_account_holder'}
            value={document.state.dd_account_holder}
            onChange={document.handleFieldChange}
            error={document.errors['dd_account_holder'] ? true : false}
            helperText={document.errors['dd_account_holder'] ?? ''}
            fullWidth
          />
          <TextField
            label='Bank Name'
            name={'dd_bank_name'}
            value={document.state.dd_bank_name}
            onChange={document.handleFieldChange}
            error={document.errors['dd_bank_name'] ? true : false}
            helperText={document.errors['dd_bank_name'] ?? ''}
            fullWidth
          />
          <TextField
            label='Bank Manager Name'
            name={'dd_bank_manager_name'}
            value={document.state.dd_bank_manager_name}
            onChange={document.handleFieldChange}
            error={document.errors['dd_bank_manager_name'] ? true : false}
            helperText={document.errors['dd_bank_manager_name'] ?? ''}
            fullWidth
          />
          <TextField
            label='Bank Address 1st Line'
            name={'dd_bank_address_1'}
            value={document.state.dd_bank_address_1}
            onChange={document.handleFieldChange}
            error={document.errors['dd_bank_address_1'] ? true : false}
            helperText={document.errors['dd_bank_address_1'] ?? ''}
            fullWidth
          />
          <TextField
            label='Bank Address 2nd Line'
            name={'dd_bank_address_2'}
            value={document.state.dd_bank_address_2}
            onChange={document.handleFieldChange}
            error={document.errors['dd_bank_address_2'] ? true : false}
            helperText={document.errors['dd_bank_address_2'] ?? ''}
            fullWidth
          />
          <TextField
            label='Bank Postcode'
            name={'dd_bank_postcode'}
            value={document.state.dd_bank_postcode}
            onChange={document.handleFieldChange}
            error={document.errors['dd_bank_postcode'] ? true : false}
            helperText={document.errors['dd_bank_postcode'] ?? ''}
            fullWidth
          />
          <TextField
            label='Account Number'
            name={'dd_acc_no'}
            value={document.state.dd_acc_no}
            onChange={document.handleFieldChange}
            error={document.errors['dd_acc_no'] ? true : false}
            helperText={document.errors['dd_acc_no'] ?? ''}
            fullWidth
          />
          <TextField
            label='Sort Code'
            name={'dd_sort'}
            value={document.state.dd_sort}
            onChange={document.handleFieldChange}
            error={document.errors['dd_sort'] ? true : false}
            helperText={document.errors['dd_sort'] ?? ''}
            fullWidth
          />
        </Box>

        <FormControlLabel
          control={
            <Checkbox
              checked={document.state.include_leasing}
              onChange={e => {
                const event = {
                  target: {
                    name: e.target.name,
                    value: e.target.checked ? true : false,
                  },
                }

                document.handleFieldChange(event)
              }}
              name='include_leasing'
              color='primary'
            />
          }
          label='Include leasing document?'
        />
        {document.state.include_leasing && (
          <Box>
            <TextField
              label='Business Type'
              name={'business_type'}
              value={document.state.business_type}
              onChange={document.handleFieldChange}
              error={document.errors['business_type'] ? true : false}
              helperText={document.errors['business_type'] ?? ''}
              fullWidth
            />

            <TextField
              label='Owner DOB'
              name={'owner_dob'}
              value={document.state.owner_dob}
              onChange={document.handleFieldChange}
              type='text'
              fullWidth
              error={document.errors['owner_dob'] ? true : false}
              helperText={document.errors['owner_dob'] ?? ''}
            />

            <TextField
              label='Date Established'
              name={'date_established'}
              value={document.state.date_established}
              onChange={document.handleFieldChange}
              type='date'
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              error={document.errors['date_established'] ? true : false}
              helperText={document.errors['date_established'] ?? ''}
            />
          </Box>
        )}

        <Box mt={4}>
          <Typography variant='h6'>Customer Telephone Account Details</Typography>
          <EditableTableField
            name='numbers'
            data={document.state.numbers}
            fields={document.numberFields}
            onChange={document.handleEditNumber}
            onAdd={document.handleAddNumber}
            onDelete={document.handleDeleteNumber}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {document.errorMessage && (
          <Typography color='error'>{document.errorMessage}</Typography>
        )}
        <Button onClick={onClose}>Back</Button>
        <Button
          onClick={() => {
            document.handleSubmit(quote.id, document.state).then(r => {
              if (r) {
                addToast('Document(s) sent for signature')
                onClose()
              }
            })
          }}
          disabled={document.submitting}>
          Generate
        </Button>
      </DialogActions>
    </>
  )
}

export default DocumentsForm
