import { Button, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import CoveringLetterQuestion from './CoveringLetterQuestion'

function CoveringLetterQuestions({ questions, setSnackbar }) {
  const [displayQuestions, setDisplayQuestions] = useState(questions)

  const handleAddQuestion = () => {
    const newQuestions = Array.from(displayQuestions)
    newQuestions.push({
      id: '',
      content: '',
      answers: ['', ''],
    })
    setDisplayQuestions(newQuestions)
  }

  return (
    <React.Fragment>
      <Typography variant='h4'>Covering Letter Questions</Typography>

      <p>
        These questions are displayed to the sales person on quote proposal page. If the
        above covering letter uses the [[questions]] keyword then these questions and the
        selected answers will be injected into the covering letter when it is generated.
      </p>

      {displayQuestions.map((x, index) => (
        <CoveringLetterQuestion
          index={index}
          defaultQuestion={x}
          defaultAnswers={x.answers}
          setSnackbar={setSnackbar}
        />
      ))}

      <Button color='primary' onClick={handleAddQuestion}>
        Add Question
      </Button>
    </React.Fragment>
  )
}

CoveringLetterQuestions.propTypes = {
  questions: PropTypes.array,
  setSnackbar: PropTypes.func,
}

CoveringLetterQuestions.defaultProps = {
  disabled: false,
}

export default CoveringLetterQuestions
