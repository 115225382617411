import React from 'react'
import ItemButton from './components/ItemButton'
import ItemContent from './components/ItemContent'
import TwoColumns from './components/TwoColumns'

function Demo({ link }) {
  return (
    <TwoColumns>
      <ItemContent>Book a demo call with a Pragma Account Manager</ItemContent>

      <ItemContent>
        <ItemButton href={link} label='Book Demo' />
      </ItemContent>
    </TwoColumns>
  )
}

export default Demo
