import { Box } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import QuotePdf from 'components/quotes/QuotePdf'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PDFJS from 'pdfjs-dist'
import config from 'config'

PDFJS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.js`

const useStyles = makeStyles(theme => ({
  loader: {
    marginTop: theme.spacing(12),
    textAlign: 'center',
  },
}))

export default function Shared() {
  const classes = useStyles()
  const { id } = useParams()
  const { loading, setLoading } = useContext(LoadingContext)
  const [pdf, setPdf] = useState()

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)

      const res = await PDFJS.getDocument(config.API_URL + `/api/quotes/share/${id}`)
        .promise
      setPdf(res)
      setLoading(false)
    }

    fetchData()
  }, [setLoading, id])

  if (loading) {
    return (
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    )
  }

  if (!pdf) {
    return (
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <Box mt={4}>
      <QuotePdf pdf={pdf} />
    </Box>
  )
}
