import { makeStyles } from '@material-ui/core/styles'
import { network as apiSave } from 'api/quote-builder'
import { QuoteContext } from 'contexts/QuoteContext'
import React, { useContext, useState } from 'react'
import Accordion from './components/Accordion'
import BundledMinutesItem from './components/DropDownItem'
import HardwareItem from './components/HardwareItem'
import StandardItem from './components/StandardItem'
import BroadbandItem from './components/BroadbandItem'

const useStyles = makeStyles(theme => ({
  section: {
    marginBottom: theme.spacing(3),
  },
  items: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
    marginTop: theme.spacing(3),
    gridGap: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(auto-fit, 240px)',
    },
  },
}))

function NetworkStep() {
  const { state, dispatch } = useContext(QuoteContext)
  const classes = useStyles()
  const [expanded, setExpanded] = useState('network-hardware-favourites')

  const handleChangeAccordion = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handleChangeNetworkHardware = (category, id, quantity, paymentType) => {
    const item = {
      id: id,
      quantity: quantity,
      payment_type: paymentType,
    }

    if (category in state.networkHardware && state.networkHardware[category].length) {
      const itemIndex = state.networkHardware[category].findIndex(obj => obj.id === id)
      if (quantity > 0) {
        if (itemIndex === -1) {
          state.networkHardware[category].push(item)
        } else {
          state.networkHardware[category][itemIndex].quantity = quantity
          state.networkHardware[category][itemIndex].payment_type = paymentType
        }
      } else {
        const data = state.networkHardware[category]
        data.splice(itemIndex, 1)
        state.networkHardware[category] = data
      }
    } else {
      if (quantity > 0) {
        state.networkHardware[category] = [item]
      }
    }

    dispatch({
      type: 'SET_NETWORK_HARDWARE',
      payload: Object.assign({}, state.networkHardware),
    })
  }

  const handleChangeNetworkServices = (id, quantity) => {
    const item = {
      id: id,
      quantity: quantity,
    }

    const itemIndex = state.networkServices.findIndex(obj => obj.id === id)
    const data = state.networkServices

    if (quantity > 0) {
      itemIndex === -1 ? data.push(item) : (data[itemIndex].quantity = quantity)
    } else {
      data.splice(itemIndex, 1)
    }

    dispatch({
      type: 'SET_NETWORK_SERVICES',
      payload: data,
    })
  }

  const handleChangeBroadbandPackage = (id, quantities, router) => {
    const data = state.broadbandPackages.filter(x => x.id !== id)

    const quantityArray = Object.keys(quantities)
      .filter(x => quantities[x])
      .map(x => ({
        id: id,
        quantity: quantities[x],
        type: x,
        router: '',
      }))

    if (quantityArray[0]) {
      quantityArray[0].router = router
    }

    quantityArray.map(x => data.push(x))

    dispatch({
      type: 'SET_BROADBAND_PACKAGES',
      payload: data,
    })
  }

  const handleChangeServices = event => {
    const { name, value } = event.target
    const newData = { ...state.services, [name]: { value: value } }
    dispatch({ type: 'SET_SERVICES', payload: newData })
  }

  return (
    <div className={classes.section}>
      <Accordion
        key={`favourites`}
        title='Favourites'
        id={`favourites`}
        expanded={expanded === `network-hardware-favourites`}
        onChange={handleChangeAccordion(`network-hardware-favourites`)}>
        <div className={classes.items}>
          {Object.keys(state.options.network_hardware).map((category, index) => {
            return Object.keys(state.options.network_hardware[category].items)
              .filter(
                item =>
                  state.options.network_hardware[category]['items'][item]['is_favourite']
              )
              .map((item, index) => {
                return (
                  <HardwareItem
                    key={index}
                    options={state.options.network_hardware}
                    hardware={state.networkHardware}
                    category={category}
                    item={item}
                    onChangeHardware={handleChangeNetworkHardware}
                  />
                )
              })
          })}

          {Object.keys(state.options.network_services)
            .filter(item => {
              return item.is_favourite
            })
            .map((key, index) => {
              return (
                <StandardItem
                  key={index}
                  options={state.options.network_services}
                  selectedItems={state.networkServices}
                  id={key}
                  onChange={handleChangeNetworkServices}
                />
              )
            })}

          {Object.keys(state.options.broadband_packages)
            .filter(item => state.options.broadband_packages[item]['is_favourite'])
            .map((key, index) => {
              return (
                <BroadbandItem
                  key={index}
                  options={state.options.broadband_packages}
                  selectedItems={state.broadbandPackages}
                  id={key}
                  onChange={handleChangeBroadbandPackage}
                />
              )
            })}

          {Object.keys(state.options.bundled_minutes).filter(
            x => Object.keys(state.options.bundled_minutes[x].items).length > 0
          ).length > 0 && (
            <BundledMinutesItem
              options={state.options.bundled_minutes}
              value={state.services.bundled_minutes.value}
              fieldName='bundled_minutes'
              label='Bundled Minutes'
              onChange={handleChangeServices}
            />
          )}
        </div>
      </Accordion>

      {Object.keys(state.options.network_hardware).map((category, index) => {
        return (
          <Accordion
            key={`network_hardware-${index}`}
            title={state.options.network_hardware[category].name}
            id={`network_hardware-${index}`}
            expanded={expanded === `network_hardware-${index}`}
            onChange={handleChangeAccordion(`network_hardware-${index}`)}>
            <div className={classes.items}>
              {Object.keys(state.options.network_hardware[category].items).map(
                (item, index) => {
                  return (
                    <HardwareItem
                      key={index}
                      options={state.options.network_hardware}
                      hardware={state.networkHardware}
                      services={state.services}
                      category={category}
                      item={item}
                      onChangeHardware={handleChangeNetworkHardware}
                    />
                  )
                }
              )}
            </div>
          </Accordion>
        )
      })}

      {Object.keys(state.options.network_services).length > 0 && (
        <Accordion
          title='Engineering'
          id='engineering'
          expanded={expanded === 'engineering'}
          onChange={handleChangeAccordion('engineering')}>
          <div className={classes.items}>
            {Object.keys(state.options.network_services).map((key, index) => {
              return (
                <StandardItem
                  key={index}
                  options={state.options.network_services}
                  selectedItems={state.networkServices}
                  id={key}
                  onChange={handleChangeNetworkServices}
                />
              )
            })}
          </div>
        </Accordion>
      )}

      {Object.keys(state.options.broadband_packages).length > 0 && (
        <Accordion
          title='Broadband & Connectivity'
          id='broadband'
          expanded={expanded === 'broadband'}
          onChange={handleChangeAccordion('broadband')}>
          <div className={classes.items}>
            {Object.keys(state.options.broadband_packages).map((key, index) => {
              return (
                <BroadbandItem
                  key={index}
                  options={state.options.broadband_packages}
                  selectedItems={state.broadbandPackages}
                  id={key}
                  onChange={handleChangeBroadbandPackage}
                />
              )
            })}
          </div>
        </Accordion>
      )}

      {Object.keys(state.options.bundled_minutes).filter(
        x => Object.keys(state.options.bundled_minutes[x].items).length > 0
      ).length > 0 && (
        <Accordion
          title='Bundled Minutes'
          id='bundlemins'
          expanded={expanded === 'bundlemins'}
          onChange={handleChangeAccordion('bundlemins')}>
          <div className={classes.items}>
            <BundledMinutesItem
              options={state.options.bundled_minutes}
              value={state.services.bundled_minutes.value}
              fieldName='bundled_minutes'
              label='Bundled Minutes'
              onChange={handleChangeServices}
            />
          </div>
        </Accordion>
      )}
    </div>
  )
}

const save = async (id, hardware, networkServices, broadband, bundledMinutes) => {
  return await apiSave(id, {
    hardware: Object.keys(hardware)
      .map(key => {
        return hardware[key]
      })
      .flat(),
    network_services: networkServices,
    broadband: broadband,
    bundled_minutes: bundledMinutes,
  })
}

export { save }
export default NetworkStep
