import React from 'react'
import Page from 'components/pdf/Page'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => {
  return {
    page: {
      width: '100%',
      display: 'block',
      margin: 'auto',
      [theme.breakpoints.up('md')]: {
        width: '50%',
      },
    },
  }
})

function QuotePdf({ pdf }) {
  const classes = useStyles()

  return (
    <>
      {pdf && (
        <div>
          {Array.from(Array(pdf.numPages)).map((_, index) => (
            <Page
              className={classes.page}
              key={index}
              pdf={pdf}
              pageNum={index + 1}
              scale={4.0}
            />
          ))}
        </div>
      )}
    </>
  )
}

export default QuotePdf
