import Container from '@material-ui/core/Container'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { customerDetails as apiSave } from 'api/quote-builder'
import { QuoteContext } from 'contexts/QuoteContext'
import React, { useContext } from 'react'
import * as Yup from 'yup'
import Title from './components/Title'

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(8),
    margin: 'auto',
    [theme.breakpoints.up('lg')]: {
      width: '60%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '40%',
    },
  },
  form: {
    marginTop: theme.spacing(1),
  },
  formControl: {
    minWidth: '100%',
    marginTop: theme.spacing(3),
  },
}))

function CustomerDetailsStep() {
  const { state, dispatch } = useContext(QuoteContext)
  const classes = useStyles()

  const handleChange = event => {
    const { name, value } = event.target
    const newData = { ...state.customerDetails, [name]: { value: value } }
    dispatch({ type: 'SET_CUSTOMER_DETAILS', payload: newData })
  }

  return (
    <form className={classes.form} noValidate>
      <Paper className={classes.paper}>
        <Container component='main' maxWidth='sm'>
          <Title>Customer Details</Title>
          <TextField
            variant='standard'
            margin='normal'
            required
            fullWidth
            id='deal_name'
            label='Deal Name'
            name='deal_name'
            defaultValue={state.customerDetails.deal_name.value}
            autoFocus
            onChange={handleChange.bind(this)}
            error={state.customerDetails.deal_name.error ? true : false}
            helperText={state.customerDetails.deal_name.error}
          />
          <TextField
            variant='standard'
            margin='normal'
            required
            fullWidth
            id='customer_business_name'
            label='Business Name'
            name='customer_business_name'
            defaultValue={state.customerDetails.customer_business_name.value}
            onChange={handleChange.bind(this)}
            error={state.customerDetails.customer_business_name.error ? true : false}
            helperText={state.customerDetails.customer_business_name.error}
          />
          <TextField
            variant='standard'
            margin='normal'
            required
            fullWidth
            id='customer_first_name'
            label='Customer First Name'
            name='customer_first_name'
            defaultValue={state.customerDetails.customer_first_name.value}
            onChange={handleChange.bind(this)}
            error={state.customerDetails.customer_first_name.error ? true : false}
            helperText={state.customerDetails.customer_first_name.error}
          />
          <TextField
            variant='standard'
            margin='normal'
            required
            fullWidth
            id='customer_last_name'
            label='Customer Last Name'
            name='customer_last_name'
            defaultValue={state.customerDetails.customer_last_name.value}
            onChange={handleChange.bind(this)}
            error={state.customerDetails.customer_last_name.error ? true : false}
            helperText={state.customerDetails.customer_last_name.error}
          />
          <TextField
            variant='standard'
            margin='normal'
            required
            fullWidth
            type='customer_email'
            id='email'
            label='Customer Email'
            name='customer_email'
            defaultValue={state.customerDetails.customer_email.value}
            onChange={handleChange.bind(this)}
            error={state.customerDetails.customer_email.error ? true : false}
            helperText={state.customerDetails.customer_email.error}
          />

          {(state.options.features.tariffs_enabled ||
            state.customerDetails.tariff.value) && (
            <FormControl variant='standard' className={classes.formControl}>
              <InputLabel id='tariff_label'>Tariff</InputLabel>
              <Select
                labelId='tariff_label'
                id='tariff'
                name='tariff'
                value={state.customerDetails.tariff.value}
                error={state.customerDetails.tariff.error ? true : false}
                onChange={handleChange.bind(this)}
                label='Tariff'>
                <MenuItem value=''>
                  <em>Please Select</em>
                </MenuItem>
                {Object.keys(state.options.tariffs).map((key, index) => {
                  return (
                    <MenuItem key={index} value={state.options.tariffs[key].id}>
                      {state.options.tariffs[key].name}
                    </MenuItem>
                  )
                })}
              </Select>
              {state.customerDetails.tariff.error && (
                <FormHelperText error={true}>
                  {state.customerDetails.tariff.error}
                </FormHelperText>
              )}
            </FormControl>
          )}
        </Container>
      </Paper>
    </form>
  )
}

const validate = async (options, values, dispatch) => {
  try {
    const rules = {
      deal_name: Yup.string().min(2).required(),
      customer_business_name: Yup.string().min(2).required(),
      customer_first_name: Yup.string().min(2).required(),
      customer_last_name: Yup.string().min(2).required(),
      customer_email: Yup.string().email().required(),
    }

    if (options.features.tariffs_enabled || values.tariff.value) {
      rules.tariff = Yup.string().required()
    }

    await Yup.object().shape(rules).validate(
      {
        deal_name: values.deal_name.value,
        customer_business_name: values.customer_business_name.value,
        customer_first_name: values.customer_first_name.value,
        customer_last_name: values.customer_last_name.value,
        customer_email: values.customer_email.value,
        tariff: values.tariff.value,
      },
      { abortEarly: false }
    )
    return true
  } catch (error) {
    const errors = {}
    error.inner.map(item => {
      var words = item.message.replace(/([A-Z])/g, ' $1')
      return (errors[item.path] = {
        error: (words.charAt(0).toUpperCase() + words.slice(1)).replace(/_/g, ' '),
      })
    })
    dispatch({ type: 'SET_CUSTOMER_DETAILS', payload: { ...values, ...errors } })

    return false
  }
}

const save = async (id, values) => {
  return await apiSave(id, {
    deal_name: values.deal_name.value,
    business_name: values.customer_business_name.value,
    first_name: values.customer_first_name.value,
    last_name: values.customer_last_name.value,
    email: values.customer_email.value,
    tariff: values.tariff.value,
  })
}

export { validate, save }
export default CustomerDetailsStep
