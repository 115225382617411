import { ButtonBase, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CloudDownload } from '@material-ui/icons'
import React from 'react'

const useStyles = makeStyles(theme => ({
  image: {
    border: 'solid red 1px',
    borderStyle: 'solid',
    borderColor: theme.palette.backgroundMain,
    borderWidth: '2px',
    transition: theme.transitions.create('border'),
    margin: theme.spacing(1),
    textAlign: 'center',
    position: 'relative',
    height: 180,
    [theme.breakpoints.down('xs')]: {
      height: 100,
    },
    '&:hover, &$focusVisible': {
      borderColor: theme.palette.primary.main,

      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15,
      },
      '& $filename': {
        display: 'none',
      },
      '& $imageMarked': {
        display: 'block',
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.6,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    width: 120,
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
  },
  filename: {
    wordWrap: 'break-word',
    lineHeight: '1.1',
    transition: theme.transitions.create('opacity', { duration: 750 }),
  },
  imageMarked: {
    display: 'none',
    transition: theme.transitions.create('opacity', { duration: 750 }),
  },
}))

const displayFilename = filename => {
  const maxLength = 70

  if (filename.length <= maxLength) {
    return filename
  }

  const extension = filename.substr(filename.length - 3)
  return filename.substring(0, maxLength - 6) + '...' + extension
}

function File({ file }) {
  const classes = useStyles()

  return (
    <ButtonBase
      focusRipple
      href={file.url}
      className={classes.image}
      focusVisibleClassName={classes.focusVisible}
      style={{
        width: 120,
      }}>
      <span
        className={classes.imageSrc}
        style={{
          backgroundImage: `url(${file.thumb})`,
        }}
      />
      <span className={classes.imageBackdrop} />
      <span className={classes.imageButton}>
        <Typography
          component='span'
          variant='subtitle1'
          color='inherit'
          className={classes.imageTitle}>
          <div className={classes.filename}>{displayFilename(file.filename)}</div>
          <div className={classes.imageMarked}>
            <CloudDownload />
          </div>
        </Typography>
      </span>
    </ButtonBase>
  )
}

export default File
