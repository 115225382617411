import { Box, Button } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useState } from 'react'

function EditModeActions({ handleSave, handleDelete }) {
  const { loading } = useContext(LoadingContext)
  const [deleteOpen, setDeleteOpen] = useState(false)

  const handleOpenDeleteDialog = id => {
    setDeleteOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteOpen(false)
  }

  const handleClickDelete = async () => {
    await handleDelete()
    setDeleteOpen(false)
  }

  return (
    <React.Fragment>
      <Box display='flex'>
        <Button
          disabled={loading}
          onClick={handleSave}
          variant='contained'
          color='primary'>
          Save
        </Button>

        <Box flexGrow='1'></Box>

        <Button
          disabled={loading}
          onClick={handleOpenDeleteDialog}
          variant='contained'
          color='secondary'>
          Delete
        </Button>
      </Box>

      <Dialog open={deleteOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle id='alert-dialog-title'>{'Library Category Delete'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to delete this library category, any linked posts will
            this category link removed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleCloseDeleteDialog} color='primary'>
            Cancel
          </Button>
          <Button disabled={loading} onClick={handleClickDelete} color='secondary'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default EditModeActions
