import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'

const useStyles = makeStyles(theme => ({
  loader: {
    'z-index': 2000,
    margin: 0,
    top: 0,
    right: 0,
    bottom: 'auto',
    width: '100%',
    position: 'fixed',
  },
}))

const LoadingContext = React.createContext()
const WithLoading = ({ children }) => {
  const [loading, setLoading] = useState(false)
  const classes = useStyles()

  const defaultContext = {
    loading,
    setLoading,
  }
  return (
    <LoadingContext.Provider value={defaultContext}>
      {loading && <LinearProgress className={classes.loader} />}
      {children}
    </LoadingContext.Provider>
  )
}

export { LoadingContext }
export default WithLoading
