import config from 'config'

export async function list(limit = 15, page = 1) {
  try {
    const result = await fetch(
      config.API_URL + `/api/blog?per_page=${limit}&page=${page}`
    )
    return await result.json()
  } catch (e) {
    return false
  }
}

export async function get(slug) {
  try {
    const result = await fetch(config.API_URL + `/api/blog/${slug}`)
    return await result.json()
  } catch (e) {
    return false
  }
}
