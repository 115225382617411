import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'

function DeleteDialog({ loading, quoteId, open, handleClose, handleSubmit }) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id='alert-dialog-title'>{'Quote Lost'}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          Are you sure you want to set this quote as lost?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleClose} color='primary'>
          Cancel
        </Button>
        <Button
          disabled={loading}
          onClick={() => handleSubmit(quoteId)}
          color='secondary'
          autoFocus>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteDialog
