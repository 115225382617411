import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import MuiAlert from '@material-ui/lab/Alert'
import { create, get, update } from 'api/tariffs'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  orm: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    minWidth: '100%',
    marginTop: theme.spacing(3),
  },
}))

function Form(props) {
  const { id } = useParams()
  const { loading, setLoading } = useContext(LoadingContext)
  const [hideContent, setHideContent] = useState(true)
  const [values, setValues] = useState({
    name: { value: '', error: '' },
    type: { value: 'percentage', error: '' },
    value: { value: '', error: '' },
  })
  const [errors, setErrors] = useState([])
  const classes = useStyles()

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      const tariff = await get(id)
      const dataValues = {
        name: { value: tariff.data.name },
        type: { value: tariff.data.type },
        value: { value: tariff.data.value },
      }
      setValues(dataValues)
      setLoading(false)
      setHideContent(false)
    }

    if (id !== undefined) {
      fetchData()
    } else {
      setLoading(false)
      setHideContent(false)
    }
  }, [id, setValues, setLoading])

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: { value: value } })
  }

  const handleSubmit = async event => {
    event.preventDefault()

    setLoading(true)
    const body = {
      name: values.name.value,
      type: values.type.value,
      value: values.value.value,
    }

    const result = id ? await update(id, body) : await create(body)
    if (result.success) {
      setLoading(false)
      props.history.push({
        pathname: '/tariffs',
        state: {
          severity: 'success',
          message: result.message,
        },
      })
    } else {
      setErrors(result.errors)
      setLoading(false)
    }
  }

  if (hideContent) {
    return null
  }

  return (
    <div>
      <Typography variant='h3' gutterBottom>
        {id ? 'Update Tariff' : 'Create Tariff'}
      </Typography>
      <Grid item md={5}>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <Paper className={classes.paper}>
            <TextField
              variant='standard'
              margin='normal'
              required
              fullWidth
              id='name'
              label='Name'
              name='name'
              defaultValue={values.name.value}
              autoFocus
              onChange={handleChange.bind(this)}
            />
            <TextField
              variant='standard'
              margin='normal'
              required
              fullWidth
              type='value'
              id='value'
              label='Percentage'
              name='value'
              defaultValue={values.value.value}
              onChange={handleChange.bind(this)}
            />
          </Paper>

          {errors.length > 0 && (
            <MuiAlert severity='error'>
              {errors.map(error => {
                return <div>{error[1]}</div>
              })}
            </MuiAlert>
          )}
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            disabled={loading}
            className={classes.submit}>
            {id ? 'Update' : 'Create'}
          </Button>
        </form>
      </Grid>
    </div>
  )
}

export default Form
