import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect } from 'react'

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: theme.breakpoints.width('md'),
  },
  paper: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  text: {
    marginBottom: theme.spacing(3),
  },
}))

function AwaitingVerification(props) {
  const { setLoading } = useContext(LoadingContext)
  const classes = useStyles()

  useEffect(() => {
    setLoading(false)
  })

  return (
    <div className={classes.container}>
      <Typography variant='h3' gutterBottom>
        Pending Verification
      </Typography>

      <Paper className={classes.paper}>
        <Typography className={classes.text}>
          Your account is currently pending verification, an admin on your account has
          been notified. Once you account has been approved you will be able to access
          Pragma Hub.
        </Typography>

        <Typography className={classes.text}>
          Once approved you will receive your welcome email, you will then be able to
          fully access Pragma Hub.
        </Typography>
      </Paper>
    </div>
  )
}

export default AwaitingVerification
