import { AuthContext } from 'contexts/AuthContext'
import React, { useContext } from 'react'
import DistributorSalesDash from './DistributorSalesDash'
import ResellerSalesDash from './ResellerSalesDash'

function SalesDash() {
  const { user } = useContext(AuthContext)
  return user.type === 'reseller' ? <ResellerSalesDash /> : <DistributorSalesDash />
}

export default SalesDash
