import {
  Avatar,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core'
import MuiList from '@material-ui/core/List'
import { makeStyles } from '@material-ui/core/styles'
import { LoadingContext } from 'contexts/LoadingContext'
import { format } from 'date-fns'
import React, { useContext, useEffect, useState, useCallback } from 'react'
import { NavLink } from 'react-router-dom'
import SubjectIcon from '@material-ui/icons/Subject'
import { list } from 'api/notifications'

const useStyles = makeStyles(theme => {
  return {
    table: {
      minWidth: 650,
    },
    pagination: {
      marginBottom: '60px',
    },
    fab: {
      margin: 0,
      top: 'auto',
      right: 20,
      bottom: 20,
      left: 'auto',
      position: 'fixed',
    },
    notificationUnread: {
      color: theme.palette.primary[900],
    },
    viewMore: {
      display: 'block',
      margin: 'auto',
    },
  }
})

function List() {
  const classes = useStyles()

  const { setLoading } = useContext(LoadingContext)

  const [imagesLoaded, setImagesLoaded] = useState(false)
  const [hideContent, setHideContent] = useState(true)
  const [notifications, setNotifications] = useState([])
  const [pageNum, setPageNum] = useState(1)
  const [hasMore, setHasMore] = useState(true)

  const fetchNotifications = useCallback(
    async pageNum => {
      setLoading(true)
      const res = await list(10, pageNum)
      setPageNum(pageNum => pageNum + 1)
      setHasMore(res.links?.next ? true : false ?? false)
      setNotifications(notifications => notifications.concat(res.data ?? []))
      setHideContent(false)
      setLoading(false)
    },
    [setPageNum, setHasMore, setNotifications, setHideContent, setLoading]
  )

  useEffect(() => {
    fetchNotifications()
  }, [fetchNotifications])

  // preload all thumbails before render, to avoid content reflow
  useEffect(() => {
    Promise.all(
      notifications.map(notification =>
        notification.image_thumbnail_path
          ? new Promise((resolve, reject) => {
              const img = new Image()
              img.src = '/api/' + notification.image_thumbnail_path
              img.addEventListener('load', () => {
                resolve(img)
              })
              img.addEventListener('error', () => {
                reject(img)
              })
            })
          : Promise.resolve()
      )
    )
      .then(promises => {
        setImagesLoaded(true)
        setLoading(false)
      })
      .catch(err => {
        setImagesLoaded(true)
      })
  }, [notifications, setLoading, setImagesLoaded])

  return (
    imagesLoaded &&
    !hideContent && (
      <>
        <Typography variant='h3' gutterBottom>
          Notifications
        </Typography>
        {notifications.length > 0 ? (
          <MuiList component={Paper}>
            {notifications.map((row, index) => (
              <ListItem
                button
                component={NavLink}
                to={`/notifications/${row.id}`}
                className={row.read_at ? '' : classes.notificationUnread}
                key={index}>
                <ListItemAvatar>
                  {row.image_thumbnail_path ? (
                    <Avatar src={'/api/' + row.image_thumbnail_path} />
                  ) : (
                    <Avatar>
                      <SubjectIcon></SubjectIcon>
                    </Avatar>
                  )}
                </ListItemAvatar>
                <ListItemText>
                  <Typography variant='h6'>{row.title}</Typography>
                  <Typography variant='subtitle1'>
                    {format(new Date(row.sent_at.datetime), 'do MMMM yyyy, HH:mm:ss')}
                  </Typography>
                  <Typography variant='body1'>{row.summary}</Typography>
                </ListItemText>
              </ListItem>
            ))}
          </MuiList>
        ) : (
          <></>
        )}

        {hasMore && (
          <Button
            className={classes.viewMore}
            onClick={() => {
              fetchNotifications(pageNum)
            }}>
            View more...
          </Button>
        )}
      </>
    )
  )
}

export default List
