import { Box } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import NumberRangeRow from 'components/number-porting/geographic-port/numbers-to-port/NumberRangeRow'
import { GeographicPortContext } from 'contexts/GeographicPortContext'
import React, { useContext } from 'react'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(4),
  },
}))

function DDIRanges() {
  const classes = useStyles()
  const { state, dispatch } = useContext(GeographicPortContext)

  const handleAdd = () => {
    dispatch({ type: 'ADD_DDI_RANGE' })
  }

  const handleUpdate = payload => {
    dispatch({ type: 'UPDATE_DDI_RANGE', payload: payload })
  }

  const handleRemove = index => {
    dispatch({ type: 'REMOVE_DDI_RANGE', payload: index })
  }

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5' gutterBottom>
        DDI Ranges
      </Typography>

      {state.number_details.ddi_ranges.map((x, index) => (
        <NumberRangeRow
          key={`single-number-ddis-${index}`}
          index={index}
          stdCode={{ value: x.std_code.value }}
          stdCodeError={
            state.errors.hasOwnProperty(`ddi_ranges.${index}.std_code`)
              ? state.errors[`ddi_ranges.${index}.std_code`]
              : ''
          }
          localNumberStart={{ value: x.local_number_start.value }}
          localNumberStartError={
            state.errors.hasOwnProperty(`ddi_ranges.${index}.local_number_start`)
              ? state.errors[`ddi_ranges.${index}.local_number_start`]
              : ''
          }
          localNumberEnd={{ value: x.local_number_end.value }}
          localNumberEndError={
            state.errors.hasOwnProperty(`ddi_ranges.${index}.local_number_end`)
              ? state.errors[`ddi_ranges.${index}.local_number_end`]
              : ''
          }
          action={{ value: x.action.value }}
          isInService={{ value: x.is_in_service.value }}
          handleUpdate={handleUpdate}
          handleDelete={() => {
            handleRemove(index)
          }}
        />
      ))}

      <Box mt={3}>
        <Button variant='contained' color='primary' onClick={handleAdd}>
          Add
        </Button>
      </Box>
    </Paper>
  )
}

export default DDIRanges
