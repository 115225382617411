import React, { useReducer } from 'react'

const initialState = {
  setup_open: false,
  products_open: false,
  services_open: false,
  apps_open: false,
  number_ports_open: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SETUP_OPEN':
      return { ...state, setup_open: action.payload }
    case 'SET_PRODUCTS_OPEN':
      return { ...state, products_open: action.payload }
    case 'SET_SERVICES_OPEN':
      return { ...state, services_open: action.payload }
    case 'SET_APPS_OPEN':
      return { ...state, apps_open: action.payload }
    case 'SET_NUMBER_PORTING_OPEN':
      return { ...state, number_porting_open: action.payload }
    case 'SET_ORDERS_OPEN':
      return { ...state, orders_open: action.payload }
    default:
      throw new Error(`Unhandle reducer action ${action.type}`)
  }
}

const MainNavContext = React.createContext()
const WithMainNav = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const defaultContext = {
    state,
    dispatch,
  }
  return (
    <MainNavContext.Provider value={defaultContext}>{children}</MainNavContext.Provider>
  )
}

export { MainNavContext }
export default WithMainNav
