import { logout } from 'api/auth'
import React, { useContext, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { AuthContext } from '../../contexts/AuthContext'

function Logout() {
  const { setAuthenticated, setUser } = useContext(AuthContext)

  useEffect(() => {
    const fetchData = async () => {
      if (await logout()) {
        setAuthenticated(false)
        setUser(null)
      }
    }

    fetchData()
  })

  return <Redirect to={`/login`} />
}

export default Logout
