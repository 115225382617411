import React from 'react'
import { Avatar } from '@material-ui/core'

function UserAvatar({ user, className }) {
  return (
    <div className={className}>
      {user.avatar ? (
        <Avatar
          src={`/api/${user.avatar}`}
          alt={`${user.first_name} ${user.last_name}`}
        />
      ) : (
        <Avatar alt={`${user.first_name} ${user.last_name}`} src={user.avatar}>
          {user.first_name.charAt(0)}
          {user.last_name.charAt(0)}
        </Avatar>
      )}
    </div>
  )
}

export default UserAvatar
