import { Box, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { Help } from '@material-ui/icons'
import ContactDetails from 'components/number-porting/geographic-port/ContactDetails'
import Reseller from 'components/number-porting/geographic-port/Reseller'
import PortabilityCheck from 'components/number-porting/PortabilityCheck'
import { AuthContext } from 'contexts/AuthContext'
import { MainNavContext } from 'contexts/MainNavContext'
import { GeographicPortContext } from 'contexts/GeographicPortContext'
import React, { useContext, useState } from 'react'
import Tour from 'reactour'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
  },
}))

const tourSteps = [
  {
    selector: '#stepper',
    content:
      'There are a number of details required to place a number, you will be guided through each of these steps.',
  },
  {
    selector: '#stepper',
    content:
      'The details you have entered will be saved against a "draft" port once you confirm the number details step.',
  },
  {
    selector: '#geographic-port-portability-checker',
    content:
      'The first thing you should do is use the installation checker.  This will tell you whether the number can be ported online and whether the postcode is correct (if the losing provider is BT Openreach).',
  },
  {
    selector: '#geographic-port-content',
    content:
      'On each step you will be prompted to enter certain details required to process the port request.',
  },
  {
    selector: '#geographic-port-next-button',
    content:
      'Once you have entered the details you can use the next button to proceed to the next step.',
  },
  {
    selector: '#number-ports-statuses-menu-item',
    content:
      'Your port will display here once it has been saved as "draft" or submitted.',
  },
]

function BasicDetails() {
  const classes = useStyles()
  const { user } = useContext(AuthContext)
  const { state } = useContext(GeographicPortContext)
  const { dispatch } = useContext(MainNavContext)
  const [showChecker, setShowChecker] = useState(false)
  const [isTourOpen, setIsTourOpen] = useState(false)

  const handleHelp = () => {
    dispatch({ type: 'SET_NUMBER_PORTING_OPEN', payload: true })
    setIsTourOpen(true)
  }

  return (
    <React.Fragment>
      <Box mb={2}>
        <Button size='small' startIcon={<Help />} onClick={handleHelp}>
          How do I place a port request?
        </Button>
      </Box>

      <Paper id='geographic-port-portability-checker' className={classes.paper}>
        <Typography variant='h6'>
          Help reduce your rejection rate: Check your installation postcode
        </Typography>

        <Box mt={2}>
          <Button
            size='small'
            color='primary'
            onClick={() => setShowChecker(!showChecker)}>
            Check Details
          </Button>
        </Box>
      </Paper>

      {showChecker && <PortabilityCheck />}
      {user.type === 'distributor' && !state.id.value && <Reseller />}
      <div id='geographic-port-content'>
        <ContactDetails />
      </div>

      <Tour
        steps={tourSteps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
      />
    </React.Fragment>
  )
}

export default BasicDetails
