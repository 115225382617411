import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { expenditure as apiSave } from 'api/quote-builder'
import { QuoteContext } from 'contexts/QuoteContext'
import React, { useContext } from 'react'
import * as Yup from 'yup'
import Title from './components/Title'

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(8),
    margin: 'auto',
    [theme.breakpoints.up('lg')]: {
      width: '60%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '40%',
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}))

function ExpenditureStep() {
  const { state, dispatch } = useContext(QuoteContext)
  const classes = useStyles()

  const handleChange = event => {
    const { name, value } = event.target
    dispatch({
      type: 'SET_EXPENDITURE',
      payload: { ...state.expenditure, [name]: { value: value } },
    })
  }

  return (
    <form className={classes.form} noValidate>
      <Paper className={classes.paper}>
        <Container component='main' maxWidth='sm'>
          <Title>Current Monthly Expenditure</Title>
          <TextField
            variant='standard'
            margin='normal'
            required
            fullWidth
            id='calls'
            label='Calls'
            name='calls'
            type='number'
            step='0.01'
            defaultValue={state.expenditure.calls.value}
            autoFocus
            onChange={handleChange.bind(this)}
            error={state.expenditure.calls.error ? true : false}
            helperText={state.expenditure.calls.error}
          />
          <TextField
            variant='standard'
            margin='normal'
            required
            fullWidth
            id='services'
            label='Services'
            name='services'
            type='number'
            step='0.01'
            defaultValue={state.expenditure.services.value}
            autoFocus
            onChange={handleChange.bind(this)}
            error={state.expenditure.services.error ? true : false}
            helperText={state.expenditure.services.error}
          />
          <TextField
            variant='standard'
            margin='normal'
            required
            fullWidth
            id='equipment'
            label='Equipment'
            name='equipment'
            type='number'
            step='0.01'
            defaultValue={state.expenditure.equipment.value}
            autoFocus
            onChange={handleChange.bind(this)}
            error={state.expenditure.equipment.error ? true : false}
            helperText={state.expenditure.equipment.error}
          />
          <TextField
            variant='standard'
            margin='normal'
            required
            fullWidth
            id='maintenance'
            label='Maintenance'
            name='maintenance'
            type='number'
            step='0.01'
            defaultValue={state.expenditure.maintenance.value}
            autoFocus
            onChange={handleChange.bind(this)}
            error={state.expenditure.maintenance.error ? true : false}
            helperText={state.expenditure.maintenance.error}
          />
        </Container>
      </Paper>
    </form>
  )
}

const validate = async (values, dispatch) => {
  try {
    await Yup.object()
      .shape({
        expenditure_calls: Yup.number()
          .min(0)
          .required()
          .typeError('you must specify a number'),
        expenditure_services: Yup.number()
          .min(0)
          .required()
          .typeError('you must specify a number'),
        expenditure_equipment: Yup.number()
          .min(0)
          .required()
          .typeError('you must specify a number'),
        expenditure_maintenance: Yup.number()
          .min(0)
          .required()
          .typeError('you must specify a number'),
      })
      .validate(
        {
          expenditure_calls: values.calls.value,
          expenditure_services: values.services.value,
          expenditure_equipment: values.equipment.value,
          expenditure_maintenance: values.maintenance.value,
        },
        { abortEarly: false }
      )
    return true
  } catch (error) {
    const errors = {}
    error.inner.map(item => {
      var words = item.message.replace(/([A-Z])/g, ' $1')
      return (errors[item.path] = {
        error: (words.charAt(0).toUpperCase() + words.slice(1)).replace(/_/g, ' '),
      })
    })
    dispatch({ type: 'SET_EXPENDITURE', payload: { ...values, ...errors } })
    return false
  }
}

const save = async (id, values) => {
  return await apiSave(id, {
    calls: values.calls.value,
    services: values.services.value,
    equipment: values.equipment.value,
    maintenance: values.maintenance.value,
  })
}

export { validate, save }
export default ExpenditureStep
