import { Button } from '@material-ui/core'
import React from 'react'

function EditButton({ editMode, toggleEditMode }) {
  return (
    <Button size='small' variant='contained' onClick={toggleEditMode}>
      {editMode ? 'Cancel Editing' : 'Edit Post'}
    </Button>
  )
}

export default EditButton
