import { QuoteContext } from 'contexts/QuoteContext'
import React, { useContext } from 'react'
import Builder from './Builder'
import ManualBuilder from './ManualBuilder'
import ReadOnly from './ReadOnly'
import MuiAlert from '@material-ui/lab/Alert'
import { AuthContext } from 'contexts/AuthContext'

export default function View(props) {
  const { state } = useContext(QuoteContext)
  const { hasPermission } = useContext(AuthContext)

  const renderBuilder = () => {
    if (state.manualConfig) {
      if (hasPermission('manual_ucp_config')) {
        return <ManualBuilder {...props} />
      } else {
        return (
          <MuiAlert severity='error' style={{ marginBottom: '24px' }}>
            You do not have permission to manually configured UCP quotes.
          </MuiAlert>
        )
      }
    }

    return <Builder {...props} />
  }

  if (!state.rawData.hasOwnProperty('status')) {
    return null
  }

  return (
    <React.Fragment>
      {state.rawData.status !== 'created' && <ReadOnly {...props} />}
      {state.rawData.status === 'created' && (
        <React.Fragment>{renderBuilder()}</React.Fragment>
      )}
    </React.Fragment>
  )
}
