import { Box, Button, TextField } from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import Delete from '@material-ui/icons/Delete'
import PropTypes from 'prop-types'

function EditableTableField({ name, fields, data, onChange, onAdd, onDelete }) {
  return (
    <>
      {data.map((datum, datumIndex) => (
        <Box key={`editable-${datumIndex}`} display='flex' justifyContent='space-between'>
          {fields.map(field => (
            <TextField
              key={`field_${name}_${field.name}_${datumIndex}`}
              label={field.label}
              value={datum[field.name]}
              onChange={e => {
                onChange(field.name, e.target.value, datumIndex)
              }}
            />
          ))}
          <Button
            onClick={() => {
              onDelete(datumIndex)
            }}>
            <Delete />
          </Button>
        </Box>
      ))}

      <Button color='primary' onClick={onAdd}>
        <Add /> Add
      </Button>
    </>
  )
}

EditableTableField.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  data: PropTypes.array,
  onChange: PropTypes.func,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
}

export default EditableTableField
