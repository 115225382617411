import { IconButton, Tooltip } from '@material-ui/core'
import DownIcon from '@material-ui/icons/ArrowDownward'
import UpIcon from '@material-ui/icons/ArrowUpward'
import React from 'react'
import PropTypes from 'prop-types'

function ProductMoveUpDown({ productId, moveUp, moveDown, setProducts, perPage, page }) {
  const handleMoveUp = async () => {
    setProducts(await moveUp(productId, parseInt(perPage), parseInt(page)))
  }

  const handleMoveDown = async () => {
    setProducts(await moveDown(productId, parseInt(perPage), parseInt(page)))
  }

  return (
    <>
      <Tooltip title='Move Up' placement='top'>
        <IconButton color='default' onClick={handleMoveUp}>
          <UpIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title='Move Down' placement='top'>
        <IconButton color='default' onClick={handleMoveDown}>
          <DownIcon />
        </IconButton>
      </Tooltip>
    </>
  )
}

ProductMoveUpDown.propTypes = {
  productId: PropTypes.string.isRequired,
  moveUp: PropTypes.func.isRequired,
  moveDown: PropTypes.func.isRequired,
  setProducts: PropTypes.func.isRequired,
  perPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
}

export default ProductMoveUpDown
