import InputLabel from '@material-ui/core/InputLabel'
import Tooltip from '@material-ui/core/Tooltip'
import { Info } from '@material-ui/icons'
import React from 'react'

function PortActionInputLabel() {
  return (
    <InputLabel id='action_label'>
      <Tooltip
        title='Select Port if you want to move it to our network, cease to cancel the number or remain to leave it on the current network'
        placement='top'>
        <span>
          <Info style={{ marginBottom: '-6px' }} /> Action
        </span>
      </Tooltip>
    </InputLabel>
  )
}

export default PortActionInputLabel
