import { Box } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import AssociatedNumbers from 'components/number-porting/geographic-port/numbers-to-port/AssociatedNumbers'
import DDIRanges from 'components/number-porting/geographic-port/numbers-to-port/DDIRanges'
import MainNumber from 'components/number-porting/geographic-port/numbers-to-port/MainNumber'
import OtherNumbers from 'components/number-porting/geographic-port/numbers-to-port/OtherNumbers'
import SingleNumberDDIs from 'components/number-porting/geographic-port/numbers-to-port/SingleNumberDDIs'
import { GeographicPortContext } from 'contexts/GeographicPortContext'
import React, { useContext } from 'react'

function NumbersToPort() {
  const { state } = useContext(GeographicPortContext)

  return (
    <React.Fragment>
      <MainNumber />
      {state.number_details.line_type.value === 'MULTI' && (
        <>
          <Box mt={3}>
            <Alert severity='info'>
              Please ensure that only numbers that are associated with the main number are
              entered into the below area. Any numbers entered that are not associated
              with the main number will be rejected.
            </Alert>
          </Box>
          <AssociatedNumbers />
          <DDIRanges />
          <SingleNumberDDIs />
          <OtherNumbers />
        </>
      )}
    </React.Fragment>
  )
}

export default NumbersToPort
