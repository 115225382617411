import { FormHelperText } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { GeographicPortContext } from 'contexts/GeographicPortContext'
import React, { useContext } from 'react'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(4),
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
}))

function MainNumberDetails() {
  const classes = useStyles()
  const { state, dispatch, LCPs } = useContext(GeographicPortContext)

  const handleChangeStdCode = event => {
    const sanitizedValue = event.target.value.replace(/\D+/g, '')
    dispatch({ type: 'SET_STD_CODE', payload: sanitizedValue })
  }

  const handleChangeMainNumberLocalNumber = event => {
    const sanitizedValue = event.target.value.replace(/\D+/g, '')
    dispatch({
      type: 'SET_MAIN_NUMBER',
      payload: { key: 'local_number', value: sanitizedValue },
    })
  }

  const handleChangeLineType = event => {
    dispatch({ type: 'SET_LINE_TYPE', payload: event.target.value })
  }

  const handleChangeLCP = event => {
    dispatch({ type: 'SET_LCP', payload: event.target.value })
  }

  const handleChangeLCPOther = event => {
    dispatch({ type: 'SET_LCP_OTHER', payload: event.target.value })
  }

  const handleChangeLcpAccountRef = event => {
    dispatch({ type: 'SET_LCP_ACCOUNT_REF', payload: event.target.value })
  }

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5' gutterBottom>
        Enter Number Details
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={6} xl={3}>
          <TextField
            variant='standard'
            margin='normal'
            fullWidth
            required
            id='std_code'
            label='STD Code (area code)'
            name='std_code'
            inputProps={{ maxLength: 5 }}
            value={state.number_details.std_code.value}
            onChange={handleChangeStdCode}
            error={state.errors.hasOwnProperty('std_code') ? true : false}
            helperText={
              state.errors.hasOwnProperty('std_code') ? state.errors.std_code : ''
            }
          />
        </Grid>

        <Grid item xs={12} lg={6} xl={3}>
          <TextField
            variant='standard'
            margin='normal'
            fullWidth
            required
            inputProps={{ maxLength: 6 }}
            id='main_number_local_number'
            label='Local Number'
            name='main_number_local_number'
            value={state.number_details.main_number.local_number.value}
            onChange={handleChangeMainNumberLocalNumber}
            error={state.errors.hasOwnProperty('local_number') ? true : false}
            helperText={
              state.errors.hasOwnProperty('local_number') ? state.errors.local_number : ''
            }
          />
        </Grid>

        <Grid item xs={12} lg={6} xl={3}>
          <FormControl
            variant='standard'
            className={classes.formControl}
            error={state.errors.hasOwnProperty('line_type') ? true : false}>
            <InputLabel id='line_type_label'>Line Type</InputLabel>
            <Select
              labelId='line_type_label'
              id='line_type'
              name='line_type'
              value={state.number_details.line_type.value}
              onChange={handleChangeLineType}
              label='Line Type'>
              <MenuItem value='SINGLE'>Single</MenuItem>
              <MenuItem value='MULTI'>Multi</MenuItem>
            </Select>
            <FormHelperText>
              {state.errors.hasOwnProperty('line_type') ? state.errors.line_type : ''}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} lg={6} xl={3}>
          <FormControl
            variant='standard'
            className={classes.formControl}
            error={state.errors.hasOwnProperty('lcp') ? true : false}>
            <InputLabel id='lcp_label'>Losing Provider</InputLabel>
            <Select
              labelId='lcp_label'
              id='lcp'
              name='lcp'
              value={state.lcp.value}
              onChange={handleChangeLCP}
              label='Losing Provider'>
              {LCPs.map(x => (
                <MenuItem key={x.key} value={x.key}>
                  {x.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {state.errors.hasOwnProperty('line_type') ? state.errors.lcp : ''}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} lg={6} xl={6}>
          <TextField
            variant='standard'
            margin='normal'
            fullWidth
            id='lcp_account_ref'
            label='Account Number with Losing Provider'
            name='lcp_account_ref'
            value={state.lcp_account_ref.value}
            onChange={handleChangeLcpAccountRef}
            error={state.errors.hasOwnProperty('lcp_account_ref') ? true : false}
            helperText={
              state.errors.hasOwnProperty('lcp_account_ref')
                ? state.errors.lcp_account_ref
                : ''
            }
          />
        </Grid>

        {state.lcp.value === 'OTHER' && (
          <Grid item xs={12} lg={6} xl={6}>
            <TextField
              variant='standard'
              margin='normal'
              fullWidth
              required
              id='lcp_other'
              label='Losing Provider Name'
              name='lcp_other'
              value={state.lcp_other.value}
              onChange={handleChangeLCPOther}
              error={state.errors.hasOwnProperty('lcp_other') ? true : false}
              helperText={
                state.errors.hasOwnProperty('lcp_other') ? state.errors.lcp_other : ''
              }
            />
          </Grid>
        )}
      </Grid>
    </Paper>
  )
}

export default MainNumberDetails
