import Container from '@material-ui/core/Container'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { validate as validateDiscountCode } from 'api/discount-codes'
import { payment as apiSave } from 'api/quote-builder'
import { QuoteContext } from 'contexts/QuoteContext'
import { useContext } from 'react'
import * as Yup from 'yup'
import Title from './components/Title'

const useStyles = makeStyles(theme => ({
  table: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(8),
    margin: 'auto',
    [theme.breakpoints.up('lg')]: {
      width: '60%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '40%',
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  formControl: {
    minWidth: '100%',
    marginTop: theme.spacing(3),
  },
  section: {
    marginBottom: theme.spacing(6),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}))

const contractLengths = ['12', '24', '36', '48', '60', '72', '84']

function PaymentStep() {
  const { state, dispatch } = useContext(QuoteContext)
  const classes = useStyles()

  const handleChange = event => {
    const { name, value } = event.target
    dispatch({
      type: 'SET_PAYMENT',
      payload: { ...state.payment, [name]: { value: value } },
    })
  }

  const handleChangeType = event => {
    dispatch({
      type: 'SET_PAYMENT',
      payload: {
        ...state.payment,
        type: event.target.value,
        contract_length: { value: '', error: '' },
        leasing: { value: '', error: '' },
      },
    })
  }

  return (
    <form className={classes.form} noValidate>
      <Paper className={classes.paper}>
        <Container component='main' maxWidth='sm'>
          <div className={classes.section}>
            <Title>Payment Options</Title>

            <FormControl component='fieldset'>
              <RadioGroup
                row
                name='system_type'
                value={state.payment.type}
                onChange={handleChangeType}>
                <FormControlLabel
                  value='cash'
                  control={<Radio color='primary' />}
                  label='Cash'
                  labelPlacement='end'
                />
                <FormControlLabel
                  value='lease'
                  control={<Radio color='primary' />}
                  label='Lease'
                  labelPlacement='end'
                />
              </RadioGroup>
            </FormControl>

            {state.payment.type === 'lease' && (
              <>
                <FormControl variant='standard' className={classes.formControl}>
                  <InputLabel id='leasing_label'>Leasing</InputLabel>
                  <Select
                    labelId='leasing_label'
                    id='leasing'
                    name='leasing'
                    value={state.payment.leasing.value ?? ''}
                    error={state.payment.leasing.error ? true : false}
                    onChange={handleChange}
                    label='leasing'>
                    <MenuItem value=''>
                      <em>None</em>
                    </MenuItem>
                    {Object.keys(state.options.leasings).map((key, index) => {
                      return (
                        <MenuItem key={index} value={state.options.leasings[key].id}>
                          {state.options.leasings[key].name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                  {state.payment.leasing.error && (
                    <FormHelperText error={true}>
                      {state.payment.leasing.error}
                    </FormHelperText>
                  )}
                </FormControl>

                {state.payment.leasing.value && (
                  <div className={classes.section}>
                    <TextField
                      variant='standard'
                      margin='normal'
                      fullWidth
                      id='early_settlement'
                      label='Early Settlement Figure'
                      name='early_settlement'
                      type='number'
                      step='0.01'
                      defaultValue={state.payment.early_settlement.value}
                      onChange={handleChange}
                      error={state.payment.early_settlement.error ? true : false}
                      helperText={state.payment.early_settlement.error}
                    />
                  </div>
                )}
              </>
            )}

            {state.payment.type === 'cash' && (
              <FormControl variant='standard' className={classes.formControl}>
                <InputLabel id='contract_length_label'>Contract Length</InputLabel>
                <Select
                  labelId='contract_length_label'
                  id='contract_length'
                  name='contract_length'
                  value={state.payment.contract_length.value}
                  error={state.payment.contract_length.error ? true : false}
                  onChange={handleChange}
                  label='Contract Length'>
                  {contractLengths.map(x => (
                    <MenuItem key={x} value={x}>
                      {x} months
                    </MenuItem>
                  ))}
                </Select>
                {state.payment.contract_length.error && (
                  <FormHelperText error={true}>
                    {state.payment.contract_length.error}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          </div>

          {(state.options.features.discount_codes_enabled ||
            state.payment.discount_code.value) && (
            <div className={classes.section}>
              <Title margin={false}>Discount Code</Title>
              <TextField
                variant='standard'
                margin='normal'
                fullWidth
                id='discount_code'
                label='Discount Code'
                name='discount_code'
                type='text'
                defaultValue={state.payment.discount_code.value}
                onChange={handleChange}
                error={state.payment.discount_code.error ? true : false}
                helperText={state.payment.discount_code.error}
              />
            </div>
          )}
        </Container>
      </Paper>
    </form>
  )
}

const validate = async (options, values, dispatch) => {
  let validCode = true
  let validFields = true
  const errors = {}

  try {
    const rules = {
      early_settlement: Yup.number()
        .min(0)
        .optional()
        .typeError('you must specify a number'),
    }

    if (values.type === 'cash') {
      rules.contract_length = Yup.number()
        .min(1)
        .required()
        .typeError('you must select a value')
    }

    if (values.price_by.value === 'user') {
      rules.price_per_user = Yup.number()
        .min(1)
        .required()
        .typeError('you must enter a number')
    }

    if (values.type === 'lease') {
      rules.leasing = Yup.string().required().typeError('you must select a value')
    }

    await Yup.object()
      .shape(rules)
      .validate(
        {
          leasing: values.leasing.value ?? 0,
          early_settlement: values.early_settlement.value ?? 0,
          contract_length: values.contract_length.value ?? 0,
          price_per_user: values.price_per_user.value ?? 0,
        },
        { abortEarly: false }
      )
  } catch (error) {
    error.inner.map(item => {
      var words = item.message.replace(/([A-Z])/g, ' $1')
      return (errors[item.path] = {
        error: (words.charAt(0).toUpperCase() + words.slice(1)).replace(/_/g, ' '),
      })
    })
    validFields = false
  }

  if (
    (options.features.discount_codes_enabled || values.discount_code.value) &&
    values.discount_code.value
  ) {
    const code = await validateDiscountCode(values.discount_code.value)
    if (!code) {
      errors['discount_code'] = { error: 'Invalid discount code' }
      validCode = false
    }
  }

  if (!validCode || !validFields) {
    dispatch({ type: 'SET_PAYMENT', payload: { ...values, ...errors } })
    return false
  }

  return true
}

const save = async (id, values) => {
  return await apiSave(id, {
    leasing: values.leasing.value,
    early_settlement: values.early_settlement.value,
    contract_length: values.contract_length.value,
    discount_code: values.discount_code.value,
    price_per_user: values.price_per_user.value,
  })
}

export { validate, save }
export default PaymentStep
