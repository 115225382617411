import React from 'react'
import Carousel from 'react-material-ui-carousel'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  carousel: {
    width: '100%',
  },
}))

function ItemCarousel(props) {
  const classes = useStyles()

  return (
    <Carousel
      className={classes.carousel}
      navButtonsAlwaysInvisible={true}
      activeIndicatorProps={{ style: { color: '#FFF' } }}
      indicatorProps={{ style: { color: 'rgba(255, 255, 255, 0.2' } }}>
      {props.children}
    </Carousel>
  )
}

export default ItemCarousel
