import { Box } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { me, signup } from 'api/auth'
import { AuthContext } from 'contexts/AuthContext'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

function Signup(props) {
  const { setAuthenticated, setUser } = useContext(AuthContext)
  const { loading, setLoading } = useContext(LoadingContext)
  const [errors, setErrors] = useState({})
  const classes = useStyles()

  useEffect(() => {
    setLoading(false)
  }, [setLoading, props.location, props.history])

  const handleChange = event => {
    setErrors({})
    const { name, value } = event.target
    setValues({ ...values, [name]: value })
  }

  const handleSubmit = async event => {
    event.preventDefault()

    setLoading(true)
    const result = await signup({
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
    })
    if (result.success) {
      setUser(await me())
      setAuthenticated(true)
    } else {
      setErrors(result.body.errors)
    }
    setLoading(false)
  }

  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
  })

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        <img
          srcSet='/Logo100.png 207w,
                    /Logo50.png 103w'
          sizes='(max-width: 600px) 211px,
                    422px'
          src='/Logo100.png'
          alt='Logo'
        />
        <Box marginTop={4}>
          <Typography component='h1' variant='h5'>
            Sign-up
          </Typography>
        </Box>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <TextField
            variant='standard'
            margin='normal'
            required
            fullWidth
            id='first_name'
            label='First Name'
            disabled={loading}
            name='first_name'
            autoFocus
            onChange={handleChange}
            error={errors.hasOwnProperty('first_name') ? true : false}
            helperText={errors.hasOwnProperty('first_name') ? errors['first_name'] : ''}
          />
          <TextField
            variant='standard'
            margin='normal'
            required
            fullWidth
            id='last_name'
            label='Last Name'
            disabled={loading}
            name='last_name'
            onChange={handleChange}
            error={errors.hasOwnProperty('last_name') ? true : false}
            helperText={errors.hasOwnProperty('last_name') ? errors['last_name'] : ''}
          />
          <TextField
            variant='standard'
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email Address'
            disabled={loading}
            name='email'
            autoComplete='email'
            onChange={handleChange}
            error={errors.hasOwnProperty('email') ? true : false}
            helperText={errors.hasOwnProperty('email') ? errors['email'] : ''}
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            disabled={loading}
            className={classes.submit}>
            Sign-up
          </Button>
          <Grid container>
            <Grid item xs>
              <Link component={RouterLink} to='/login' variant='body2'>
                Back to login
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  )
}

export default Signup
