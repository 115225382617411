import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import { externalIdUpdate } from 'api/number-porting'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useToasts } from 'react-toast-notifications'

function UpdateExternalIdModal({ id, reference, afterSubmit, children }) {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(reference)
  const { addToast } = useToasts()

  const handleOpen = (id, isOwner) => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = event => {
    setValue(event.target.value)
  }

  const handleSubmit = async () => {
    setLoading(true)

    try {
      await externalIdUpdate(id, { external_id: value })

      if (afterSubmit) {
        await afterSubmit()
      }
    } catch (e) {
      addToast('Unable to update external ID', { appearance: 'error' })
    }

    setLoading(false)
    setOpen(false)
  }

  return (
    <React.Fragment>
      <span onClick={handleOpen}>{children}</span>

      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Number Port Gamma Reference</DialogTitle>
        <DialogContent>
          <div>
            <TextField
              variant='standard'
              margin='normal'
              fullWidth
              id='value'
              label='Gamma Reference'
              name='value'
              value={value}
              onChange={handleChange}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={loading} onClick={handleSubmit} color='primary'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

UpdateExternalIdModal.propTypes = {
  id: PropTypes.number,
  reference: PropTypes.string,
  afterSubmit: PropTypes.func,
}

export default UpdateExternalIdModal
