import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import { saveManualConfigData as apiSave } from 'api/quote-builder'
import TableSearchBox from 'components/TableSearchBox'
import TableToolbar from 'components/TableToolbar'
import React, { useState } from 'react'
import Title from './components/Title'

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(8),
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(8),
    margin: 'auto',
    [theme.breakpoints.up('lg')]: {
      width: '80%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '60%',
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  table: {
    minWidth: 500,
  },
}))

function ManualConfigStep({ products, productChanges, setProductChanges }) {
  const classes = useStyles()
  const [productsToList, setProductsToList] = useState(products)

  const handleChangeQuantity = (id, value) => {
    const newValues = { ...productChanges }
    newValues[id] = value

    setProductChanges(newValues)
  }

  const getValue = (id, defaultValue) => {
    if (productChanges.hasOwnProperty(id)) {
      return productChanges.quantity
    }

    return defaultValue
  }

  const handleSearch = event => {
    const searchTerm = event.target.value.replace(/\s/g, '').toLowerCase()
    const newProductsToList = products.filter(x => {
      // remove spaces and lower case product names
      const productName = x.product.name.replace(/\s/g, '').toLowerCase()
      const productCode = x.product.product_code
        ? x.product.product_code.replace(/\s/g, '').toLowerCase()
        : ''

      return productName.includes(searchTerm) || productCode.includes(searchTerm)
    })

    setProductsToList(newProductsToList)
  }

  return (
    <form className={classes.form} noValidate>
      <Paper className={classes.paper}>
        <Container component='main'>
          <Title>Manual Config</Title>

          <TableContainer>
            <TableToolbar>
              <TableSearchBox label='Search products' handleChange={handleSearch} />
            </TableToolbar>

            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Product</TableCell>
                  <TableCell>Code</TableCell>
                  <TableCell align='right'></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productsToList.map(x => (
                  <TableRow key={x.product.id}>
                    <TableCell scope='row'>{x.product.name}</TableCell>
                    <TableCell scope='row'>{x.product.code}</TableCell>
                    <TableCell scope='row' align='right'>
                      <TextField
                        variant='standard'
                        margin='normal'
                        id='quantity'
                        label='Quantity'
                        name='quantity'
                        type='number'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={getValue(x.product.id, x.quantity)}
                        onChange={event => {
                          handleChangeQuantity(x.product.id, event.target.value)
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Paper>
    </form>
  )
}

const save = async (id, productChanges) => {
  const products = []
  Object.keys(productChanges).map(key => {
    return products.push({ id: key, quantity: productChanges[key] })
  })

  return await apiSave(id, { products: products })
}

export { save }
export default ManualConfigStep
