import config from 'config'

export async function get(from, to, uuid) {
  try {
    let url = config.API_URL + `/api/sales-commission/${from}/${to}/${uuid}`
    const result = await fetch(url)
    return await result.json()
  } catch (e) {
    return false
  }
}
