import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect } from 'react'

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: theme.breakpoints.width('md'),
    margin: 'auto',
    textAlign: 'center',
    fontFamily: '"Montserrat", sans-serif',
  },
  logo: {
    width: '200px',
    [theme.breakpoints.up('lg')]: {
      width: '300px',
      marginBottom: '-24px',
    },
  },
  video: {
    width: '100%',
    maxWidth: theme.breakpoints.width('md'),
  },
  headline: {
    fontWeight: 700,
    fontSize: 20,
  },
  button: {
    backgroundColor: '#221B43',
    border: '1px solid #707070',
    borderRadius: '15px',
    color: 'white',
    fontSize: '16px',
    padding: '12px 40px',
    textDecoration: 'none',
  },
}))

function Candio() {
  const classes = useStyles()
  const { setLoading } = useContext(LoadingContext)

  useEffect(() => {
    setLoading(false)
  }, [setLoading])

  return (
    <Box className={classes.container}>
      <img
        alt='Candio'
        src='https://candio.co.uk/wp-content/uploads/2020/06/Candio-logo-with-padding.png'
        title='Candio'
        className={classes.logo}
      />

      <p className={classes.headline}>White label digital services</p>
      <p>
        Candio helps technology resellers, utility companies, membership organisations,
        professional services businesses and marketing agencies deliver white-label
        digital services that add tangible value to end users and recurring revenue to our
        partners
      </p>

      <GetInTouch />

      <div>
        <video
          className={classes.video}
          controls
          poster='/candio-video.png'
          src='/candio-video.mp4'
        />
      </div>
    </Box>
  )
}

function GetInTouch() {
  const classes = useStyles()

  return (
    <Box mt={4} mb={5}>
      <a
        href='https://candio.co.uk/get-in-touch'
        target='_blank'
        rel='noopener noreferrer'
        className={classes.button}>
        Get In Touch
      </a>
    </Box>
  )
}

export default Candio
