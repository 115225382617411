import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { get } from 'api/sales-dash'
import LineChart from 'components/charts/LineChart'
import PieChart from 'components/charts/PieChart'
import { LoadingContext } from 'contexts/LoadingContext'
import { format, subDays } from 'date-fns'
import React, { useContext, useEffect, useState } from 'react'
import DataBox from './components/DataBox'

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: theme.breakpoints.width('lg'),
    margin: 'auto',
  },
  description: {
    fontSize: '0.8rem',
  },
  headlineStats: {
    display: 'grid',
    gridGap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridGap: theme.spacing(6),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
  },
  largeText: {
    fontSize: '4rem',
  },
  moneyText: {
    fontSize: '2rem',
  },
  line: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(6),
    },
  },
  chart: {
    height: '300px',
    textAlign: 'center',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
  },
  pie: {
    height: '500px',
  },
  pies: {
    display: 'grid',
    gridGap: theme.spacing(2),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      gridGap: theme.spacing(6),
    },
    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
      marginBottom: theme.spacing(8),
    },
  },
}))

const getQueryString = (from, accountManagerId) => {
  const formattedFrom = format(subDays(new Date(), from), 'yyy-MM-dd')
  let ret = `?from=${formattedFrom}`

  if (accountManagerId !== 'all') {
    ret += `&account_manager_id=${accountManagerId}`
  }

  return ret
}

function DistributorSalesDash() {
  const classes = useStyles()
  const { loading, setLoading } = useContext(LoadingContext)
  const [from, setFrom] = useState('30')
  const [accountManagerId, setAccountManagerId] = useState('all')
  const [data, setData] = useState({})
  const [hideContent, setHideContent] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const res = await get()
      setData(res.data)
      setHideContent(false)
      setLoading(false)
    }

    fetchData()
  }, [setLoading])

  const handleChangeDateRange = async event => {
    setLoading(true)
    const queryString = getQueryString(event.target.value, accountManagerId)
    const res = await get(queryString)
    setFrom(event.target.value)
    setData(res.data)
    setLoading(false)
  }

  const handleChangeAccountManager = async event => {
    setLoading(true)
    const queryString = getQueryString(from, event.target.value)
    const res = await get(queryString)
    setAccountManagerId(event.target.value)
    setData(res.data)
    setLoading(false)
  }

  if (hideContent) {
    return null
  }

  return (
    <div className={classes.container}>
      <Typography variant='h3' gutterBottom>
        Dashboard
      </Typography>

      <Select
        style={{ fontSize: '1.5rem', marginRight: '2rem' }}
        id='date_range'
        disabled={loading}
        onChange={handleChangeDateRange}
        value={from}
        name='date_range'>
        <MenuItem value='30'>Last 30 Days</MenuItem>
        <MenuItem value='60'>Last 60 Days</MenuItem>
        <MenuItem value='90'>Last 90 Days</MenuItem>
        <MenuItem value='180'>Last 180 Days</MenuItem>
        <MenuItem value='365'>Last 365 Days</MenuItem>
      </Select>

      <Select
        style={{ fontSize: '1.5rem' }}
        id='account_manager_id'
        disabled={loading}
        onChange={handleChangeAccountManager}
        value={accountManagerId}
        name='account_manager_id'>
        <MenuItem value='all'>All Quotes</MenuItem>
        {data.account_managers.map(x => (
          <MenuItem key={x.id} value={x.id}>
            {x.first_name} {x.last_name}
          </MenuItem>
        ))}
      </Select>

      <p className={classes.description}>{data.description}</p>

      <div className={classes.headlineStats}>
        <DataBox title='Raised Quotes'>
          <div className={classes.largeText}>{data.quotes_count}</div>
        </DataBox>
        <DataBox title='Quote Value'>
          <div className={classes.moneyText}>
            £{(data.total_value / 100).toLocaleString()}
          </div>
        </DataBox>
        <DataBox title='Average Margin'>
          <div className={classes.largeText}>{data.average_margin}%</div>
        </DataBox>
        <DataBox title='Profit Total'>
          <div className={classes.moneyText}>
            £{(data.total_profit / 100).toLocaleString()}
          </div>
        </DataBox>
      </div>

      <div className={`${classes.chart} ${classes.line}`}>
        <Typography variant='subtitle1' gutterBottom>
          Quotes Raised
        </Typography>
        <LineChart
          data={[
            {
              id: 'Quotes Raised',
              data: data.quotes_by_date.map(item => ({
                x: item.date,
                y: item.value,
              })),
            },
          ]}
          colors={{ scheme: 'paired' }}
          tooltipX='Date'
          tooltipY='Quotes Raised'
        />
      </div>

      {data.resellers.length > 0 && (
        <div className={classes.pies}>
          <div className={`${classes.chart} ${classes.pie}`}>
            <Typography variant='subtitle1' gutterBottom>
              Quotes By Reseller
            </Typography>
            <PieChart
              data={data.resellers.map(item => ({
                id: `${item.name}`,
                value: item.count,
              }))}
              colors={{ scheme: 'paired' }}
            />
          </div>

          <div className={`${classes.chart} ${classes.pie}`}>
            <Typography variant='subtitle1' gutterBottom>
              Value By Reseller
            </Typography>
            <PieChart
              data={data.resellers.map(item => ({
                id: `${item.name}`,
                value: item.value / 100,
              }))}
              colors={{ scheme: 'paired' }}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default DistributorSalesDash
