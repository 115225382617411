import { CircularProgress } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden'
import MobileStepper from '@material-ui/core/MobileStepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import {
  details as fetchDetails,
  manualConfigData as fetchManualConfigData,
  options as fetchOptions,
} from 'api/quote-builder'
import { FeatureToggleContext } from 'contexts/FeatureToggleContext'
import { HelpContext } from 'contexts/HelpContext'
import { LoadingContext } from 'contexts/LoadingContext'
import { QuoteContext } from 'contexts/QuoteContext'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import CustomerDetailsStep, {
  save as SaveCustomerDetailsStep,
  validate as ValidateCustomerDetailsStep,
} from './steps/CustomerDetailsStep'
import ExpenditureStep, {
  save as SaveExpenditureStep,
  validate as ValidateExpenditureStep,
} from './steps/ExpenditureStep'
import ManualConfigStep, { save as SaveManualConfig } from './steps/ManualConfigStep'
import NumbersStep, {
  save as SaveNumbersStep,
  validate as ValidateNumbersStep,
} from './steps/NumbersStep'
import PaymentStep, {
  save as SavePaymentStep,
  validate as ValidatePaymentStep,
} from './steps/PaymentStep'
import ProposalStep from './steps/proposal-step/ProposalStep'
import ServicesStep, {
  save as SaveServicesStep,
  validate as ValidateServicesStep,
} from './steps/ServicesStep'
import SummaryStep from './steps/SummaryStep'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepper: {
    background: 'transparent',
    marginBottom: theme.spacing(6),
  },
  loader: {
    textAlign: 'center',
    marginTop: theme.spacing(8),
  },
}))

export default function ManualBuilder(props) {
  const classes = useStyles()
  const { id, step } = useParams()
  const { dispatch, hitLimits } = useContext(QuoteContext)
  const { setShowHelp, setHelpType } = useContext(HelpContext)
  const { featureToggles } = useContext(FeatureToggleContext)
  const [activeStep, setActiveStep] = useState(0)
  const { loading, setLoading } = useContext(LoadingContext)
  const [options, setOptions] = useState()
  const [hideContent, setHideContent] = useState(true)
  const [customerDetails, setCustomerDetails] = useState({
    deal_name: { value: '', error: '' },
    customer_business_name: { value: '', error: '' },
    customer_first_name: { value: '', error: '' },
    customer_last_name: { value: '', error: '' },
    customer_email: { value: '', error: '' },
    tariff: { value: '', error: '' },
  })
  const [services, setServices] = useState({
    system_type: { value: 'cloud', error: '' },
    system_users: { value: null, error: '' },
    system_users_type: { value: '0', error: '' },
    number_of_sip_trunks: { value: '', error: '' },
    remote_users: { value: '', error: '' },
    uc_license: { value: '' },
    crm_integration: { value: '' },
    bundled_minutes: { value: '' },
  })
  const [manualConfigProducts, setManualConfigProducts] = useState({})
  const [manualConfigProductChanges, setManualConfigProductChanges] = useState({})
  const [numbers, setNumbers] = useState({
    porting: { value: null, error: '' },
    new_numbers: { value: null, error: '' },
  })
  const [expenditure, setExpenditure] = useState({
    calls: { value: 0, error: '' },
    services: { value: 0, error: '' },
    equipment: { value: 0, error: '' },
    maintenance: { value: 0, error: '' },
  })
  const [payment, setPayment] = useState({
    leasing: { value: '', error: '' },
    contract_length: { value: '', error: '' },
    early_settlement: { value: 0, error: '' },
    discount_code: { value: '', error: '' },
  })
  const [steps, setSteps] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)

      let result = []
      if (id) {
        result = await Promise.all([
          fetchOptions(),
          fetchManualConfigData(id),
          fetchDetails(id),
        ])
      } else {
        result = await Promise.all([fetchOptions()])
      }

      setOptions(result[0])
      setManualConfigProducts(result[1])

      const steps = [
        {
          slug: 'customer-details',
          label: 'Customer Details',
          completed: false,
        },
        {
          slug: 'system-details',
          label: 'System Details',
          completed: false,
        },
        {
          slug: 'manual-config',
          label: 'Manual Config',
          completed: false,
        },
        {
          name: 'numbers',
          slug: 'numbers',
          label: 'Numbers',
          completed: false,
        },
        {
          name: 'expenditure',
          slug: 'expenditure',
          label: 'Expenditure',
          completed: false,
        },
        {
          name: 'payment',
          slug: 'payment',
          label: 'Payment',
          completed: false,
        },
        {
          name: 'summary',
          slug: 'summary',
          label: 'Summary',
          completed: false,
        },
        {
          name: 'proposal',
          slug: 'proposal',
          label: 'Proposal',
          completed: null,
        },
      ]

      if (id) {
        const details = result[2]
        setCustomerDetails({
          deal_name: {
            value: details.data.deal_name,
            error: '',
          },
          customer_business_name: {
            value: details.data.customer_details.business_name,
            error: '',
          },
          customer_first_name: {
            value: details.data.customer_details.first_name,
            error: '',
          },
          customer_last_name: {
            value: details.data.customer_details.last_name,
            error: '',
          },
          customer_email: { value: details.data.customer_details.email, error: '' },
          tariff: { value: details.data.customer_details.tariff, error: '' },
        })
        setServices({
          system_type: { value: details.data.services.system_type, error: '' },
          system_users: { value: details.data.services.system_users, error: '' },
          system_users_type: {
            value: details.data.services.system_users_type,
            error: '',
          },
          number_of_sip_trunks: {
            value: details.data.services.number_of_sip_trunks,
            error: '',
          },
          remote_users: { value: details.data.services.remote_users, error: '' },
          uc_license: { value: details.data.services.uc_license },
          crm_integration: { value: details.data.services.crm_integration },
          bundled_minutes: { value: details.data.services.bundled_minutes },
        })
        setNumbers({
          new_numbers: { value: details.data.numbers.new_numbers, error: '' },
          porting: { value: details.data.numbers.number_porting, error: '' },
        })
        setExpenditure({
          calls: { value: details.data.expenditure.calls / 100, error: '' },
          services: { value: details.data.expenditure.services / 100, error: '' },
          equipment: { value: details.data.expenditure.equipment / 100, error: '' },
          maintenance: { value: details.data.expenditure.maintenance / 100, error: '' },
        })
        setPayment({
          leasing: { value: details.data.payment.leasing, error: '' },
          contract_length: { value: details.data.contract_length, error: '' },
          early_settlement: {
            value: details.data.payment.early_settlement / 100,
            error: '',
          },
          discount_code: { value: details.data.payment.discount_code, error: '' },
        })

        steps[0].completed = details.data.completed_steps.customer_details
        steps[1].completed = details.data.completed_steps.services
        steps[2].completed = details.data.completed_steps.services
        steps[3].completed = details.data.completed_steps.numbers
        steps[4].completed = details.data.completed_steps.expenditure
        steps[5].completed = details.data.completed_steps.expenditure
        steps[6].completed = details.data.completed_steps.payment

        const stepIndex = step ? steps.findIndex(obj => obj.slug === step) : -1
        if (stepIndex !== -1) {
          setActiveStep(stepIndex)
        }
      }

      setSteps(steps)
      setHideContent(false)
      setLoading(false)
    }

    if (featureToggles.indexOf('quote_builder_help') !== -1) {
      setHelpType('quote-builder')
      setShowHelp(true)
    }
    fetchData()

    return () => {
      setShowHelp(false)
    }
  }, [setLoading, id, step, setShowHelp, setHelpType, featureToggles])

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return (
          <CustomerDetailsStep
            options={options}
            values={customerDetails}
            onChange={handleChangeCustomerDetails}
          />
        )
      case 1:
        return (
          <ServicesStep services={services} onChangeServices={handleChangeServices} />
        )
      case 2:
        return (
          <ManualConfigStep
            products={manualConfigProducts}
            productChanges={manualConfigProductChanges}
            setProductChanges={setManualConfigProductChanges}
          />
        )
      case 3:
        return <NumbersStep values={numbers} onChange={handleChangeNunbers} />
      case 4:
        return <ExpenditureStep values={expenditure} onChange={handleChangeExpenditure} />
      case 5:
        return (
          <PaymentStep
            options={options}
            values={payment}
            onChange={handleChangePayment}
          />
        )
      case 6:
        return <SummaryStep steps={steps} />
      case 7:
        return (
          <ProposalStep
            src={options.proposal_url}
            questions={options.proposal_questions}
          />
        )
      default:
        return 'Error'
    }
  }

  const getNextText = activeStep => {
    if (activeStep === 7) {
      return 'Generate Proposal'
    }

    return 'Save'
  }

  const getLastNext = () => {
    return steps.length - 1
  }

  const handleNext = async () => {
    let success = true
    setLoading(true)

    let quoteId = id
    if (activeStep === 0) {
      if (
        await ValidateCustomerDetailsStep(options, customerDetails, setCustomerDetails)
      ) {
        quoteId = await SaveCustomerDetailsStep(id, customerDetails)
        if (!quoteId) {
          success = false
        }
      } else {
        success = false
      }
    } else if (activeStep === 1) {
      success =
        (await ValidateServicesStep(services, setServices)) &&
        (await SaveServicesStep(id, services))
    } else if (activeStep === 2) {
      success = await SaveManualConfig(id, manualConfigProductChanges)
    } else if (activeStep === 3) {
      success =
        (await ValidateNumbersStep(numbers, setNumbers)) &&
        (await SaveNumbersStep(id, numbers))
    } else if (activeStep === 4) {
      success =
        (await ValidateExpenditureStep(expenditure, setExpenditure)) &&
        (await SaveExpenditureStep(id, expenditure))
    } else if (activeStep === 5) {
      success =
        (await ValidatePaymentStep(options, payment, setPayment)) &&
        (await SavePaymentStep(id, payment))
    }

    if (success && quoteId) {
      const limit = hitLimits(
        services.system_type.value,
        services.system_users.value,
        services.remote_users.value,
        services.number_of_sip_trunks.value
      )

      dispatch({
        type: 'SET_MANUAL_CONFIG',
        payload: limit,
      })

      setHideContent(true)
      if (limit) {
        const nextStep = activeStep + 1
        const slug = steps[nextStep].slug
        props.history.push(`/quotes/${quoteId}/${slug}`)
      } else {
        props.history.push(`/quotes/${quoteId}/handsets`)
      }
    }
    setLoading(false)
  }

  const handleBack = () => {
    window.scrollTo(0, 0)
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleChangeCustomerDetails = event => {
    const { name, value } = event.target
    setCustomerDetails({ ...customerDetails, [name]: { value: value } })
  }

  const handleChangeServices = event => {
    const { name, value } = event.target
    setServices({ ...services, [name]: { value: value } })
  }

  const handleChangeNunbers = event => {
    const { name, value } = event.target
    setNumbers({ ...numbers, [name]: { value: value } })
  }

  const handleChangeExpenditure = event => {
    const { name, value } = event.target
    setExpenditure({ ...expenditure, [name]: { value: value } })
  }

  const handleChangePayment = event => {
    const { name, value } = event.target
    setPayment({ ...payment, [name]: { value: value } })
  }

  const handleChangeStep = step => {
    if (id) {
      const stepIndex = step ? steps.findIndex(obj => obj.slug === step) : -1
      if (stepIndex !== -1) {
        setHideContent(true)
        props.history.push(`/quotes/${id}/${step}`)
      }
    }
  }

  if (hideContent) {
    return (
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <Hidden only='xs'>
        <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel>
          {steps.map(item => (
            <Step key={item.label} completed={item.completed}>
              <StepLabel
                style={{ cursor: 'pointer' }}
                onClick={() => handleChangeStep(item.slug)}>
                {item.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Hidden>
      <div>
        {getStepContent(activeStep)}

        <Hidden only='xs'>
          <div align='right'>
            <Button
              disabled={activeStep === 0 || loading}
              onClick={handleBack}
              className={classes.backButton}>
              Back
            </Button>
            {activeStep !== getLastNext() && (
              <Button
                variant='contained'
                color='primary'
                disabled={loading}
                onClick={handleNext}>
                {getNextText(activeStep)}
              </Button>
            )}
          </div>
        </Hidden>
      </div>

      <Hidden smUp>
        <MobileStepper
          only='xs'
          style={{ marginTop: '24px' }}
          variant='progress'
          steps={steps.length}
          position='static'
          activeStep={activeStep}
          className={classes.root}
          nextButton={
            <Button
              size='small'
              onClick={handleNext}
              disabled={activeStep === getLastNext() || loading}>
              {getNextText(activeStep)}
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button
              size='small'
              onClick={handleBack}
              disabled={activeStep === 0 || loading}>
              <KeyboardArrowLeft />
              Back
            </Button>
          }
        />
      </Hidden>
    </div>
  )
}
