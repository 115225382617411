import config from 'config'
import { getJsonHeaders } from './helpers'

export async function get() {
  try {
    const result = await fetch(config.API_URL + '/api/proposals')
    return await result.json()
  } catch (e) {
    return false
  }
}

export async function generate(id, selectedPages, pricingAfterPage, answers, cl) {
  const url =
    config.API_URL +
    `/api/quotes/builder/proposal/${id}?sp=${selectedPages}&pap=${pricingAfterPage}&q=${btoa(
      JSON.stringify(answers)
    )}&cl=${btoa(JSON.stringify(cl))}`

  await fetch(url)
}

export async function create(file) {
  try {
    const body = {
      file: file,
    }

    const res = await fetch(config.API_URL + `/api/proposals`, {
      method: 'POST',
      headers: await getJsonHeaders(),
      body: JSON.stringify(body),
    })

    const decoded = await res.json()

    if (res.status === 201) {
      return {
        success: true,
        message: 'Successfully uploaded proposal document',
        data: decoded.data,
      }
    }

    return {
      success: false,
      message: decoded.message,
      errors: Object.entries(decoded.errors).map(error => {
        return error
      }),
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to upload proposal document',
    }
  }
}

export async function updateCoveringLetter(body) {
  try {
    const res = await fetch(config.API_URL + `/api/proposals/covering-letter`, {
      method: 'POST',
      headers: await getJsonHeaders(),
      body: JSON.stringify(body),
    })

    const decoded = await res.json()

    if (res.status === 200) {
      return {
        success: true,
        message: 'Successfully updated covering letter',
      }
    }

    return {
      success: false,
      message: decoded.message,
      errors: Object.entries(decoded.errors).map(error => {
        return error
      }),
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to update covering letter',
    }
  }
}

export async function updateCoveringLetterQuestion(body) {
  try {
    const res = await fetch(config.API_URL + `/api/proposals/question`, {
      method: 'POST',
      headers: await getJsonHeaders(),
      body: JSON.stringify(body),
    })

    const decoded = await res.json()

    if (res.status === 200) {
      return {
        success: true,
        data: decoded.data,
        message: 'Successfully saved question',
      }
    }

    return {
      success: false,
      message: decoded.message,
      errors: Object.entries(decoded.errors).map(error => {
        return error
      }),
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to save question',
    }
  }
}

export async function deleteCoveringLetterQuestion(id) {
  try {
    const res = await fetch(config.API_URL + `/api/proposals/question/${id}`, {
      method: 'DELETE',
      headers: await getJsonHeaders(),
      body: JSON.stringify({}),
    })

    if (res.status === 204) {
      return true
    }

    return false
  } catch (e) {
    return false
  }
}
