import { AuthContext } from 'contexts/AuthContext'
import React, { useContext } from 'react'

function CostPriceTableDisplay({ value }) {
  const { user } = useContext(AuthContext)

  return (
    <>
      {user.type !== 'reseller' && parseFloat(value) === 0 ? (
        <span>Variable</span>
      ) : (
        value
      )}
    </>
  )
}

export default CostPriceTableDisplay
