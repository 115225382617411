import QuoteContext from 'contexts/QuoteContext'
import React from 'react'
import ViewContainer from './ViewContainer'
import { useParams } from 'react-router-dom'

export default function View(props) {
  const { id } = useParams()

  return (
    <QuoteContext id={id}>
      <ViewContainer {...props} />
    </QuoteContext>
  )
}
