import config from 'config'
import { getJsonHeaders } from './helpers'

export async function list(limit = 15, page = 1) {
  try {
    const result = await fetch(
      config.API_URL + `/api/training?per_page=${limit}&page=${page}`
    )
    return await result.json()
  } catch (e) {
    return false
  }
}

export async function get(id) {
  try {
    const result = await fetch(config.API_URL + `/api/training/${id}`)
    return await result.json()
  } catch (e) {
    return false
  }
}

export async function create(body) {
  try {
    const res = await fetch(config.API_URL + `/api/training`, {
      method: 'POST',
      headers: await getJsonHeaders(),
      body: JSON.stringify(body),
    })

    if (res.status === 201) {
      return {
        success: true,
        message: 'Successfully created training date',
      }
    }

    const decoded = await res.json()
    return {
      success: false,
      message: decoded.message,
      errors: Object.entries(decoded.errors).map(error => {
        return error
      }),
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to create training date',
    }
  }
}

export async function update(id, body) {
  try {
    const res = await fetch(config.API_URL + `/api/training/${id}`, {
      method: 'PUT',
      headers: await getJsonHeaders(),
      body: JSON.stringify(body),
    })

    if (res.status === 200) {
      return {
        success: true,
        message: 'Successfully updated training date',
      }
    }

    const decoded = await res.json()
    return {
      success: false,
      message: decoded.message,
      errors: Object.entries(decoded.errors).map(error => {
        return error
      }),
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to update training date',
      errors: [['', 'Failed to update training date']],
    }
  }
}

export async function deleteTraining(id) {
  try {
    const res = await fetch(config.API_URL + `/api/training/${id}`, {
      method: 'DELETE',
      headers: await getJsonHeaders(),
      body: JSON.stringify({}),
    })

    if (res.status === 204) {
      return true
    }

    return false
  } catch (e) {
    return false
  }
}
