import { ResponsiveLine } from '@nivo/line'
import { format } from 'date-fns'
import React from 'react'
import styles from './LineChart.module.scss'

function LineChart({
  data,
  tooltipX = 'x',
  tooltipY = 'y',
  colors = { scheme: 'nivo' },
}) {
  const getXTickValues = () => {
    const rawValues = data
      .map(d => d.data.map(x => x.x))
      .reduce((acc, data) => acc.concat(data), [])
    const values = rawValues.filter(onlyUnique)

    const divisor = Math.round(values.length / 30)
    return values.filter((_, i) => i % divisor === 0)
  }

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index
  }

  const toolTipElement = props => {
    return (
      <div className={styles.tooltip}>
        {tooltipX}: {props.point.data.xFormatted}
        <br />
        {tooltipY}: {props.point.data.yFormatted}
      </div>
    )
  }

  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 15, right: 20, bottom: 50, left: 50 }}
      curve='linear'
      enableArea='true'
      tooltip={toolTipElement}
      axisTop={null}
      axisRight={null}
      xFormat={v => format(new Date(v), 'dd/MM/yyyy')}
      axisBottom={{
        orient: 'bottom',
        tickSize: 5,
        format: v => format(new Date(v), 'dd'),
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendOffset: 36,
        legendPosition: 'middle',
        tickValues: getXTickValues(),
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendOffset: -40,
        legendPosition: 'middle',
      }}
      lineWidth={'2px'}
      colors={colors}
      pointLabel='y'
      pointLabelYOffset={-12}
      useMesh={true}
      gridXValues={getXTickValues()}
    />
  )
}

export default LineChart
