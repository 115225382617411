import { IconButton, Tooltip } from '@material-ui/core'
import Star from '@material-ui/icons/Star'
import StarBorder from '@material-ui/icons/StarBorder'
import React from 'react'
import PropTypes from 'prop-types'

function ProductFavourite({ productId, isFavourite, onAdd, onRemove }) {
  return isFavourite ? (
    <Tooltip title='Remove from favourites' placement='top'>
      <IconButton
        color='default'
        onClick={() => {
          onRemove(productId)
        }}>
        <Star />
      </IconButton>
    </Tooltip>
  ) : (
    <Tooltip title='Add to favourites' placement='top'>
      <IconButton
        color='default'
        onClick={() => {
          onAdd(productId)
        }}>
        <StarBorder />
      </IconButton>
    </Tooltip>
  )
}

ProductFavourite.propTypes = {
  productId: PropTypes.string.isRequired,
  isFavourite: PropTypes.number.isRequired,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
}

export default ProductFavourite
