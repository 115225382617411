import { Box } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import { IconButton } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import DeleteIcon from '@material-ui/icons/Delete'
import React from 'react'
import PortActionInputLabel from './PortActionInputLabel'

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
}))

function NumberRow({
  index,
  stdCode,
  stdCodeError,
  localNumber,
  localNumberError,
  action,
  isInService,
  handleUpdate,
  handleDelete,
}) {
  const classes = useStyles()

  const handleChangeStdCode = event => {
    const sanitizedValue = event.target.value.replace(/\D+/g, '')
    const payload = { ...getPayload(), std_code: sanitizedValue }
    handleUpdate(payload)
  }

  const handleChangeLocalNumber = event => {
    const sanitizedValue = event.target.value.replace(/\D+/g, '')
    const payload = { ...getPayload(), local_number: sanitizedValue }
    handleUpdate(payload)
  }

  const handleChangeAction = event => {
    const payload = { ...getPayload(), action: event.target.value }
    handleUpdate(payload)
  }

  const handleChangeIsInService = event => {
    const payload = { ...getPayload(), is_in_service: event.target.value === 'yes' }
    handleUpdate(payload)
  }

  const getPayload = () => {
    return {
      index: index,
      std_code: stdCode.value,
      local_number: localNumber.value,
      action: action.value,
      is_in_service: isInService.value,
    }
  }

  return (
    <Box display='flex' mb={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            variant='standard'
            margin='normal'
            fullWidth
            required
            id='std_code'
            label='STD Code (area code)'
            name='std_code'
            value={stdCode.value}
            onChange={handleChangeStdCode}
            error={stdCodeError ? true : false}
            helperText={stdCodeError}
            inputProps={{ maxLength: 5 }}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <TextField
            variant='standard'
            margin='normal'
            fullWidth
            required
            id='local_number'
            label='Local Number'
            name='local_number'
            value={localNumber.value}
            onChange={handleChangeLocalNumber}
            error={localNumberError ? true : false}
            helperText={localNumberError}
            inputProps={{ maxLength: 6 }}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <FormControl variant='standard' className={classes.formControl}>
            <PortActionInputLabel />
            <Select
              labelId='action_label'
              id='action'
              name='action'
              value={action.value}
              onChange={handleChangeAction}
              label='Action'>
              <MenuItem value='PORT'>Port</MenuItem>
              <MenuItem value='CEASE'>Cease</MenuItem>
              <MenuItem value='REMAIN'>Remain</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <FormControl variant='standard' className={classes.formControl}>
            <InputLabel id='in_service_label'>In Service</InputLabel>
            <Select
              labelId='in_service_label'
              id='is_in_service'
              name='is_in_service'
              value={isInService.value ? 'yes' : 'no'}
              onChange={handleChangeIsInService}
              label='In Service'>
              <MenuItem value='yes'>Yes</MenuItem>
              <MenuItem value='no'>No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Box ml={3} alignSelf='flex-end'>
        <IconButton color='secondary' onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

export default NumberRow
