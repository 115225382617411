import { Box, Button, CircularProgress, TextField } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { createShareLink, deleteShareLink, sharingInfo } from 'api/quotes'
import SharingLinkList from 'components/share-links/ShareLinks'
import React, { useEffect, useState } from 'react'

function Sharing({ id }) {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const [shareOpen, setShareOpen] = useState(false)
  const [shareLabel, setShareLabel] = useState('')
  const [creatingShareLink, setCreatingShareLink] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      setData(await sharingInfo(id))
      setLoading(false)
    }

    fetchData()
  }, [id, setLoading])

  const handleOpenShareDialog = () => {
    setShareOpen(true)
  }

  const handleCloseShareDialog = () => {
    setShareOpen(false)
  }

  const handleChangeShareLabel = event => {
    setShareLabel(event.target.value)
  }

  const handleCreateShare = async () => {
    setCreatingShareLink(true)
    const res = await createShareLink(id, { label: shareLabel })
    setData(res)
    setCreatingShareLink(false)
    setShareOpen(false)
    setShareLabel('')
  }

  if (loading || !data) {
    return (
      <Box display='flex' justifyContent='center'>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box mb={3}>
      <SharingLinkList
        shareLinks={data.data.share_links}
        setShareLinks={setData}
        createShareLink={handleOpenShareDialog}
        deleteShareLink={deleteShareLink}
        shareName='Quote'
      />

      <Dialog open={shareOpen} onClose={handleCloseShareDialog}>
        <DialogTitle id='share-link'>{'Create Share Link'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='share-link-dialog-description'>
            Enter a label for the link. This can be anything, for example the name of the
            person this being sent to or the company.
          </DialogContentText>

          <TextField
            autoFocus
            margin='dense'
            id='share_label'
            label='Label'
            type='text'
            required
            value={shareLabel}
            onChange={handleChangeShareLabel}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={creatingShareLink}
            onClick={handleCloseShareDialog}
            color='default'>
            Cancel
          </Button>
          <Button
            disabled={creatingShareLink || !shareLabel}
            onClick={handleCreateShare}
            color='primary'>
            Create Link
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default Sharing
