import { Button, Grid, Paper, TextField, Typography } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'
import { create, get, update } from 'api/announcements'
import ImageCropper from 'components/ImageCropper'
import { LoadingContext } from 'contexts/LoadingContext'
import { NotificationContext } from 'contexts/NotificationContext'
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js'
import React, { useContext, useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { useParams } from 'react-router-dom'
import Alert from '@material-ui/lab/Alert'
import { useToasts } from 'react-toast-notifications'

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: theme.spacing(3),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 1, 2),
  },
  editor: {
    height: 'auto',
  },
  textArea: {
    border: 'none',
  },
}))

function Form(props) {
  const { id } = useParams()
  const [errors, setErrors] = useState([])

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [image, setImage] = useState(null)
  const [imageCropped, setImageCropped] = useState(null)

  const [cropWidth, setCropWidth] = useState(0)
  const [cropHeight, setCropHeight] = useState(0)

  const { loading, setLoading } = useContext(LoadingContext)
  const { fetchNotifications } = useContext(NotificationContext)

  const { addToast } = useToasts()

  const classes = useStyles()

  const [values, setValues] = useState({
    title: { value: '', error: '' },
    summary: { value: '', error: '' },
    published_at: { value: '', error: '' },
    image_full_path: { value: '' },
  })

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      if (id !== undefined) {
        const res = await get(id)
        const dataValues = {
          title: { value: res.data.title, error: '' },
          summary: { value: res.data.summary, error: '' },
          published_at: { value: res.data.published_at, error: '' },
          image_full_path: { value: res.data.image_full_path },
        }
        setValues(dataValues)

        setEditorState(EditorState.createWithContent(convertFromRaw(res.data.content)))
      } else {
      }
      setLoading(false)
    }

    fetchData()
  }, [id, setLoading, setValues])

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: { value: value } })
  }

  const handleSubmit = async event => {
    event.preventDefault()

    await saveAnnouncement(true)
  }

  const handleSubmitDraft = async event => {
    event.preventDefault()

    await saveAnnouncement(false)
  }

  const saveAnnouncement = async (publish = true) => {
    const announcement = {
      title: values.title.value,
      summary: values.summary.value,
      content: convertToRaw(editorState.getCurrentContent()),
      publish: publish,
      image: imageCropped,
    }

    const result = id ? await update(id, announcement) : await create(announcement)
    if (result.success) {
      fetchNotifications()
      setLoading(false)
      props.history.push({
        pathname: '/announcements',
        state: {
          severity: 'success',
          message: result.message,
        },
      })
      addToast(publish ? 'Announcement published' : 'Announcement saved as draft', {
        appearance: 'success',
      })
    } else {
      setErrors(result.errors)
      setLoading(false)
    }
  }

  const handleImageUpload = async event => {
    const reader = new FileReader()
    const file = event.target.files[0]
    reader.readAsDataURL(file)

    reader.onloadend = () => {
      const img = new Image()
      img.onload = () => {
        setCropWidth(img.width)
        setCropHeight(img.height)
        setImage(reader.result)
        setValues(values => ({ ...values, image_full_path: { value: '' } }))
        setLoading(false)
      }
      img.src = reader.result
    }
  }

  const handleImageCrop = data => {
    setImageCropped(data)
  }

  const handleUnpublish = async event => {
    event.preventDefault()

    await saveAnnouncement(false)
    addToast('Announcement un-published')
    fetchNotifications()
  }

  return loading ? null : (
    <>
      <Typography variant='h3' gutterBottom>
        {id ? 'Update Announcement' : 'Create Announcement'}
      </Typography>

      {values.published_at.value && (
        <Alert severity='info'>Changes cannot be made to published announcements.</Alert>
      )}

      <form className={classes.form} onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item sm={12} md={7} xl={5}>
            <Paper className={classes.paper}>
              <TextField
                variant='standard'
                margin='normal'
                required
                fullWidth
                id='title'
                label='Title'
                name='title'
                inputProps={{
                  maxLength: 100,
                  readOnly: values.published_at.value ? true : false,
                }}
                defaultValue={values.title.value}
                onChange={handleChange.bind(this)}
              />

              <TextField
                variant='standard'
                margin='normal'
                required
                fullWidth
                id='summary'
                label='Summary'
                name='summary'
                inputProps={{
                  maxLength: 255,
                  readOnly: values.published_at.value ? true : false,
                }}
                defaultValue={values.summary.value}
                onChange={handleChange.bind(this)}
              />

              {!values.published_at.value && (
                <>
                  <input
                    accept='image/*'
                    style={{ display: 'none' }}
                    id='image'
                    name='image'
                    multiple
                    type='file'
                    onChange={handleImageUpload}
                  />
                  <label htmlFor='image'>
                    <Button variant='contained' component='span' color='primary'>
                      {values.image_full_path.value
                        ? 'Set New Image'
                        : 'Set Image (Optional)'}
                    </Button>
                  </label>
                </>
              )}

              {!image && values.image_full_path.value && (
                <img
                  src={'/api/' + values.image_full_path.value}
                  width={300}
                  alt=''></img>
              )}

              {image && (
                <ImageCropper
                  imageBase64={image}
                  onChange={handleImageCrop}
                  minImageWidth={200}
                  minImageHeight={200}
                  defaultCropWidth={cropWidth}
                  defaultCropHeight={cropHeight}
                  maxRenderWidth={1920}
                />
              )}

              <Editor
                editorState={editorState}
                placeholder='Add your announcement content here'
                onEditorStateChange={setEditorState}
                editorClassName={classes.editor}
                readOnly={values.published_at.value}
                toolbar={{
                  options: ['inline', 'list', 'textAlign', 'history'],
                  inline: {
                    options: ['bold', 'italic', 'underline'],
                  },
                  list: {
                    options: ['unordered', 'ordered'],
                  },
                  textAlign: {
                    options: ['left', 'center', 'right'],
                  },
                }}></Editor>
            </Paper>

            {errors.length > 0 && (
              <MuiAlert severity='error'>
                {errors.map((error, index) => {
                  return <div key={index}>{error[1]}</div>
                })}
              </MuiAlert>
            )}

            {!values.published_at.value && (
              <Button
                type='submit'
                variant='contained'
                color='primary'
                disabled={loading}
                className={classes.submit}>
                Publish
              </Button>
            )}

            {id && values.published_at.value && (
              <Button
                variant='contained'
                color='secondary'
                disabled={loading}
                className={classes.submit}
                onClick={handleUnpublish}>
                Un-publish
              </Button>
            )}

            {!values.published_at.value && (
              <Button
                variant='contained'
                color='default'
                disabled={loading}
                className={classes.submit}
                onClick={handleSubmitDraft}>
                Save Draft
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default Form
