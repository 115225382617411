import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles(theme => ({
  margin: {
    marginBottom: theme.spacing(3),
  },
}))

function Title({ variant, margin, ...props }) {
  const classes = useStyles()

  return (
    <Typography variant={variant} className={margin ? classes.margin : ''}>
      {props.children}
    </Typography>
  )
}

Title.propTypes = {
  variant: PropTypes.string,
  margin: PropTypes.bool,
}

Title.defaultProps = {
  variant: 'h5',
  margin: true,
}

export default Title
