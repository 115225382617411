import GeographicPortStepper from 'components/number-porting/geographic-port/GeographicPortStepper'
import { GeographicPortContext } from 'contexts/GeographicPortContext'
import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import GeographicPortSummary from './GeographicPortSummary'

function GeographicPortContent(props) {
  const { state, dispatch } = useContext(GeographicPortContext)
  const { id } = useParams()

  useEffect(() => {
    if (!id) {
      dispatch({ type: 'RESET' })
    }
  }, [id, dispatch])

  if (state.loading) {
    return null
  }

  return state.status.value === 'DRAFT' ? (
    <GeographicPortStepper />
  ) : (
    <GeographicPortSummary />
  )
}

export default GeographicPortContent
