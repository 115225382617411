import { Box, Button } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import MuiAlert from '@material-ui/lab/Alert'
import { DatePicker } from '@material-ui/pickers'
import { get } from 'api/sales-commission'
import { list as userList } from 'api/users'
import { LoadingContext } from 'contexts/LoadingContext'
import { format } from 'date-fns'
import React, { useContext, useEffect, useState } from 'react'
import AllSummary from './AllSummary'
import SalesAgentSummary from './SalesAgentSummary'

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: theme.breakpoints.width('lg'),
    margin: 'auto',
  },
  formControl: {
    minWidth: 200,
  },
}))

function SalesCommission() {
  const classes = useStyles()
  const { loading, setLoading } = useContext(LoadingContext)
  const [users, setUsers] = useState()
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [salesAgentId, setSalesAgentId] = useState('all')
  const [results, setResults] = useState()

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const res = await userList(undefined, 1000)
      setUsers(res)

      setLoading(false)
    }

    fetchData()
  }, [setLoading])

  const handleChangeStartDate = value => {
    setStartDate(value)
    setResults()
  }

  const handleChangeEndDate = value => {
    setEndDate(value)
    setResults()
  }

  const handleChangeSalesAgent = event => {
    setSalesAgentId(event.target.value)
    setResults()
  }

  const fetchResults = async () => {
    setLoading(true)
    const res = await get(
      format(startDate, 'yyyy-MM-dd'),
      format(endDate, 'yyyy-MM-dd'),
      salesAgentId
    )
    setResults(res)
    setLoading(false)
  }

  if (!users || !users.data) {
    return null
  }

  return (
    <div className={classes.container}>
      <Typography variant='h3' gutterBottom>
        Potential Sales Commission
      </Typography>

      <Box display='flex' mb={4}>
        <DatePicker
          style={{ marginRight: '2rem' }}
          disabled={loading}
          label='From'
          value={startDate}
          format='dd/MM/yyyy'
          onChange={handleChangeStartDate}
          maxDate={endDate}
          animateYearScrolling
        />
        <DatePicker
          style={{ marginRight: '2rem' }}
          disabled={loading}
          label='To'
          value={endDate}
          format='dd/MM/yyyy'
          onChange={handleChangeEndDate}
          minDate={startDate}
          animateYearScrolling
        />

        <FormControl className={classes.formControl}>
          <InputLabel id='sales_agent_id_label'>Sales Agent</InputLabel>
          <Select
            style={{ marginRight: '2rem' }}
            disabled={loading}
            labelId='sales_agent_id_label'
            id='sales_agent_id'
            name='sales_agent_id'
            value={salesAgentId}
            onChange={handleChangeSalesAgent}>
            <MenuItem value='all'>All</MenuItem>
            {users.data.map(x => (
              <MenuItem key={x.id} value={x.id}>
                {x.first_name} {x.last_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          disabled={loading || !startDate || !endDate || !salesAgentId}
          variant='contained'
          color='primary'
          onClick={fetchResults}>
          Submit
        </Button>
      </Box>

      {results &&
        (results.errors ? (
          <MuiAlert severity='error'>
            {Object.values(results.errors).map((error, index) => (
              <div key={index}>{error[0]}</div>
            ))}
          </MuiAlert>
        ) : (
          <React.Fragment>
            {results.quotes.length === 0 && <p>No won quotes in this date range</p>}

            {results.quotes.length > 0 && (
              <div>
                <Box mb={4}>
                  <Button
                    variant='contained'
                    color='primary'
                    href={`/api/sales-commission/${format(
                      startDate,
                      'yyyy-MM-dd'
                    )}/${format(endDate, 'yyyy-MM-dd')}/${salesAgentId}/download`}>
                    Download
                  </Button>
                </Box>

                {salesAgentId === 'all' ? (
                  <AllSummary results={results} />
                ) : (
                  <SalesAgentSummary results={results} />
                )}
              </div>
            )}
          </React.Fragment>
        ))}
    </div>
  )
}

export default SalesCommission
