import { Box, FormHelperText, Tooltip, IconButton } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Paper from '@material-ui/core/Paper'
import { Info } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { GeographicPortContext } from 'contexts/GeographicPortContext'
import React, { useContext } from 'react'

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.backgroundMain,
    color: 'white',
    marginTop: theme.spacing(3),
    padding: theme.spacing(4),
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}))

function PortConfirmation() {
  const classes = useStyles()
  const { state, dispatch } = useContext(GeographicPortContext)

  const handleChangeLineActive = event => {
    dispatch({ type: 'SET_LINE_ACTIVE', payload: event.target.checked })
    const newErrors = { ...state.errors }
    delete newErrors['line_active']
    dispatch({ type: 'SET_ERRORS', payload: newErrors })
  }

  const handleChangeHasConsent = event => {
    dispatch({ type: 'SET_HAS_CONSENT', payload: event.target.checked })
    const newErrors = { ...state.errors }
    delete newErrors['has_consent']
    dispatch({ type: 'SET_ERRORS', payload: newErrors })
  }

  const handleChangeDirectoryListings = event => {
    dispatch({ type: 'SET_CHECKED_DIRECTORY_LISTINGS', payload: event.target.checked })
    const newErrors = { ...state.errors }
    delete newErrors['checked_directory_listings']
    dispatch({ type: 'SET_ERRORS', payload: newErrors })
  }

  const handleChangeDisclaimer = event => {
    dispatch({ type: 'SET_CHECKED_LOA_DISCLAIMER', payload: event.target.checked })
    const newErrors = { ...state.errors }
    delete newErrors['checked_loa_disclaimer']
    dispatch({ type: 'SET_ERRORS', payload: newErrors })
  }

  const handleLoaChange = event => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      dispatch({ type: 'SET_LOA_FILE', payload: reader.result })
    }
  }

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5' gutterBottom>
        Port Confirmation
      </Typography>

      <Box mt={3}>
        <Box mb={3}>
          <FormControlLabel
            className={classes.checkbox}
            checked={state.line_active.value}
            control={<Checkbox style={{ color: 'white' }} />}
            label='Is this line currently active? Tick to proceed (ie, is there an established'
            labelPlacement='start'
            onChange={handleChangeLineActive}
          />
          {state.errors.hasOwnProperty('line_active') && (
            <FormHelperText error={true}>{state.errors.line_active}</FormHelperText>
          )}
        </Box>

        <Box mb={3}>
          <FormControlLabel
            className={classes.checkbox}
            checked={state.has_consent.value}
            control={<Checkbox style={{ color: 'white' }} />}
            label="Do you have the customer's consent to port this number and have you correctly filled in the letter of authority? Tick to proceed"
            labelPlacement='start'
            onChange={handleChangeHasConsent}
          />
          {state.errors.hasOwnProperty('has_consent') && (
            <FormHelperText error={true}>{state.errors.has_consent}</FormHelperText>
          )}
        </Box>

        <Box mb={3}>
          <FormControlLabel
            className={classes.checkbox}
            checked={state.checked_directory_listings.value}
            control={<Checkbox style={{ color: 'white' }} />}
            label="It is your responsibility to ensure that you check with your customer whether the telephone number(s) that you're porting to Gamma have Directory Listings. Their current provider may not charge for this service but Gamma will be charged by BT and will pass the charges on to you."
            labelPlacement='start'
            onChange={handleChangeDirectoryListings}
          />
          {state.errors.hasOwnProperty('checked_directory_listings') && (
            <FormHelperText error={true}>
              {state.errors.checked_directory_listings}
            </FormHelperText>
          )}
        </Box>

        <Box mb={3}>
          <FormControlLabel
            className={classes.checkbox}
            checked={state.checked_loa_disclaimer.value}
            control={<Checkbox style={{ color: 'white' }} />}
            label='A Port or Migration request means that the end customer is changing supplier and therefore from both a regulatory and industry level a signed CLOA is required. We therefore need you to confirm that you hold a Letter of Authority for this request. Our network may request a copy of the Letter of Authority should we need this to progress your order or if there is a dispute raised. Please tick this box to confirm that you have a Letter of Authority.'
            labelPlacement='start'
            onChange={handleChangeDisclaimer}
          />
          {state.errors.hasOwnProperty('checked_loa_disclaimer') && (
            <FormHelperText error={true}>
              {state.errors.checked_loa_disclaimer}
            </FormHelperText>
          )}
        </Box>

        <Box mb={2} mt={1}>
          <div>Please upload your CLOA</div>
          <div style={{ marginBottom: '1rem', fontSize: '0.8rem' }}>
            <Tooltip
              title={
                <>
                  It is a legal requirement that you have one for all Port or Migration
                  orders. The losing network may request a copy of the CLOA up to 12
                  months after your order has completed, and it is the Reseller’s
                  responsibility to ensure that you have one.
                  <br />
                  <br />
                  *CLOAs are mandatory for Non-Geographic Port Orders, so your order will
                  not progress without one.
                </>
              }
              placement='top'>
              <IconButton color='default' size='small'>
                <Info style={{ color: 'white' }} />
              </IconButton>
            </Tooltip>
            <i>
              It is mandatory to have a copy of the CLOA but you do not have to upload if
              you would prefer not to.
            </i>
          </div>
          <input
            style={{ marginLeft: '1rem' }}
            id='loa'
            name='loa'
            type='file'
            onChange={handleLoaChange}
          />
          {state.errors.hasOwnProperty('loa_file') && (
            <FormHelperText error={true}>{state.errors.loa_file}</FormHelperText>
          )}
        </Box>
      </Box>
    </Paper>
  )
}

export default PortConfirmation
