import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import React from 'react'
import Routes from 'Routes'

export default function Layout() {
  return (
    <Container maxWidth='xl' component='main'>
      <CssBaseline />
      <Routes />
    </Container>
  )
}
