import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PortabilityCheckComponent from 'components/number-porting/PortabilityCheck'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect } from 'react'

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: theme.breakpoints.width('lg'),
    margin: 'auto',
  },
}))

function PortabilityCheck(props) {
  const { setLoading } = useContext(LoadingContext)
  const classes = useStyles()

  useEffect(() => {
    setLoading(false)
  }, [setLoading])

  return (
    <div className={classes.container}>
      <Typography variant='h3' gutterBottom>
        BT Portability Check
      </Typography>
      <Grid container spacing={4}>
        <Grid item>
          <PortabilityCheckComponent />
        </Grid>
      </Grid>
    </div>
  )
}

export default PortabilityCheck
