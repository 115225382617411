import { Toolbar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 2, 3, 2),
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
}))

function TableToolbar({ children }) {
  const classes = useStyles()

  return <Toolbar className={classes.root}>{children}</Toolbar>
}

TableToolbar.propTypes = {
  children: PropTypes.node,
}

export default TableToolbar
