import { Box, Button, TextField, Tooltip } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { IconButton } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import { Delete, FileCopy, Share } from '@material-ui/icons'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

function ShareLinks({
  shareLinks,
  setShareLinks,
  createShareLink,
  deleteShareLink,
  shareName,
  tableProps,
}) {
  const handleLinkFocus = event => {
    event.preventDefault()
    const { target } = event
    const extensionStarts = target.value.lastIndexOf('.')
    target.focus()
    target.setSelectionRange(0, extensionStarts)
  }

  const handleCopyToClipboard = id => {
    /* Get the text field */
    const copyText = document.getElementById(id)

    /* Select the text field */
    copyText.select()
    copyText.setSelectionRange(0, 99999) /* For mobile devices */

    /* Copy the text inside the text field */
    document.execCommand('copy')
  }

  const handleMobileShare = async url => {
    //check if native sharing is available
    if (navigator.share) {
      const shareData = {
        title: shareName,
        text: `Wanted to share this with you - ${shareName}`,
        url: url,
      }
      await navigator.share(shareData)
    } else {
      alert('Functionality not supported on this browser')
    }
  }

  const getMailToShareBody = x => {
    return encodeURIComponent(`Wanted to share this with you - ${shareName}\n\n${x.url}`)
  }

  const renderShareLinkRow = x => {
    return (
      <TableRow key={x.id}>
        <TableCell scope='row'>{x.label}</TableCell>
        <TableCell scope='row'>{x.item.name}</TableCell>
        <TableCell scope='row'>
          <TextField
            fullWidth
            value={x.url}
            id={`share-link-${x.id}`}
            onFocus={handleLinkFocus}
          />
        </TableCell>
        <TableCell scope='row'>{x.use_count}</TableCell>
        <TableCell scope='row'>{x.last_used}</TableCell>
        <TableCell align='right'>
          <Tooltip title='Copy to clipboard' placement='top'>
            <IconButton
              aria-label='copy to clipboard'
              color='default'
              onClick={() => {
                handleCopyToClipboard(`share-link-${x.id}`)
              }}>
              <FileCopy />
            </IconButton>
          </Tooltip>

          <Tooltip title='Share' placement='top'>
            {navigator.share ? (
              <IconButton
                aria-label='share url'
                color='default'
                onClick={() => {
                  handleMobileShare(x.url)
                }}>
                <Share />
              </IconButton>
            ) : (
              <IconButton
                href={`mailto:?body=${getMailToShareBody(x)}`}
                aria-label='share url'
                color='default'>
                <Share />
              </IconButton>
            )}
          </Tooltip>

          <DeleteShareLinkButton
            shareLink={x}
            setShareLinks={setShareLinks}
            deleteShareLink={deleteShareLink}
          />
        </TableCell>
      </TableRow>
    )
  }

  return (
    <React.Fragment>
      <Typography variant='h6' gutterBottom>
        Sharing
      </Typography>

      <p>
        If you are sharing with a user who can access the library you can simply copy the
        URL of this page from the browser address bar.
      </p>

      <p>
        If you would like to share externally you can create a sharing link below, these
        links can be used without a login.
      </p>

      <Box mb={2}>
        <Button
          size='small'
          variant='contained'
          color='primary'
          onClick={createShareLink}>
          Create Share Link
        </Button>
      </Box>

      {shareLinks.length > 0 && (
        <TableContainer {...tableProps}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Label</TableCell>
                <TableCell>Sharing</TableCell>
                <TableCell>Shareable Link</TableCell>
                <TableCell>Views</TableCell>
                <TableCell>Last Viewed</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{shareLinks.map(x => renderShareLinkRow(x))}</TableBody>
          </Table>
        </TableContainer>
      )}
    </React.Fragment>
  )
}

function DeleteShareLinkButton({ shareLink, setShareLinks, deleteShareLink }) {
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const handleOpenDeleteDialog = () => {
    setDeleteOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteOpen(false)
  }

  const handleDelete = async () => {
    setDeleting(true)
    setDeleteOpen(false)
    const res = await deleteShareLink(shareLink.id)
    setShareLinks(res)
  }

  return (
    <React.Fragment>
      <IconButton
        aria-label='delete share link'
        color='secondary'
        onClick={handleOpenDeleteDialog}>
        {deleting ? <CircularProgress size={20} color='secondary' /> : <Delete />}
      </IconButton>

      <Dialog open={deleteOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle id='delete-share-link'>{'Delete Share Link'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='delete-share-link-dialog-description'>
            Are you sure you want to delete this share link?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={deleting} onClick={handleCloseDeleteDialog} color='default'>
            Cancel
          </Button>
          <Button disabled={deleting} onClick={handleDelete} color='secondary'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

ShareLinks.propTypes = {
  shareable: PropTypes.bool,
  shareLinks: PropTypes.array,
  setShareLinks: PropTypes.func,
  createShareLink: PropTypes.func,
  deleteShareLink: PropTypes.func,
  shareName: PropTypes.string,
  tableProps: PropTypes.object,
}

ShareLinks.defaultProps = {
  tableProps: {},
}

export default ShareLinks
