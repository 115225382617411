import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles(theme => ({
  loader: {
    textAlign: 'center',
    marginTop: theme.spacing(6),
  },
}))

function Loader({ href, label }) {
  const classes = useStyles()

  return (
    <div className={classes.loader}>
      <CircularProgress />
    </div>
  )
}

export default Loader
