import * as Sentry from '@sentry/react'
import { me } from 'api/auth'
import config from 'config'
import { LoadingContext } from 'contexts/LoadingContext'
import LogRocket from 'logrocket'
import React, { useContext, useEffect, useState } from 'react'

const AuthContext = React.createContext()
const WithAuth = ({ children }) => {
  const { setLoading } = useContext(LoadingContext)
  const [authenticated, setAuthenticated] = useState(false)
  const [authenticating, setAuthenticating] = useState(true)
  const [user, setUser] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const user = await me()
      if (user) {
        setAuthenticated(true)
        setUser(user)

        if (config.SENTRY_DSN) {
          Sentry.setUser({ email: user.email })
        }

        if (config.LOG_ROCKET && user.type === 'reseller') {
          LogRocket.init(config.LOG_ROCKET, {
            dom: {
              textSanitizer: false,
              inputSanitizer: false,
            },

            network: {
              requestSanitizer: request => {
                request.headers['Cookie'] = ''
                request.headers['X-XSRF-TOKEN'] = ''
                request.body = null
                return request
              },

              responseSanitizer: response => {
                response.headers['set-cookie'] = ''
                response.body = null
                return response
              },
            },
          })

          LogRocket.identify(user.id, {
            name: `${user.first_name} ${user.last_name}`,
            email: user.email,

            // Add your own custom user variables here, ie:
            type: user.type,
          })
        }
      }
      setAuthenticating(false)
    }

    fetchData()
  }, [setLoading])

  const hasPermission = permission => {
    return user && user.permissions && user.permissions.indexOf(permission) !== -1
  }

  const hasAccountFeature = feature => {
    return user && user.account_features && user.account_features.indexOf(feature) !== -1
  }

  const defaultContext = {
    authenticating,
    authenticated,
    setAuthenticated,
    user,
    setUser,
    hasPermission,
    hasAccountFeature,
  }
  return <AuthContext.Provider value={defaultContext}>{children}</AuthContext.Provider>
}

export { AuthContext }
export default WithAuth
