import { Box } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import MuiAlert from '@material-ui/lab/Alert'
import { resetPasswordEmail } from 'api/auth'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

function EmailForm() {
  const { loading, setLoading } = useContext(LoadingContext)
  const [messages, setMessages] = useState([])
  const [messagesSeverity, setMessagesSeverity] = useState('info')
  const classes = useStyles()

  useEffect(() => {
    setLoading(false)
  }, [setLoading])

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: value })
  }

  const handleSubmit = async event => {
    event.preventDefault()

    setLoading(true)
    const result = await resetPasswordEmail(values.email)
    if (result.success) {
      setLoading(false)
      setMessages([['', 'We have emailed your password reset link']])
      setMessagesSeverity('success')
    } else {
      setMessages(result.errors)
      setMessagesSeverity('error')
      setLoading(false)
    }
  }

  const [values, setValues] = useState({
    email: '',
  })

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        <Link href='/'>
          <img alt='Logo' src='/Logo100.png' />
        </Link>
        <Box marginTop={4}>
          <Typography component='h1' variant='h5'>
            Reset Password
          </Typography>
        </Box>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <TextField
            variant='standard'
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email Address'
            name='email'
            autoComplete='email'
            autoFocus
            onChange={handleChange.bind(this)}
          />
          {messages.length > 0 && (
            <MuiAlert severity={messagesSeverity}>
              {messages.map((message, index) => {
                return <div key={index}>{message[1]}</div>
              })}
            </MuiAlert>
          )}
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            disabled={loading}
            className={classes.submit}>
            Submit
          </Button>
          <Grid container>
            <Grid item xs>
              <Link component={RouterLink} to='/login' variant='body2'>
                Back to login
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  )
}

export default EmailForm
