import { Box } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { numbers as apiSave } from 'api/quote-builder'
import { QuoteContext } from 'contexts/QuoteContext'
import React, { useContext } from 'react'
import * as Yup from 'yup'
import Title from './components/Title'

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(8),
    margin: 'auto',
    [theme.breakpoints.up('lg')]: {
      width: '60%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '40%',
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}))

function NumbersStep() {
  const { state, dispatch } = useContext(QuoteContext)
  const classes = useStyles()

  const handleChangeNunbers = event => {
    const { name, value } = event.target
    const sanitizedValue = value.replace(/\D+/g, '')
    const newData = { ...state.numbers, [name]: { value: sanitizedValue, error: '' } }
    dispatch({ type: 'SET_NUMBERS', payload: newData })
  }

  const handleChangePorting = event => {
    const { name, value } = event.target
    const sanitizedValue = value.replace(/\D+/g, '')
    const newPorting = { ...state.numbers.porting, [name]: { quantity: sanitizedValue } }
    const newData = { ...state.numbers, porting: newPorting }
    dispatch({ type: 'SET_NUMBERS', payload: newData })
  }

  return (
    <form className={classes.form} noValidate>
      <Paper className={classes.paper}>
        <Container component='main' maxWidth='sm'>
          <Title>Numbers</Title>
          <TextField
            variant='standard'
            margin='normal'
            required
            fullWidth
            id='new_numbers'
            label='New Numbers'
            name='new_numbers'
            autoFocus
            defaultValue={state.numbers.new_numbers.value ?? 0}
            onChange={handleChangeNunbers}
            error={state.numbers.new_numbers.error ? true : false}
            helperText={state.numbers.new_numbers.error}
          />

          <Box marginTop={3}>
            <Title>Porting</Title>
          </Box>

          {Object.keys(state.options.number_porting).map(x => (
            <TextField
              key={x}
              variant='standard'
              margin='normal'
              fullWidth
              id='porting'
              label={state.options.number_porting[x].name}
              name={x}
              defaultValue={
                state.numbers.porting.hasOwnProperty(x)
                  ? state.numbers.porting[x].quantity
                  : 0
              }
              onChange={handleChangePorting}
              error={
                state.numbers.porting.hasOwnProperty(x) && state.numbers.porting[x].error
                  ? true
                  : false
              }
              helperText={
                state.numbers.porting.hasOwnProperty(x) && state.numbers.porting[x].error
              }
            />
          ))}
        </Container>
      </Paper>
    </form>
  )
}

const validate = async (values, dispatch) => {
  try {
    await Yup.object()
      .shape({
        new_numbers: Yup.number()
          .min(0)
          .required()
          .typeError('you must specify a number'),
      })
      .validate(
        {
          new_numbers: values.new_numbers.value ? values.new_numbers.value : 0,
        },
        { abortEarly: false }
      )
    return true
  } catch (error) {
    const errors = {}
    error.inner.map(item => {
      var words = item.message.replace(/([A-Z])/g, ' $1')
      return (errors[item.path] = {
        error: (words.charAt(0).toUpperCase() + words.slice(1)).replace(/_/g, ' '),
      })
    })
    dispatch({ type: 'SET_NUMBERS', payload: { ...values, ...errors } })
    return false
  }
}

const save = async (id, numbers) => {
  return await apiSave(id, {
    new_numbers: numbers.new_numbers.value,
    number_porting: Object.keys(numbers.porting).map(x => ({
      id: x,
      quantity: numbers.porting[x].quantity,
    })),
  })
}

export { validate, save }
export default NumbersStep
