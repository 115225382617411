import { Button, Paper, TextField } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'
import Chip from '@material-ui/core/Chip'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import MuiAlert from '@material-ui/lab/Alert'
import { createShareLink, deleteShareLink } from 'api/library/library'
import { submitForm } from 'api/library/posts'
import SharingLinkList from 'components/share-links/ShareLinks'
import { AuthContext } from 'contexts/AuthContext'
import TextContentRenderer from 'components/TextContentRenderer'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import FileViewer from './FileViewer'

const useStyles = makeStyles(theme => ({
  section: {
    display: 'grid',
    gridGap: theme.spacing(2),
    gridTemplateAreas: '"column-2" "column-1"',

    [theme.breakpoints.up('md')]: {
      gridGap: theme.spacing(4),
      gridTemplateColumns: '8fr 4fr',
    },
  },
  paper: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  paperForm: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  video: {
    width: '100%',
    height: '400px',
  },
  formControl: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))
function PostView({ post, shareable }) {
  const classes = useStyles()
  const { user } = useContext(AuthContext)
  const [viewPost, setViewPost] = useState(post)
  const [shareLabel, setShareLabel] = useState('')
  const [shareType, setShareType] = useState('')
  const [shareFile, setShareFile] = useState('')
  const [shareOpen, setShareOpen] = useState(false)
  const [creatingShareLink, setCreatingShareLink] = useState(false)
  const [shareLinks, setShareLinks] = useState([])

  useEffect(() => {
    if (viewPost.data.hasOwnProperty('share_links')) {
      const links = viewPost.data.share_links
      viewPost.data.files.map(x => x.share_links.map(x => links.push(x)))
      setShareLinks(links)
    }
  }, [viewPost])

  const handleOpenShareDialog = () => {
    setShareOpen(true)
  }

  const handleCloseShareDialog = () => {
    setShareOpen(false)
  }

  const handleChangeShareLabel = event => {
    setShareLabel(event.target.value)
  }

  const handleChangeShareType = event => {
    setShareType(event.target.value)
  }

  const handleChangeShareFile = event => {
    setShareFile(event.target.value)
  }

  const handleCreateShare = async () => {
    setCreatingShareLink(true)
    const shareId = shareType === 'post' ? viewPost.data.id : shareFile
    const res = await createShareLink(shareId, shareType, { label: shareLabel })
    setViewPost(res)
    setCreatingShareLink(false)
    setShareOpen(false)
    setShareLabel('')
  }

  const isShareSubmitDisabled = () => {
    if (creatingShareLink) {
      return true
    }

    if (!shareLabel) {
      return true
    }

    if (!shareType) {
      return true
    }

    if (shareType === 'file' && !shareFile) {
      return true
    }

    return false
  }

  return (
    <Box mb={8}>
      <Typography variant='h3' gutterBottom>
        {viewPost.data.name}
      </Typography>

      <Box mb={2}>
        {viewPost.data.categories.map(cat =>
          user ? (
            <Chip
              key={cat.id}
              clickable
              component={Link}
              to={`/library/categories/${cat.id}`}
              label={cat.name}
            />
          ) : (
            <Chip key={cat.id} label={cat.name} />
          )
        )}
      </Box>

      <Box className={classes.section}>
        {(viewPost.data.content ||
          viewPost.data.image ||
          viewPost.data.youtube_id ||
          user) && (
          <Box>
            <Box>
              {viewPost.data.image && (
                <Box mb={3}>
                  <img
                    style={{ maxWidth: '100%' }}
                    alt='Post feature'
                    src={viewPost.data.image}
                  />
                </Box>
              )}

              {viewPost.data.content && (
                <Paper className={classes.paper}>
                  <TextContentRenderer content={viewPost.data.content} />
                </Paper>
              )}

              {viewPost.data.youtube_id && (
                <Box mb={3}>
                  <iframe
                    title='video'
                    className={classes.video}
                    src={`https://www.youtube.com/embed/${viewPost.data.youtube_id}`}
                    frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen></iframe>
                </Box>
              )}
            </Box>

            {post.data.form && <PostForm postId={post.data.id} form={post.data.form} />}

            {user && shareable && (
              <Box mb={3}>
                <SharingLinkList
                  shareLinks={shareLinks}
                  setShareLinks={setViewPost}
                  createShareLink={handleOpenShareDialog}
                  deleteShareLink={deleteShareLink}
                  shareName={viewPost.data.name}
                  tableProps={{ component: Paper }}
                />
              </Box>
            )}
          </Box>
        )}

        {viewPost.data.files.length > 0 && (
          <FileViewer files={viewPost.data.files} folders={viewPost.data.folders} />
        )}
      </Box>

      <Dialog open={shareOpen} onClose={handleCloseShareDialog}>
        <DialogTitle id='share-link'>{'Create Share Link'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='share-link-dialog-description'>
            Enter a label for the link. This can be anything, for example the name of the
            person this being sent to or the company.
          </DialogContentText>

          <TextField
            autoFocus
            margin='dense'
            id='share_label'
            label='Label'
            type='text'
            required
            value={shareLabel}
            onChange={handleChangeShareLabel}
            fullWidth
          />

          <FormControl variant='standard' className={classes.formControl}>
            <InputLabel id='share_type_label'>What would you like to share?</InputLabel>
            <Select
              labelId='share_type_label'
              id='share_type'
              name='share_type'
              value={shareType}
              onChange={handleChangeShareType}>
              <MenuItem value='post'>Full Post</MenuItem>
              <MenuItem value='file'>Specific File</MenuItem>
            </Select>
          </FormControl>

          {shareType === 'file' && (
            <FormControl variant='standard' className={classes.formControl}>
              <InputLabel id='share_file_label'>Which file?</InputLabel>
              <Select
                labelId='share_file_label'
                id='share_file'
                name='share_file'
                value={shareFile}
                onChange={handleChangeShareFile}>
                {viewPost.data.files.map(x => (
                  <MenuItem key={`share-file-${x.id}`} value={x.id}>
                    {x.filename}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={creatingShareLink}
            onClick={handleCloseShareDialog}
            color='default'>
            Cancel
          </Button>
          <Button
            disabled={isShareSubmitDisabled()}
            onClick={handleCreateShare}
            color='primary'>
            Create Link
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

function PostForm({ postId, form }) {
  const classes = useStyles()

  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState({})
  const [submitted, setSubmitted] = useState(false)
  const [errors, setErrors] = useState([])

  useEffect(() => {
    const newValues = {}

    for (const item of form.fields) {
      newValues[item.name] = ''
    }

    setValues(newValues)
  }, [form.fields])

  const handleChange = e => {
    setErrors([])
    const { name, value } = e.target
    const newValues = { ...values }
    newValues[name] = value
    setValues(newValues)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    const res = await submitForm(postId, values)
    res.success ? setSubmitted(true) : setErrors(res.errors)
    setLoading(false)
  }

  if (submitted) {
    return (
      <Box marginBottom={2}>
        <MuiAlert severity='success'>Form successfully submitted.</MuiAlert>
      </Box>
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      {form.name && (
        <Typography variant='h6' gutterBottom>
          {form.name}
        </Typography>
      )}
      {form.description && (
        <Typography variant='body1' gutterBottom>
          {form.description}
        </Typography>
      )}
      <Paper className={classes.paperForm}>
        {form.fields.map((x, index) => {
          switch (x.type) {
            case 'textfield':
              return (
                <FormTextField
                  key={`form-${index}`}
                  id={x.name}
                  label={x.label}
                  name={x.name}
                  value={values[x.label]}
                  required={x.required}
                  onChange={handleChange}
                />
              )
            case 'textarea':
              return (
                <FormTextArea
                  key={`form-${index}`}
                  id={x.name}
                  label={x.label}
                  name={x.name}
                  value={values[x.label]}
                  required={x.required}
                  onChange={handleChange}
                />
              )
            case 'dropdown':
              return (
                <FormDropdown
                  key={`form-${index}`}
                  label={x.label}
                  dropdownItems={x.option_items}
                  id={x.name}
                  name={x.name}
                  value={values[x.label]}
                  required={x.required}
                  onChange={handleChange}
                />
              )
            case 'multiselect':
              return (
                <FormMultiselect
                  key={`form-${index}`}
                  label={x.label}
                  optionItems={x.option_items}
                  id={x.name}
                  name={x.name}
                  value={values[x.label]}
                  required={x.required}
                  onChange={handleChange}
                />
              )
            default:
              return null
          }
        })}
      </Paper>

      {errors.length > 0 && (
        <Box marginBottom={2}>
          <MuiAlert severity='error'>
            {errors.map(x => (
              <div>{x[1]}</div>
            ))}
          </MuiAlert>
        </Box>
      )}

      <Box marginBottom={5}>
        <Button type='submit' variant='contained' color='primary' disabled={loading}>
          Submit Form
        </Button>
      </Box>
    </form>
  )
}

function FormTextField(props) {
  return <TextField variant='standard' margin='normal' fullWidth {...props} />
}

function FormTextArea(props) {
  return (
    <TextField
      multiline
      rows={5}
      variant='standard'
      margin='normal'
      fullWidth
      {...props}
    />
  )
}

function FormDropdown({ label, dropdownItems, ...props }) {
  const classes = useStyles()

  return (
    <FormControl variant='standard' className={classes.formControl}>
      <InputLabel id='form_dropdown_label'>{label}</InputLabel>
      <Select labelId='form_dropdown_label' {...props}>
        {dropdownItems &&
          dropdownItems.map(x => (
            <MenuItem key={x} value={x}>
              {x}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}

function FormMultiselect({ name, label, optionItems, onChange }) {
  const classes = useStyles()
  const [values, setValues] = useState([])

  const handleChange = e => {
    const newValues = Array.from(values)
    const index = newValues.indexOf(e.target.name)

    if (e.target.checked) {
      if (index === -1) {
        newValues.push(e.target.name)
      }
    } else {
      if (index !== -1) {
        newValues.splice(index, 1)
      }
    }

    setValues(newValues)
    onChange({ target: { name: name, value: newValues.join(', ') } })
  }

  return (
    <Box marginTop={2}>
      <FormControl variant='standard' className={classes.formControl}>
        <FormLabel>{label}</FormLabel>
        <FormGroup>
          {optionItems.map(x => (
            <FormControlLabel
              control={<Checkbox color='primary' onChange={handleChange} name={x} />}
              label={x}
            />
          ))}
        </FormGroup>
      </FormControl>
    </Box>
  )
}

PostView.propTypes = {
  shareable: PropTypes.bool,
}

PostView.defaultProps = {
  shareable: true,
}

export default PostView
