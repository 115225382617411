import config from 'config'
import { getJsonHeaders } from './helpers'

export async function help(type, body) {
  try {
    const res = await fetch(config.API_URL + `/api/help/${type}`, {
      method: 'POST',
      headers: await getJsonHeaders(),
      body: JSON.stringify(body),
    })

    if (res.status === 200) {
      return {
        success: true,
        message: 'Successfully sent message',
      }
    }

    const decoded = await res.json()
    return {
      success: false,
      message: decoded.message,
      errors: Object.entries(decoded.errors).map(error => {
        return error
      }),
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to send message',
      errors: [['', 'Failed to send message']],
    }
  }
}
