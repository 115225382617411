import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { QuoteContext } from 'contexts/QuoteContext'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import React, { useContext, useEffect, useState } from 'react'
import SummaryBox from 'components/quotes/SummaryBox'

const useStyles = makeStyles(theme => ({
  actions: {
    marginTop: theme.spacing(3),
    minHeight: '100px',
  },
  action: {
    width: '100px',
    marginTop: '0',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  button: {
    width: '140px',
    marginTop: '0',
  },
  items: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gridGap: theme.spacing(3),
  },
  item: {
    position: 'relative',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    textAlign: 'center',
  },
  box: {
    width: '200px',
    height: '200px',
    marginTop: theme.spacing(2),
    color: '#CCC',
    overflow: 'hidden',
    margin: 'auto',
  },
  formControl: {
    width: '100px',
  },
  videoIcon: {
    position: 'absolute',
    left: '0',
    top: '0',
  },
}))

function HardwareItem({ options, hardware, category, item, onChangeHardware }) {
  const classes = useStyles()
  const [selected, setSelected] = useState(false)
  const [quantity, setQuantity] = useState('')
  const [paymentType, setPaymentType] = useState('upfront')
  const [loading, setLoading] = useState(true)
  const { state } = useContext(QuoteContext)

  useEffect(() => {
    setLoading(true)
    if (category in hardware && hardware[category].length) {
      const itemIndex = hardware[category].findIndex(obj => obj.id === item)
      if (itemIndex !== -1) {
        setQuantity(hardware[category][itemIndex].quantity)
        setPaymentType(hardware[category][itemIndex].payment_type)
        setSelected(true)
      }
    }
    setLoading(false)
  }, [category, hardware, item, setQuantity, setSelected])

  const handleClick = () => {
    setSelected(true)
  }

  const handleChangeQuantity = event => {
    const value = parseInt(event.target.value)
    setQuantity(value > 0 ? value : '')
    handleChangeHardware(value, paymentType)
  }

  const handleChangePaymentType = event => {
    const value = event.target.value
    setPaymentType(value)
    handleChangeHardware(quantity, value)
  }

  const handleBlur = () => {
    if (!quantity) {
      setSelected(false)
    }
  }

  const handleChangeHardware = (qty, pt) => {
    onChangeHardware(category, item, qty, pt)
  }

  return (
    <SummaryBox
      header={options[category].items[item].name}
      video_url={options[category].items[item].video_url}
      interop_url={options[category].items[item].interop_url}
      image_url={options[category].items[item].image_url}
      image_thumb_url={options[category].items[item].image_thumb_url}
      description={options[category].items[item].description}>
      {loading && <CircularProgress />}

      {!loading && !selected && (
        <Button
          variant='contained'
          color='primary'
          style={{ marginTop: '16px' }}
          className={classes.button}
          onClick={handleClick}>
          Select
        </Button>
      )}

      {!loading && selected && (
        <div onBlur={handleBlur}>
          <TextField
            className={classes.action}
            variant='standard'
            margin='normal'
            required
            autoFocus
            value={quantity}
            onChange={handleChangeQuantity}
            id='quantity'
            label='Quantity'
            name='quantity'
            type='number'
          />

          {state.services.system_type.value === 'cloud' &&
            options[category].items[item].monthly_rrp > 0 && (
              <FormControl variant='standard' className={classes.formControl}>
                <InputLabel id='payment_type_label'>Payment</InputLabel>
                <Select
                  labelId='payment_type_label'
                  id='payment_type'
                  name='payment_type'
                  value={paymentType}
                  disabled={!quantity}
                  onChange={handleChangePaymentType}
                  label='Payment Type'>
                  <MenuItem value='upfront'>Upfront</MenuItem>
                  <MenuItem value='monthly'>Monthly</MenuItem>
                </Select>
              </FormControl>
            )}
        </div>
      )}
    </SummaryBox>
  )
}

export default HardwareItem
