import { Button, Typography } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import { makeStyles } from '@material-ui/core/styles'
import { ArrowBack } from '@material-ui/icons'
import { get } from 'api/blog'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: '1280px',
    margin: 'auto',
  },
  media: {
    height: 0,
    paddingTop: '22.5%', // 40:9
  },
}))

function Post(props) {
  const { slug } = useParams()
  const { setLoading } = useContext(LoadingContext)
  const [post, setPost] = useState({
    title: '',
    content: '',
    featured_media: { url: '' },
  })

  const classes = useStyles()

  useEffect(() => {
    const fetchData = async () => {
      const res = await get(slug)
      setPost(res)
      setLoading(false)
    }

    fetchData()
  }, [slug, setLoading])

  if (!post.title) return null

  return (
    <div>
      <Card className={classes.card}>
        <div style={{ position: 'absolute' }}>
          <Button
            onClick={() => {
              props.history.goBack()
            }}
            size='small'
            variant='contained'
            color='primary'
            disableElevation
            className={classes.button}
            startIcon={<ArrowBack />}>
            Back
          </Button>
        </div>

        <CardMedia
          className={classes.media}
          image={post.featured_media.url}
          title={post.title}
        />
        <CardContent>
          <Typography
            variant='h5'
            component='h2'
            dangerouslySetInnerHTML={{ __html: post.title }}
          />
          <Typography
            variant='body2'
            component='p'
            dangerouslySetInnerHTML={{ __html: post.content }}
          />
        </CardContent>
      </Card>
    </div>
  )
}

export default Post
