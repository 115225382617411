import {
  Avatar,
  Button,
  Card,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core'
import { SortableListFieldItem } from 'components/SortableListField'
import { SortableListField } from 'components/SortableListField'
import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useState } from 'react'

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: '1em',
  },
}))

function ReorderPosts({ posts, onChange, onCancel }) {
  const classes = useStyles()
  const [orderedPosts, setOrderedPosts] = useState(Array.from(posts.data))

  const handleReorder = value => {
    setOrderedPosts(value)
  }

  const handleSubmit = async value => {
    var result = orderedPosts.reduce(function (prev, next, index) {
      prev[next.id] = index
      return prev
    }, {})
    onChange(result)
  }

  return (
    <>
      <SortableListField onChange={handleReorder}>
        {orderedPosts.map((post, index) => (
          <SortableListFieldItem key={post.id} index={index} item={post}>
            <Card className={classes.card}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar src={post.image} />
                </ListItemAvatar>
                <ListItemText>{post.name}</ListItemText>
              </ListItem>
            </Card>
          </SortableListFieldItem>
        ))}
      </SortableListField>
      <Button onClick={handleSubmit}>Save Order</Button>
      <Button onClick={onCancel}>Cancel</Button>
    </>
  )
}

export default ReorderPosts
