import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { home, search as apiSearch } from 'api/my-library/library'
import CreateButtons from 'components/library/home/CreateButtons'
import Home from 'components/library/home/Home'
import { LoadingContext } from 'contexts/LoadingContext'
import debounce from 'lodash.debounce'
import React, { useContext, useEffect, useRef, useState } from 'react'

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: theme.breakpoints.width('lg'),
    margin: 'auto',
  },
}))

function LibraryHome(props) {
  const classes = useStyles()
  const { setLoading } = useContext(LoadingContext)
  const [data, setData] = useState()
  const [search, setSearch] = useState('')
  const [searchResults, setSearchResults] = useState()

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      setData(await home())
      setLoading(false)
    }

    fetchData()
  }, [setLoading])

  const handleChangeSearch = async event => {
    setLoading(true)
    const searchTerm = event.target.value
    setSearch(searchTerm)

    if (searchTerm === '') {
      setLoading(false)
      setSearchResults()
    } else {
      debouncedSearch(searchTerm)
    }
  }

  const debouncedSearch = useRef(
    debounce(async searchTerm => {
      setSearchResults(await apiSearch(searchTerm))
      setLoading(false)
    }, 1000)
  ).current

  if (!data) {
    return null
  }

  return (
    <div className={classes.container}>
      <Typography variant='h3' gutterBottom>
        MyLibrary
      </Typography>
      {data.categories.all.length === 0 ? (
        <Box pt={2}>
          <p>MyLibrary is a place where you can store your own documents and videos.</p>
          <p>
            To get started you will need to create a category. Once created, you can
            either create more categories or create posts.
          </p>
          <p>
            A post can contain an image, text, video and file downloads. You'll be able to
            easily search across all your categories and posts to find what you need.
          </p>

          <Box mb={3} mt={5} display='flex'>
            <CreateButtons
              categoryLink='/my-library/categories/create'
              postLink='/my-library/posts/create'
              canCreatePost={data.categories.all.length > 0}
            />
          </Box>
        </Box>
      ) : (
        <React.Fragment>
          <Box mb={3} display='flex'>
            <CreateButtons
              categoryLink='/my-library/categories/create'
              postLink='/my-library/posts/create'
              canCreatePost={data.categories.all.length > 0}
            />
          </Box>
          <Home
            data={data}
            searchResults={searchResults}
            search={search}
            handleChangeSearch={handleChangeSearch}
            history={props.history}
            linkPrefix='my-library'
          />
        </React.Fragment>
      )}
    </div>
  )
}

export default LibraryHome
