import { deleteCategory, list, update } from 'api/my-library/categories'
import CategoryForm from 'components/library/categories/CategoryForm'
import EditModeActions from 'components/library/categories/EditModeActions'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'

function CategoryEdit({ category, setCategory, toggleEditMode, history }) {
  const { setLoading } = useContext(LoadingContext)
  const { addToast } = useToasts()
  const [values, setValues] = useState(category)
  const [categories, setCategories] = useState({})
  const [errors, setErrors] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const res = await list(1000)
      setCategories({ ...res, data: res.data.filter(x => x.id !== category.id) })
      setLoading(false)
    }

    fetchData()
  }, [category, setLoading])

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: value })
  }

  const handleSave = async () => {
    setLoading(true)
    const res = await update(category.id, {
      name: values.name,
      description: values.description,
      featured: values.featured,
      parent_category_id: values.parent_category ? values.parent_category.id : null,
    })

    setLoading(false)

    if (res.success) {
      setCategory(values)
      addToast('Updated Successfully', { appearance: 'success' })
      toggleEditMode()
    } else {
      setErrors(res.errors)
    }
  }

  const handleDelete = async () => {
    setLoading(true)
    const res = await deleteCategory(category.id)
    setLoading(false)

    if (res) {
      addToast('Deleted Successfully', { appearance: 'success' })
      history.push(`/my-library`)
    } else {
      setErrors([['', 'Error deleting category']])
    }
  }

  if (!categories.data) {
    return null
  }

  return (
    <React.Fragment>
      <CategoryForm
        values={values}
        errors={errors}
        handleChange={handleChange}
        categories={categories}
      />

      <EditModeActions handleSave={handleSave} handleDelete={handleDelete} />
    </React.Fragment>
  )
}

export default CategoryEdit
