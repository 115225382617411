import { Box, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'block',
    textDecoration: 'none',
    padding: theme.spacing(4, 6, 3, 6),
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(4, 8, 3, 8),
      textAlign: 'left',
    },
  },
  logo: {
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(6),
      textAlign: 'right',
    },
  },
  logoImage: {
    width: '200px',
    [theme.breakpoints.up('lg')]: {
      width: '300px',
      marginBottom: '-24px',
    },
  },
}))

function CandioBanner() {
  const classes = useStyles()
  return (
    <Paper
      className={classes.paper}
      component='a'
      target='_blank'
      rel='noopener noreferrer'
      href='https://candio.co.uk'>
      <Box className={classes.logo}>
        <img
          className={classes.logoImage}
          alt='Candio'
          src='https://candio.co.uk/wp-content/uploads/2020/06/Candio-logo-with-padding.png'
          title='Candio'
        />

        <div
          style={{
            fontFamily: '"Montserrat", sans-serif',
            fontWeight: 700,
            fontSize: 16,
          }}>
          White label digital services
        </div>
      </Box>

      <p
        style={{
          fontFamily: '"Montserrat", sans-serif',
        }}>
        Candio helps technology resellers, utility companies, membership organisations,
        professional services businesses and marketing agencies deliver white-label
        digital services that add tangible value to end users and recurring revenue to our
        partners
      </p>
    </Paper>
  )
}

export default CandioBanner
