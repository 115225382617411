import QuoteContext from 'contexts/QuoteContext'
import React from 'react'
import Builder from './Builder'

export default function Create(props) {
  return (
    <QuoteContext>
      <Builder {...props} />
    </QuoteContext>
  )
}
