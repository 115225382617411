import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles(theme => ({
  inProgressChip: {
    background: theme.palette.warning.main,
    color: 'white',
  },
  completeChip: {
    background: theme.palette.primary.main,
    color: 'white',
  },
  acceptedChip: {
    background: theme.palette.success.main,
    color: 'white',
  },
  rejectedChip: {
    background: theme.palette.error.main,
    color: 'white',
  },
}))

const getStatusChipClass = status => {
  switch (status) {
    case 'IN_PROGRESS':
      return 'inProgressChip'
    case 'COMPLETED':
      return 'completeChip'
    case 'ACCEPTED':
      return 'acceptedChip'
    case 'REJECTED':
      return 'rejectedChip'
    default:
      return ''
  }
}

const getDisplayStatus = status => {
  return status.replace(/_/g, ' ')
}

function NumberPortStatusChip({ hubStatus, status }) {
  const classes = useStyles()

  const getLabel = () => {
    if (hubStatus === status) {
      return getDisplayStatus(hubStatus)
    } else {
      return `${getDisplayStatus(hubStatus)} / ${getDisplayStatus(status)}`
    }
  }

  return (
    <Chip label={getLabel()} className={`${classes[getStatusChipClass(hubStatus)]}`} />
  )
}

export default NumberPortStatusChip
