import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthContext } from './../../contexts/AuthContext'

function querystring(name, url = window.location.href) {
  name = name.replace(/[[]]/g, '\\$&')

  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i')
  const results = regex.exec(url)

  if (!results) {
    return null
  }
  if (!results[2]) {
    return ''
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

function UnauthenticatedRoute({ component: C, ...routeProps }) {
  const redirect = querystring('redirect')
  const { authenticating, authenticated } = useContext(AuthContext)
  return (
    !authenticating && (
      <Route
        {...routeProps}
        render={props =>
          !authenticated ? (
            <C {...props} />
          ) : (
            <Redirect to={redirect === '' || redirect === null ? '/' : redirect} />
          )
        }
      />
    )
  )
}

export default UnauthenticatedRoute
