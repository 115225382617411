import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import SummaryBox from './SummaryBox'

const useStyles = makeStyles(theme => ({
  summaryBlue: {
    backgroundColor: theme.palette.primary.main,
  },
  transparentText: {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  summaryText: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    color: '#FFF',
  },
}))

const getPounds = value => {
  return (
    (value / 100)
      .toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      .split('.')[0] ?? ''
  )
}

const getPence = value => {
  return (
    (value / 100)
      .toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      .split('.')[1] ?? '00'
  )
}

function QuoteSummaryWithLeasing({ summaryData }) {
  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <React.Fragment>
      <SummaryBox
        className={classes.summaryBlue}
        header={t('Quote Monthly')}
        text={
          <>
            <Typography variant='h2'>
              {'£' + getPounds(summaryData.summary.totals.monthly_rrp)}
            </Typography>
            <Typography variant='body1'>
              .{getPence(summaryData.summary.totals.monthly_rrp)}
            </Typography>
            <Typography className={classes.transparentText} variant='caption'>
              For contract term of {summaryData.contract_length} months
            </Typography>
          </>
        }
        variant='text'>
        <Typography className={classes.summaryText} variant='body1'>
          Monthly charges.
        </Typography>
      </SummaryBox>

      {summaryData.summary.hasOwnProperty('leasing') && (
        <React.Fragment>
          {summaryData.summary.leasing.initial !==
            summaryData.summary.leasing.monthly && (
            <SummaryBox
              className={classes.summaryBlue}
              header='Initial Lease Rental'
              text={
                <>
                  <Typography variant='h2'>
                    {'£' + getPounds(summaryData.summary.leasing.initial)}
                  </Typography>
                  <Typography variant='body1'>
                    .{getPence(summaryData.summary.leasing.initial)}
                  </Typography>
                  <Typography className={classes.transparentText} variant='caption'>
                    Your first rental payment
                  </Typography>
                </>
              }
              variant='text'>
              <Typography className={classes.summaryText} variant='body1'>
                Your first lease rental payment total.
              </Typography>
            </SummaryBox>
          )}

          <SummaryBox
            className={classes.summaryBlue}
            header='Monthly Lease Rental'
            text={
              <>
                <Typography variant='h2'>
                  {'£' + getPounds(summaryData.summary.leasing.monthly)}
                </Typography>
                <Typography variant='body1'>
                  .{getPence(summaryData.summary.leasing.monthly)}
                </Typography>
                <Typography className={classes.transparentText} variant='caption'>
                  Rental payments
                </Typography>
              </>
            }
            variant='text'>
            <Typography className={classes.summaryText} variant='body1'>
              Your lease rental payment amounts.
            </Typography>
          </SummaryBox>

          <SummaryBox
            className={classes.summaryBlue}
            header='Total Monthly'
            text={
              <>
                <Typography variant='h2'>
                  {'£' +
                    getPounds(
                      summaryData.summary.leasing.monthly +
                        summaryData.summary.totals.monthly_rrp
                    )}
                </Typography>
                <Typography variant='body1'>
                  .
                  {getPence(
                    summaryData.summary.leasing.monthly +
                      summaryData.summary.totals.monthly_rrp
                  )}
                </Typography>
                <Typography className={classes.transparentText} variant='caption'>
                  Rental payments
                </Typography>
              </>
            }
            variant='text'>
            <Typography className={classes.summaryText} variant='body1'>
              Monthly + lease monthly rental
            </Typography>
          </SummaryBox>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default QuoteSummaryWithLeasing
