import { makeStyles } from '@material-ui/core/styles'
import GettingStartedComponent from 'components/GettingStarted'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect } from 'react'

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: theme.breakpoints.width('lg'),
    margin: 'auto',
  },
}))

function GettingStarted() {
  const classes = useStyles()
  const { setLoading } = useContext(LoadingContext)

  useEffect(() => {
    setLoading(false)
  })

  return (
    <div className={classes.container}>
      <GettingStartedComponent />
    </div>
  )
}

export default GettingStarted
