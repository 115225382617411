import { Button, Typography } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { updateCoveringLetterQuestion, deleteCoveringLetterQuestion } from 'api/proposals'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}))

function CoveringLetterQuestion({ index, defaultQuestion, defaultAnswers, setSnackbar }) {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [question, setQuestion] = useState(defaultQuestion)
  const [answers, setAnswers] = useState(defaultAnswers)
  const [deleted, setDeleted] = useState(false)

  const handleChangeQuestion = event => {
    const newQuestion = { ...question }
    newQuestion.content = event.target.value
    setQuestion(newQuestion)
  }

  const handleChangeAnswer = (index, event) => {
    const newAnswers = Array.from(answers)
    newAnswers.splice(index, 1, event.target.value)
    setAnswers(newAnswers)
  }

  const AddAnswer = () => {
    const newAnswers = Array.from(answers)
    newAnswers.push('')
    setAnswers(newAnswers)
  }

  const handleSubmit = async () => {
    setLoading(true)

    const newAnswers = answers.filter(x => x !== '')
    setAnswers(newAnswers)

    const res = await updateCoveringLetterQuestion({
      id: question.id,
      content: question.content,
      answers: newAnswers,
    })
    setLoading(false)

    if (res.success) {
      const newQuestion = { ...question }
      newQuestion.id = res.data.id
      setQuestion(newQuestion)
      setSnackbar('success', res.message)
    } else {
      setSnackbar('error', res.message)
    }
  }

  const handleDelete = async () => {
    setLoading(true)
    if (question.id) {
      const res = await deleteCoveringLetterQuestion(question.id)
      setLoading(false)
      if (res) {
        setSnackbar('success', 'Delete successful')
        setDeleted(true)
      } else {
        setSnackbar('error', 'Delete failed')
      }
    } else {
      setDeleted(true)
    }
  }

  if (deleted) {
    return null
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color='textSecondary' gutterBottom>
          Question {index + 1}
        </Typography>
        <TextField
          variant='standard'
          margin='normal'
          required
          fullWidth
          value={question.content}
          id={`question_${question.id}`}
          name={`question_${question.id}`}
          label='Question'
          placeholder='Type your question here'
          disabled={loading}
          onChange={handleChangeQuestion}
          style={{ marginBottom: '2rem' }}
        />

        {answers.map((x, index) => (
          <TextField
            variant='standard'
            margin='normal'
            fullWidth
            value={x}
            id={`answer_${index}`}
            name={`answer_${index}`}
            label={`Answer ${index + 1}`}
            placeholder='Type an answer here'
            disabled={loading}
            onChange={event => handleChangeAnswer(index, event)}
          />
        ))}
      </CardContent>
      <CardActions>
        <Button
          disabled={loading}
          variant='contained'
          color='primary'
          onClick={handleSubmit}>
          Save Question
        </Button>
        <Button
          style={{ marginRight: 'auto' }}
          disabled={loading}
          onClick={AddAnswer}
          size='small'>
          Add Another Answer
        </Button>
        <Button disabled={loading} onClick={handleDelete} size='small' color='secondary'>
          Delete Question
        </Button>
      </CardActions>
    </Card>
  )
}

CoveringLetterQuestion.propTypes = {
  index: PropTypes.number,
  defaultQuestion: PropTypes.object,
  defaultAnswers: PropTypes.object,
  setSnackbar: PropTypes.func,
}

export default CoveringLetterQuestion
