import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Fab from '@material-ui/core/Fab'
import { IconButton } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Snackbar from '@material-ui/core/Snackbar'
import { makeStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import MuiAlert from '@material-ui/lab/Alert'
import { deleteDiscountCode, enableToggle, list } from 'api/discount-codes'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  pagination: {
    marginBottom: '60px',
  },
  fab: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  },
  toggle: {
    marginLeft: theme.spacing(1),
  },
}))

function List(props) {
  const classes = useStyles()
  const { loading, setLoading } = useContext(LoadingContext)
  const [enabled, setEnabled] = useState(true)
  const [discountCodes, setDiscountCodes] = useState({})
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [hideContent, setHideContent] = useState(true)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = useState('info')
  const [snackbarMessage, setSnackbarMessage] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const data = await list()
      setDiscountCodes(data)
      setEnabled(data.meta.enabled)
      setLoading(false)
      setHideContent(false)
    }

    if (props.location.state) {
      setSnackbarSeverity(props.location.state.severity)
      setSnackbarMessage(props.location.state.message)
      setSnackbarOpen(true)
      props.history.replace()
    }

    fetchData()
  }, [setLoading, props.location, props.history])

  const handleChangePage = async (event, newPage) => {
    setLoading(true)
    setDiscountCodes(
      await list(parseInt(discountCodes.meta.per_page), parseInt(newPage + 1))
    )
    setLoading(false)
  }

  const handleChangeRowsPerPage = async event => {
    setLoading(true)
    setDiscountCodes(await list(parseInt(event.target.value, 10)))
    setLoading(false)
  }

  const handleOpenDeleteDialog = id => {
    setDeleteId(id)
    setDeleteOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteOpen(false)
  }

  const handleDelete = async id => {
    setLoading(true)
    const success = await deleteDiscountCode(id)

    if (success) {
      setDiscountCodes(await list(parseInt(discountCodes.meta.per_page)))
      setSnackbarSeverity('success')
      setSnackbarMessage('Successfully deleted discount code')
    } else {
      setSnackbarSeverity('error')
      setSnackbarMessage('Failed to delete discount code')
    }

    setDeleteOpen(false)
    setLoading(false)
    setSnackbarOpen(true)
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  const handleChangeEnabled = async () => {
    setLoading(true)
    const res = await enableToggle(!enabled)
    if (res.success) {
      setEnabled(!enabled)
    }
    setLoading(false)
  }

  if (hideContent) {
    return null
  }

  if (!enabled) {
    return (
      <div>
        <Typography variant='h3' gutterBottom>
          Discount Codes
          <span className={classes.toggle}>
            {loading && <CircularProgress size={20} />}
            {!loading && (
              <Switch
                checked={enabled}
                onChange={handleChangeEnabled}
                name='enabled'
                color='primary'
              />
            )}
          </span>
        </Typography>

        <Typography variant='body1' gutterBottom>
          <em>Discount codes are currently disabled.</em>
        </Typography>
      </div>
    )
  }

  return (
    <div>
      <Typography variant='h3' gutterBottom>
        Discount Codes
        <span className={classes.toggle}>
          {loading && <CircularProgress size={20} />}
          {!loading && (
            <Switch
              checked={enabled}
              onChange={handleChangeEnabled}
              name='enabled'
              color='primary'
            />
          )}
        </span>
      </Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Discount Percentage</TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {discountCodes.data.map(row => (
              <TableRow key={row.id}>
                <TableCell scope='row'>{row.code}</TableCell>
                <TableCell scope='row'>{row.value}</TableCell>
                <TableCell align='right'>
                  <IconButton
                    aria-label='edit'
                    color='default'
                    component={NavLink}
                    to={`/discount-codes/update/${row.id}`}>
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    aria-label='delete'
                    color='secondary'
                    onClick={() => handleOpenDeleteDialog(row.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={classes.pagination}
        rowsPerPageOptions={[5, 15, 25]}
        component='div'
        count={discountCodes.meta.total}
        rowsPerPage={parseInt(discountCodes.meta.per_page)}
        page={discountCodes.meta.current_page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog open={deleteOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle id='alert-dialog-title'>{'Discount Code Delete'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to delete this discount code?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleCloseDeleteDialog} color='primary'>
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={() => handleDelete(deleteId)}
            color='secondary'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <MuiAlert
          elevation={6}
          variant='filled'
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      <Fab
        className={classes.fab}
        color='secondary'
        aria-label='add'
        component={NavLink}
        to='/discount-codes/create'>
        <AddIcon />
      </Fab>
    </div>
  )
}

export default List
