import Link from '@material-ui/core/Link'
import { list } from 'api/blog'
import React, { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import ItemCarousel from './components/ItemCarousel'
import ItemContent from './components/ItemContent'
import Loader from './components/Loader'
import OneColumn from './components/OneColumn'

function News() {
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      setItems(await list(9))
      setLoading(false)
    }

    fetchData()
  }, [setLoading])

  if (loading) {
    return <Loader />
  }

  return (
    <OneColumn>
      <ItemContent>
        {items.data.length ? (
          <ItemCarousel>
            {items.data.map((item, i) => (
              <Item key={i} item={item} />
            ))}
          </ItemCarousel>
        ) : (
          <div>
            <em>No recent news</em>
          </div>
        )}
      </ItemContent>
    </OneColumn>
  )
}

function Item(props) {
  return (
    <div style={{ height: '100%' }}>
      <div
        style={{ maxHeight: '76px', overflow: 'hidden', textOverflow: 'ellipsis' }}
        dangerouslySetInnerHTML={{ __html: props.item.title }}
      />
      <Link component={RouterLink} to={`/blog`}>
        View All
      </Link>{' '}
      |{' '}
      <Link component={RouterLink} to={`/blog/${props.item.slug}`}>
        Read more
      </Link>
    </div>
  )
}

export default News
