import { makeStyles } from '@material-ui/core/styles'
import { list } from 'api/training'
import React, { useEffect, useState } from 'react'
import ItemButton from './components/ItemButton'
import ItemCarousel from './components/ItemCarousel'
import ItemContent from './components/ItemContent'
import Loader from './components/Loader'
import OneColumn from './components/OneColumn'
import TwoColumns from './components/TwoColumns'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  dateBox: {
    backgroundColor: '#FFF',
    color: '#000',
    height: '80px',
    minWidth: '80px',
    padding: theme.spacing(2),
  },
  description: {
    marginRight: '16px',
    maxHeight: '76px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  month: {
    fontSize: '0.9rem',
  },
  date: {
    fontSize: '1.2rem',
  },
}))

function Training({ link }) {
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      setItems(await list(10))
      setLoading(false)
    }

    fetchData()
  }, [setLoading])

  if (loading) {
    return <Loader />
  }

  if (!items.data.length) {
    return (
      <OneColumn>
        <ItemContent>
          <div>
            <em>No upcoming training</em>
          </div>
        </ItemContent>
      </OneColumn>
    )
  }

  return (
    <div style={{ marginTop: '20px' }}>
      <ItemCarousel>
        {items.data.map((item, i) => (
          <TwoColumns key={i}>
            <ItemContent>
              <Item item={item} />
            </ItemContent>

            <ItemContent>
              {item.url && <ItemButton href={item.url} label='Book Training' />}
            </ItemContent>
          </TwoColumns>
        ))}
      </ItemCarousel>
    </div>
  )
}

function Item(props) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.description}>
        <p style={{ margin: 0 }}>{props.item.title}</p>
      </div>
      <div className={classes.dateBox}>
        <div className={classes.month}>{props.item.month.substr(0, 3)}</div>
        <div className={classes.date}>
          <strong>{props.item.date}</strong>
        </div>
      </div>
    </div>
  )
}

export default Training
