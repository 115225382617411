import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles(theme => ({
  button: {
    height: '80px',
    width: '120px',
    color: '#FFF',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}))

function ItemButton({ href, label, onClick }) {
  const classes = useStyles()

  return (
    <Button
      className={classes.button}
      component={Link}
      href={href}
      onClick={onClick}
      target='_blank'
      type='submit'
      variant='contained'
      color='primary'>
      {label}
    </Button>
  )
}

export default ItemButton
