import {
  Box,
  Button,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Folder, Description } from '@material-ui/icons'
import Delete from '@material-ui/icons/Delete'
import MuiAlert from '@material-ui/lab/Alert'
import { AuthContext } from 'contexts/AuthContext'
import { useFileManager } from 'contexts/FileManagerContext'
import React, { useContext, useState, useEffect } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import config from 'config'

const maxFileSize = config.MY_LIBRARY_MAX_FILE_SIZE

function DraggableFolder({ children, folder, index, onDelete }) {
  return (
    <Draggable
      key={`sort-item-${folder.id}`}
      draggableId={`draggable-folder-${index + 1}`}
      folder={folder.name}
      index={index}>
      {provided => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}>
          <Droppable key={index} droppableId={folder.name} type='FILE'>
            {provided => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                key={`${folder.id}-${index}`}>
                <ListItem>
                  <ListItemAvatar>
                    <Folder></Folder>
                  </ListItemAvatar>
                  <ListItemText>{folder.name}</ListItemText>
                  {children.length === 0 && (
                    <IconButton onClick={e => onDelete(folder)}>
                      <Delete color='secondary' />
                    </IconButton>
                  )}
                </ListItem>
                {children}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      )}
    </Draggable>
  )
}

function DraggableFile({ file, index, onSetDisplayType, onDelete }) {
  return (
    <Draggable
      key={`sort-item-${file.folder_name}-${index}-${file.id}`}
      draggableId={`draggable-file-${file.folder_name}-${index + 1}`}
      fileId={file.id}
      index={index}>
      {provided => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}>
          <ListItem key={`files-${index}-${file.id}`}>
            <ListItemText primary={file.filename} />

            <Tooltip
              placement='top'
              title='Display the file as a large tile or as part of a small list'>
              <Select
                id='display'
                name='display'
                label='Display'
                onChange={e => onSetDisplayType(file, e.target.value)}
                value={file.display_type}>
                <MenuItem value='large'>Large</MenuItem>
                <MenuItem value='small'>Small</MenuItem>
              </Select>
            </Tooltip>

            <IconButton onClick={e => onDelete(file.id)}>
              <Delete color='secondary' />
            </IconButton>
          </ListItem>
        </div>
      )}
    </Draggable>
  )
}

function FileEditor({
  onAddFile,
  onDeleteFile,
  onAddFolder,
  onDeleteFolder,
  onSetFileDisplayType,
  onSortFiles,
  onSortFolders,
}) {
  const { user } = useContext(AuthContext)
  const {
    state,
    addFolder,
    updateFiles,
    setFileDisplayType,
    dispatch,
    deleteFolder,
    updateFolders,
  } = useFileManager()
  const [newFiles, setNewFiles] = useState([])
  const [fileError, setFileError] = useState(null)
  const [newFolderName, setNewFolderName] = useState('')
  const [files, setFiles] = useState(state.files)

  useEffect(() => {
    setFiles(state.files)
  }, [state, setFiles])

  const handleDeleteFile = id => {
    dispatch({ type: 'DELETE_FILE', payload: { id: id } })
    onDeleteFile(id)
  }

  const handleSetFileDisplayType = (file, value) => {
    onSetFileDisplayType(file.id, value)
    setFileDisplayType(file, value)
  }

  const handleAddFile = event => {
    setFileError('')
    const file = event.target.files[0]

    if (user.type === 'reseller' && file.size > maxFileSize) {
      setFileError(`Max file size is ${maxFileSize / 1024 / 1024}MB`)
      return
    }

    const newFilesValue = [
      ...newFiles,
      {
        id: '',
        display_type: 'large',
        filename: file.name,
        file: file,
        folder_name: 'root',
      },
    ]
    onAddFile(newFilesValue)
    setNewFiles(newFilesValue)
  }

  const handleUpdateNewFile = (filename, attr, value) => {
    const newFilesValue = newFiles.map(newFile =>
      newFile.filename === filename ? { ...newFile, [attr]: value } : newFile
    )
    onAddFile(newFilesValue)
    setNewFiles(newFilesValue)
  }

  const onDragEnd = event => {
    if (event.type === 'FILE') {
      const sourceFolder = state.folders.find(x => x.name === event.source.droppableId)

      let sourceFolderFiles = files.filter(x => x.folder_name === sourceFolder.name)
      let destinationFolderFiles = []

      const [removed] = sourceFolderFiles.splice(event.source.index, 1)

      if (event.destination) {
        const destinationFolder = state.folders.find(
          x => x.name === event.destination.droppableId
        )

        if (destinationFolder.name === sourceFolder.name) {
          sourceFolderFiles.splice(event.destination.index, 0, removed)
        } else {
          // file is moving to another folder
          destinationFolderFiles = files.filter(
            x => x.folder_name === destinationFolder.name
          )

          destinationFolderFiles.splice(event.destination.index, 0, {
            ...removed,
            folder_name: destinationFolder.name,
          })

          updateFiles(
            destinationFolderFiles.map((x, index) => ({ ...x, sort_order: index }))
          )
        }
      }
      updateFiles(sourceFolderFiles.map((x, index) => ({ ...x, sort_order: index })))

      onSortFiles(
        sourceFolderFiles
          .concat(destinationFolderFiles)
          .map((x, index) => ({ ...x, sort_order: index }))
      )
    } else if (event.type === 'FOLDER') {
      let newFolders = state.folders.filter(x => x.id !== '')
      const [removed] = newFolders.splice(event.source.index, 1)
      newFolders.splice(event.destination.index, 0, removed)

      updateFolders(newFolders.map((x, index) => ({ ...x, sort_order: index })))
      onSortFolders(newFolders.map((x, index) => ({ ...x, sort_order: index })))
    }
  }

  return (
    <Box>
      <Typography variant='h6' gutterBottom>
        Files
      </Typography>

      <input
        style={{ display: 'none' }}
        id='file_add'
        name='file_add'
        type='file'
        onChange={handleAddFile}
      />
      <label
        htmlFor='file_add'
        className='MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary'>
        <div>Add File Download</div>
      </label>

      {fileError && (
        <Box mt={2}>
          <MuiAlert severity='error'>{fileError}</MuiAlert>
        </Box>
      )}

      {newFiles.map((newFile, index) => (
        <ListItem key={index}>
          <ListItemText>{newFile.filename}</ListItemText>
          <Tooltip
            placement='top'
            title='Display the file as a large tile or as part of a small list'>
            <Select
              id={`display-${index}`}
              name={`display-${index}`}
              label='Display'
              onChange={e =>
                handleUpdateNewFile(newFile.filename, 'display_type', e.target.value)
              }
              value={newFile.display_type}>
              <MenuItem value='large'>Large</MenuItem>
              <MenuItem value='small'>Small</MenuItem>
            </Select>
          </Tooltip>
        </ListItem>
      ))}

      {state.files.length > 0 && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='root' type='FILE'>
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef} key={`root-0`}>
                <ListItem>
                  <ListItemAvatar>
                    <Description />
                  </ListItemAvatar>
                  <ListItemText>Not in folder</ListItemText>
                </ListItem>
                {files
                  .filter(file => file.folder_name === 'root')
                  .filter(file => file.deleted !== true)
                  .map((file, index) => (
                    <DraggableFile
                      file={file}
                      key={index}
                      index={index}
                      onSetDisplayType={handleSetFileDisplayType}
                      onDelete={handleDeleteFile}
                    />
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>

          <Droppable droppableId='folders' type='FOLDER'>
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef} key='root'>
                {state.folders.length > 0 &&
                  state.folders
                    .filter(x => x.id !== '' && x.deleted !== true)
                    .map((folder, index) => (
                      <DraggableFolder
                        key={index}
                        folder={folder}
                        onDelete={() => {
                          deleteFolder(folder)
                          onDeleteFolder(folder.id)
                        }}
                        index={index}>
                        {files
                          .filter(file => file.folder_name === folder.name)
                          .filter(file => file.deleted !== true)
                          .map((file, index) => (
                            <DraggableFile
                              file={file}
                              key={index}
                              index={index}
                              onSetDisplayType={handleSetFileDisplayType}
                              onDelete={handleDeleteFile}
                            />
                          ))}
                      </DraggableFolder>
                    ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>

          <TextField
            inputProps={{ maxLength: 32 }}
            value={newFolderName}
            onChange={e => {
              setNewFolderName(e.target.value)
            }}
            placeholder='Add new folder'
          />
          <Button
            onClick={() => {
              addFolder(newFolderName)
              onAddFolder(newFolderName)
              setNewFolderName('')
            }}>
            Add
          </Button>
        </DragDropContext>
      )}
    </Box>
  )
}

export default FileEditor
