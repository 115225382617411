import { HelpContext } from 'contexts/HelpContext'
import React, { useContext, useEffect } from 'react'
import ItemButton from './components/ItemButton'
import ItemContent from './components/ItemContent'
import TwoColumns from './components/TwoColumns'

function Help() {
  const { setHelpOpen, setHelpType } = useContext(HelpContext)

  useEffect(() => {
    setHelpType('sales-hub')
  })

  const handleOpen = () => {
    setHelpOpen(true)
  }

  return (
    <>
      <TwoColumns>
        <ItemContent>Got a question for one of our sales team?</ItemContent>

        <ItemContent>
          <ItemButton onClick={handleOpen} label='Ask Question' />
        </ItemContent>
      </TwoColumns>
    </>
  )
}

export default Help
