import GeographicPortContext from 'contexts/GeographicPortContext'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import GeographicPortContent from './GeographicPortContent'

function GeographicPort(props) {
  const { setLoading } = useContext(LoadingContext)
  const { id } = useParams()

  useEffect(() => {
    setLoading(false)
  }, [setLoading])

  return (
    <GeographicPortContext id={id}>
      <GeographicPortContent />
    </GeographicPortContext>
  )
}

export default GeographicPort
