import { TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

function TableSearchBox({ disabled, label, defaultValue, handleChange }) {
  return (
    <TextField
      fullWidth
      variant='outlined'
      id='search'
      label={label}
      defaultValue={defaultValue}
      onChange={handleChange}
      disabled={disabled}
    />
  )
}

TableSearchBox.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  handleChange: PropTypes.func,
}

TableSearchBox.defaultProps = {
  disabled: false,
  defaultValue: '',
}

export default TableSearchBox
