import { Box } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React from 'react'
import DataBox from './components/DataBox'

const useStyles = makeStyles(theme => ({
  headlineStats: {
    display: 'grid',
    gridGap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridGap: theme.spacing(6),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
  },
  largeText: {
    fontSize: '2rem',
  },
}))

function SalesAgentSummary({ results }) {
  const classes = useStyles()

  return (
    <Box>
      <div className={classes.headlineStats}>
        <DataBox title='Total Profit'>
          <div className={classes.largeText}>
            £{(results.total_profit / 100).toLocaleString()}
          </div>
        </DataBox>

        <DataBox title='Total Commission'>
          <div className={classes.largeText}>
            £{(results.total_commission / 100).toLocaleString()}
          </div>
        </DataBox>

        <DataBox title='Commission Percent'>
          <div className={classes.largeText}>{results.commission_percent}%</div>
        </DataBox>
      </div>

      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Deal Name</TableCell>
              <TableCell>Business Name</TableCell>
              <TableCell>Won</TableCell>
              <TableCell>Profit</TableCell>
              <TableCell>Commission</TableCell>
              <TableCell>Telemarketer Commission</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.quotes.map(row => (
              <TableRow key={row.id}>
                <TableCell scope='row'>{row.deal_name}</TableCell>
                <TableCell scope='row'>{row.business_name}</TableCell>
                <TableCell scope='row'>{row.won_at}</TableCell>
                <TableCell scope='row'>£{(row.profit / 100).toLocaleString()}</TableCell>
                <TableCell scope='row'>
                  £{(row.commission / 100).toLocaleString()}
                </TableCell>
                <TableCell scope='row'>
                  £{(row.telemarketer_commission / 100).toLocaleString()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default SalesAgentSummary
