import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { GeographicPortContext } from 'contexts/GeographicPortContext'
import React, { useContext } from 'react'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(4),
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
}))

function CustomerDetails() {
  const classes = useStyles()

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5' gutterBottom>
        Enter Customer Details
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <CustomerTextField
            name='company_name'
            label='Company Name'
            textFieldProps={{ required: true }}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <CustomerTextField
            name='first_name'
            label='First Name'
            textFieldProps={{ required: true }}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <CustomerTextField
            name='last_name'
            label='Last Name'
            textFieldProps={{ required: true }}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <CustomerTextField name='email' label='Email' />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <CustomerTextField
            name='contact_number'
            label='Contact Number'
            numbersOnly={true}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <CustomerTextField name='job_title' label='Job Title' />
        </Grid>
      </Grid>
    </Paper>
  )
}

function CustomerTextField({ name, label, numbersOnly, textFieldProps }) {
  const { state, dispatch } = useContext(GeographicPortContext)

  const handleChange = event => {
    const { name, value } = event.target
    const sanitizedValue = numbersOnly ? value.replace(/\D+/g, '') : value

    dispatch({
      type: 'SET_CUSTOMER',
      payload: { key: name, value: sanitizedValue },
    })
  }

  return (
    <TextField
      variant='standard'
      margin='normal'
      fullWidth
      id={name}
      label={label}
      name={name}
      value={state.customer[name].value}
      onChange={handleChange}
      error={state.errors.hasOwnProperty(name) ? true : false}
      helperText={state.errors.hasOwnProperty(name) ? state.errors[name] : ''}
      {...textFieldProps}
    />
  )
}

export default CustomerDetails
