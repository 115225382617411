import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { ChevronRight, CloudDownloadOutlined } from '@material-ui/icons'
import Link from 'components/Link'
import React from 'react'
import Post from './Post'

const useStyles = makeStyles(theme => ({
  searchResults: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
}))

function SearchResults({ data, linkPrefix }) {
  const classes = useStyles()

  const hasSearchResults = () => {
    return (
      Object.values(data).filter(x => {
        return x.length > 0
      }).length > 0
    )
  }

  return (
    <React.Fragment>
      <Typography variant='h5' gutterBottom>
        Search Results
      </Typography>

      {data ? (
        <React.Fragment>
          {data.posts.length > 0 && (
            <Box mb={2}>
              <p>
                <strong>Posts</strong>
              </p>
              <Paper className={classes.searchResults}>
                {data.posts.map(x => (
                  <Post key={x.id} post={x} linkPrefix={linkPrefix} />
                ))}
              </Paper>
            </Box>
          )}

          {data.file_count > 0 && (
            <Box mb={2}>
              <p>
                <strong>Files</strong>
              </p>
              <Paper className={classes.searchResults}>
                {data.files.map(x => (
                  <File key={x.id} file={x} linkPrefix={linkPrefix} />
                ))}
              </Paper>
            </Box>
          )}

          {data.categories.length > 0 && (
            <Box mb={2}>
              <p>
                <strong>Categories</strong>
              </p>
              <Paper className={classes.searchResults}>
                {data.categories.map(x => (
                  <Category key={x.id} category={x} linkPrefix={linkPrefix} />
                ))}
              </Paper>
            </Box>
          )}

          {!hasSearchResults() && <p>No results found</p>}
        </React.Fragment>
      ) : (
        <Box display='flex' justifyContent='center' p={3}>
          <CircularProgress />
        </Box>
      )}
    </React.Fragment>
  )
}

function Category({ category, linkPrefix }) {
  return (
    <Link to={`/${linkPrefix}/categories/${category.id}`}>
      <Box display='flex' alignItems='center' mb={1}>
        <ChevronRight /> {category.name}
      </Box>
    </Link>
  )
}

function File({ file, linkPrefix }) {
  return (
    <Link to={`/${linkPrefix}/posts/${file.post.id}`}>
      <Box display='flex' alignItems='center' mb={1}>
        <CloudDownloadOutlined style={{ marginRight: '0.5rem' }} /> {file.post.name} /{' '}
        {file.filename}
      </Box>
    </Link>
  )
}

export default SearchResults
