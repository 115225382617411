import {
  Box,
  FormControlLabel,
  FormControl,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { GeographicPortContext } from 'contexts/GeographicPortContext'
import { useContext } from 'react'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(4),
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  portDateTypeLabel: {
    marginBottom: theme.spacing(2),
  },
}))

function PciCompliance() {
  const classes = useStyles()
  const { state, dispatch } = useContext(GeographicPortContext)

  const handleChange = event => {
    dispatch({
      type: 'SET_PCI_COMPLIANCE',
      payload: event.target.value === '1' ? true : false,
    })
  }

  const getValue = value => {
    if (value === true) {
      return '1'
    } else if (value === false) {
      return '0'
    } else {
      return ''
    }
  }

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5' gutterBottom>
        iPECS PCI
      </Typography>

      <Box mb={4}>
        <FormControl component='fieldset'>
          <FormLabel component='legend' className={classes.portDateTypeLabel}>
            Is this port request for the iPECS PCI service?
          </FormLabel>
          <RadioGroup
            value={getValue(state.pci_compliance.value)}
            onChange={handleChange}>
            <FormControlLabel value={'1'} control={<Radio />} label='Yes' />
            <FormControlLabel value={'0'} control={<Radio />} label='No' />
          </RadioGroup>
        </FormControl>
      </Box>
    </Paper>
  )
}

export default PciCompliance
