import { Box, Typography } from '@material-ui/core'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import TextField from '@material-ui/core/TextField'
import CostPriceField from 'components/CostPriceField'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      style={{ width: '100%' }}
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function ProductCode({ name, isOwner, defaultValue, handleChange }) {
  return (
    <TextField
      variant='standard'
      margin='normal'
      fullWidth
      disabled={!isOwner}
      id={name}
      label='Product Code'
      name={name}
      defaultValue={defaultValue}
      onChange={handleChange}
    />
  )
}

function UpfrontRRP({ name, defaultValue, handleChange }) {
  const { t } = useTranslation()

  return (
    <TextField
      variant='standard'
      margin='normal'
      required
      fullWidth
      type='number'
      step='0.01'
      id={name}
      label={t('Upfront RRP')}
      name={name}
      InputLabelProps={{
        shrink: true,
      }}
      defaultValue={defaultValue}
      onChange={handleChange}
    />
  )
}

function MonthlyRRP({ name, defaultValue, handleChange }) {
  const { t } = useTranslation()

  return (
    <TextField
      variant='standard'
      margin='normal'
      required
      fullWidth
      type='number'
      step='0.01'
      id={name}
      label={t('Monthly RRP')}
      name={name}
      InputLabelProps={{
        shrink: true,
      }}
      defaultValue={defaultValue}
      onChange={handleChange}
    />
  )
}

function Section({ type, isOwner, values, setValues, handleChange }) {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <ProductCode
        name={`${type}_product_code`}
        isOwner={isOwner}
        defaultValue={values[`${type}_product_code`].value}
        handleChange={handleChange}
      />
      <CostPriceField
        values={values}
        setValues={setValues}
        valuesProperty={`${type}_upfront_cost_price`}
        label={t('Upfront Cost')}
      />
      <CostPriceField
        values={values}
        setValues={setValues}
        valuesProperty={`${type}_monthly_cost_price`}
        label={t('Monthly Cost')}
      />
      <UpfrontRRP
        name={`${type}_upfront_rrp`}
        defaultValue={values[`${type}_upfront_rrp`].value}
        handleChange={handleChange}
      />
      <MonthlyRRP
        name={`${type}_monthly_rrp`}
        defaultValue={values[`${type}_monthly_rrp`].value}
        handleChange={handleChange}
      />

      {values.has_contract_pricing && (
        <React.Fragment>
          <Typography variant='subtitle1' style={{ marginTop: '3rem' }}>
            3 Year Contract Pricing
          </Typography>
          <CostPriceField
            values={values}
            setValues={setValues}
            valuesProperty={`${type}_upfront_cost_price_contract_3_year`}
            label={t('Upfront Cost')}
          />
          <CostPriceField
            values={values}
            setValues={setValues}
            valuesProperty={`${type}_monthly_cost_price_contract_3_year`}
            label={t('Monthly Cost')}
          />
          <UpfrontRRP
            name={`${type}_upfront_rrp_contract_3_year`}
            defaultValue={values[`${type}_upfront_rrp_contract_3_year`].value}
            handleChange={handleChange}
          />
          <MonthlyRRP
            name={`${type}_monthly_rrp_contract_3_year`}
            defaultValue={values[`${type}_monthly_rrp_contract_3_year`].value}
            handleChange={handleChange}
          />

          <Typography variant='subtitle1' style={{ marginTop: '3rem' }}>
            5 Year Contract Pricing
          </Typography>
          <CostPriceField
            values={values}
            setValues={setValues}
            valuesProperty={`${type}_upfront_cost_price_contract_5_year`}
            label={t('Upfront Cost')}
          />
          <CostPriceField
            values={values}
            setValues={setValues}
            valuesProperty={`${type}_monthly_cost_price_contract_5_year`}
            label={t('Monthly Cost')}
          />
          <UpfrontRRP
            name={`${type}_upfront_rrp_contract_5_year`}
            defaultValue={values[`${type}_upfront_rrp_contract_5_year`].value}
            handleChange={handleChange}
          />
          <MonthlyRRP
            name={`${type}_monthly_rrp_contract_5_year`}
            defaultValue={values[`${type}_monthly_rrp_contract_5_year`].value}
            handleChange={handleChange}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

function FormPricingByResellerTypedummy({
  isOwner,
  values,
  setValues,
  handleChange,
  ...props
}) {
  const [tabValue, setTabValue] = useState(0)

  const handleChangeTab = (_, newValue) => {
    setTabValue(newValue)
  }

  return (
    <React.Fragment>
      <Tabs
        indicatorColor='primary'
        value={tabValue}
        onChange={handleChangeTab}
        variant='fullWidth'
        {...props}>
        <Tab label='Wholesale' />
        <Tab label='VSP' />
        <Tab label='SP' />
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        <Section
          type='wholesale'
          isOwner={isOwner}
          values={values}
          setValues={setValues}
          handleChange={handleChange}
        />
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <Section
          type='vsp'
          isOwner={isOwner}
          values={values}
          setValues={setValues}
          handleChange={handleChange}
        />
      </TabPanel>

      <TabPanel value={tabValue} index={2}>
        <Section
          type='sp'
          isOwner={isOwner}
          values={values}
          setValues={setValues}
          handleChange={handleChange}
        />
      </TabPanel>
    </React.Fragment>
  )
}

FormPricingByResellerTypedummy.propTypes = {
  isOwner: PropTypes.bool,
  values: PropTypes.object,
  setValues: PropTypes.func,
  handleChange: PropTypes.func,
}

export default FormPricingByResellerTypedummy
