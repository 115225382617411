import { generateDocument } from 'api/quote-builder'
import { useEffect } from 'react'
import { useState } from 'react'

const defaultState = {
  company_number: '',
  business_type: '',
  company_phone: '',
  company_fax: '',
  company_mobile: '',
  ten_or_more_employees: false,
  billing_email_address: '',
  position: '',
  address_first_line: '',
  address_town: '',
  address_city: '',
  address_county: '',
  address_postcode: '',
  dd_account_holder: '',
  dd_bank_name: '',
  dd_bank_manager_name: '',
  dd_bank_postcode: '',
  dd_bank_address_1: '',
  dd_bank_address_2: '',
  dd_acc_no: '',
  dd_sort: '',
  invoice_address: '',
  owner_dob: '',
  date_established: '',
  numbers: [],
  include_leasing: false,
}

export function useDocument(quote) {
  const numberFields = [
    { label: 'Number', name: 'number' },
    { label: 'BT account number', name: 'bt_account_number' },
    { label: 'Postcode', name: 'postcode' },
    { label: 'No of lines', name: 'no_of_lines' },
    { label: 'Type', name: 'type' },
  ]

  const [state, setState] = useState(() => {
    const docState = localStorage.getItem(`quote-${quote.id}-doc-state`)
    return docState
      ? JSON.parse(docState)
      : {
          ...defaultState,
        }
  })

  const [submitting, setSubmitting] = useState(false)

  const [errors, setErrors] = useState({})
  const [errorMessage, setErrorMessage] = useState('')

  const handleFieldChange = e => {
    const newState = { ...state, [e.target.name]: e.target.value }
    setState(newState)
  }

  useEffect(() => {
    localStorage.setItem(`quote-${quote.id}-doc-state`, JSON.stringify(state))
  }, [state, quote.id])

  const handleAddNumber = () => {
    const newState = {
      ...state,
      numbers: [
        ...state.numbers,
        {
          number: '',
          bt_account_number: '',
          postcode: '',
          no_of_lines: 1,
          type: '',
        },
      ],
    }
    setState(newState)
  }

  const handleEditNumber = (name, value, index) => {
    const newState = {
      ...state,
      numbers: state.numbers.map((n, i) => (i === index ? { ...n, [name]: value } : n)),
    }
    setState(newState)
  }

  const handleDeleteNumber = index => {
    const newState = {
      ...state,
      numbers: state.numbers.filter((n, i) => i !== index),
    }
    setState(newState)
  }

  const handleSubmit = async id => {
    setErrors({})
    setErrorMessage('')
    setSubmitting(true)
    const res = await generateDocument(id, state)
    setSubmitting(false)

    if (!res.success) {
      if (res.body.errors) {
        setErrors(res.body.errors)
        setErrorMessage('Please complete all required fields')
      } else {
        setErrorMessage(
          res.body.message ?? 'We encountered an error sending the documents'
        )
      }
      return false
    }

    localStorage.removeItem(`quote-${quote.id}-doc-state`)

    return true
  }

  return {
    state,
    errors,
    errorMessage,
    submitting,
    handleFieldChange,
    handleSubmit,
    handleAddNumber,
    handleEditNumber,
    handleDeleteNumber,
    numberFields,
  }
}
