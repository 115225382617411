import { IconButton, TextField, Typography } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import Tooltip from '@material-ui/core/Tooltip'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import OptionsIcon from '@material-ui/icons/Settings'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { updateSettings } from 'api/quotes'
import React, { useState } from 'react'
import debounce from 'lodash.debounce'

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  colourPicker: {
    cursor: 'pointer',
    border: 'none',
    margin: '0 5px',
    background: 'none',
    '&::-webkit-color-swatch-wrapper': {
      padding: '2px 5px',
    },
    '&::-webkit-color-swatch': {
      border: '0px solid #000',
      borderRadius: '10px',
    },
  },
}))

const quoteableLicences = [
  { id: 'monthly', label: 'Monthly' },
  { id: 'contract_3_year', label: '3 Year' },
  { id: 'contract_5_year', label: '5 Year' },
  { id: 'lifetime', label: 'Lifetime' },
]

function QuoteOptions({ initialValues }) {
  const classes = useStyles()

  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [expanded, setExpanded] = React.useState('display')

  const [values, setValues] = useState(initialValues)

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: value })
  }

  const handleChangeLicence = event => {
    const { name, checked } = event.target
    const newValues = Array.from(values.quotable_licences)

    if (checked) {
      newValues.push(name)
    } else {
      const index = newValues.indexOf(name)
      newValues.splice(index, 1)
    }

    setValues({ ...values, quotable_licences: newValues })
  }

  const handleChangeLabels = event => {
    const { name, value } = event.target
    const newValues = { ...values.quote_labels }
    newValues[name] = value
    setValues({ ...values, quote_labels: newValues })
  }

  const handleChangeProfitDisplay = () => {
    setValues({ ...values, profit_display: !values.profit_display })
  }

  const handleChangeBundleLifetime = () => {
    setValues({ ...values, bundle_lifetime: !values.bundle_lifetime })
  }

  const handleChangeLeaseBoxesOnSummary = () => {
    setValues({
      ...values,
      show_lease_boxes_on_summary: !values.show_lease_boxes_on_summary,
    })
  }

  const handleChangeContractTermOnQuote = () => {
    setValues({
      ...values,
      display_contract_term_on_quote: !values.display_contract_term_on_quote,
    })
  }

  const handleChangeCoveringLetterEnabled = () => {
    setValues({
      ...values,
      covering_letter_enabled: !values.covering_letter_enabled,
    })
  }

  const handleChangeQuoteColor = debounce(value => {
    setValues({
      ...values,
      highlight_color_primary: value,
    })
  }, 500)

  const handleChangeSummaryPriceEditing = () => {
    setValues({
      ...values,
      summary_price_editing: !values.summary_price_editing,
    })
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleChangeExpanded = panel => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const getSystemTypeQuestion = () =>
    // temporarily disable onprem ucp quotes
    null
  /*<FormControl variant='standard' className={classes.formControl}>
      <Tooltip
        title={<React.Fragment>Limit the systems that can be quoted.</React.Fragment>}>
        <InputLabel id='quotable_systems_label'>Quotable Systems</InputLabel>
      </Tooltip>
      <Select
        labelId='quotable_systems_label'
        id='quotable_systems'
        name='quotable_systems'
        value={values.quotable_systems}
        onChange={handleChange}
        label='Quotable Systems'>
        <MenuItem value='all'>Cloud & UCP</MenuItem>
        <MenuItem value='cloud'>Cloud Only</MenuItem>
      </Select>
    </FormControl>*/

  const handleSubmit = async () => {
    setLoading(true)
    await updateSettings(values)
    setOpen(false)
    setLoading(false)
  }

  return (
    <>
      <Tooltip placement='top' title='Quote settings'>
        <IconButton aria-label='settings' color='default' onClick={handleOpen}>
          <OptionsIcon />
        </IconButton>
      </Tooltip>

      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth='sm'>
        <DialogTitle id='alert-dialog-title'>Quote Settings</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <Accordion
              elevation={0}
              expanded={expanded === 'display'}
              onChange={handleChangeExpanded('display')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} id='display'>
                <Typography>Display</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <FormControl variant='standard' className={classes.formControl}>
                    <Tooltip
                      title={
                        <React.Fragment>
                          The quote summary page can display in external or internal mode.
                          Internal gives extra details and allows prices to be changed. It
                          can be toggled in the top bar with the{' '}
                          <VisibilityIcon fontSize='inherit' /> button.
                        </React.Fragment>
                      }>
                      <InputLabel id='default_display_label'> Default Display</InputLabel>
                    </Tooltip>
                    <Select
                      labelId='default_display_label'
                      id='default_display'
                      name='default_display'
                      value={values.default_display}
                      onChange={handleChange}
                      label='Default Display'>
                      <MenuItem value='External'>External</MenuItem>
                      <MenuItem value='Internal'>Internal</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl variant='standard' className={classes.formControl}>
                    <Tooltip
                      title={
                        <React.Fragment>
                          On the quote summary page the user can download a costing sheet.
                          This will download as a CSV or PDF based on this setting.
                        </React.Fragment>
                      }>
                      <InputLabel id='costing_sheet_type_label'>
                        {' '}
                        Costing Sheet Type
                      </InputLabel>
                    </Tooltip>
                    <Select
                      labelId='costing_sheet_type_label'
                      id='costing_sheet_type'
                      name='costing_sheet_type'
                      value={values.costing_sheet_type}
                      onChange={handleChange}
                      label='Costing Sheet Type'>
                      <MenuItem value='csv'>CSV</MenuItem>
                      <MenuItem value='pdf'>PDF</MenuItem>
                    </Select>
                  </FormControl>

                  <Tooltip
                    title={
                      <React.Fragment>
                        If enabled the "internal" displays and downloadable CSV will
                        display cost and profit information for non-admin users.
                      </React.Fragment>
                    }>
                    <FormControlLabel
                      className={classes.formControl}
                      control={
                        <Switch
                          checked={values.profit_display}
                          onChange={handleChangeProfitDisplay}
                          name='profit_display'
                          color='primary'
                        />
                      }
                      label='Display Profit/Cost (for non-admin users)'
                    />
                  </Tooltip>

                  <FormControlLabel
                    className={classes.formControl}
                    control={
                      <>
                        <Switch
                          checked={values.summary_price_editing}
                          onChange={handleChangeSummaryPriceEditing}
                          name='summary_price_editing'
                          color='primary'
                        />
                        <div
                          style={{
                            width: '50px',
                            height: '50px',
                          }}
                        />
                      </>
                    }
                    label='Allow price editing on quote summary (for non-admin users)'
                  />

                  <FormControlLabel
                    className={classes.formControl}
                    control={
                      <Switch
                        checked={values.show_lease_boxes_on_summary}
                        onChange={handleChangeLeaseBoxesOnSummary}
                        name='show_lease_boxes_on_summary'
                        color='primary'
                      />
                    }
                    label='Separate boxes on the quote summary for lease values'
                  />

                  <FormControlLabel
                    className={classes.formControl}
                    control={
                      <Switch
                        checked={values.display_contract_term_on_quote}
                        onChange={handleChangeContractTermOnQuote}
                        name='display_contract_term_on_quote'
                        color='primary'
                      />
                    }
                    label='Contract term on quote downloads'
                  />

                  <FormControlLabel
                    className={classes.formControl}
                    control={
                      <Switch
                        checked={values.covering_letter_enabled}
                        onChange={handleChangeCoveringLetterEnabled}
                        name='covering_letter_enabled'
                        color='primary'
                      />
                    }
                    label='Enable covering letters on proposals'
                  />

                  <FormControlLabel
                    className={classes.formControl}
                    control={
                      <input
                        className={classes.colourPicker}
                        value={values.highlight_color_primary ?? '#2196f3'}
                        onChange={e => handleChangeQuoteColor(e.target.value)}
                        type='color'
                        id='pdf-color'
                      />
                    }
                    label='Quote highlight color'
                  />
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion
              elevation={0}
              expanded={expanded === 'systems'}
              onChange={handleChangeExpanded('systems')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} id='systems'>
                <Typography>Systems</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  {getSystemTypeQuestion()}
                  <FormControl component='fieldset' className={classes.formControl}>
                    <FormLabel component='legend'>Licence Options</FormLabel>
                    <FormGroup>
                      {quoteableLicences.map(x => (
                        <FormControlLabel
                          key={x.id}
                          control={
                            <Checkbox
                              checked={values.quotable_licences.indexOf(x.id) !== -1}
                              onChange={handleChangeLicence}
                              name={x.id}
                            />
                          }
                          label={x.label}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion
              elevation={0}
              expanded={expanded === 'labels'}
              onChange={handleChangeExpanded('labels')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} id='labels'>
                <Typography>Labels</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <TextField
                    variant='standard'
                    margin='normal'
                    fullWidth
                    id='customer_details'
                    label='Step 1 (Customer Details)'
                    name='customer_details'
                    value={values.quote_labels.customer_details}
                    onChange={handleChangeLabels}
                    inputProps={{ maxLength: 30 }}
                  />

                  <TextField
                    variant='standard'
                    margin='normal'
                    fullWidth
                    id='services'
                    label='Step 2 (System Details)'
                    name='services'
                    value={values.quote_labels.services}
                    onChange={handleChangeLabels}
                    inputProps={{ maxLength: 30 }}
                  />

                  <TextField
                    variant='standard'
                    margin='normal'
                    fullWidth
                    id='hardware'
                    label='Step 3 (Devices, Apps & Integrations)'
                    name='hardware'
                    value={values.quote_labels.hardware}
                    onChange={handleChangeLabels}
                  />

                  <TextField
                    variant='standard'
                    margin='normal'
                    fullWidth
                    id='network'
                    label='Step 4 (Network & Engineering)'
                    name='network'
                    value={values.quote_labels.network}
                    onChange={handleChangeLabels}
                    inputProps={{ maxLength: 30 }}
                  />

                  <TextField
                    variant='standard'
                    margin='normal'
                    fullWidth
                    id='numbers'
                    label='Step 5 (Numbers)'
                    name='numbers'
                    value={values.quote_labels.numbers}
                    onChange={handleChangeLabels}
                    inputProps={{ maxLength: 30 }}
                  />

                  <TextField
                    variant='standard'
                    margin='normal'
                    fullWidth
                    id='expenditure'
                    label='Step 6 (Expenditure)'
                    name='expenditure'
                    value={values.quote_labels.expenditure}
                    onChange={handleChangeLabels}
                    inputProps={{ maxLength: 30 }}
                  />

                  <TextField
                    variant='standard'
                    margin='normal'
                    fullWidth
                    id='network'
                    label='Step 7 (Payment)'
                    name='network'
                    value={values.quote_labels.payment}
                    onChange={handleChangeLabels}
                    inputProps={{ maxLength: 30 }}
                  />
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion
              elevation={0}
              expanded={expanded === 'pricing'}
              onChange={handleChangeExpanded('pricing')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} id='pricing'>
                <Typography>Pricing</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <Tooltip
                    title={
                      <React.Fragment>
                        If you lifetime licence product is setup with a monthly cost,
                        toggling this on will mean that the monthly cost (multiplied by
                        the contract length) is bundled into the upfront cost.
                      </React.Fragment>
                    }>
                    <FormControlLabel
                      className={classes.formControl}
                      control={
                        <Switch
                          checked={values.bundle_lifetime}
                          onChange={handleChangeBundleLifetime}
                          name='bundle_lifetime'
                          color='primary'
                        />
                      }
                      label='Bundle monthly lifetime licence into upfront price'
                    />
                  </Tooltip>
                </div>
              </AccordionDetails>
            </Accordion>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={() => handleSubmit()}
            color='primary'
            autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default QuoteOptions
