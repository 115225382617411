import { makeStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import React, { useEffect, useState } from 'react'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  switch: {
    marginBottom: theme.spacing(1),
  },
  notSelected: {
    opacity: '0.5',
  },
}))

function Page({
  pdf,
  pageNum,
  selectedPageNum,
  scale,
  selected,
  toggleSelected,
  totalSelected,
}) {
  const classes = useStyles()
  const [canvasUrl, setCanvasUrl] = useState(null)

  const handleToggleSelected = () => {
    toggleSelected(pageNum, !selected)
  }

  useEffect(() => {
    const getPage = async () => {
      const page = await pdf.getPage(pageNum)
      const viewport = page.getViewport({ scale: scale })
      const { width, height } = viewport
      const canvas = document.createElement('canvas')
      canvas.width = width
      canvas.height = height
      canvas.className = 'page'
      await page.render({
        canvasContext: canvas.getContext('2d'),
        viewport,
      }).promise

      setCanvasUrl(canvas.toDataURL())
    }

    getPage()
  }, [pageNum, pdf, scale])

  if (!canvasUrl) {
    return null
  }

  return (
    <div className={classes.container}>
      <div>
        <Switch
          disabled={selected && totalSelected === 1}
          className={classes.switch}
          checked={selected}
          color='primary'
          onChange={handleToggleSelected}
          name='selected'
        />
        {selectedPageNum > 0 && <>Page {selectedPageNum}</>}
      </div>
      <img
        className={`${!selected ? classes.notSelected : ''}`}
        src={canvasUrl}
        alt='PDF page'
      />
    </div>
  )
}

export default Page
